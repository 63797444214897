export type Temp = {
  id: number;
  value: string;
};

export const temp = [
  {
    id: 1,
    value: '1번옵션',
  },
  {
    id: 2,
    value: '2번옵션',
  },
  {
    id: 3,
    value: '3번옵션',
  },
  {
    id: 4,
    value: '4번옵션',
  },
];
