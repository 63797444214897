import * as S from '@styles/components/TableViewStyle';
import CTable from '@components/Common/CTable/CTable';
import { TableColumnTypes } from '@typedef/components/Common/CTable/table.column.types';
import { EmployeeTypes } from '@typedef/components/Setup/Employee/employee.types';

type Props = {
  columns: TableColumnTypes<EmployeeTypes>[];
  tableData: EmployeeTypes[];
  insertEmployee: () => void;
  updateEmployee: (idx?: number) => void;
  deleteEmployee: () => Promise<void>;
};

const SetupEmployee = ({
  columns,
  tableData,
  insertEmployee,
  updateEmployee,
  deleteEmployee,
}: Props) => {
  return (
    <S.Main>
      <S.TopBtns>
        <h3>전체 {tableData.length}</h3>
        <S.Btns>
          <S.Btn type='button' onClick={insertEmployee} className='active'>
            직원등록
          </S.Btn>
          <S.Btn type='button' onClick={() => updateEmployee()}>
            수정
          </S.Btn>
          <S.Btn type='button' onClick={deleteEmployee}>
            삭제
          </S.Btn>
        </S.Btns>
      </S.TopBtns>
      <CTable columns={columns} tableData={tableData} />
    </S.Main>
  );
};

export default SetupEmployee;
