import { Main, Split, Bottoms } from "@styles/components/ModalBodyStyle";
import * as S from "@styles/components/FormStyle";
import CSelect from "@components/Common/CSelect/CSelect";
import QRCode from "react-qr-code";
import { ShopDeviceTypes } from "@typedef/components/Inventory/shop.device.types";
import { TelecomTypes } from "@typedef/components/Telecom/telecom.types";
import { DeviceInfoTypes } from "@typedef/components/Device/device.info.types";
import { DeviceTypes } from "@typedef/components/Device/device.types";
import Loading from "@components/Common/Loading/Loading";
import { SelectDefaultTypes } from "@typedef/components/Common/CSelect/select.default.types";
import { volumeUnits } from "@libs/volume";
import CDatePicker from "@components/Common/CDatePicker/CDatePicker";
import { formatTimezone } from "@libs/commonFuncs";
import { ClientHistoryTypes } from "@typedef/components/Client/client.history.types";
import styled from "styled-components";

type Props = {
  loading: boolean;
  type: "phone" | "etc";
  inputs: Partial<ShopDeviceTypes>;
  clientHistory: ClientHistoryTypes[];
  onChangeInputs: (e: { target: HTMLInputElement }) => void;
  onChangeSelect: (name: string, selected: any) => void;
  telecoms: TelecomTypes[];
  devices: DeviceTypes[];
  colors: DeviceInfoTypes[];
  volume: {
    num: number;
    unit: "MB" | "GB" | "TB";
  };
  onChangeDate: (name: string, date: Date) => void;
  update: () => Promise<void>;
  isAgency: boolean;
};

const ShopDeviceUpdate = ({
  loading,
  type,
  inputs,
  clientHistory,
  onChangeInputs,
  onChangeSelect,
  telecoms,
  devices,
  colors,
  volume,
  onChangeDate,
  update,
  isAgency,
}: Props) => {
  return (
    <Main width={70}>
      {loading && <Loading />}
      <S.Row>
        <Split
          width={66.666}
          borderL
          style={{
            borderRight: "1px solid #eee",
          }}
        >
          <S.Grid>
            <S.InputBox>
              <p>상태</p>
              <S.Input
                defaultValue={inputs.status?.name}
                placeholder="상태"
                disabled
              />
            </S.InputBox>

            {type === "phone" && (
              <S.InputBox>
                <p>통신사</p>
                <CSelect<TelecomTypes>
                  options={telecoms}
                  value={(telecom: TelecomTypes) => telecom.telecomId}
                  label={(telecom: TelecomTypes) => telecom.name}
                  defaultValue={telecoms.filter(
                    (telecom) => telecom.telecomId === inputs.telecom?.idx
                  )}
                  func={(selected: TelecomTypes) =>
                    onChangeSelect("telecom", selected)
                  }
                  clearable={false}
                  disabled={!isAgency}
                  placeholder="통신사"
                />
              </S.InputBox>
            )}
            {type === "etc" && (
              <S.InputBox>
                <p>제조사</p>
                <S.Input
                  name="maker_nm"
                  defaultValue={inputs?.maker_nm}
                  onBlur={onChangeInputs}
                  disabled={!isAgency}
                  placeholder="제조사"
                />
              </S.InputBox>
            )}
            <S.InputBox>
              <p>모델명</p>
              {type === "phone" && (
                <CSelect<DeviceTypes>
                  options={devices}
                  value={(device: DeviceTypes) => device.idx}
                  label={(device: DeviceTypes) => device.model_name}
                  defaultValue={devices.filter(
                    (device) => device.idx === inputs.device_info?.device?.idx
                  )}
                  func={(selected: DeviceTypes) =>
                    onChangeSelect("device_info.device", selected)
                  }
                  clearable={false}
                  disabled={!isAgency}
                  placeholder="모델명"
                />
              )}
              {type === "etc" && (
                <S.Input
                  name="model_name"
                  defaultValue={inputs?.model_name}
                  onBlur={onChangeInputs}
                  disabled={!isAgency}
                  placeholder="모델명"
                />
              )}
            </S.InputBox>
            <S.InputBox>
              <p>일련번호</p>
              <S.Input
                name="serial_number"
                defaultValue={inputs?.serial_number}
                onBlur={onChangeInputs}
                disabled={!isAgency}
                placeholder="일련번호"
              />
            </S.InputBox>
            <S.InputBox>
              <p>색상</p>
              {type === "phone" && (
                <CSelect<DeviceInfoTypes>
                  options={colors}
                  value={(device: DeviceInfoTypes) => device.idx}
                  label={(device: DeviceInfoTypes) => device.color.name}
                  key={inputs.device_info?.idx}
                  defaultValue={colors.filter(
                    (device) => device.idx === inputs.device_info?.idx
                  )}
                  func={(selected: DeviceInfoTypes) => {
                    onChangeSelect("device_info", selected);
                  }}
                  disabled={!isAgency}
                  placeholder="색상"
                />
              )}
              {type === "etc" && (
                <S.Input
                  name="device_color"
                  defaultValue={inputs?.device_color}
                  onBlur={onChangeInputs}
                  disabled={!isAgency}
                  placeholder="색상"
                />
              )}
            </S.InputBox>
            <S.InputBox>
              <p>용량</p>
              {type === "phone" && (
                <S.Input
                  key="volume"
                  value={inputs.device_info?.device?.volume}
                  placeholder="용량"
                  disabled
                />
              )}
              {type === "etc" && (
                <S.TwoInputs>
                  <S.Input
                    key={volume.num}
                    defaultValue={volume.num}
                    onBlur={onChangeInputs}
                    placeholder="숫자만 입력"
                  />
                  <CSelect<SelectDefaultTypes>
                    options={volumeUnits()}
                    value={(unit: SelectDefaultTypes) => unit.value}
                    label={(unit: SelectDefaultTypes) => unit.name}
                    defaultValue={volumeUnits().filter(
                      (unit) => unit.value === volume.unit
                    )}
                    func={(selected: SelectDefaultTypes) => {
                      onChangeSelect("volume", selected.value);
                    }}
                    disabled={!isAgency}
                    placeholder="용량"
                  />
                </S.TwoInputs>
              )}
            </S.InputBox>
            <S.InputBox>
              <p>입고일</p>
              <CDatePicker
                name="in"
                selected={formatTimezone(inputs.in_date!)}
                func={onChangeDate}
                maxDate={inputs.out_date && formatTimezone(inputs.out_date)}
              />
            </S.InputBox>
            {/* {inputs.out_date && (
              <S.InputBox>
                <p>출고일</p>
                <CDatePicker
                  name='out'
                  selected={formatTimezone(inputs.out_date)}
                  func={onChangeDate}
                  minDate={formatTimezone(inputs.in_date!)}
                />
              </S.InputBox>
            )} */}
            <S.InputBox>
              <p>입고가</p>
              <S.MoneyInputs>
                <S.Input
                  key="price"
                  defaultValue={
                    type === "phone"
                      ? inputs.device_info?.device?.price
                      : inputs.price
                  }
                  placeholder="입고가"
                />
              </S.MoneyInputs>
            </S.InputBox>
            {inputs.out_date ? (
              <S.InputBox>
                <p>출고가</p>
                <S.MoneyInputs>
                  <S.Input
                    key="price"
                    defaultValue={inputs.price}
                    placeholder="출고가"
                  />
                </S.MoneyInputs>
              </S.InputBox>
            ) : null}
            <S.InputBox mb={0}>
              <p>보유처</p>
              <S.Input
                defaultValue={inputs.location?.name}
                placeholder="보유처"
                disabled
              />
            </S.InputBox>
            <S.InputBox>
              <p>메모</p>
              <S.Input
                name="memo"
                defaultValue={inputs.memo}
                onBlur={onChangeInputs}
                disabled={!isAgency}
                placeholder="메모"
              />
            </S.InputBox>
            <S.InputBox>
              <p>QR 코드</p>
              <Split
                width={100}
                align="flex-start"
                justify="center"
                style={{
                  marginBottom: "1vw",
                  padding: "0 0 0 0",
                }}
              >
                <QRCode value={inputs.qr_base ?? ""} size={38}></QRCode>
              </Split>
            </S.InputBox>
          </S.Grid>
        </Split>
        <ClientHistoryContainer>
          <p>이력</p>
          {clientHistory.map((data) => (
            <ClientHistoryStyle>
              <article className="thead">
                <section>출고일</section>
                <section>거래처명</section>
                <section>부서명</section>
                <section>입고가</section>
                <section>출고가</section>
              </article>
              <article className="tbody">
                <section>{formatTimezone(data.createdAt)}</section>
                <section>{data.clientName}</section>
                <section>{data.departmentName}</section>
                <section>{data.inPrice.toLocaleString()}</section>
                <section>{data.outPrice.toLocaleString()}</section>
              </article>
            </ClientHistoryStyle>
          ))}
        </ClientHistoryContainer>
      </S.Row>
      {isAgency && (
        <Bottoms>
          <button type="button" onClick={update}>
            재고 수정하기
          </button>
        </Bottoms>
      )}
    </Main>
  );
};

export default ShopDeviceUpdate;

const ClientHistoryContainer = styled(Split)`
  width: calc(100% - 66.666%);
  height: 532px;

  display: flex;
  flex-direction: column;
  gap: 0.5vw 1.5vw;

  overflow-y: auto;

  & > p {
    width: 120px;
    font-weight: 500;
  }
`;

const ClientHistoryStyle = styled.div`
  padding: 16px;

  border: 1px solid #ccc;
  border-radius: 6px;

  display: flex;
  gap: 12px;

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  & > article {
    &:first-child {
      padding-right: 12px;

      border-right: 1px solid #eee;

      font-weight: 500;
    }

    & > section {
      height: 24px;

      display: flex;
      align-items: center;
    }
  }
`;
