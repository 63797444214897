import PolicyManage from '../components/PolicyManage';
import { useState } from 'react';
import { apiRoute, requestSecurePatch, requestSecurePost } from '@libs/api';
import useToken from '@hooks/useToken';
import { PolicyTypes } from '@typedef/components/Setup/Policy/policy.types';
import { useRecoilValue } from 'recoil';
import { getTelecoms } from '@stories/Selector';

type Props = {
  idx?: number;
  close: () => void;
  reload: () => void;
};

const PolicyManageContainer = ({ idx, close, reload }: Props) => {
  const { getToken } = useToken();
  const [inputs, setInputs] = useState<Partial<PolicyTypes>>({});
  const telecoms = useRecoilValue(getTelecoms(getToken()!));

  // onChange (input)
  const onChangeInputs = (e: { target: HTMLInputElement }) => {
    const { name, value } = e.target;
    setInputs((inputs) => ({
      ...inputs,
      [name]: value,
    }));
  };

  // onChange (select)
  const onChangeSelect = <T extends {}>(name: string, selected: T) => {
    setInputs((inputs) => ({
      ...inputs,
      [name]: selected,
    }));
  };

  // 파일올리기
  const onChangeFile = (e: { target: HTMLInputElement }) => {
    const file = e.target.files;
    if (file) {
      setInputs((inputs) => ({
        ...inputs,
        img: file[0],
      }));
    }
  };

  // 등록
  const insert = async () => {
    if (!inputs.title || !inputs.telecomId || !inputs.img) {
      return;
    }
    const formData = new FormData();
    formData.append('title', inputs.title);
    formData.append('telecomId', inputs.telecomId.toString());
    formData.append('img', inputs.img);

    const { config } = await requestSecurePost(
      apiRoute.setup.policy.postPolicy,
      {},
      formData,
      getToken()!,
      true, // isFormData
    );
    if (config.status >= 200 && config.status < 400) {
      alert('성공적으로 등록이 완료되었습니다.');
      close();
      reload();
    }
  };

  // 수정
  const update = async () => {
    const { config } = await requestSecurePatch(
      apiRoute.setup.policy.patchPolicy,
      {},
      inputs,
      getToken()!,
    );
    if (config.status >= 200 && config.status < 400) {
      alert('성공적으로 수정이 완료되었습니다.');
      close();
      reload();
    }
  };

  return (
    <PolicyManage
      type={idx ? '수정' : '등록'}
      inputs={inputs}
      onChangeInputs={onChangeInputs}
      onChangeSelect={onChangeSelect}
      onChangeFile={onChangeFile}
      telecoms={telecoms}
      save={idx ? update : insert}
    />
  );
};

export default PolicyManageContainer;
