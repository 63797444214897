import ShopDeviceUpdate from "../components/ShopDeviceUpdate";
import { useState, useEffect, useMemo } from "react";
import { apiRoute, requestSecureGet, requestSecurePatch } from "@libs/api";
import useToken from "@hooks/useToken";
import useUser from "@hooks/useUser";
import { useRecoilValue } from "recoil";
import { getDevices, getTelecoms } from "@stories/Selector";
import { ShopDeviceTypes } from "@typedef/components/Inventory/shop.device.types";
import { DeviceInfoTypes } from "@typedef/components/Device/device.info.types";
import { DeviceTypes } from "@typedef/components/Device/device.types";
import { formatDate, splitVolume } from "@libs/commonFuncs";
import { useLocation, useSearchParams } from "react-router-dom";
import { ClientHistoryTypes } from "@typedef/components/Client/client.history.types";

type Props = {
  idx: number;
  close: () => void;
  reload: () => void;
};

const ShopDeviceUpdateContainer = ({ idx, close, reload }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const { getToken } = useToken();
  const { getUser } = useUser();
  // AGENCY만 수정 가능
  const role = useMemo(() => getUser().role, [getUser]);
  // 재고가 휴대폰인지 기타 단말인지
  const [type, setType] = useState<"phone" | "etc">("phone");
  const [inputs, setInputs] = useState<Partial<ShopDeviceTypes>>({});
  // 재고 거래 이력
  const [clientHistory, setClientHistory] = useState<ClientHistoryTypes[]>([]);
  // 통신사 선택
  const telecoms = useRecoilValue(getTelecoms(getToken()!));
  // 모델명 선택
  const devices = useRecoilValue(getDevices(getToken()!));
  // 색상 선택
  const [colors, setColors] = useState<DeviceInfoTypes[]>([]);
  // 용량 (수기(기타)에서 사용)
  const [volume, setVolume] = useState<{
    num: number;
    unit: "MB" | "GB" | "TB";
  }>({
    num: 0,
    unit: "GB",
  });
  const [loading, setLoading] = useState<boolean>(true);

  // onChange (일련번호, 메모)
  const onChangeInputs = (e: { target: HTMLInputElement }) => {
    const { name, value } = e.target;
    setInputs((inputs) => ({
      ...inputs,
      [name]: value,
    }));
  };

  // onChange (select)
  const onChangeSelect = (name: string, selected: any) => {
    // 모델명 변경 시
    if (name === "device_info.device") {
      // device_info.device = selected
      setInputs((inputs) => ({
        ...inputs,
        device_info: {
          ...inputs.device_info!,
          device: selected as DeviceTypes,
        },
      }));
      getColorByModelName(selected.model_name as string);
      return;
    }
    if (name === "volume") {
      setVolume((volume) => ({
        ...volume,
        unit: selected as "MB" | "GB" | "TB",
      }));
      return;
    }

    setInputs((inputs) => ({
      ...inputs,
      [name]: selected,
    }));
  };

  // 색상 조회
  const getColorByModelName = async (name: string) => {
    const { config, data } = await requestSecureGet<DeviceInfoTypes[]>(
      apiRoute.device.getDevicesByModelName + name,
      {},
      getToken()!
    );
    if (config.status >= 200 && config.status < 400) {
      setColors(data);
    }
  };

  // 입고일, 출고일 수정
  const onChangeDate = (name: string, date: Date) => {
    if (name === "in") {
      setInputs((inputs) => ({
        ...inputs,
        in_date: formatDate(date),
      }));
    } else if (name === "out") {
      setInputs((inputs) => ({
        ...inputs,
        out_date: formatDate(date),
      }));
    }
  };

  // 수정
  const update = async () => {
    if (type === "phone" && inputs.device_info?.idx === 0) {
      alert("색상을 선택해 주세요.");
      return;
    }

    const postData = {
      idx: idx,
      device_info_idx: inputs.device_info?.idx,
      in_date: inputs.in_date,
      location_idx: inputs.location?.idx,
      memo: inputs?.memo,
      move_date: inputs?.move_date,
      out_date: inputs.out_date,
      serial_number: inputs?.serial_number,
      shop_depot_idx: inputs.shop_depot?.idx,
    };

    // 휴대폰 POST 데이터
    const phoneData = Object.assign(postData, {
      telecom_idx: inputs.telecom?.idx,
    });

    // 수기(기타) POST 데이터
    const etcData = Object.assign(postData, {
      device_color: inputs.device_color,
      maker_nm: inputs.maker_nm,
      model_name: inputs.model_name,
      price: inputs.price,
      volume: volume.num + volume.unit,
    });

    const { config } = await requestSecurePatch(
      apiRoute.shopDevice.patchDevice,
      {},
      type === "phone" ? phoneData : etcData,
      getToken()!
    );
    if (config.status >= 200 && config.status < 400) {
      alert("성공적으로 수정이 완료되었습니다.");
      close();
      reload();
    }
  };

  // 선택한 재고 상세 조회
  const getCheckedData = async () => {
    const { config, data } = await requestSecureGet<ShopDeviceTypes>(
      apiRoute.shopDevice.getDevice + idx,
      {},
      getToken()!
    );
    if (config.status >= 200 && config.status < 400) {
      if (data.device_info.device.model_name) {
        setType("phone");
        getColorByModelName(data.device_info?.device?.model_name);
      } else {
        setType("etc");
        const { num, unit } = splitVolume(data.volume);
        setVolume({
          num: +num,
          unit: unit,
        });
      }
      setInputs(data);
      setLoading(false);
    }
  };

  // 거래이력 리스트 조회
  const getClientHistoryData = async () => {
    const { config, data } = await requestSecureGet<ClientHistoryTypes[]>(
      apiRoute.client.getClientHistory + location.search,
      {},
      getToken()!
    );

    if (config.status >= 200 && config.status < 400) {
      setClientHistory(data);
      setLoading(false);
    } else {
      alert("거래처 조회에 실패하였습니다.");
      setLoading(false);
    }
  };

  useEffect(() => {
    searchParams.set("shopDeviceId", String(idx));
    setSearchParams(new URLSearchParams(searchParams));
  }, [idx]);

  useEffect(() => {
    getCheckedData();
    getClientHistoryData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ShopDeviceUpdate
      loading={loading}
      type={type}
      inputs={inputs}
      clientHistory={clientHistory}
      onChangeInputs={onChangeInputs}
      onChangeSelect={onChangeSelect}
      telecoms={telecoms}
      devices={devices}
      colors={colors}
      volume={volume}
      onChangeDate={onChangeDate}
      update={update}
      isAgency={role.includes("AGENCY")}
    />
  );
};

export default ShopDeviceUpdateContainer;
