import * as S from '@styles/components/FormStyle';
import { RadioBtn, RadioBtns } from '../Styles/PayOpenStyle';
import CSelect from '@components/Common/CSelect/CSelect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle as checkRegular } from '@fortawesome/fontawesome-free-regular';
import { faCheckCircle as checkSolid } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { TelecomTypes } from '@typedef/components/Telecom/telecom.types';
import { SelectDefaultTypes } from '@typedef/components/Common/CSelect/select.default.types';
import { PhoneplanTypes } from '@typedef/components/Telecom/Phoneplan/phoneplan.types';
import { SubserviceTypes } from '@typedef/components/Telecom/Subservice/subservice.types';
import { InsuranceTypes } from '@typedef/components/Telecom/Insurance/insurance.types';
import { OpenTypes } from '@typedef/components/Pay/Open/open.types';

type Props = {
  inputs: Partial<OpenTypes>;
  onChangeInputs: (e: { target: HTMLInputElement }) => void;
  onChangeSelect: <T extends {}>(name: string, selected: T) => void;
  telecoms: TelecomTypes[];
  phoneplans: PhoneplanTypes[];
  subservices: SubserviceTypes[];
  insurances: InsuranceTypes[];
  total: number;
};

const PhoneplanOpen = ({
  inputs,
  onChangeInputs,
  onChangeSelect,
  telecoms,
  phoneplans,
  subservices,
  insurances,
  total,
}: Props) => {
  return (
    <S.Grid grid={3}>
      <S.InputBox>
        <p>통신사</p>
        <CSelect<TelecomTypes>
          options={telecoms}
          value={(telecom: TelecomTypes) => telecom.telecomId}
          label={(telecom: TelecomTypes) => telecom.name}
          defaultValue={telecoms.filter(
            (telecom) => telecom.telecomId === inputs.telecom?.telecomId,
          )}
          func={(selected: TelecomTypes) => onChangeSelect('telecom', selected)}
          placeholder='통신사'
        />
      </S.InputBox>
      <S.InputBox>
        <p>요금제</p>
        <CSelect<PhoneplanTypes>
          options={phoneplans.filter(
            (phoneplan) =>
              phoneplan.telecom.telecomId === inputs.telecom?.telecomId,
          )}
          value={(phoneplan: PhoneplanTypes) => phoneplan.phonePlanId}
          label={(phoneplan: PhoneplanTypes) => phoneplan.name}
          defaultValue={phoneplans.filter(
            (phoneplan) =>
              phoneplan.phonePlanId === inputs.phonePlan?.phonePlanId,
          )}
          func={(selected: PhoneplanTypes) => {
            onChangeSelect('phonePlan', selected);
          }}
          placeholder={
            inputs.telecom?.telecomId ? '요금제' : '통신사를 먼저 선택해 주세요'
          }
        />
      </S.InputBox>
      <S.InputBox>
        <p>할인유형</p>
        <RadioBtns>
          {contractTypes.map((contract) => (
            <RadioBtn
              type='button'
              key={contract.value}
              onClick={() => onChangeSelect('contractType', contract.value)}
              className={
                contract.value === inputs.contractType ? 'active' : ''
              }>
              <FontAwesomeIcon
                id={contract.value}
                icon={
                  contract.value === inputs.contractType
                    ? checkSolid
                    : (checkRegular as IconProp)
                }
                className='icon'
              />
              {contract.name}
            </RadioBtn>
          ))}
        </RadioBtns>
      </S.InputBox>
      <S.InputBox>
        <p>약정개월</p>
        <CSelect<SelectDefaultTypes>
          options={months}
          value={(month: SelectDefaultTypes) => month.value}
          label={(month: SelectDefaultTypes) => month.name}
          defaultValue={months.filter(
            (month) => month.value === inputs.contractMonth,
          )}
          func={(selected: SelectDefaultTypes) =>
            onChangeSelect('contractMonth', selected.value)
          }
          placeholder='약정개월'
        />
      </S.InputBox>
      <S.InputBox>
        <p>개통유형</p>
        <CSelect<SelectDefaultTypes>
          options={openTypes}
          value={(type: SelectDefaultTypes) => type.value}
          label={(type: SelectDefaultTypes) => type.name}
          defaultValue={openTypes.filter(
            (openType) => openType.value === inputs.openType,
          )}
          func={(selected: SelectDefaultTypes) =>
            onChangeSelect('openType', selected.value)
          }
          placeholder='개통유형'
        />
      </S.InputBox>
      <S.InputBox>
        <p>부가서비스</p>
        <CSelect<SubserviceTypes>
          options={subservices.filter(
            (subservice) => subservice.telecomId === inputs.telecom?.telecomId,
          )}
          value={(subservice: SubserviceTypes) => subservice.subServiceId}
          label={(subservice: SubserviceTypes) => subservice.name}
          defaultValue={subservices.filter((subservice) =>
            inputs.subServices?.some(
              (selected) => selected.subServiceId === subservice.subServiceId,
            ),
          )}
          func={(selected: SubserviceTypes[]) =>
            onChangeSelect('subServices', selected)
          }
          placeholder={
            inputs.telecom?.telecomId
              ? '부가서비스'
              : '통신사를 먼저 선택해 주세요'
          }
          isMulti
        />
      </S.InputBox>
      <S.InputBox>
        <p>보험</p>
        <CSelect<InsuranceTypes>
          options={insurances.filter(
            (insurance) => insurance.telecomId === inputs.telecom?.telecomId,
          )}
          value={(insurance: InsuranceTypes) => insurance.insuranceId}
          label={(insurance: InsuranceTypes) => insurance.name}
          defaultValue={insurances.filter(
            (insurance) =>
              insurance.insuranceId === inputs.insurance?.insuranceId,
          )}
          func={(selected: InsuranceTypes) =>
            onChangeSelect<InsuranceTypes>('insurance', selected)
          }
          placeholder={
            inputs.telecom?.telecomId ? '보험' : '통신사를 먼저 선택해 주세요'
          }
          clearable
          height={135}
        />
      </S.InputBox>
      <S.InputBox>
        <p>요금제 기본료</p>
        <S.MoneyInputs>
          <S.Input
            value={inputs.phonePlan?.price}
            placeholder='요금제 기본료'
            disabled
          />
        </S.MoneyInputs>
      </S.InputBox>
      <S.InputBox>
        <p>선택약정 할인</p>
        <S.MoneyInputs>
          <S.Input
            defaultValue={
              inputs.phonePlan
                ? inputs.contractType === 'PHONE'
                  ? inputs.phonePlan.price * 0.25
                  : 0
                : undefined
            }
            key={inputs.contractType}
            placeholder='선택약정 할인'
            disabled
          />
        </S.MoneyInputs>
      </S.InputBox>
      <S.InputBox mb={0}>
        <p>기타 할인</p>
        <S.MoneyInputs>
          <S.Input
            name='discountPhoneEtc'
            onBlur={onChangeInputs}
            defaultValue={inputs.discountPhoneEtc}
            placeholder='기타 할인'
          />
        </S.MoneyInputs>
      </S.InputBox>
      <S.InputBox>
        <p>월 요금제</p>
        <S.MoneyInputs>
          <S.Input
            value={total.toLocaleString()}
            placeholder='월 요금제'
            disabled
          />
        </S.MoneyInputs>
      </S.InputBox>
    </S.Grid>
  );
};

export default PhoneplanOpen;

// 할인유형
const contractTypes = [
  {
    name: '선약',
    value: 'PHONE',
  },
  {
    name: '공시',
    value: 'DEVICE',
  },
  {
    name: '무약정',
    value: 'NONE',
  },
];

// 개통유형
const openTypes = [
  {
    name: '신규',
    value: 'NEW',
  },
  {
    name: '번호이동',
    value: 'MNP',
  },
  {
    name: '기기변경',
    value: 'DEV',
  },
  {
    name: '전환신규',
    value: 'GEN',
  },
];

// 약정개월
const months = [
  {
    name: '현금',
    value: 0,
  },
  {
    name: '6개월',
    value: 6,
  },
  {
    name: '12개월',
    value: 12,
  },
  {
    name: '18개월',
    value: 18,
  },
  {
    name: '24개월',
    value: 24,
  },
  {
    name: '30개월',
    value: 30,
  },
  {
    name: '36개월',
    value: 36,
  },
  {
    name: '48개월',
    value: 48,
  },
];
