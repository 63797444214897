import Device from "../Device";
import DeviceManageContainer from "./DeviceManageContainer";
import { useState, useEffect, useCallback, useMemo } from "react";
import { apiRoute, requestSecureDelete, requestSecureGet } from "@libs/api";
import useToken from "@hooks/useToken";
import { useSetRecoilState } from "recoil";
import { loading, modal, modalHeader } from "@stories/Atom";
import { TableColumnTypes } from "@typedef/components/Common/CTable/table.column.types";
import { DeviceTypes } from "@typedef/components/Device/device.types";

// 단말관리 > 단말관리
const DeviceContainer = () => {
  const { getToken } = useToken();
  // 테이블 데이터
  const [tableData, setTableData] = useState<DeviceTypes[]>([]);
  const [checkedIdxes, setCheckedIdxes] = useState<number[]>([]);
  // 모달설정
  const setModal = useSetRecoilState(modal);
  const setModalHeader = useSetRecoilState(modalHeader);
  // 테이블 로딩
  const setLoading = useSetRecoilState(loading);
  const [reload, setReload] = useState<number>(0);

  // 단말 상세조회 및 수정
  const updateDevice = useCallback(
    (idx?: number) => {
      if (!!!idx && checkedIdxes.length === 0) {
        alert("수정할 단말을 선택해 주세요.");
        return;
      } else if (!!!idx && checkedIdxes.length > 1) {
        alert("수정할 단말을 하나만 선택해 주세요.");
        return;
      }
      setModalHeader("단말 상세조회");
      setModal(
        <DeviceManageContainer
          idx={idx ?? checkedIdxes[0]}
          close={() => setModal(null)}
          reload={() => setReload((prev) => prev + 1)}
        />
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [checkedIdxes]
  );

  // 체크박스
  const onChangeCheckbox = useCallback(
    (idx: number) => {
      if (checkedIdxes.includes(idx)) {
        setCheckedIdxes(
          checkedIdxes.filter((checkedIdx) => checkedIdx !== idx)
        );
        return;
      }
      setCheckedIdxes((idxes) => [...idxes, idx]);
    },
    [checkedIdxes]
  );

  // 테이블 컬럼
  const columns: TableColumnTypes<DeviceTypes>[] = useMemo(
    () => [
      {
        Header: "",
        accessor: "idx",
        disableSortBy: true,
        width: 70,
        Cell: ({ value }: any) => (
          <input
            type="checkbox"
            defaultChecked={checkedIdxes.includes(value)}
            onChange={() => onChangeCheckbox(value)}
          />
        ),
      },
      {
        Header: "No",
        width: 70,
        Cell: ({ row }: any) => <>{row.index + 1}</>,
      },
      {
        Header: "모델명",
        accessor: "model_name",
        disableSortBy: true,
        Cell: ({ row, value }: any) => (
          <div
            className="detail"
            onClick={() => updateDevice(row.original.idx)}
          >
            {value}
          </div>
        ),
      },
      {
        Header: "펫네임",
        accessor: "pet_name",
        disableSortBy: true,
      },
      {
        Header: "용량",
        accessor: "volume",
        disableSortBy: true,
      },
      {
        Header: "가격",
        accessor: "price",
        Cell: ({ value }: any) => <>{value.toLocaleString()}</>,
      },
    ],

    [checkedIdxes, onChangeCheckbox, updateDevice]
  );

  // 단말 등록
  const insertDevice = () => {
    setModalHeader("단말 등록");
    setModal(
      <DeviceManageContainer
        close={() => setModal(null)}
        reload={() => setReload((prev) => prev + 1)}
      />
    );
  };

  // 단말 삭제
  const deleteDevice = async () => {
    if (checkedIdxes.length === 0) {
      alert("삭제할 단말을 선택해 주세요");
      return;
    } else if (
      window.confirm(
        `선택한 ${checkedIdxes.length}개의 단말을 삭제하시겠습니까?`
      )
    ) {
      await Promise.all(
        checkedIdxes.map(
          (idx) =>
            new Promise((resolve, reject) => {
              requestSecureDelete(
                apiRoute.device.deleteDevice + idx,
                {},
                getToken()!
              ).then((data) => {
                if (data.config.status >= 200 && data.config.status < 400) {
                  resolve(data);
                }
              });
            })
        )
      ).then(() => {
        alert("성공적으로 삭제가 완료되었습니다.");
        setReload((prev) => prev + 1);
      });
    }
  };

  const getTableData = async () => {
    const { config, data } = await requestSecureGet<DeviceTypes[]>(
      apiRoute.device.getDevices,
      {},
      getToken()!
    );
    if (config.status >= 200 && config.status < 400) {
      setTableData(data);
      setLoading(false);
    } else {
      setTableData([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  return (
    <Device
      columns={columns}
      tableData={tableData}
      insertDevice={insertDevice}
      updateDevice={updateDevice}
      deleteDevice={deleteDevice}
    />
  );
};

export default DeviceContainer;
