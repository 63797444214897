import { Main, Split, Bottoms } from '@styles/components/ModalBodyStyle';
import * as S from '@styles/components/FormStyle';
import {
  Colors,
  Color,
  Description,
  CalendarRange,
} from '../styles/SheduleStyle';
import { ScheduleTypes } from '@typedef/components/Schedule/schedule.types';
import CCalendar from '@components/Common/CCalendar/CCalendar';

type Props = {
  type: string;
  inputs: Partial<ScheduleTypes>;
  onChangeDate: (date: Date | Date[]) => void;
  onChangeInputs: (e: {
    target: HTMLInputElement | HTMLTextAreaElement;
  }) => void;
  onClickColor: (color: string) => void;
  setSelectedDate: (date: Date) => string;
  save: () => Promise<void>;
};

const ScheduleManage = ({
  type,
  inputs,
  onChangeDate,
  onChangeInputs,
  onClickColor,
  setSelectedDate,
  save,
}: Props) => {
  return (
    <Main width={60}>
      <S.Row>
        <Split>
          <Description>
            <h4>시작일과 종료일을 선택해 주세요</h4>
          </Description>
          <CalendarRange>
            <CCalendar
              onChangeDate={onChangeDate}
              isRange
              selectedFunc={setSelectedDate}
            />
          </CalendarRange>
        </Split>
        <Split borderL>
          <S.InputBox>
            <p>Start</p>
            <S.Input value={inputs.startDate} className='date' disabled />
          </S.InputBox>
          <S.InputBox>
            <p>End</p>
            <S.Input value={inputs.endDate} className='date' disabled />
          </S.InputBox>
          <S.InputBox>
            <p>Title</p>
            <S.Input
              name='title'
              onChange={onChangeInputs}
              defaultValue={inputs.title}
              placeholder='Title'
            />
          </S.InputBox>
          <S.InputBox>
            <S.Textarea
              name='content'
              onBlur={onChangeInputs}
              defaultValue={inputs.content}
            />
          </S.InputBox>
          <S.InputBox>
            <p>Color</p>
            <Colors>
              {colors.map((color) => (
                <Color
                  key={color}
                  bgc={color}
                  onClick={() => onClickColor(color)}
                  className={inputs.colorHex === color ? 'active' : ''}
                />
              ))}
            </Colors>
          </S.InputBox>
        </Split>
      </S.Row>
      <Bottoms>
        <button type='button' onClick={save} disabled={!!!inputs.title}>
          일정 {type}하기
        </button>
      </Bottoms>
    </Main>
  );
};

export default ScheduleManage;

const colors = [
  '#FFC7C7',
  '#FFEB99',
  '#FFD3B6',
  '#c3e2db',
  '#cbe6ff',
  '#F0D9FF',
  '#DFDFDE',
];
