import { Main, Split } from "@styles/components/ModalBodyStyle";
import * as S from "@styles/components/FormStyle";
import { ShopTypes } from "@typedef/components/Shop/shop.types";

type Props = {
  inputs: Partial<ShopTypes>;
};

const ShopDetail = ({ inputs }: Props) => {
  return (
    <Main width={35}>
      <Split>
        <S.InputBox isRow>
          <p>업체명</p>
          <S.Input value={inputs.name} readOnly />
        </S.InputBox>
        <S.InputBox isRow>
          <p>업체구분</p>
          <S.Input
            value={
              inputs.role === "JSOL"
                ? "관리자"
                : inputs.role?.includes("AGENCY")
                ? "대리점"
                : "판매점"
            }
            readOnly
          />
        </S.InputBox>
        <S.InputBox isRow>
          <p>대표전화</p>
          <S.Input value={inputs.phone} readOnly />
        </S.InputBox>
        <S.InputBox isRow>
          <p>팩스</p>
          <S.Input value={inputs.fax} readOnly />
        </S.InputBox>
        <S.InputBox isRow>
          <p>주소</p>
          <S.Input value={inputs.address} readOnly />
        </S.InputBox>
        <S.InputBox isRow>
          <p>담당자</p>
          <S.Input value={inputs.owner?.name} readOnly />
        </S.InputBox>
      </Split>
    </Main>
  );
};

export default ShopDetail;
