import SetupPolicy from '../SetupPolicy';
import PolicyManageContainer from './PolicyManageContainer';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { apiRoute, requestSecureDelete, requestSecureGet } from '@libs/api';
import useToken from '@hooks/useToken';
import { useSetRecoilState } from 'recoil';
import { loading, modal, modalHeader } from '@stories/Atom';
import { TableColumnTypes } from '@typedef/components/Common/CTable/table.column.types';
import { PolicyTypes } from '@typedef/components/Setup/Policy/policy.types';

const SetupPolicyContainer = () => {
  const { getToken } = useToken();
  // 테이블 데이터
  const [tableData, setTableData] = useState<PolicyTypes[]>([]);
  const [checkedIdxes, setCheckedIdxes] = useState<number[]>([]);
  // 모달설정
  const setModal = useSetRecoilState(modal);
  const setModalHeader = useSetRecoilState(modalHeader);
  // 테이블 로딩
  const setLoading = useSetRecoilState(loading);
  const [reload, setReload] = useState<number>(0);

  // 정책 상세조회 및 수정
  const updatePolicy = useCallback(
    (idx?: number) => {
      if (!!!idx && checkedIdxes.length === 0) {
        alert('수정할 정책을 선택해 주세요.');
        return;
      } else if (!!!idx && checkedIdxes.length > 1) {
        alert('수정할 정책을 하나만 선택해 주세요.');
        return;
      }
      setModalHeader('정책 상세조회');
      setModal(
        <PolicyManageContainer
          idx={idx ?? checkedIdxes[0]}
          close={() => setModal(null)}
          reload={() => setReload((prev) => prev + 1)}
        />,
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [checkedIdxes],
  );

  // 체크박스
  const onChangeCheckbox = useCallback(
    (idx: number) => {
      if (checkedIdxes.includes(idx)) {
        setCheckedIdxes(
          checkedIdxes.filter((checkedIdx) => checkedIdx !== idx),
        );
        return;
      }
      setCheckedIdxes((idxes) => [...idxes, idx]);
    },
    [checkedIdxes],
  );

  // 테이블 컬럼
  const columns: TableColumnTypes[] = useMemo(
    () => [
      {
        Header: '',
        accessor: 'policyId',
        disableSortBy: true,
        width: 70,
        Cell: ({ value }: any) => (
          <input
            type='checkbox'
            defaultChecked={checkedIdxes.includes(value)}
            onChange={() => onChangeCheckbox(value)}
          />
        ),
      },
      {
        Header: 'No',
        width: 70,
        Cell: ({ row }: any) => <>{tableData.length - row.index}</>,
      },
      {
        Header: '작성일시',
        accessor: 'time',
      },
      {
        Header: '적용일자',
        accessor: 'effectiveDate',
      },
      {
        Header: '제목',
        accessor: 'name',
        disableSortBy: true,
        Cell: ({ row, value }: any) => (
          <div
            className='detail'
            onClick={() => updatePolicy(row.original.policyId)}>
            {value}
          </div>
        ),
      },
      {
        Header: '파일',
        accessor: 'name_eng',
        disableSortBy: true,
      },
      {
        Header: '작성자',
        accessor: 'writerName',
        disableSortBy: true,
      },
    ],
    [checkedIdxes, onChangeCheckbox, tableData.length, updatePolicy],
  );

  // 정책 등록
  const insertPolicy = () => {
    setModalHeader('정책 등록');
    setModal(
      <PolicyManageContainer
        close={() => setModal(null)}
        reload={() => setReload((prev) => prev + 1)}
      />,
    );
  };

  // 정책 삭제
  const deletePolicy = async () => {
    if (checkedIdxes.length === 0) {
      alert('삭제할 정책을 선택해 주세요');
      return;
    } else if (
      window.confirm(
        `선택한 ${checkedIdxes.length}개의 정책을 삭제하시겠습니까?`,
      )
    ) {
      await Promise.all(
        checkedIdxes.map(
          (idx) =>
            new Promise((resolve, reject) => {
              requestSecureDelete(
                apiRoute.setup.policy.deletePolicy + idx,
                {},
                getToken()!,
              ).then((data) => {
                if (data.config.status >= 200 && data.config.status < 400) {
                  resolve(data);
                }
              });
            }),
        ),
      ).then(() => {
        alert('성공적으로 삭제가 완료되었습니다.');
        setReload((prev) => prev + 1);
      });
    }
  };

  const getTableData = async () => {
    const { config, data } = await requestSecureGet<{
      policies: PolicyTypes[];
    }>(apiRoute.setup.policy.getPolicies, {}, getToken()!);
    if (config.status >= 200 && config.status < 400) {
      setTableData(data.policies);
      setLoading(false);
    } else {
      setTableData([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  return (
    <SetupPolicy
      columns={columns}
      tableData={tableData}
      insertPolicy={insertPolicy}
      updatePolicy={updatePolicy}
      deletePolicy={deletePolicy}
    />
  );
};

export default SetupPolicyContainer;
