import DeviceBarcode from '../DeviceBarcode';
import BarcodeManageContainer from './BarcodeManageContainer';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { apiRoute, requestSecureDelete, requestSecureGet } from '@libs/api';
import useToken from '@hooks/useToken';
import { useSetRecoilState } from 'recoil';
import { loading, modal, modalHeader } from '@stories/Atom';
import { TableColumnTypes } from '@typedef/components/Common/CTable/table.column.types';
import { DeviceInfoTypes } from '@typedef/components/Device/device.info.types';
import DetailCell from '@components/Common/CTable/components/DetailCell';

// 단말관리 > 바코드관리
const DeviceBarcodeContainer = () => {
  const { getToken } = useToken();
  // 테이블 데이터
  const [tableData, setTableData] = useState<DeviceInfoTypes[]>([]);
  const [checkedIdxes, setCheckedIdxes] = useState<number[]>([]);
  // 모달설정
  const setModal = useSetRecoilState(modal);
  const setModalHeader = useSetRecoilState(modalHeader);
  // 테이블 로딩
  const setLoading = useSetRecoilState(loading);
  const [reload, setReload] = useState<number>(0);

  // 바코드 상세조회 및 수정
  const updateBarcode = useCallback(
    (idx?: number) => {
      if (!!!idx && checkedIdxes.length === 0) {
        alert('수정할 바코드를 선택해 주세요.');
        return;
      } else if (!!!idx && checkedIdxes.length > 1) {
        alert('수정할 바코드를 하나만 선택해 주세요.');
        return;
      }
      setModalHeader('바코드 상세조회');
      setModal(
        <BarcodeManageContainer
          idx={idx ?? checkedIdxes[0]}
          close={() => setModal(null)}
          reload={() => setReload((prev) => prev + 1)}
        />,
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [checkedIdxes],
  );

  // 체크박스
  const onChangeCheckbox = useCallback(
    (idx: number) => {
      if (checkedIdxes.includes(idx)) {
        setCheckedIdxes(
          checkedIdxes.filter((checkedIdx) => checkedIdx !== idx),
        );
        return;
      }
      setCheckedIdxes((idxes) => [...idxes, idx]);
    },
    [checkedIdxes],
  );

  // 테이블 컬럼
  const columns: TableColumnTypes<DeviceInfoTypes>[] = useMemo(
    () => [
      {
        Header: '',
        accessor: 'idx',
        disableSortBy: true,
        width: 70,
        Cell: ({ value }: any) => (
          <input
            type='checkbox'
            defaultChecked={checkedIdxes.includes(value)}
            onChange={() => onChangeCheckbox(value)}
          />
        ),
      },
      {
        Header: 'No',
        width: 70,
        Cell: ({ row }) => <>{tableData.length - row.index}</>,
      },

      {
        Header: '바코드',
        accessor: 'barcode',
        disableSortBy: true,
        Cell: ({ row, value }) => (
          <DetailCell
            value={value as string}
            func={() => updateBarcode(row.original.idx)}
          />
        ),
      },
      {
        Header: '바코드색상',
        accessor: 'barcode_color',
        disableSortBy: true,
      },
      {
        Header: '모델명',
        accessor: 'device.model_name',
        disableSortBy: true,
      },
      {
        Header: '펫네임',
        accessor: 'device.pet_name',
        disableSortBy: true,
      },
      {
        Header: '색상',
        accessor: 'color.name',
        disableSortBy: true,
      },
    ],

    [checkedIdxes, onChangeCheckbox, tableData.length, updateBarcode],
  );

  // 바코드 등록
  const insertBarcode = () => {
    setModalHeader('바코드 등록');
    setModal(
      <BarcodeManageContainer
        close={() => setModal(null)}
        reload={() => setReload((prev) => prev + 1)}
      />,
    );
  };

  // 바코드 삭제
  const deleteBarcode = async () => {
    if (checkedIdxes.length === 0) {
      alert('삭제할 바코드를 선택해 주세요.');
    } else if (
      window.confirm(
        `선택한 바코드 ${checkedIdxes.length}개를 삭제하시겠습니까?`,
      )
    ) {
      await Promise.all(
        checkedIdxes.map(
          (idx) =>
            new Promise((resolve, reject) => {
              requestSecureDelete(
                apiRoute.device.info.deleteInfo + idx,
                {},
                getToken()!,
              ).then((data) => {
                if (data.config.status >= 200 && data.config.status < 400) {
                  resolve(data);
                }
              });
            }),
        ),
      ).then(() => {
        alert('성공적으로 삭제가 완료되었습니다.');
        setReload((prev) => prev + 1);
      });
    }
  };

  const getTableData = async () => {
    setLoading(true);
    const { config, data } = await requestSecureGet<DeviceInfoTypes[]>(
      apiRoute.device.info.getInfos,
      {},
      getToken()!,
    );
    if (config.status >= 200 && config.status < 400) {
      setTableData(data);
      setLoading(false);
    } else {
      setTableData([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    getTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  return (
    <DeviceBarcode
      columns={columns}
      tableData={tableData}
      insertBarcode={insertBarcode}
      updateBarcode={updateBarcode}
      deleteBarcode={deleteBarcode}
    />
  );
};

export default DeviceBarcodeContainer;
