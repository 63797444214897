import * as S from '@styles/components/FormStyle';
import CSelect from '@components/Common/CSelect/CSelect';
import { SelectDefaultTypes } from '@typedef/components/Common/CSelect/select.default.types';
import { TelecomTypes } from '@typedef/components/Telecom/telecom.types';
import { CustomerTypes } from '@typedef/components/Customer/customer.types';
import { OpenTypes } from '@typedef/components/Pay/Open/open.types';

type Props = {
  customerType: string;
  setCustomerType: React.Dispatch<React.SetStateAction<string>>;
  inputs: Partial<OpenTypes>;
  onChangeInputs: (e: { target: HTMLInputElement }) => void;
  onChangeCustomer: (e: { target: HTMLInputElement }) => void;
  onChangeSelect: <T extends {}>(name: string, selected: T) => void;
  onChangeCustomerTelecom: (selected: TelecomTypes) => void;
  telecoms: TelecomTypes[];
  customers: CustomerTypes[];
  today: Date;
  splitPhone: {
    phone1: string;
    phone2: string;
    phone3: string;
  };
  splitOpenPhone: {
    openPhone1: string;
    openPhone2: string;
    openPhone3: string;
  };
};

const CustomerOpen = ({
  customerType,
  setCustomerType,
  inputs,
  onChangeInputs,
  onChangeCustomer,
  onChangeSelect,
  onChangeCustomerTelecom,
  telecoms,
  customers,
  today,
  splitPhone,
  splitOpenPhone,
}: Props) => {
  return (
    <S.Grid grid={3} key={inputs.customer?.customerId}>
      <S.InputBox>
        <p>고객유형</p>
        <CSelect<SelectDefaultTypes>
          options={customerTypes}
          value={(type: SelectDefaultTypes) => type.value}
          label={(type: SelectDefaultTypes) => type.name}
          defaultValue={customerTypes.filter(
            (selected) => selected.value === customerType,
          )}
          func={(selected: SelectDefaultTypes) =>
            setCustomerType(selected.value.toString())
          }
          placeholder='고객유형'
        />
      </S.InputBox>
      <S.InputBox>
        <p>고객명</p>
        {customerType === 'NEW' ? (
          <S.Input
            name='name'
            onBlur={onChangeCustomer}
            defaultValue={inputs.customer?.name}
            placeholder='고객명'
          />
        ) : (
          <CSelect<CustomerTypes>
            options={customers}
            value={(customer: CustomerTypes) => customer.customerId}
            label={(customer: CustomerTypes) => customer.name}
            defaultValue={customers.filter(
              (customer) => customer.name === inputs.customer?.name,
            )}
            func={(selected: CustomerTypes) => {
              onChangeSelect('customer', selected);
            }}
            placeholder='고객명'
          />
        )}
      </S.InputBox>
      <S.InputBox>
        <p>생년월일</p>
        <S.TwoInputs>
          <S.Input
            name='birthday'
            onBlur={onChangeCustomer}
            defaultValue={inputs.customer?.birthday}
            maxLength={6}
            placeholder='주민등록번호 앞자리'
          />
          <S.Input
            name='genderNo'
            onBlur={onChangeCustomer}
            defaultValue={inputs.customer?.genderNo}
            maxLength={1}
            placeholder='뒷자리 한글자'
          />
        </S.TwoInputs>
      </S.InputBox>
      <S.InputBox>
        <p>관리번호</p>
        <S.Input
          name='manageNo'
          onBlur={onChangeCustomer}
          defaultValue={inputs.customer?.manageNo}
          placeholder='관리번호'
        />
      </S.InputBox>
      <S.InputBox>
        <p>주소</p>
        <S.Input
          name='address'
          onBlur={onChangeCustomer}
          defaultValue={inputs.customer?.address}
          placeholder='주소'
        />
      </S.InputBox>
      <S.InputBox>
        <p>상세주소</p>
        <S.Input
          name='addressDetail'
          onBlur={onChangeCustomer}
          defaultValue={inputs.customer?.addressDetail}
          placeholder='상세주소'
        />
      </S.InputBox>
      <S.InputBox>
        <p>기존 통신사</p>
        <CSelect<TelecomTypes>
          options={telecoms}
          value={(telecom: TelecomTypes) => telecom.telecomId}
          label={(telecom: TelecomTypes) => telecom.name}
          defaultValue={telecoms.filter(
            (telecom) =>
              telecom.telecomId === inputs.customer?.telecom?.telecomId,
          )}
          func={(selected: TelecomTypes) => {
            onChangeCustomerTelecom(selected);
          }}
          placeholder='기존 통신사'
          height={135}
        />
      </S.InputBox>
      <S.InputBox>
        <p>기존 휴대전화</p>
        <S.ThreeInputs>
          <S.Input
            name='phone1'
            onBlur={onChangeInputs}
            defaultValue={splitPhone.phone1}
            maxLength={4}
          />
          <S.Input
            name='phone2'
            onBlur={onChangeInputs}
            defaultValue={splitPhone.phone2}
            maxLength={4}
          />
          <S.Input
            name='phone3'
            onBlur={onChangeInputs}
            defaultValue={splitPhone.phone3}
            maxLength={4}
          />
        </S.ThreeInputs>
      </S.InputBox>
      <S.InputBox>
        <p>개통 휴대전화</p>
        <S.ThreeInputs>
          <S.Input
            name='openPhone1'
            onBlur={onChangeInputs}
            defaultValue={splitOpenPhone.openPhone1}
            maxLength={4}
          />
          <S.Input
            name='openPhone2'
            onBlur={onChangeInputs}
            defaultValue={splitOpenPhone.openPhone2}
            maxLength={4}
          />
          <S.Input
            name='openPhone3'
            onBlur={onChangeInputs}
            defaultValue={splitOpenPhone.openPhone3}
            maxLength={4}
          />
        </S.ThreeInputs>
      </S.InputBox>
      <S.InputBox>
        <p>메모</p>
        <S.Input
          name='memo'
          onBlur={onChangeCustomer}
          defaultValue={inputs.customer?.memo}
          placeholder='메모'
        />
      </S.InputBox>
    </S.Grid>
  );
};

export default CustomerOpen;

// 고객유형
const customerTypes = [
  {
    name: '신규',
    value: 'NEW',
  },
  {
    name: '기존',
    value: 'EXIST',
  },
];
