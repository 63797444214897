import BarcodeManage from "../components/BarcodeManage";
import { useState, useEffect, useCallback } from "react";
import {
  apiRoute,
  requestSecureGet,
  requestSecurePatch,
  requestSecurePost,
} from "@libs/api";
import useToken from "@hooks/useToken";
import { DeviceInfoTypes } from "@typedef/components/Device/device.info.types";
import { useRecoilValue } from "recoil";
import { getDeviceColors, getDeviceInfos, getDevices } from "@stories/Selector";

type Props = {
  idx?: number;
  close: () => void;
  reload: () => void;
};

// 단말관리 > 바코드관리 관리 모달
const BarcodeManageContainer = ({ idx, close, reload }: Props) => {
  const { getToken } = useToken();
  const [inputs, setInputs] = useState<Partial<DeviceInfoTypes>>({});
  // 단말기초(device) 리스트 조회
  const devices = useRecoilValue(getDevices(getToken()!));
  // 단말색상(device-color) 리스트 조회
  const colors = useRecoilValue(getDeviceColors(getToken()!));
  // 단말상세(device-info) 리스트 조회
  const infos = useRecoilValue(getDeviceInfos(getToken()!));
  const [message, setMessage] = useState("");

  // 이미 추가된 바코드인지 확인
  const valid = useCallback(() => {
    if (inputs.device?.idx && inputs.color?.idx) {
      const filtered = infos.filter(
        (info) =>
          info.device.idx === inputs.device?.idx &&
          info.color.idx === inputs.color?.idx
      );
      if (filtered.length) {
        setMessage("이미 추가된 바코드입니다.");
      } else {
        setMessage("");
      }
    } else {
      return;
    }
  }, [infos, inputs.color?.idx, inputs.device?.idx]);

  // onChange (input)
  const onChangeInputs = (e: { target: HTMLInputElement }) => {
    const { name, value } = e.target;
    setInputs((inputs) => ({
      ...inputs,
      [name]: value,
    }));
  };

  // onChange (select)
  const onChangeSelect = (name: string, selected: any) => {
    setInputs((inputs) => ({
      ...inputs,
      [name]: selected,
    }));
  };

  // 등록
  const insert = async () => {
    const { config } = await requestSecurePost(
      apiRoute.device.info.postInfo,
      {},
      { devices: [inputs] },
      getToken()!
    );
    if (config.status >= 200 && config.status < 400) {
      alert("성공적으로 등록이 완료되었습니다.");
      close();
      reload();
    }
  };

  // 수정
  const update = async () => {
    const { config } = await requestSecurePatch(
      apiRoute.device.info.patchInfo,
      {},
      inputs,
      getToken()!
    );
    if (config.status >= 200 && config.status < 400) {
      alert("성공적으로 수정이 완료되었습니다.");
      close();
      reload();
    }
  };

  const getCheckedData = async () => {
    const { config, data } = await requestSecureGet<DeviceInfoTypes>(
      apiRoute.device.info.getInfo + idx,
      {},
      getToken()!
    );
    if (config.status >= 200 && config.status < 400) {
      setInputs(data);
    }
  };

  useEffect(() => {
    if (idx) return;
    valid();
  }, [inputs.device, inputs.color, valid, idx]);

  useEffect(() => {
    if (idx) {
      getCheckedData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idx]);

  return (
    <BarcodeManage
      type={idx ? "수정" : "등록"}
      inputs={inputs}
      onChangeInputs={onChangeInputs}
      onChangeSelect={onChangeSelect}
      devices={devices}
      colors={colors}
      message={message}
      save={idx ? update : insert}
    />
  );
};

export default BarcodeManageContainer;
