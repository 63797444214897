import InventoryRequest from '../InventoryRequest';
import { useState, useEffect, useMemo, useCallback } from 'react';
import { apiRoute, requestSecureGet, requestSecurePatch } from '@libs/api';
import useToken from '@hooks/useToken';
import useUser from '@hooks/useUser';
import { TableColumnTypes } from '@typedef/components/Common/CTable/table.column.types';
import { useSetRecoilState } from 'recoil';
import { loading, modal, modalHeader } from '@stories/Atom';
import ShopDeviceAllocateContainer from './ShopDeviceAllocateContainer';
import ShopDeviceRequestContainer from './ShopDeviceRequestContainer';
import { InventoryRequestTypes } from '@typedef/components/Inventory/Request/inventory.request.types';
import CopyCell from '@components/Common/CTable/components/CopyCell';

// 재고관리 > 재고요청관리 (대리점, 판매점)
const InventoryRequestContainer = () => {
  const { getToken } = useToken();
  const { getUser } = useUser();
  const role = useMemo(() => getUser().role, [getUser]);
  // 테이블 데이터
  const [tableData, setTableData] = useState<InventoryRequestTypes[]>([]);
  const [checkedIdxes, setCheckedIdxes] = useState<any[]>([]);
  // 모달설정 (단말요청, 재고배정)
  const setModal = useSetRecoilState(modal);
  const setModalHeader = useSetRecoilState(modalHeader);
  // 테이블 로딩
  const setLoading = useSetRecoilState(loading);
  const [reload, setReload] = useState<number>(0);

  // 체크박스
  const onChangeCheckbox = useCallback(
    (idx: number) => {
      if (checkedIdxes.includes(idx)) {
        setCheckedIdxes(
          checkedIdxes.filter((checkedIdx) => checkedIdx !== idx),
        );
        return;
      }
      setCheckedIdxes((idxes) => [...idxes, idx]);
    },
    [checkedIdxes],
  );

  const columns: TableColumnTypes<InventoryRequestTypes>[] = useMemo(
    () => [
      {
        accessor: 'idx',
        disableSortBy: true,
        width: 70,
        Cell: ({ value }) => (
          <input
            type='checkbox'
            defaultChecked={checkedIdxes.includes(value as number)}
            onChange={() => onChangeCheckbox(value as number)}
          />
        ),
      },
      {
        Header: 'No',
        width: 70,
        Cell: ({ row }) => <div>{tableData.length - row.index}</div>,
      },
      {
        Header: '상태',
        accessor: 'status',
        width: 100,
      },
      {
        Header: '통신사',
        accessor: 'telecom_name',
        disableSortBy: true,
        width: 100,
      },
      {
        Header: '모델명',
        accessor: 'model_name',
        disableSortBy: true,
      },
      {
        Header: '색상',
        accessor: 'color_name',
        disableSortBy: true,
      },
      {
        Header: '배정단말 일련번호',
        accessor: 'shop_device.serial_number',
        disableSortBy: true,
        Cell: ({ value }) => <CopyCell value={value} />,
      },
      {
        Header: '용량',
        accessor: 'volume',
        disableSortBy: true,
      },
      {
        Header: '요청자',
        accessor: 'requestor.name',
        disableSortBy: true,
      },
      {
        Header: '처리자',
        accessor: 'receiver.name',
        disableSortBy: true,
      },
    ],
    [checkedIdxes, onChangeCheckbox, tableData.length],
  );

  // 대리점 > 재고배정
  const allocate = () => {
    if (!!!checkedIdxes.length) {
      alert('배정할 재고를 선택해 주세요.');
      return;
    }

    if (checkedIdxes.length > 1) {
      alert('배정할 재고를 하나만 선택해 주세요.');
      return;
    }

    const filtered = tableData.filter(
      (request) =>
        request.idx === checkedIdxes[0] && request.status === '단말요청',
    );

    if (!!!filtered.length) {
      alert('단말 요청 상태만 배정할 수 있습니다.');
      return;
    }

    setModalHeader('재고배정');
    setModal(
      <ShopDeviceAllocateContainer
        checked={filtered[0]}
        close={() => setModal(null)}
        reload={() => setReload((prev) => prev + 1)}
      />,
    );
  };

  // 대리점 > 반려
  const reject = async () => {
    if (!!!checkedIdxes.length) {
      alert('반려할 요청을 선택해 주세요.');
      return;
    }

    // 배정 상태의 요청 필터링
    const filtered = tableData.filter(
      (request) =>
        checkedIdxes.some((idx) => idx === request.idx) &&
        request.status !== '단말요청',
    );

    if (filtered.length) {
      alert('이미 배정한 요청은 반려할 수 없습니다.');
      return;
    }

    if (window.confirm(`${checkedIdxes.length}개의 요청을 반려하시겠습니까?`)) {
      await Promise.all(
        checkedIdxes.map(
          (idx) =>
            new Promise((resolve, reject) => {
              requestSecurePatch(
                apiRoute.shopDevice.request.patchReject,
                {},
                { idx: idx },
                getToken()!,
              ).then((data) => {
                if (data.config.status >= 200 && data.config.status < 400) {
                  resolve(data);
                }
              });
            }),
        ),
      ).then(() => {
        alert('성공적으로 반려가 완료되었습니다.');
        setReload((prev) => prev + 1);
      });
    }
  };

  // 판매점 > 단말요청
  const request = () => {
    setModalHeader('단말요청');
    setModal(
      <ShopDeviceRequestContainer
        close={() => setModal(null)}
        reload={() => setReload((prev) => prev + 1)}
      />,
    );
  };

  // 판매점 > 요청취소
  const cancelRequest = async () => {
    if (!!!checkedIdxes.length) {
      alert('반려할 요청을 선택해 주세요.');
      return;
    }

    // 배정 상태의 요청 필터링
    const filtered = tableData.filter(
      (request) =>
        checkedIdxes.some((idx) => idx === request.idx) &&
        request.status !== '요청',
    );

    if (filtered.length) {
      alert('이미 배정되거나 반려된 요청은 취소할 수 없습니다.');
      return;
    }

    if (window.confirm(`${checkedIdxes.length}개의 요청을 취소하시겠습니까?`)) {
      await Promise.all(
        checkedIdxes.map(
          (idx) =>
            new Promise((resolve, reject) => {
              requestSecurePatch(
                apiRoute.shopDevice.request.patchCancel + `/${idx}/cancel`,
                {},
                { idx: idx },
                getToken()!,
              ).then((data) => {
                if (data.config.status >= 200 && data.config.status < 400) {
                  resolve(data);
                }
              });
            }),
        ),
      ).then(() => {
        alert('성공적으로 취소가 완료되었습니다.');
        setReload((prev) => prev + 1);
      });
    }
  };

  const getTableData = useCallback(async () => {
    setLoading(true);
    const { config, data } = await requestSecureGet<any>(
      apiRoute.shopDevice.request.getRequests,
      {},
      getToken()!,
    );
    if (config.status >= 200 && config.status < 400) {
      setTableData(data);
      setLoading(false);
    }
  }, [getToken, setLoading]);

  useEffect(() => {
    getTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  return (
    <InventoryRequest
      role={role}
      columns={role.includes('AGENCY') ? columns : columns}
      tableData={tableData}
      allocate={allocate}
      reject={reject}
      request={request}
      cancelRequest={cancelRequest}
    />
  );
};

export default InventoryRequestContainer;
