import styled from 'styled-components';

export const FileInput = styled.input`
  line-height: 38px;
  flex: 1;
  padding-left: 15px;
  position: relative;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-size: 0.9rem;

  &::file-selector-button {
    line-height: 25px;
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translate(0, -50%);
    background-color: #eee;
    border: 1px solid #ccc;
    border-radius: 3px;
    font-family: 'Pretendard', 'Apple SD Gothic Neo';
    cursor: pointer;
  }
`;
