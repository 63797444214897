import TelecomInsurance from "../TelecomInsurance";
import InsuranceManageContainer from "./InsuranceManageContainer";
import { useCallback, useEffect, useMemo, useState } from "react";
import { apiRoute, requestSecureDelete, requestSecureGet } from "@libs/api";
import useToken from "@hooks/useToken";
import { useSetRecoilState } from "recoil";
import { loading, modal, modalHeader } from "@stories/Atom";
import { TableColumnTypes } from "@typedef/components/Common/CTable/table.column.types";
import { InsuranceTypes } from "@typedef/components/Telecom/Insurance/insurance.types";

const TelecomInsuranceContainer = () => {
  const { getToken } = useToken();
  // 테이블 데이터
  const [tableData, setTableData] = useState<InsuranceTypes[]>([]);
  const [checkedIdxes, setCheckedIdxes] = useState<number[]>([]);
  // 모달설정
  const setModal = useSetRecoilState(modal);
  const setModalHeader = useSetRecoilState(modalHeader);
  // 테이블 로딩
  const setLoading = useSetRecoilState(loading);
  const [reload, setReload] = useState<number>(0);

  // 보험 상세조회 및 수정
  const updateInsurance = useCallback(
    (idx?: number) => {
      if (!!!idx && checkedIdxes.length === 0) {
        alert("수정할 보험을 선택해 주세요.");
        return;
      } else if (!!!idx && checkedIdxes.length > 1) {
        alert("수정할 보험을 하나만 선택해 주세요.");
        return;
      }
      setModalHeader("보험 상세조회");
      setModal(
        <InsuranceManageContainer
          idx={idx ?? checkedIdxes[0]}
          close={() => setModal(null)}
          reload={() => setReload((prev) => prev + 1)}
        />
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [checkedIdxes]
  );

  // 체크박스
  const onChangeCheckbox = useCallback(
    (idx: number) => {
      if (checkedIdxes.includes(idx)) {
        setCheckedIdxes(
          checkedIdxes.filter((checkedIdx) => checkedIdx !== idx)
        );
        return;
      }
      setCheckedIdxes((idxes) => [...idxes, idx]);
    },
    [checkedIdxes]
  );

  // 테이블 컬럼
  const columns: TableColumnTypes<InsuranceTypes>[] = useMemo(
    () => [
      {
        Header: "",
        accessor: "insuranceId",
        disableSortBy: true,
        width: 70,
        Cell: ({ value }: any) => (
          <input
            type="checkbox"
            defaultChecked={checkedIdxes.includes(value)}
            onChange={() => onChangeCheckbox(value)}
          />
        ),
      },
      {
        Header: "No",
        width: 70,
        Cell: ({ row }: any) => <>{row.index + 1}</>,
      },
      {
        Header: "통신사",
        accessor: "telecomId",
        width: 70,
      },
      {
        Header: "보험명",
        accessor: "name",
        disableSortBy: true,
        Cell: ({ row, value }: any) => (
          <div
            className="detail"
            onClick={() => updateInsurance(row.original.insuranceId)}
          >
            {value}
          </div>
        ),
      },
      {
        Header: "금액",
        accessor: "price",
        width: 70,
      },
      {
        Header: "대상",
        accessor: "targetExp",
        disableSortBy: true,
      },
      {
        Header: "보상범위",
        accessor: "rangeExp",
        disableSortBy: true,
      },
      {
        Header: "자기부담금",
        accessor: "selfPriceExp",
        disableSortBy: true,
      },
    ],
    [checkedIdxes, onChangeCheckbox, updateInsurance]
  );

  // 부가서비스 등록
  const insertInsurance = () => {
    setModalHeader("보험 등록");
    setModal(
      <InsuranceManageContainer
        close={() => setModal(null)}
        reload={() => setReload((prev) => prev + 1)}
      />
    );
  };

  // 보험 삭제
  const deleteInsurance = async () => {
    if (checkedIdxes.length === 0) {
      alert("삭제할 보험을 선택해 주세요");
      return;
    } else if (
      window.confirm(
        `선택한 ${checkedIdxes.length}개의 보험을 삭제하시겠습니까?`
      )
    ) {
      await Promise.all(
        checkedIdxes.map(
          (idx) =>
            new Promise((resolve, reject) => {
              requestSecureDelete(
                apiRoute.telecom.insurance.deleteInsurance + idx,
                {},
                getToken()!
              ).then((data) => {
                if (data.config.status >= 200 && data.config.status < 400) {
                  resolve(data);
                }
              });
            })
        )
      ).then(() => {
        alert("성공적으로 삭제가 완료되었습니다.");
        setReload((prev) => prev + 1);
      });
    }
  };

  const getTableData = async () => {
    const { config, data } = await requestSecureGet<{
      insurances: InsuranceTypes[];
    }>(apiRoute.telecom.insurance.getInsurances, {}, getToken()!);
    if (config.status >= 200 && config.status < 400) {
      setTableData(data.insurances);
      setLoading(false);
    } else {
      setTableData([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  return (
    <TelecomInsurance
      columns={columns}
      tableData={tableData}
      insertInsurance={insertInsurance}
      updateInsurance={updateInsurance}
      deleteInsurance={deleteInsurance}
    />
  );
};

export default TelecomInsuranceContainer;
