import {Navigate, Route, Routes} from "react-router-dom";
import BoardContainer from "@components/Board/Board/containers/BoardContainer";
import BoardCategoryContainer from "@components/Board/Category/containers/BoardCategoryContainer";
import BoardQNAContainer from "@components/Board/QNA/containers/BoardQNAContainer";
import DeviceBarcodeContainer from "@components/Device/Barcode/containers/DeviceBarcodeContainer";
import DeviceColorContainer from "@components/Device/Color/containers/DeviceColorContainer";
import DeviceContainer from "@components/Device/Device/containers/DeviceContainer";
import DeviceMakerContainer from "@components/Device/Maker/containers/DeviceMakerContainer";
import JSolMainContainer from "@components/Main/containers/JSolMainContainer";
import ShopRequestContainer from "@components/Shop/Request/containers/ShopRequestContainer";
import ShopContainer from "@components/Shop/Shop/containers/ShopContainer";
import TelecomInsuranceContainer from "@components/Telecom/Insurance/containers/TelecomInsuranceContainer";
import TelecomPhoneplanContainer from "@components/Telecom/Phoneplan/containers/TelecomPhoneplanContainer";
import TelecomSubserviceContainer from "@components/Telecom/Subservice/containers/TelecomSubserviceContainer";
import TelecomContainer from "@components/Telecom/Telecom/containers/TelecomContainer";
import TelecomPublicSupportContainer from "@components/Telecom/PublicSupport/containers/TelecomPublicSupportContainer";
// import TempShopContainer from "@components/Shop/Shop/containers/TempShopContainer";

const JSolRoleNavigation = () => {
    return (
        <Routes>
            {/* 메인 */}
            <Route path="" element={<JSolMainContainer/>}/>
            {/* 업체관리 */}
            <Route path="/shops/shop" element={<ShopContainer/>}/>
            {/* <Route path="/shops/shop" element={<TempShopContainer />} /> */}
            <Route path="/shops/reqeust" element={<ShopRequestContainer/>}/>
            {/* 단말관리 */}
            <Route path="/devices/device" element={<DeviceContainer/>}/>
            <Route path="/devices/color" element={<DeviceColorContainer/>}/>
            <Route path="/devices/barcode" element={<DeviceBarcodeContainer/>}/>
            <Route path="/devices/maker" element={<DeviceMakerContainer/>}/>
            {/* 통신사관리 */}
            {/* 통신사관리 - 통신사관리 */}
            <Route path="/telecoms/telecom" element={<TelecomContainer/>}/>
            {/* 통신사관리 - 요금제관리 */}
            <Route
                path="/telecoms/phoneplan"
                element={<TelecomPhoneplanContainer/>}
            />
            {/* 통신사관리 - 부가서비스관리 */}
            <Route
                path="/telecoms/subservice"
                element={<TelecomSubserviceContainer/>}
            />
            {/* 통신사관리 - 보험금관리 */}
            <Route
                path="/telecoms/insurance"
                element={<TelecomInsuranceContainer/>}
            />
            {/* 통신사관리 - 공시지원금관리*/}
            <Route
                path="/telecoms/publicsupport"
                element={<TelecomPublicSupportContainer/>}
            />
            {/* 게시글관리 */}
            <Route path="/boards/category" element={<BoardCategoryContainer/>}/>
            <Route path="/boards/board" element={<BoardContainer/>}/>
            <Route path="/boards/qna" element={<BoardQNAContainer/>}/>
            <Route path="*" element={<Navigate to=""/>}/>
        </Routes>
    );
};

export default JSolRoleNavigation;
