import BarcodeInsert from '../components/BarcodeInsert';
import { apiRoute, requestSecureGet } from '@libs/api';
import useToken from '@hooks/useToken';
import { ShopDeviceInsertTypes } from '@typedef/components/Inventory/Put/shop.device.insert.types';
import { DeviceBarcodeTypes } from '@typedef/components/Device/Barcode/device.barcode.types';
import { TelecomTypes } from '@typedef/components/Telecom/telecom.types';
import { DeviceCategoryTypes } from '@typedef/components/Device/Category/device.category.types';

type Props = {
  telecoms: TelecomTypes[];
  categories: DeviceCategoryTypes[];
  inputs: ShopDeviceInsertTypes;
  setInputs: React.Dispatch<React.SetStateAction<ShopDeviceInsertTypes>>;
  onChangeInputs: (e: {
    target: HTMLInputElement | HTMLTextAreaElement;
  }) => void;
  onChangeSelect: (name: string, selected: any) => void;
  barcodeRef: React.RefObject<HTMLInputElement>;
};

const BarcodeInsertContainer = ({
  telecoms,
  categories,
  inputs,
  setInputs,
  onChangeInputs,
  onChangeSelect,
  barcodeRef,
}: Props) => {
  const { getToken } = useToken();

  // 바코드 값을 분리하여 일련번호 조회
  const getSerialNumber = (value: string) => {
    const barcode = value.toUpperCase();
    if (barcode.startsWith('SM')) {
      // 삼성
      return barcode.substring(8, 15);
    } else if (barcode.startsWith('70')) {
      // KT전용
      return barcode.substring(7, 14);
    } else if (barcode.startsWith('S')) {
      // IPhone12 퍼플 이전
      return barcode.substring(1);
    }
  };

  // 바코드 검색
  const onChangeBarcodeValue = async (
    e: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    const { value } = e.currentTarget;
    if (!!!value) return;

    // 바코드 값으로 단말 검색
    const { config, data } = await requestSecureGet<DeviceBarcodeTypes>(
      apiRoute.device.barcode.getBarcodes + value,
      {},
      getToken()!,
    );
    if (config.status >= 200 && config.status < 400) {
      if (data !== null) {
        setInputs((inputs) => ({
          ...inputs,
          device_color: data.color.name,
          model_name: data.device.model_name,
          price: data.device.price,
          volume: data.device.volume,
        }));
      } else {
        alert('바코드 내역이 없습니다.');
        return;
      }
    }

    // 단말 일련번호 값
    const serial_number = getSerialNumber(value);
    if (serial_number) {
      setInputs((inputs) => ({
        ...inputs,
        serial_number: serial_number,
      }));
    }
  };

  return (
    <BarcodeInsert
      telecoms={telecoms}
      categories={categories}
      inputs={inputs}
      onChangeInputs={onChangeInputs}
      onChangeSelect={onChangeSelect}
      onChangeBarcodeValue={onChangeBarcodeValue}
      barcodeRef={barcodeRef}
    />
  );
};

export default BarcodeInsertContainer;
