import ReactSelect from 'react-select';
import styled from 'styled-components';

export const Select = styled(ReactSelect)`
  flex: 1;

  // Selected + 화살표
  .react-select__control {
    height: 40px;
    border-radius: 3px;
  }

  // Selected
  .react-select__value-container {
    padding: 0 15px;
  }

  .react-select__placeholder {
    margin: 0;
    color: #999;
    font-size: 0.9rem;
    font-weight: 300;
  }

  .react-select__control {
    .custom {
      width: 36px;
      ${({ theme }) => theme.flex.row}
      align-items: center;
      justify-content: center;
      padding: 0;
      color: #bbb;
    }
  }

  // 셀렉트 열렸을 때
  .react-select__control--menu-is-open {
    // 화살표
    .react-select__dropdown-indicator {
      transform: scaleY(-1);
    }

    .custom {
      transform: scaleY(1);
    }
  }

  .react-select__menu {
    padding: 10px;
  }

  // 스크롤
  .react-select__menu-list {
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-track {
      background: #eee;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background: #ccc;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #ccc;
    }
  }

  // Option
  .react-select__option {
    border-bottom: 1px solid #eee;
    cursor: pointer;
    padding: 10px;

    &:hover {
      background-color: ${({ theme }) => theme.colors.brand100};
    }

    &:last-child {
      border-bottom: none;
    }
  }

  .react-select__option--is-focused {
    background-color: ${({ theme }) => theme.colors.brand100};
  }

  // Option-selected
  .react-select__option--is-selected {
    ${({ theme }) => theme.flex.row}
    align-items: center;
    position: relative;
    background: none;
    color: ${({ theme }) => theme.colors.brand500};
    font-weight: 700;

    &::before {
      font-family: 'FontAwesome';
      content: '\f058';
      margin-right: 10px;
    }
  }

  // disabled
  .react-select__control--is-disabled {
    background-color: #fff;
    border-color: #ccc;

    .react-select__single-value--is-disabled {
      color: #333;
    }
    .react-select__indicator-separator {
      background-color: #ccc;
    }
  }
`;
