import styled from 'styled-components';

export const Toggle = styled.section`
  input[type='checkbox'] {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  label {
    width: 50px;
    height: 30px;
    display: block;
    background: ${({ theme }) => theme.colors.grey200};
    border-radius: 100px;
    position: relative;
    text-indent: -9999px;
    cursor: pointer;
  }

  label:after {
    content: '';
    position: absolute;
    top: 5px;
    left: 5px;
    width: 20px;
    height: 20px;
    background: #fff;
    border-radius: 90px;
    transition: 0.3s;
  }

  input:checked + label {
    background: ${({ theme }) => theme.colors.brand500};
  }

  input:checked + label:after {
    left: calc(100% - 5px);
    transform: translateX(-100%);
  }
`;
