import PhonePlanManage from "../components/PublicSupportManage";
import { useState, useEffect } from "react";
import {
  apiRoute,
  requestSecureGet,
  requestSecurePatch,
  requestSecurePost,
} from "@libs/api";
import useToken from "@hooks/useToken";
import { useRecoilValue } from "recoil";
import {
  getDeviceInfos,
  getTelecomPhoneplan,
  getTelecoms,
} from "@stories/Selector";
import { PublicSupportTypes } from "@typedef/components/Telecom/PublicSupport/publicSupport.types";

type Props = {
  idx?: number;
  close: () => void;
  reload: () => void;
};

// 통신사관리 > 공시지원금관리 관리 모달
const PublicSupportManageContainer = ({ idx, close, reload }: Props) => {
  const { getToken } = useToken();
  const [inputs, setInputs] = useState<Partial<PublicSupportTypes>>({});
  // 통신사 리스트 조회
  const telecoms = useRecoilValue(getTelecoms(getToken()!));
  // 단말 리스트 조회
  const deviceInfos = useRecoilValue(getDeviceInfos(getToken()!));
  // 요금제 리스트 조회
  const phonePlans = useRecoilValue(getTelecomPhoneplan(getToken()!));

  // onChange (input)
  const onChangeInputs = (e: { target: HTMLInputElement }) => {
    const { name, value } = e.target;
    let saveValue = value;
    if (["amount"].includes(name)) {
      saveValue = value.replace(/[^0-9]/g, "");
    }
    setInputs((inputs) => ({
      ...inputs,
      [name]: saveValue,
    }));
  };

  // onChange (select)
  const onChangeSelect = <T extends {}>(name: string, selected: T) => {
    setInputs((inputs) => ({
      ...inputs,
      [name]: selected,
    }));
  };

  // 등록
  const insert = async () => {
    // 현재 inputs 값 체크
    console.log("inputs :", inputs);
    const { config } = await requestSecurePost(
      apiRoute.telecom.publicSupport.postPublicSupport,
      {},
      inputs,
      getToken()!
    );
    if (config.status >= 200 && config.status < 400) {
      alert("성공적으로 등록이 완료되었습니다.");
      close();
      reload();
    }
  };

  // 수정
  const update = async () => {
    const { config } = await requestSecurePatch(
      apiRoute.telecom.publicSupport.patchPublicSupport + idx,
      {},
      inputs,
      getToken()!
    );
    if (config.status >= 200 && config.status < 400) {
      alert("성공적으로 수정이 완료되었습니다.");
      close();
      reload();
    }
  };

  const getCheckedData = async () => {
    const { config, data } = await requestSecureGet<PublicSupportTypes>(
      apiRoute.telecom.publicSupport.getPublicSupport + idx,
      {},
      getToken()!
    );
    if (config.status >= 200 && config.status < 400) {
      setInputs(data);
    }
  };

  useEffect(() => {
    if (idx) {
      getCheckedData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idx]);

  return (
    <PhonePlanManage
      type={idx ? "수정" : "등록"}
      inputs={inputs}
      onChangeInputs={onChangeInputs}
      onChangeSelect={onChangeSelect}
      telecoms={telecoms}
      deviceInfos={deviceInfos}
      phonePlans={phonePlans}
      save={idx ? update : insert}
    />
  );
};

export default PublicSupportManageContainer;
