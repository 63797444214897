import * as S from '@styles/components/TableViewStyle';
import CTable from '@components/Common/CTable/CTable';
import { TableColumnTypes } from '@typedef/components/Common/CTable/table.column.types';

type Props = {
  columns: TableColumnTypes[];
  tableData: any;
  insertConsult: () => void;
  updateConsult: (idx?: number) => void;
  deleteConsult: () => Promise<void>;
};

const CustomerConsult = ({
  columns,
  tableData,
  insertConsult,
  updateConsult,
  deleteConsult,
}: Props) => {
  return (
    <S.Main>
      <S.TopBtns>
        <h3>전체 {tableData.length}</h3>
        <S.Btns>
          <S.Btn type='button' onClick={insertConsult} className='active'>
            상담등록
          </S.Btn>
          <S.Btn type='button' onClick={() => updateConsult()}>
            수정
          </S.Btn>
          <S.Btn type='button' onClick={deleteConsult}>
            삭제
          </S.Btn>
        </S.Btns>
      </S.TopBtns>
      <CTable columns={columns} tableData={tableData} />
    </S.Main>
  );
};

export default CustomerConsult;
