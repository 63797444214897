import styled from 'styled-components';

const space = '15px';
const halfSpace = '7.5px';
const radius = '7px';

export const Main = styled.main`
  flex: 1;
  ${({ theme }) => theme.flex.col}
  margin: 30px;
  background-color: #fff;
  border-radius: 15px;
`;

export const TopBtns = styled.section`
  height: 70px;
  ${({ theme }) => theme.flex.row}
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  border-bottom: 1px solid #eee;
`;

export const Btns = styled.div`
  .active {
    background-color: ${({ theme }) => theme.colors.brand500};
    color: #fff;

    &::after {
      font-family: 'FontAwesome';
      content: '\f055';
      margin-left: 10px;
      font-weight: 100;
    }
  }

  .minus {
    &::after {
      content: '\f056';
    }
  }

  .check {
    &::after {
      content: '\f00c';
    }
  }

  .excel {
    /* background-color: #34a81d; */
  }
`;

export const Btn = styled.button`
  height: 35px;
  line-height: 35px;
  margin-left: 10px;
  padding: 0 10px;
  background-color: ${({ theme }) => theme.colors.brand200};
  color: ${({ theme }) => theme.colors.brand900};
  font-size: 0.85rem;
  font-weight: 600;
  border-radius: 7px;
`;

export const FilterBox = styled.section`
  width: 300px;
  margin-bottom: 30px;
  position: relative;

  .inputs {
    ${({ theme }) => theme.flex.row}
    align-items: center;
    margin-top: 7.5px;

    .react-datepicker__input-container {
      input {
        width: 100%;
        height: 35px;
        background-color: ${({ theme }) => theme.colors.grey200};
        text-align: center;
        border-radius: ${radius};
      }
    }
    span {
      margin: 0 ${halfSpace};
      text-align: center;
    }
  }

  .btns {
    ${({ theme }) => theme.flex.row}
    flex-wrap: wrap;
    align-items: center;
    margin-top: 7.5px;
    button {
      flex: 1;
      height: 35px;
      margin-right: ${halfSpace};
      background-color: ${({ theme }) => theme.colors.grey200};
      text-align: center;
      border-radius: ${radius};

      &:last-child {
        margin-right: 0;
      }
    }
  }
`;

export const FilterBtns = styled.section`
  ${({ theme }) => theme.flex.row}
  align-items: center;
  justify-content: space-between;

  .active {
    padding: 0 ${space};
    background-color: ${({ theme }) => theme.colors.brand900};
    color: #fff;
    border-radius: ${radius};
  }
`;

export const FilterBtn = styled.button`
  height: 35px;
  font-weight: 300;
  &::before {
    font-family: 'FontAwesome';
    margin-right: ${halfSpace};
  }
`;
