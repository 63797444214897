import * as S from "@styles/components/TableViewStyle";
import CTable from "@components/Common/CTable/CTable";
import { TableColumnTypes } from "@typedef/components/Common/CTable/table.column.types";
import { InsuranceTypes } from "@typedef/components/Telecom/Insurance/insurance.types";

type Props = {
  columns: TableColumnTypes<InsuranceTypes>[];
  tableData: InsuranceTypes[];
  insertInsurance: () => void;
  updateInsurance: (idx?: number) => void;
  deleteInsurance: () => Promise<void>;
};

const TelecomInsurance = ({
  columns,
  tableData,
  insertInsurance,
  updateInsurance,
  deleteInsurance,
}: Props) => {
  return (
    <S.Main>
      <S.TopBtns>
        <h3>전체 {tableData.length}</h3>

        <S.Btns>
          <S.Btn type="button" onClick={insertInsurance} className="active">
            보험등록
          </S.Btn>
          <S.Btn type="button" onClick={() => updateInsurance()}>
            수정
          </S.Btn>
          <S.Btn type="button" onClick={deleteInsurance}>
            삭제
          </S.Btn>
        </S.Btns>
      </S.TopBtns>
      <CTable columns={columns} tableData={tableData} />
    </S.Main>
  );
};

export default TelecomInsurance;
