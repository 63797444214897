import { Main, Split, Bottoms } from '@styles/components/ModalBodyStyle';
import * as S from '@styles/components/FormStyle';
import CSelect from '@components/Common/CSelect/CSelect';
import { ConsultTypes } from '@typedef/components/Customer/Consult/consult.types';
import { CustomerTypes } from '@typedef/components/Customer/customer.types';
import { TelecomTypes } from '@typedef/components/Telecom/telecom.types';
import { SelectDefaultTypes } from '@typedef/components/Common/CSelect/select.default.types';

type Props = {
  type: string;
  inputs: Partial<ConsultTypes>;
  onChangeInputs: (e: {
    target: HTMLInputElement | HTMLTextAreaElement;
  }) => void;
  onChangeSelect: <T extends {}>(name: string, selected: T) => void;
  customers: CustomerTypes[];
  telecoms: TelecomTypes[];
  save: () => Promise<void>;
};

const ConsultManage = ({
  type,
  inputs,
  onChangeInputs,
  onChangeSelect,
  customers,
  telecoms,
  save,
}: Props) => {
  return (
    <Main>
      <S.Row>
        <Split width={33.333} justify='space-between'>
          <S.Col>
            <S.SubTitle>
              <h3>고객 정보</h3>
            </S.SubTitle>
            <S.InputBox>
              <p>고객명</p>
              <CSelect<CustomerTypes>
                options={customers}
                value={(customer: CustomerTypes) => customer.customerId}
                label={(customer: CustomerTypes) =>
                  `${customer.name} (${customer.birthday})`
                }
                func={(selected: CustomerTypes) =>
                  onChangeSelect('customer', selected)
                }
                placeholder='고객명'
              />
            </S.InputBox>
            <S.InputBox>
              <p>생년월일</p>
              <S.Input
                value={inputs.customer?.birthday}
                placeholder='생년월일'
                disabled
              />
            </S.InputBox>
            <S.InputBox>
              <p>휴대전화</p>
              <S.Input
                value={inputs.customer?.phone}
                placeholder='휴대전화'
                disabled
              />
            </S.InputBox>
            <S.InputBox>
              <p>관리번호</p>
              <S.Input
                value={inputs.customer?.manageNo}
                placeholder='관리번호'
                disabled
              />
            </S.InputBox>
          </S.Col>
          <p>신규고객인 경우 등록을 먼저 해주세요</p>
        </Split>
        <Split width={66.666} borderL>
          <S.SubTitle isRow justify='space-between'>
            <h3>상담 정보</h3>
            {/* <label>
              <input type='checkbox' defaultChecked={false} />
              수기입력
            </label> */}
          </S.SubTitle>
          <S.Grid>
            <S.InputBox>
              <p>희망 개통유형</p>
              <CSelect<SelectDefaultTypes>
                options={openTypes}
                value={(type: SelectDefaultTypes) => type.value}
                label={(type: SelectDefaultTypes) => type.name}
                func={(selected: SelectDefaultTypes) =>
                  onChangeSelect('openType', selected.value)
                }
                placeholder='개통유형'
              />
            </S.InputBox>
            <S.InputBox>
              <p>희망 할인유형</p>
              <CSelect<SelectDefaultTypes>
                options={contractTypes}
                value={(type: SelectDefaultTypes) => type.value}
                label={(type: SelectDefaultTypes) => type.name}
                func={(selected: SelectDefaultTypes) =>
                  onChangeSelect('contractType', selected.value)
                }
                placeholder='약정유형'
              />
            </S.InputBox>
            <S.InputBox>
              <p>희망 통신사</p>
              <CSelect<TelecomTypes>
                options={telecoms}
                value={(telecom: TelecomTypes) => telecom.telecomId}
                label={(telecom: TelecomTypes) => telecom.name}
                func={(selected: TelecomTypes) =>
                  onChangeSelect('wishTelecomName', selected.name)
                }
                placeholder='통신사'
              />
            </S.InputBox>
            <S.InputBox>
              <p>희망 모델명</p>
              <S.Input
                name='wishModelName'
                onChange={onChangeInputs}
                placeholder='ex) AIP13-512'
              />
            </S.InputBox>
            <S.InputBox>
              <p>희망 색상</p>
              <S.Input
                name='wishDeviceColor'
                onChange={onChangeInputs}
                placeholder='ex) 시에라블루'
              />
            </S.InputBox>
            <S.InputBox>
              <p>희망 요금제</p>
              <S.Input
                name='wishPhonePlan'
                onChange={onChangeInputs}
                placeholder='ex) 슈퍼플랜 스페셜'
              />
            </S.InputBox>
            <S.InputBox>
              <p>메모</p>
              <S.Textarea name='memo' onChange={onChangeInputs} />
            </S.InputBox>
          </S.Grid>
        </Split>
      </S.Row>
      <Bottoms>
        <button
          type='button'
          onClick={save}
          disabled={!!!inputs.customer?.customerId}>
          상담 {type}하기
        </button>
      </Bottoms>
    </Main>
  );
};

export default ConsultManage;

// 할인유형
const contractTypes = [
  {
    name: '선약',
    value: 'PHONE',
  },
  {
    name: '공시',
    value: 'DEVICE',
  },
  {
    name: '무약정',
    value: 'NONE',
  },
];

// 개통유형
const openTypes = [
  {
    name: '신규',
    value: 'NEW',
  },
  {
    name: '번호이동',
    value: 'MNP',
  },
  {
    name: '기기변경',
    value: 'DEV',
  },
  {
    name: '전환신규',
    value: 'GEN',
  },
];
