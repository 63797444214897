import styled from 'styled-components';

const padding = '1.5vw';

type Props = {
  width?: number;
  height?: number;
  isRow?: boolean;
  align?: string;
  justify?: string;
  borderL?: boolean;
};

// 본문 + submit
// 너비지정
export const Main = styled.main<Props>`
  width: ${(props) => (props.width ? props.width : 70)}vw;
  ${({ theme }) => theme.flex.col}
  flex-direction: ${(props) => props.isRow && 'row'};
  background-color: #fff;
  border-radius: 0 0 0.5vw 0.5vw;
`;

// 본문 분할
// 높이지정
export const Split = styled.section<Props>`
  width: ${({ width }) => width || 100}%;
  height: ${({ height }) => height}vh;
  ${({ theme }) => theme.flex.col}
  flex-direction: ${(props) => props.isRow && 'row'};
  align-items: ${({ align }) => align};
  justify-content: ${({ justify }) => justify};
  padding: ${padding};
  border-left: ${({ borderL }) => borderL && '1px solid #eee'};
`;

// 본문 하단 (submit)
export const Bottoms = styled.section<{ self?: string }>`
  ${({ theme }) => theme.flex.row}
  padding: calc(${padding} / 2) ${padding};
  border-top: 1px solid #eee;

  button {
    width: 100%;
    height: 40px;
    align-self: ${({ self }) => self};
    background-color: ${({ theme }) => theme.colors.brand900};
    border-radius: 3px;
    color: #fff;
    font-weight: 500;

    &:disabled {
      background-color: #0f29624c;
      color: #fff;
      cursor: auto;
    }
  }
`;
