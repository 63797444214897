import BoardCategoryManage from '../components/BoardCategoryManage';
import { useState, useEffect } from 'react';
import {
  apiRoute,
  requestSecureGet,
  requestSecurePatch,
  requestSecurePost,
} from '@libs/api';
import useToken from '@hooks/useToken';
import { BoardCategoryTypes } from '@typedef/components/Board/Category/board.category.types';

type Props = {
  idx?: number;
  close: () => void;
  reload: () => void;
};

const BoardCategoryManageContainer = ({ idx, close, reload }: Props) => {
  const { getToken } = useToken();
  const [inputs, setInputs] = useState<Partial<BoardCategoryTypes>>({});

  // onChange (input)
  const onChangeInputs = (e: { target: HTMLInputElement }) => {
    const { name, value } = e.target;
    setInputs((inputs) => ({
      ...inputs,
      [name]: value,
    }));
  };

  const insert = async () => {
    const { config } = await requestSecurePost(
      apiRoute.board.category.postCategory,
      {},
      { ...inputs, isPublic: 1 },
      getToken()!,
    );
    if (config.status >= 200 && config.status < 400) {
      alert('성공적으로 등록이 완료되었습니다');
      close();
      reload();
    }
  };

  const update = async () => {
    const { config } = await requestSecurePatch(
      apiRoute.board.category.patchCategory,
      {},
      inputs,
      getToken()!,
    );
    if (config.status >= 200 && config.status < 400) {
      alert('성공적으로 수정이 완료되었습니다');
      close();
      reload();
    }
  };

  const getCheckedData = async (idx: number) => {
    const { config, data } = await requestSecureGet<{
      boardCategory: BoardCategoryTypes;
    }>(apiRoute.board.category.getCategory + idx, {}, getToken()!);
    if (config.status >= 200 && config.status < 400) {
      setInputs(data.boardCategory);
    }
  };

  useEffect(() => {
    if (idx) {
      getCheckedData(idx);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idx]);

  return (
    <BoardCategoryManage
      type={idx ? '수정' : '등록'}
      inputs={inputs}
      onChangeInputs={onChangeInputs}
      save={idx ? update : insert}
    />
  );
};

export default BoardCategoryManageContainer;
