import ShopDeviceTempout from "../components/ShopDeviceTempOut";
import React, {useCallback, useEffect, useState} from "react";
import {apiRoute, requestSecureGet, requestSecurePatch} from "@libs/api";
import useToken from "@hooks/useToken";
import {ShopDeviceTypes} from "@typedef/components/Inventory/shop.device.types";
import {ShopDeviceReleaseTypes} from "@typedef/components/Inventory/Release/shop.device.release.types";
import dayjs from "dayjs";
import {ClientDepartmentTypes, ClientTypes,} from "@typedef/components/Client/client.types";

type Props = {
    close: () => void;
    reload: () => void;
    type: "수리" | "대여";
};

const ShopDeviceTempOutContainer = ({close, reload, type}: Props) => {
    const typeIdx = type === "수리" ? 8 : 9;
    const {getToken} = useToken();
    const today = dayjs();
    // 전체 재고 목록
    const [devices, setDevices] = useState<ShopDeviceReleaseTypes[]>([]);
    // 검색한 재고 목록
    const [searchDevices, setSearchDevices] = useState<ShopDeviceReleaseTypes[]>(
        []
    );
    // 거래처 목록
    const [clients, setClients] = useState<ClientTypes[]>([]);
    const [checkedIdxes, setCheckedIdxes] = useState<number[]>([]);
    const [inputs, setInputs] = useState<{
        year: string;
        month: string;
        day: string;
        memo: string;
        client: ClientTypes | null;
        department: ClientDepartmentTypes | null;
    }>({
        year: today.format("YYYY"),
        month: today.format("MM"),
        day: today.format("DD"),
        memo: "",
        client: null,
        department: null,
    });

    // 일련번호로 재고 검색
    const searchBySerial = (value: string) => {
        const filtered = devices.filter((device) =>
            device.serial_number.includes(value)
        );
        if (value.length < 1) {
            setSearchDevices(devices);
        } else {
            setSearchDevices(filtered);
        }
    };

    // 출고할 단말 선택(체크박스)
    const onChangeCheckbox = (idx: number, checked: boolean) => {
        // 체그 했을경우
        if (checked) {
            setCheckedIdxes((checkedIdxes) => [...checkedIdxes, idx]);
        } else {
            // 체크 해제 했을경우
            const filtered = checkedIdxes.filter(
                (releaseDevice) => releaseDevice !== idx
            );
            setCheckedIdxes(filtered);
        }
    };

    // onChange (출고일)
    const onChangeSelect = (name: string, selected: string) => {
        setInputs((inputs) => ({
            ...inputs,
            [name]: selected,
        }));
    };

    // 거래처 선택
    const onChangeClient = (client: ClientTypes | null) => {
        setInputs({
            ...inputs,
            client: client,
            department: inputs.client === client ? inputs.department : null,
        });
    };

    // 부서 선택
    const onChangeDepartment = (dept: ClientDepartmentTypes | null) => {
        setInputs({...inputs, department: dept});
    };

    // onChange (메모)
    const onChangeInputs = (e: { target: HTMLInputElement }) => {
        const {name, value} = e.target;
        setInputs((inputs) => ({
            ...inputs,
            [name]: value,
        }));
    };

    // 선택한 재고 출고하기
    const tempOut = async () => {
        const checkedDevices = devices.filter((device) =>
            checkedIdxes.some((idx) => idx === device.idx)
        );

        // 선택된 재고들의 status와 category를 강제로 변경해서 요청한다.
        const deviceIdxes = checkedDevices.map((device) => device.idx);

        const patchData = {
            deviceIdxes: deviceIdxes,
            // 수리 8 대여 9
            statusIdx: typeIdx,
            date: `${inputs.year}-${inputs.month}-${inputs.day}`,
            memo: inputs.memo,
            clientId: inputs.client?.clientId,
            clientName: inputs.client?.name,
            departmentId: inputs.department?.departmentId,
            departmentName: inputs.department?.name,
        };

        const {config} = await requestSecurePatch(
            apiRoute.shopDevice.release.tempOut,
            {},
            patchData,
            getToken()!
        );
        if (config.status >= 200 && config.status < 400) {
            alert(`성공적으로 ${type} 처리되었습니다.`);
            close();
            reload();
            setCheckedIdxes([])
        }
    };

    // 출고 가능한 재고 조회
    const getReleaseDeviceData = useCallback(async () => {
        const {config, data} = await requestSecureGet<ShopDeviceTypes[]>(
            apiRoute.shopDevice.getDevices + "?status_type=in",
            {},
            getToken()!
        );
        if (config.status >= 200 && config.status < 400) {
            const processed = data.map((data) => {
                return {
                    idx: data.idx,
                    telecom_maker_name: data.maker_nm ?? data.telecom.name,
                    model_name: data.device_info.device.model_name ?? data.model_name,
                    color: data.device_info.color.name ?? data.device_color,
                    serial_number: data.serial_number,
                    out_price: data.device_info.device.price ?? data.price,
                    category_idx: 6,
                    category_name: "판매",
                };
            });
            setDevices(processed);
            setSearchDevices(processed);
        }
    }, [getToken]);

    // 거래처 조회
    const getClients = async () => {
        const {config, data} = await requestSecureGet<ClientTypes[]>(
            apiRoute.client.getClients,
            {},
            getToken()!
        );

        if (config.status >= 200 && config.status < 400) {
            setClients(data);
        }
    };

    useEffect(() => {
        getReleaseDeviceData();
        getClients();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ShopDeviceTempout
            type={type}
            searchDevices={searchDevices}
            checkedIdxes={checkedIdxes}
            clients={clients}
            searchBySerial={searchBySerial}
            onChangeCheckbox={onChangeCheckbox}
            inputs={inputs}
            onChangeInputs={onChangeInputs}
            onChangeSelect={onChangeSelect}
            tempOut={tempOut}
            onChangeClient={onChangeClient}
            onChangeDepartment={onChangeDepartment}
        />
    );
};

export default ShopDeviceTempOutContainer;
