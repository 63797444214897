import * as S from '@styles/components/TableViewStyle';
import CTable from '@components/Common/CTable/CTable';
import { TableColumnTypes } from '@typedef/components/Common/CTable/table.column.types';
import { ShopDeviceTypes } from '@typedef/components/Inventory/shop.device.types';

type Props = {
  columns: TableColumnTypes<ShopDeviceTypes>[];
  tableData: ShopDeviceTypes[];
  release: () => void;
  cancelRelease: () => Promise<void>;
  updateShopDevice: (idx?: number) => void;
  deleteShopDevice: () => Promise<void>;
};

const InventoryRelease = ({
  columns,
  tableData,
  release,
  cancelRelease,
  updateShopDevice,
  deleteShopDevice,
}: Props) => {
  return (
    <S.Main>
      <S.TopBtns>
        <h3>전체 {tableData.length}</h3>
        <S.Btns>
          <S.Btn type='button' onClick={release} className='active minus'>
            재고출고
          </S.Btn>
          <S.Btn type='button' onClick={cancelRelease}>
            입고처리
          </S.Btn>
          <S.Btn type='button' onClick={() => updateShopDevice()}>
            수정
          </S.Btn>
          <S.Btn type='button' onClick={deleteShopDevice}>
            삭제
          </S.Btn>
        </S.Btns>
      </S.TopBtns>
      <CTable columns={columns} tableData={tableData} />
    </S.Main>
  );
};

export default InventoryRelease;
