import { createGlobalStyle } from 'styled-components';
import img from '@assets/image';

export const GlobalStyle = createGlobalStyle<{ overflow: boolean }>`
  *{
    margin: 0;
    padding: 0;
    font-family: 'Pretendard','Apple SD Gothic Neo';
    box-sizing: border-box;
  }

  body {
    width: 100%;
    max-width: 1920px;
    height: 100vh;
    color: #191919;
    overflow: ${({ overflow }) => (overflow ? 'hidden' : 'visible')};
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1rem;
  }

  input, textarea {
    background: none;
    border: none;
    outline-color: ${({ theme }) => theme.colors.brand500};
    font-size: 1rem;

   &::placeholder {
    color: #999;
    font-size: 0.9rem;
    font-weight: 300;
   }
  }

  // 라디오
  input[type='radio'] {
    width: 18px;
    height: 18px;
    accent-color: ${({ theme }) => theme.colors.brand500};
  }

  // 체크박스
  input[type='checkbox'] {
    width: 18px;
    height: 18px;
    ${({ theme }) => theme.flex.row}
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 3px;
    appearance: none;
    cursor: pointer;
    transition: background-color 0.2s ;

    &::after {
      background: url(${img.checkIcon}) no-repeat center;
      background-size: contain;
      content: "";
      width: 100%;
      height: 100%;
      filter: invert(99%) sepia(5%) saturate(81%) hue-rotate(29deg) brightness(107%) contrast(87%);
    }

    &:checked {
      background-color: ${({ theme }) => theme.colors.brand500};
      border-color: ${({ theme }) => theme.colors.brand500};

      &::after {
        filter:  invert(96%) sepia(1%) saturate(578%) hue-rotate(26deg) brightness(122%) contrast(100%);
      }

    }
  }

  // 숫자
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  // input 자동완성 스타일 삭제
  input:-webkit-autofill {
	-webkit-box-shadow: 0 0 0 1000px white inset;
	box-shadow: 0 0 0 1000px white inset;
  }
`;
