export type VolumeTypes = 'MB' | 'GB' | 'TB';

export function volumeUnits() {
  return [
    {
      name: 'MB',
      value: 'MB',
    },
    {
      name: 'GB',
      value: 'GB',
    },
    {
      name: 'TB',
      value: 'TB',
    },
  ];
}
