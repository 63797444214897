import * as S from "@styles/components/TableViewStyle";
import CTable from "@components/Common/CTable/CTable";
import { TableColumnTypes } from "@typedef/components/Common/CTable/table.column.types";
import { TelecomTypes } from "@typedef/components/Telecom/telecom.types";

type Props = {
  columns: TableColumnTypes<TelecomTypes>[];
  tableData: TelecomTypes[];
  insertTelecom: () => void;
  updateTelecom: (idx?: number) => void;
  deleteTelecom: () => Promise<void>;
};

const Telecom = ({
  columns,
  tableData,
  insertTelecom,
  updateTelecom,
  deleteTelecom,
}: Props) => {
  return (
    <S.Main>
      <S.TopBtns>
        <h3>전체&nbsp;{tableData.length}</h3>
        <S.Btns>
          <S.Btn type="button" onClick={insertTelecom} className="active">
            통신사등록
          </S.Btn>
          <S.Btn type="button" onClick={() => updateTelecom()}>
            수정
          </S.Btn>
          {/* <S.Btn type='button' onClick={deleteTelecom}>
            삭제
          </S.Btn> */}
        </S.Btns>
      </S.TopBtns>
      <CTable columns={columns} tableData={tableData} />
    </S.Main>
  );
};

export default Telecom;
