import { Toggle } from '../styles/ToggleCellStyle';

type Props = {
  idx: number;
  enable: boolean;
  onChangeEnable: (idx: number, enable: boolean | string) => Promise<void>;
};

const ToggleCell = ({ idx, enable, onChangeEnable }: Props) => {
  return (
    <Toggle>
      <input
        type='checkbox'
        defaultChecked={enable}
        onChange={() => onChangeEnable(idx, enable)}
        id={`toggle_${idx}`}
      />
      <label htmlFor={`toggle_${idx}`}>Toggle</label>
    </Toggle>
  );
};

export default ToggleCell;
