import * as S from './styles/CTableStyle';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from 'react-table';
import { useRecoilValue } from 'recoil';
import { loading } from '@stories/Atom';
import Loading from '../Loading/Loading';
import {
  FiFilter,
  FiX,
  FiChevronsLeft,
  FiChevronLeft,
  FiChevronRight,
  FiChevronsRight,
  FiFrown,
} from 'react-icons/fi';

type Props = {
  columns: any;
  tableData: any;
  filterComponent?: JSX.Element;
  filterFunc?: {
    apply: () => void;
    reset: () => void;
  };
  filtering?: {
    name: string;
    value: string;
  }[];
};

const CTable = ({
  columns,
  tableData,
  filterComponent,
  filterFunc,
  filtering,
}: Props) => {
  const [visibleFilterBox, setVisibleFilterBox] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const isLoading = useRecoilValue(loading);
  const filterRef = useRef<HTMLElement>(null);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    // setPageSize,
    setGlobalFilter,
    state: {
      pageIndex,
      // pageSize
    },
  } = useTable(
    {
      columns,
      data: tableData,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  // 테이블 데이터 검색
  const onChangeSearchValue = (value: string) => {
    setSearchValue(value);
    setGlobalFilter(value);
  };

  // 테이블 데이터 검색 삭제
  const deleteSearchValue = () => {
    setSearchValue('');
    setGlobalFilter('');
  };

  // 필터 내용이 바뀌어도 검색내역 지우기 전까지 검색내역 유지
  // useEffect(() => {
  //   setGlobalFilter(searchValue);
  // }, [searchValue, setGlobalFilter]);

  const filterBoxHandler = useCallback(
    (e: MouseEvent) => {
      if (visibleFilterBox) {
        if (!filterRef.current?.contains(e.target as Node)) {
          setVisibleFilterBox(false);
        }
      }
    },
    [visibleFilterBox],
  );

  useEffect(() => {
    if (filterComponent) {
      window.addEventListener('click', filterBoxHandler);
      return () => {
        window.removeEventListener('click', filterBoxHandler);
      };
    }
  }, [filterComponent, filterBoxHandler]);

  return (
    <S.CTableBox>
      <S.Col>
        <S.Row mb={15}>
          {/* 필터버튼 */}
          {filterComponent && (
            <S.FilterBtn
              type='button'
              onClick={() => {
                setVisibleFilterBox((prev) => !prev);
              }}>
              <FiFilter />
              <p>필터</p>
            </S.FilterBtn>
          )}

          <S.FilterBox visible={visibleFilterBox} ref={filterRef}>
            {filterComponent && filterComponent}
            <S.FilterBtns>
              <button
                type='button'
                onClick={() => {
                  filterFunc?.reset();
                  setVisibleFilterBox(false);
                }}>
                초기화
              </button>
              <button
                type='button'
                onClick={() => {
                  filterFunc?.apply();
                  setVisibleFilterBox(false);
                }}
                className='active'>
                적용
              </button>
            </S.FilterBtns>
          </S.FilterBox>

          {/* 검색창 */}
          <S.SearchInput
            key={searchValue}
            defaultValue={searchValue}
            onKeyUp={(e) => {
              if (e.key === 'Enter') onChangeSearchValue(e.currentTarget.value);
            }}
            placeholder='검색어 입력 후 Enter 키를 눌러주세요'
          />
        </S.Row>
        <S.Row mb={15}>
          {/* 검색내역 */}
          {searchValue && (
            <S.SearchValue>
              <p>{`"${searchValue}"`}</p>
              <button type='button' onClick={deleteSearchValue}>
                <FiX />
              </button>
            </S.SearchValue>
          )}
          {/* 필터내역 */}
          {/* {filtered && filtered} */}
        </S.Row>
        <S.Table {...getTableProps()}>
          <S.Thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    style={{
                      width: column.width,
                    }}
                    className={
                      column.disableSortBy
                        ? ''
                        : column.isSorted
                        ? column.isSortedDesc
                          ? 'sorted down'
                          : 'sorted up'
                        : 'sorted'
                    }>
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </S.Thead>
          <S.Tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps({})}
                        data-label={cell.column.Header}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </S.Tbody>
        </S.Table>
      </S.Col>
      {isLoading ? (
        <Loading isTable />
      ) : tableData.length && page.length ? (
        <S.Pagination>
          <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            <FiChevronsLeft />
          </button>
          <button onClick={() => previousPage()} disabled={!canPreviousPage}>
            <FiChevronLeft />
          </button>
          <S.Index>
            {pageIndex + 1}&nbsp;/&nbsp;{pageOptions.length}
          </S.Index>
          <button onClick={() => nextPage()} disabled={!canNextPage}>
            <FiChevronRight />
          </button>
          <button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}>
            <FiChevronsRight />
          </button>
        </S.Pagination>
      ) : (
        <S.None>
          <FiFrown className='icon' />
          <p>등록된 데이터가 없습니다.</p>
        </S.None>
      )}
    </S.CTableBox>
  );
};

export default CTable;
