import React from "react";
import styled from "styled-components";
import img from "@assets/image";

type Props = {
  value: string;
  func: () => void;
};

const DetailCell = ({ value, func }: Props) => {
  // console.log("detailCell 실행1");
  return (
    <DetailBox onClick={func}>
      <div className="detail">
        <img src={img.infoIcon} alt="상세보기아이콘" />
        <p>{value}</p>
      </div>
    </DetailBox>
  );
};

export default React.memo(DetailCell);

const DetailBox = styled.section`
  width: 100%;
  overflow: hidden;

  .detail {
    width: fit-content;
    max-width: 100%;
    line-height: 30px;
    ${({ theme }) => theme.flex.row}
    align-items: center;
    padding: 0 7px;
    background-color: ${({ theme }) => theme.colors.brand200};
    border-radius: 5px;
    font-weight: 500;
    cursor: pointer;

    img {
      width: 15px;
      margin-right: 5px;
    }

    p {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`;
