import { Main, Split, Bottoms } from '@styles/components/ModalBodyStyle';
import * as S from '@styles/components/FormStyle';
import { DealerTypes } from '@typedef/components/Setup/Dealer/dealer.types';
import Loading from '@components/Common/Loading/Loading';

type Props = {
  type: string;
  inputs: Partial<DealerTypes>;
  splitFax: {
    fax1: string;
    fax2: string;
    fax3: string;
  };
  splitPhone: {
    phone1: string;
    phone2: string;
    phone3: string;
  };
  onChangeInputs: (e: {
    target: HTMLInputElement | HTMLTextAreaElement;
  }) => void;
  save: () => Promise<void>;
  loading: boolean;
};

const DealerManage = ({
  type,
  inputs,
  splitFax,
  splitPhone,
  onChangeInputs,
  save,
  loading,
}: Props) => {
  return (
    <Main width={35}>
      {loading && <Loading />}
      <Split>
        <S.InputBox isRow>
          <p>
            이름<span>*</span>
          </p>
          <S.Input
            name='name'
            onChange={onChangeInputs}
            defaultValue={inputs.name}
            placeholder='이름'
          />
        </S.InputBox>
        <S.InputBox isRow>
          <p>
            연락처<span>*</span>
          </p>
          <S.ThreeInputs>
            <S.Input
              name='phone1'
              key='inputs_phone1'
              onChange={onChangeInputs}
              value={splitPhone.phone1}
              maxLength={4}
            />
            <S.Input
              name='phone2'
              key='inputs_phone2'
              onChange={onChangeInputs}
              value={splitPhone.phone2}
              maxLength={4}
            />
            <S.Input
              name='phone3'
              key='inputs_phone3'
              onChange={onChangeInputs}
              value={splitPhone.phone3}
              maxLength={4}
            />
          </S.ThreeInputs>
        </S.InputBox>
        <S.InputBox isRow>
          <p>이메일</p>
          <S.Input
            name='email'
            onBlur={onChangeInputs}
            defaultValue={inputs.email}
            placeholder='ex) honggildong@naver.com'
          />
        </S.InputBox>
        <S.InputBox isRow>
          <p>팩스</p>
          <S.ThreeInputs>
            <S.Input
              name='fax1'
              key='inputs_fax1'
              onChange={onChangeInputs}
              value={splitFax.fax1}
              maxLength={4}
            />
            <S.Input
              name='fax2'
              key='inputs_fax2'
              onChange={onChangeInputs}
              value={splitFax.fax2}
              maxLength={4}
            />
            <S.Input
              name='fax3'
              key='inputs_fax3'
              onChange={onChangeInputs}
              value={splitFax.fax3}
              maxLength={4}
            />
          </S.ThreeInputs>
        </S.InputBox>
        <S.InputBox isRow>
          <p>주소</p>
          <S.AddressInputs>
            <S.Input
              name='address'
              onBlur={onChangeInputs}
              defaultValue={inputs.address}
              placeholder='주소'
            />
            <S.Input
              name='address_detail'
              onBlur={onChangeInputs}
              defaultValue={inputs.addressDetail}
              placeholder='상세 주소'
            />
          </S.AddressInputs>
        </S.InputBox>
        <S.InputBox isRow>
          <p>메모</p>
          <S.Textarea
            name='memo'
            onBlur={onChangeInputs}
            defaultValue={inputs.memo}
          />
        </S.InputBox>
      </Split>
      <Bottoms>
        <button
          type='button'
          onClick={save}
          disabled={
            !!!inputs.name || !!!splitPhone.phone1 || !!!splitPhone.phone2
          }>
          입고처 {type}하기
        </button>
      </Bottoms>
    </Main>
  );
};

export default DealerManage;
