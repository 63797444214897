import styled from 'styled-components';

// 일정 + 캘린더
export const Main = styled.main`
  flex: 1;
  display: grid;
  grid-template-columns: 25vw 1fr;
  gap: 0 30px;
  padding: 30px;
`;

// 일정
export const Schedules = styled.section`
  padding: 30px;
  background-color: rgb(255, 255, 255, 0.5);
  border-radius: 15px;

  header {
    ${({ theme }) => theme.flex.row}
    align-items: center;
    justify-content: space-between;

    button {
      background-color: #fff;
      padding: 0.5vw;
      border-radius: 0.25vw;
    }
  }

  button {
  }
`;

export const Schedule = styled.button<{ color?: string }>`
  width: 100%;
  ${({ theme }) => theme.flex.col}
  margin-top: 1vw;
  padding: 1vw;
  background-color: #fff;
  border-left: 10px solid ${({ color }) => color};
  border-radius: 0.5vw;
  h4 {
    margin-bottom: 0.5vw;
  }
  p {
    text-align: left;
  }
`;

// 캘린더
export const Calendar = styled.section`
  padding: 30px;
  background-color: #fff;
  border-radius: 15px;

  .react-calendar__navigation {
    margin-bottom: 1vw;
  }

  // 월요일 ~ 일요일
  .react-calendar__month-view__weekdays {
    height: 40px !important;
    text-align: left;
    .react-calendar__month-view__weekdays__weekday {
      line-height: 40px;
      abbr {
        padding-left: 1vw;
        &::after {
          content: '요일';
        }
      }
    }
  }

  .ccalendar {
    .react-calendar__tile {
      display: flex;
      justify-content: flex-start;
      padding: 1vw 0 0 1vw;
    }
  }
`;

// 일정 등록 > "시작일과 종료일을 선택해 주세요"
export const Description = styled.section`
  ${({ theme }) => theme.flex.row}
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5vw;
  padding: 0.5vw;
  background-color: ${({ theme }) => theme.colors.grey200};
  border-radius: 7px;
`;

// 일정 등록 > 캘린더
export const CalendarRange = styled.section`
  height: 100%;
  .react-calendar__navigation {
    margin-bottom: 1vw;
  }
`;

// 일정 등록 > 시작일, 종료일
export const DateInputs = styled.section`
  ${({ theme }) => theme.flex.row}.date {
    width: 10vw;
    flex: 0;
    margin-right: 1vw;
  }
`;

// 일정 등록 > 색상 선택
export const Colors = styled.section`
  width: 100%;
  height: 38px;
  ${({ theme }) => theme.flex.row}
  justify-content: space-between;

  .active::after {
    font-family: 'FontAwesome';
    content: '\f00c';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 1.3rem;
  }
`;

export const Color = styled.button<{ bgc: string }>`
  width: 38px;
  position: relative;
  background-color: ${({ bgc }) => bgc};
  border-radius: 50%;
`;
