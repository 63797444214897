import { Navigate, Route, Routes } from 'react-router-dom';
import JoinContainer from '@components/Join/Join/containers/JoinContainer';
import JoinCompleted from '@components/Join/Completed/JoinCompleted';
import JoinTermsContainer from '@components/Join/Terms/containers/JoinTermsContainer';
import LoginContainer from '@components/Login/containers/LoginContainer';

const LoginNavigation = () => {
  return (
    <Routes>
      <Route path='/' element={<LoginContainer />} />
      <Route path='/terms' element={<JoinTermsContainer />} />
      <Route path='/join' element={<JoinContainer />} />
      <Route path='/completed' element={<JoinCompleted />} />
      <Route path='*' element={<Navigate to='/' />} />
    </Routes>
  );
};

export default LoginNavigation;
