import * as S from './styles/MobileNBStyle';
import { Link } from 'react-router-dom';
import img from '@assets/image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { useSetRecoilState } from 'recoil';
import { mobileNB } from '@stories/Atom';

const MobileHeader = () => {
  const setShowMobileNB = useSetRecoilState(mobileNB);

  return (
    <S.MobileHeader>
      <Link to=''>
        <img src={img.mcallLogo} alt='MCALL로고' />
      </Link>
      <button
        type='button'
        onClick={() => setShowMobileNB(true)}
        className='hamburger'>
        <FontAwesomeIcon icon={faBars} />
      </button>
    </S.MobileHeader>
  );
};

export default MobileHeader;
