import ShopInfo from '../components/ShopInfo';
import React, { useState, useEffect, useMemo, useRef } from 'react';
import { apiRoute, requestSecureGet } from '@libs/api';
import useUser from '@hooks/useUser';
import useToken from '@hooks/useToken';
import { ShopTypes } from '@typedef/components/Shop/shop.types';
import { requestSecurePatch } from '@libs/api';

const ShopInfoContainer = () => {
  const { getToken } = useToken();
  const { getUser } = useUser();
  const [shop, setShop] = useState<Partial<ShopTypes>>({});
  // const [employees, setEmployees] = useState();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const role = useMemo(() => getUser().role, [getUser]);
  const shopId = useMemo(() => getUser().shopId, [getUser]);
  const addressRef = useRef<HTMLInputElement>(null);

  // onChange(input)
  const onChangeInputs = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setShop((shop) => ({
      ...shop,
      [name]: value,
    }));
  };

  // 우편번호 검색
  const searchZonecode = () => {
    new window.daum.Postcode({
      oncomplete: function (data: any) {
        var extraRoadAddr = '';
        if (data.bname !== '' && /[동|로|가]$/g.test(data.bname)) {
          extraRoadAddr += data.bname;
        }
        if (data.buildingName !== '' && data.apartment === 'Y') {
          extraRoadAddr +=
            extraRoadAddr !== '' ? ', ' + data.buildingName : data.buildingName;
        }
        if (extraRoadAddr !== '') {
          extraRoadAddr = ' (' + extraRoadAddr + ')';
        }
        setShop((shop) => ({
          ...shop,
          zoneCode: data.zonecode,
          address: data.roadAddress + ' (' + data.jibunAddress + ')',
        }));
        // 상세주소로 포커스
        addressRef.current?.focus();
      },
    }).open();
  };
  // 수정
  const update = async () => {
    const { config } = await requestSecurePatch(
      apiRoute.shop.patchShop,
      {},
      shop,
      getToken()!,
    );
    if (config.status >= 200 && config.status < 400) {
      alert('성공적으로 수정이 완료되었습니다.');
      window.location.reload();
    }
  };

  // 담당자 수정에 사용할 직원 리스트 조회
  const getEmployees = async () => {
    const { config } = await requestSecureGet(
      apiRoute.setup.employee.getEmployees,
      {},
      getToken()!,
    );
    if (config.status >= 200 && config.status < 400) {
    }
  };

  // 소속정보 데이터 조회
  const getMyShopData = async () => {
    const { config, data } = await requestSecureGet<{
      shop: ShopTypes;
    }>(apiRoute.shop.getShop + shopId, {}, getToken()!);
    if (config.status >= 200 && config.status < 400) {
      const { shop } = data;
      setShop(shop);
    }
  };

  useEffect(() => {
    getMyShopData();
    getEmployees();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ShopInfo
      shop={shop}
      role={role}
      isEdit={isEdit}
      setIsEdit={setIsEdit}
      onChangeInputs={onChangeInputs}
      searchZonecode={searchZonecode}
      addressRef={addressRef}
      update={update}
    />
  );
};

export default ShopInfoContainer;
