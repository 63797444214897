import * as S from '@styles/components/TableViewStyle';
import CTable from '@components/Common/CTable/CTable';
import { TableColumnTypes } from '@typedef/components/Common/CTable/table.column.types';
import { CustomerHistoryTypes } from '@typedef/components/Customer/History/customer.history.types';

type Props = {
  columns: TableColumnTypes<CustomerHistoryTypes>[];
  tableData: CustomerHistoryTypes[];
};

const CustomerHistory = ({ columns, tableData }: Props) => {
  return (
    <S.Main>
      <S.TopBtns>
        <h3>전체 {tableData.length}</h3>
      </S.TopBtns>
      <CTable columns={columns} tableData={tableData} />
    </S.Main>
  );
};

export default CustomerHistory;
