import { Route, Routes } from 'react-router-dom';
import LoginNavigation from './components/LoginNavigation';
import MainNavigation from './components/MainNavigation';
import useToken from '@hooks/useToken';

const RootNavigation = () => {
  const { getToken } = useToken();

  return (
    <Routes>
      <Route
        path='*'
        element={getToken() ? <MainNavigation /> : <LoginNavigation />}
      />
    </Routes>
  );
};

export default RootNavigation;
