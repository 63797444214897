import * as S from './styles/LoadingStyle';

type Props = {
  isTable?: boolean;
};

const Loading = ({ isTable }: Props) => {
  return (
    <S.Loading isTable={isTable}>
      <div className='loadingio-spinner-spin-evaqu21v8cc'>
        <div className='ldio-kubs124ks9'>
          <div>
            <div></div>
          </div>
          <div>
            <div></div>
          </div>
          <div>
            <div></div>
          </div>
          <div>
            <div></div>
          </div>
          <div>
            <div></div>
          </div>
          <div>
            <div></div>
          </div>
          <div>
            <div></div>
          </div>
          <div>
            <div></div>
          </div>
        </div>
      </div>
    </S.Loading>
  );
};

export default Loading;
