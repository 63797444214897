import styled from "styled-components";

const space = "1.5vw";

// 백그라운드
export const BackGround = styled.div`
  width: 100%;
  min-height: 100vh;
  height: 100%;
  position: fixed;
  background-color: rgb(0, 0, 0, 0.35);
  z-index: 100;
`;

// 모달 전체
export const ModalBox = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 0.5vw;
`;

// 모달 헤더(제목, 닫기)
export const Header = styled.header`
  ${({ theme }) => theme.flex.row}
  align-items: center;
  justify-content: space-between;
  padding: calc(${space} / 2) ${space};
  border-bottom: 1px solid #eee;
  position: relative;

  button {
    width: 35px;
    height: 35px;
    ${({ theme }) => theme.flex.col}
    align-items: center;
    justify-content: center;
    position: relative;
    margin-left: 10px;
    background-color: ${({ theme }) => theme.colors.grey200};
    border-radius: 0.5vw;
    cursor: pointer;

    img {
      display: block;
      filter: invert(85%) sepia(21%) saturate(9%) hue-rotate(27deg)
        brightness(92%) contrast(95%);
    }
  }
`;
