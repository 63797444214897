import { DefaultTheme } from 'styled-components';

// 디바이스별 분기점
const media = {
  mobile: `screen and (max-width: 480px)`,
  tablet: `screen and (max-width: 768px)`,
  laptop: `screen and (max-width: 1024px)`,
};

// 색상
const colors = {
  brand900: '#0F2962',
  brand500: '#4F91CD',
  brand200: '#E9EFF7',
  brand100: '#EFF4FA',
  grey500: '#eee',
  grey200: '#F4F4F4',
  grey100: '#F9F9F9',
  sucess500: '#55AA19',
  danger500: '#DB5441',
};

// felx 속성
const flex = {
  row: `
    display: flex;
    flex-direction: row;
  `,
  col: `
    display: flex;
    flex-direction: column;
  `,
};

export const theme: DefaultTheme = {
  colors,
  media,
  flex,
};
