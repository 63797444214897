import styled from 'styled-components';

export const CCalendar = styled.section`
  width: 100%;
  height: 100%;

  .react-calendar {
    width: 100%;
    height: 100%;
    ${({ theme }) => theme.flex.col}
    background: none;
    border: none;
    * {
      margin: 0;
      padding: 0;
    }
  }

  // 네비게이션
  .react-calendar__navigation {
    height: 30px;

    .react-calendar__navigation__arrow {
      ${({ theme }) => theme.flex.row}
      align-items: center;
      justify-content: center;
      .arrow {
        font-size: 1.17rem;
      }
    }

    // YYYY년 MM월
    .react-calendar__navigation__label {
      &:disabled {
        color: #191919;
        font-size: 1.17rem;
        font-weight: 700;
        background: none;
      }
    }
  }

  // 본문
  .react-calendar__viewContainer {
    flex: 1;

    .react-calendar__month-view {
      height: 100%;

      div:first-child {
        ${({ theme }) => theme.flex.col}
        height: 100%;
      }
    }
  }

  // 월요일 ~ 일요일
  .react-calendar__month-view__weekdays {
    height: 30px !important;
    flex-direction: row !important;

    .react-calendar__month-view__weekdays__weekday {
      line-height: 30px;
      border-top: 1px solid #eee;

      abbr {
        font-size: 1rem;
        font-weight: 500;
        text-decoration: none;
      }
    }
  }

  // 01 ~ 31
  .react-calendar__month-view__days {
    flex: 1;

    // 이전, 다음 달
    .react-calendar__month-view__days__day--neighboringMonth {
    }

    .react-calendar__month-view__days__day {
      position: relative;
      border: 1px solid #eee;
      border-bottom: none;

      &:nth-child(1),
      &:nth-child(1n + 7) {
        border-left: none;
      }

      &:nth-child(7n) {
        border-right: none;
      }
    }
  }

  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background: none;
  }
  .react-calendar__navigation button[disabled] {
    background-color: #f0f0f0;
  }
  abbr[title] {
    text-decoration: none;
  }
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background: #f9f9f9;
  }
  .react-calendar__tile--now {
    background: none;
    color: #4f91cd;
    font-weight: bold;
    text-decoration: underline;
  }
  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    background: #f9f9f9;
  }
  .react-calendar__tile--hasActive:enabled:hover,
  .react-calendar__tile--hasActive:enabled:focus {
    background: #f9f9f9;
  }
  .react-calendar__tile--active {
    background: #4f91cd;
    font-weight: bold;
    color: #fff;
  }
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: #4f91cd;
    color: #fff;
  }
  .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #f9f9f9;
  }
  .react-calendar__tile--range,
  .range {
    background: #f9f9f9;
    color: #4f91cd;
  }
  .react-calendar__tile--rangeStart,
  .start {
    background: #4f91cd;
    color: #fff;
  }
  .react-calendar__tile--rangeEnd,
  .end {
    background: #4f91cd;
    color: #fff;
  }
`;

export const Schedules = styled.section`
  width: 100%;
  ${({ theme }) => theme.flex.row}
  position: absolute;
  bottom: 1vw;
`;

export const Schedule = styled.div<{ bgc: string }>`
  width: 1vw;
  height: 1vw;
  background-color: ${({ bgc }) => bgc};
  margin-right: 0.25vw;
  border-radius: 50%;
`;
