import {Navigate, Route, Routes} from "react-router-dom";
import CustomerContainer from "@components/Customer/Customer/containers/CustomerContainer";
// import CustomerConsultContainer from '@components/Customer/Consult/containers/CustomerConsultContainer';
import CustomerHistoryContainer from "@components/Customer/History/containers/CustomerHistoryContainer";
import HelpFAQContainer from "@components/Help/FAQ/containers/FAQContainer";
import HelpNoticeContainer from "@components/Help/Notice/containers/NoticeContainer";
// import HelpQnAContainer from '@components/Help/QNA/containers/QnAContainer';
import InventoryContainer from "@components/Inventory/Inventory/containers/InventoryContainer";
import InventoryHistoryContainer from "@components/Inventory/History/containers/InventoryHistoryContainer";
import InventoryPutContainer from "@components/Inventory/Put/containers/InventoryPutContainer";
import InventoryReleaseContainer from "@components/Inventory/Release/containers/InventoryReleaseContainer";
import InventoryRequestContainer from "@components/Inventory/Request/containers/InventoryRequestContainer";
import AgencyMainContainer from "@components/Main/containers/AgencyMainContainer";
import PayCreditContainer from "@components/Pay/Credit/containers/PayCreditContainer";
import PayOpenContainer from "@components/Pay/Open/containers/PayOpenContainer";
import PayPaybackContainer from "@components/Pay/Payback/containers/PayPaybackContainer";
import PayRetroactiveContainer from "@components/Pay/Retroactive/containers/PayRetroactiveContainer";
import ScheduleContainer from "@components/Schedule/containers/ScheduleContainer";
import SetupBasicContainer from "@components/Setup/Basic/containers/SetupBasicContainer";
import SetupDealerContainer from "@components/Setup/Dealer/containers/SetupDealerContainer";
import SetupDepotContainer from "@components/Setup/Depot/containers/SetupDepotContainer";
import SetupEmployeeContainer from "@components/Setup/Employee/containers/SetupEmployeeContainer";
import SetupPolicyContainer from "@components/Setup/Policy/containers/SetupPolicyContainer";
import SetupRelatedContainer from "@components/Setup/Related/containers/SetupRelatedContainer";
import NoticeDetailContainer from "@components/Help/Notice/containers/NoticeDetailContainer";
import ClientContainer from "@components/Client/containers/ClientContainer";
import ClientHistoryContainer from "@components/Client/containers/ClientHistoryContainer";
import InventoryTempOutContainer from "@components/Inventory/TempOut/containers/InventoryTempOutContainer";
// import InventoryRepairContainer from "@components/Inventory/Repair/containers/InventoryRepairContainer";

// 대리점
const AgencyRoleNavigation = () => {
    return (
        <Routes>
            {/* 메인 */}
            <Route path="" element={<AgencyMainContainer/>}/>

            {/* 재고관리 */}
            <Route
                path="/inventory/request"
                element={<InventoryRequestContainer/>}
            />
            <Route path="/inventory/device" element={<InventoryContainer/>}/>
            <Route path="/inventory/put" element={<InventoryPutContainer/>}/>
            <Route // 출고관리
                path="/inventory/release"
                element={<InventoryReleaseContainer/>}
            />
            <Route // 수리관리
                path="/inventory/repair"
                element={<InventoryTempOutContainer key={"재고관리수리페이지"} type={"수리"}/>}
            />
            <Route // 대여관리
                path="/inventory/borrow"
                element={<InventoryTempOutContainer key={"재고관리대여페이지"}  type={"대여"}/>}
            />
            <Route
                path="/inventory/history"
                element={<InventoryHistoryContainer/>}
            />

            {/* 정산관리 */}
            <Route path="/pay/open" element={<PayOpenContainer/>}/>
            <Route path="/pay/credit" element={<PayCreditContainer/>}/>
            <Route path="/pay/retroactive" element={<PayRetroactiveContainer/>}/>
            <Route path="/pay/payback" element={<PayPaybackContainer/>}/>

            {/* 고객관리 */}
            <Route path="/customers/customer" element={<CustomerContainer/>}/>
            <Route path="/customers/history" element={<CustomerHistoryContainer/>}/>

            {/* 거래처관리 */}
            <Route path="/clients/client" element={<ClientContainer/>}/>
            <Route
                path="/clients/client/history"
                element={<ClientHistoryContainer/>}
            />

            {/* 일정 */}
            <Route path="/schedule" element={<ScheduleContainer/>}/>

            {/* 설정 */}
            <Route path="/setup/basic" element={<SetupBasicContainer/>}/>
            <Route path="/setup/employee" element={<SetupEmployeeContainer/>}/>
            <Route path="/setup/depot" element={<SetupDepotContainer/>}/>
            <Route path="/setup/dealer" element={<SetupDealerContainer/>}/>
            <Route path="/setup/related" element={<SetupRelatedContainer/>}/>
            <Route path="/setup/policy" element={<SetupPolicyContainer/>}/>

            {/* 고객센터 */}
            <Route path="/help/notice" element={<HelpNoticeContainer/>}/>
            <Route path="/help/notice/*" element={<NoticeDetailContainer/>}/>
            <Route path="/help/faq" element={<HelpFAQContainer/>}/>

            <Route path="*" element={<Navigate to=""/>}/>
        </Routes>
    );
};

export default AgencyRoleNavigation;
