import styled from 'styled-components';

export const Main = styled.main`
  min-height: calc(100vh - 130px);
  ${({ theme }) => theme.flex.col}
  margin: 30px;
  background-color: #fff;
  border-radius: 15px;
`;

export const TopBtns = styled.section`
  height: 70px;
  ${({ theme }) => theme.flex.row}
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  border-bottom: 1px solid #eee;
`;

export const Categories = styled.section`
  ${({ theme }) => theme.flex.row}
  margin-left: 30px;

  .active {
    background-color: ${({ theme }) => theme.colors.brand500};
    color: #fff;
  }

  .btn {
    &::after {
      font-family: 'FontAwesome';
      content: '\f055';
      margin-left: 10px;
      font-weight: 100;
    }
  }
`;

export const Category = styled.button`
  height: 35px;
  line-height: 35px;
  margin-left: 10px;
  padding: 0 10px;
  background-color: ${({ theme }) => theme.colors.brand200};
  color: ${({ theme }) => theme.colors.brand900};
  font-size: 0.85rem;
  font-weight: 600;
  border-radius: 5px;
`;

export const Boards = styled.section`
  ${({ theme }) => theme.flex.col}

  .faq {
    cursor: pointer;
  }
`;

// 공지사항
export const Board = styled.section`
  width: 100%;
  ${({ theme }) => theme.flex.row}
  align-items: center;
  justify-content: space-between;
  padding: 15px 30px;
  border-bottom: 1px solid #eee;

  span {
    margin-right: 20px;
    padding: 5px 10px;
    background-color: ${({ theme }) => theme.colors.brand200};
    border-radius: 5px;
    font-weight: 500;
  }

  .notice:hover {
    text-decoration: underline;
  }

  .date {
    justify-self: flex-end;
  }
`;

// 공지사항 상세보기 본문
export const NoticeContent = styled.section`
  padding: 30px;
  white-space: pre-line;
`;

// FAQ
export const FAQ = styled.section``;

export const FAQContent = styled.section<{ isActive: boolean }>`
  display: ${({ isActive }) => (isActive ? 'block' : 'none')};
  padding: 60px;
  border-bottom: 1px solid #eee;
`;

export const Row = styled.section`
  ${({ theme }) => theme.flex.row}
  align-items: center;
`;

export const None = styled.section`
  ${({ theme }) => theme.flex.row}
  align-items: center;
  justify-content: center;
`;
