import styled from 'styled-components';

const space = '30px';
const radius = '0.5vw';

export const Aside = styled.aside`
  width: 25vw;
  ${({ theme }) => theme.flex.col}
  background-color: ${({ theme }) => theme.colors.brand200};
  padding: ${space};
`;

export const Calendar = styled.section`
  width: 100%;
  min-height: 40%;
  background-color: #fff;
  margin-bottom: ${space};
  padding: 15px;
  border-radius: ${radius};

  .ccalendar {
    .react-calendar__navigation__label {
      font-size: 1rem !important;
    }

    .react-calendar__month-view__weekdays__weekday {
      border: none !important;
    }

    .react-calendar__month-view__days__day {
      border: none !important;
    }
  }
`;

export const Schedules = styled.section`
  flex: 1;
  ${({ theme }) => theme.flex.col}
  border-radius: ${radius};

  header {
    ${({ theme }) => theme.flex.row}
    align-items: center;
    justify-content: space-between;
  }
`;

export const ScheduleBox = styled.section`
  flex: 1;
  ${({ theme }) => theme.flex.col}
  margin-top: 1vw;
  padding: ${space};
  background-color: #fff;
  border-radius: ${radius};

  .nothing {
    ${({ theme }) => theme.flex.col}
    text-align: center;
  }
`;

export const Schedule = styled.section`
  width: 100%;
  ${({ theme }) => theme.flex.row}
  align-items: center;
  margin-bottom: 1vw;
`;

export const Dot = styled.div<{ bgc: string }>`
  width: 1vw;
  height: 1vw;
  margin-right: 1vw;
  background-color: ${({ bgc }) => bgc};
  border-radius: 50%;
`;
