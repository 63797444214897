import DealerManage from '../components/DealerManage';
import { useCallback, useEffect, useState } from 'react';
import {
  apiRoute,
  requestSecureGet,
  requestSecurePatch,
  requestSecurePost,
} from '@libs/api';
import useToken from '@hooks/useToken';
import { DealerTypes } from '@typedef/components/Setup/Dealer/dealer.types';

type Props = {
  idx?: number;
  close: () => void;
  reload: () => void;
};

const DealerManageContainer = ({ idx, close, reload }: Props) => {
  const { getToken } = useToken();
  const [inputs, setInputs] = useState<Partial<DealerTypes>>({});
  const [splitFax, setSplitFax] = useState({
    fax1: '',
    fax2: '',
    fax3: '',
  });
  const [splitPhone, setSplitPhone] = useState({
    phone1: '010',
    phone2: '',
    phone3: '',
  });
  // 상세조회, 수정 모달에서만 사용, loading이 true인 경우 스피너 표출
  const [loading, setLoading] = useState<boolean>(false);

  // onChange (input)
  const onChangeInputs = useCallback(
    (e: { target: HTMLInputElement | HTMLTextAreaElement }) => {
      const { name, value } = e.target;
      if (name.includes('phone')) {
        setSplitPhone((splitPhone) => ({
          ...splitPhone,
          [name]: value,
        }));
        return;
      }
      if (name.includes('fax')) {
        setSplitFax((splitFax) => ({
          ...splitFax,
          [name]: value,
        }));
        return;
      }
      if (value.length === 0) return;
      setInputs((inputs) => ({
        ...inputs,
        [name]: value,
      }));
    },
    [],
  );

  // 등록
  const insert = async () => {
    const postData = {
      ...inputs,
    };

    // 팩스번호
    if (splitFax.fax1 && splitFax.fax2) {
      let fax;
      fax = `${splitFax.fax1}-${splitFax.fax2}`;
      if (splitFax.fax3) {
        fax += `-${splitFax.fax3}`;
      }
      postData.fax = fax;
    }

    // 연락처
    if (splitPhone.phone1 && splitPhone.phone2) {
      let phone;
      phone = `${splitPhone.phone1}-${splitPhone.phone2}`;
      if (splitPhone.phone3) {
        phone += `-${splitPhone.phone3}`;
      }
      postData.phone = phone;
    }

    const { config } = await requestSecurePost(
      apiRoute.setup.dealer.postDealer,
      {},
      postData,
      getToken()!,
    );
    if (config.status >= 200 && config.status < 400) {
      alert('성공적으로 등록이 완료되었습니다.');
      close();
      reload();
    }
  };

  // 수정
  const update = async () => {
    const patchData = {
      ...inputs,
    };

    // 팩스번호
    if (splitFax.fax1 && splitFax.fax2) {
      let fax;
      fax = `${splitFax.fax1}-${splitFax.fax2}`;
      if (splitFax.fax3) {
        fax += `-${splitFax.fax3}`;
      }
      patchData.fax = fax;
    }

    // 휴대전화
    if (splitPhone.phone1 && splitPhone.phone2) {
      let phone;
      phone = `${splitPhone.phone1}-${splitPhone.phone2}`;
      if (splitPhone.phone3) {
        phone += `-${splitPhone.phone3}`;
      }
      patchData.phone = phone;
    }

    const { config } = await requestSecurePatch(
      apiRoute.setup.dealer.patchDealer,
      {},
      { ...patchData, dealerId: idx },
      getToken()!,
    );
    if (config.status >= 200 && config.status < 400) {
      alert('성공적으로 수정이 완료되었습니다.');
      close();
      reload();
    }
  };

  const getCheckedData = async () => {
    const { config, data } = await requestSecureGet<{ dealer: DealerTypes }>(
      apiRoute.setup.dealer.getDealer + idx,
      {},
      getToken()!,
    );
    if (config.status >= 200 && config.status < 400) {
      setInputs(data.dealer);
      // 입고처의 휴대전화 정보 나눠서 넣기
      if (data.dealer.phone) {
        const split = data.dealer.phone.split('-');
        setSplitPhone(() => ({
          phone1: split[0],
          phone2: split[1],
          phone3: split[2],
        }));
      }
      if (data.dealer.fax) {
        const split = data.dealer.fax.split('-');
        setSplitFax(() => ({
          fax1: split[0],
          fax2: split[1],
          fax3: split[2],
        }));
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (idx) {
      setLoading(true);
      getCheckedData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DealerManage
      type={idx ? '수정' : '등록'}
      inputs={inputs}
      splitFax={splitFax}
      splitPhone={splitPhone}
      onChangeInputs={onChangeInputs}
      save={idx ? update : insert}
      loading={loading}
    />
  );
};

export default DealerManageContainer;
