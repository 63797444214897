import SetupBasic from '../SetupBasic';
import { useMemo } from 'react';
import useUser from '@hooks/useUser';
import { useSetRecoilState } from 'recoil';
import { modal, modalHeader } from '@stories/Atom';
import PasswdupdateContainer from './PasswdUpdateContainer';

const SetupBasicContainer = () => {
  const { getUser } = useUser();
  const setModal = useSetRecoilState(modal);
  const header = useSetRecoilState(modalHeader);
  const role = useMemo(() => getUser().role, [getUser]);
  const name = useMemo(() => getUser().name, [getUser]);

  // 비밀번호변경, 개인정보 수정 버튼 클릭 시
  const manage = (type: 'pw' | 'basic') => {
    if (type === 'pw') {
      header('비밀번호 변경');
      setModal(<PasswdupdateContainer />);
    } else {
      header('개인정보 수정');
    }
  };

  return <SetupBasic name={name} role={role} manage={manage} />;
};

export default SetupBasicContainer;
