import * as S from '@styles/components/FormStyle';
import CSelect from '@components/Common/CSelect/CSelect';
import { temp, Temp } from '@components/temp';
import { OpenTypes } from '@typedef/components/Pay/Open/open.types';
import CDatePicker from '@components/Common/CDatePicker/CDatePicker';
import { DateTypes, hours, minutes } from '@libs/date';
import { formatDate } from '@libs/commonFuncs';

type Props = {
  inputs: Partial<OpenTypes>;
  onChangeInputs: (e: { target: HTMLInputElement }) => void;
  onChangeSelect: <T extends {}>(name: string, selected: T) => void;
  openTime: {
    date: Date;
    hour: string;
    minute: string;
  };
  onChangeOpenTime: (name: string, selected: Date | string) => void;
};

const BillOpen = ({
  inputs,
  onChangeInputs,
  onChangeSelect,
  openTime,
  onChangeOpenTime,
}: Props) => {
  return (
    <S.Grid grid={3}>
      <S.InputBox>
        <p>개통일자</p>
        <CDatePicker
          name='date'
          selected={formatDate(openTime.date)}
          func={onChangeOpenTime}
        />
      </S.InputBox>
      <S.InputBox>
        <p>개통시간</p>
        <S.TwoInputs>
          <CSelect
            options={hours()}
            value={(hour: DateTypes) => hour.value}
            label={(hour: DateTypes) => hour.name}
            defaultValue={hours().filter(
              (hour) => hour.value === openTime.hour,
            )}
            func={(selected: DateTypes) =>
              onChangeOpenTime('hour', selected.value)
            }
            height={175}
            indicator='시'
          />
          <CSelect
            options={minutes()}
            value={(minute: DateTypes) => minute.value}
            label={(minute: DateTypes) => minute.name}
            defaultValue={minutes().filter(
              (minute) => minute.value === openTime.minute,
            )}
            func={(selected: DateTypes) =>
              onChangeOpenTime('minute', selected.value)
            }
            height={175}
            indicator='분'
          />
        </S.TwoInputs>
      </S.InputBox>
      <S.InputBox>
        <p>판매점명</p>
        <CSelect<Temp>
          options={temp}
          value={(temp: Temp) => temp.id}
          label={(temp: Temp) => temp.value}
          func={console.log('hi')}
          placeholder='판매점명'
        />
      </S.InputBox>
      <S.InputBox>
        <p>정산점명</p>
        <CSelect<Temp>
          options={temp}
          value={(temp: Temp) => temp.id}
          label={(temp: Temp) => temp.value}
          func={console.log('hi')}
          placeholder='정산점명'
        />
      </S.InputBox>
      <S.InputBox>
        <p>정책호수</p>
        <S.Input
          name='policyNumber'
          defaultValue={inputs.policyNumber}
          onBlur={onChangeInputs}
          placeholder='정책호수'
        />
      </S.InputBox>
      <S.InputBox>
        <p>정책코드</p>
        <S.Input
          name='policyCode'
          defaultValue={inputs.policyCode}
          onBlur={onChangeInputs}
          placeholder='정책코드'
        />
      </S.InputBox>
      <S.InputBox>
        <p>추가액</p>
        <S.MoneyInputs>
          <S.Input
            name='calculatePlus'
            defaultValue={inputs.calculatePlus}
            onBlur={onChangeInputs}
            placeholder='추가액'
          />
        </S.MoneyInputs>
      </S.InputBox>
      <S.InputBox>
        <p>추가액 메모</p>
        <S.Input
          name='calculatePlusMemo'
          defaultValue={inputs.calculatePlusMemo}
          onBlur={onChangeInputs}
          placeholder='추가액 메모'
        />
      </S.InputBox>
      <S.InputBox>
        <p>차감액</p>
        <S.MoneyInputs>
          <S.Input
            name='calculateMinus'
            defaultValue={inputs.calculateMinus}
            onBlur={onChangeInputs}
            placeholder='차감액'
          />
        </S.MoneyInputs>
      </S.InputBox>
      <S.InputBox mb={0}>
        <p>차감액 메모</p>
        <S.Input
          name='calculateMinusMemo'
          defaultValue={inputs.calculateMinusMemo}
          onBlur={onChangeInputs}
          placeholder='차감액 메모'
        />
      </S.InputBox>
      <S.InputBox mb={0}>
        <p>정산금</p>
        <S.MoneyInputs>
          <S.Input
            name='calculatePrice'
            onBlur={onChangeInputs}
            defaultValue={inputs.calculatePrice}
            placeholder='정산금'
          />
        </S.MoneyInputs>
      </S.InputBox>
      <S.InputBox>
        <p>실정산금</p>
        <S.MoneyInputs>
          <S.Input placeholder='실정산금' />
        </S.MoneyInputs>
      </S.InputBox>
    </S.Grid>
  );
};

export default BillOpen;
