import * as S from './styles/JoinCompletedStyle';
import { useNavigate } from 'react-router-dom';
import img from '@assets/image';

const JoinCompleted = () => {
  const navigate = useNavigate();

  return (
    <S.JoinCompleted>
      <S.Header>
        <img src={img.mcallLogo} alt='MCALL로고' />
      </S.Header>
      <S.ProcessBox>
        <h2>회원가입</h2>
        <S.Process>
          <p>약관동의</p>
          <p>업체정보 입력</p>
          <p className='active'>가입완료</p>
          <span className='nav-indicator'></span>
        </S.Process>
      </S.ProcessBox>
      <S.Main>
        <h2>회원가입 승인 대기중</h2>
        <article>
          <p>MCALL을 찾아주셔서 대단히 감사합니다.</p>
          <p>MCALL은 관리자의 승인을 통해 서비스 이용이 가능합니다.</p>
          <p>
            승인결과는 회원 가입 시 입력한 담당자 이메일로 보내드리겠습니다.
            (영업일 기준 최대 2~3일 소요)
          </p>
          <p>항상 노력하는 MCALL이 되도록 최선을 다하겠습니다.</p>
          <p>감사합니다.</p>
        </article>
        <S.Btns>
          <S.Btn type='button' onClick={() => navigate('/login')}>
            로그인 화면으로 가기
          </S.Btn>
        </S.Btns>
      </S.Main>
    </S.JoinCompleted>
  );
};

export default JoinCompleted;
