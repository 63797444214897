import * as S from '@styles/components/FormStyle';
import { Info } from '../styles/InventoryPutStyle';
import CSelect from '@components/Common/CSelect/CSelect';
import { ShopDeviceInsertTypes } from '@typedef/components/Inventory/Put/shop.device.insert.types';
import { TelecomTypes } from '@typedef/components/Telecom/telecom.types';
import { DeviceCategoryTypes } from '@typedef/components/Device/Category/device.category.types';

type Props = {
  telecoms: TelecomTypes[];
  categories: DeviceCategoryTypes[];
  inputs: ShopDeviceInsertTypes;
  onChangeInputs: (e: {
    target: HTMLInputElement | HTMLTextAreaElement;
  }) => void;
  onChangeSelect: (name: string, selected: any) => void;
  onChangeBarcodeValue: (
    e: React.KeyboardEvent<HTMLInputElement>,
  ) => Promise<void>;
  barcodeRef: React.RefObject<HTMLInputElement>;
};

const BarcodeInsert = ({
  telecoms,
  categories,
  inputs,
  onChangeInputs,
  onChangeSelect,
  onChangeBarcodeValue,
  barcodeRef,
}: Props) => {
  return (
    <S.Col>
      <S.InputBox>
        <h4>바코드로 단말 검색</h4>
        <S.Input
          ref={barcodeRef}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              onChangeBarcodeValue(e);
            }
          }}
          autoFocus
          placeholder='바코드 스캔 후 Enter 키를 눌러주세요'
        />
      </S.InputBox>
      <Info>
        <h4>단말 정보</h4>
        <div>
          모델명
          <p>{inputs.model_name || '모델명'}</p>
        </div>
        <div>
          색상
          <p>{inputs.device_color || '색상'}</p>
        </div>
        <div>
          일련번호
          <p>{inputs.serial_number || '일련번호'}</p>
        </div>
        <div>
          용량
          <p>{inputs.volume || '용량'}</p>
        </div>
        <div>
          입고가
          <p className='price'>{inputs.price.toLocaleString()}</p>
        </div>
      </Info>
      <S.InputBox isRow>
        <p>
          입고유형<span>&nbsp;*</span>
        </p>
        <CSelect<DeviceCategoryTypes>
          options={categories}
          value={(category: DeviceCategoryTypes) => category.idx}
          label={(category: DeviceCategoryTypes) => category.name}
          defaultValue={categories.filter(
            (category) => category.idx === inputs.category_idx,
          )}
          func={(selected: DeviceCategoryTypes) => {
            onChangeSelect('category_idx', selected.idx);
            onChangeSelect('category_name', selected.name);
          }}
          clearable={false}
          height={100}
          placeholder='입고유형'
        />
      </S.InputBox>
      <S.InputBox isRow>
        <p>
          통신사<span>&nbsp;*</span>
        </p>
        <CSelect<TelecomTypes>
          options={telecoms}
          value={(telecom: TelecomTypes) => telecom.telecomId}
          label={(telecom: TelecomTypes) => telecom.name}
          defaultValue={telecoms.filter(
            (telecom) => telecom.telecomId === inputs.telecom_idx,
          )}
          func={(selected: TelecomTypes) => {
            onChangeSelect('telecom_idx', selected.telecomId);
            onChangeSelect('telecom_name', selected.name);
          }}
          clearable={false}
          height={160}
          placeholder='통신사'
        />
      </S.InputBox>
      <S.InputBox isRow>
        <p>메모</p>
        <S.Input
          name='memo'
          defaultValue={inputs.memo}
          onBlur={onChangeInputs}
          placeholder='메모'
        />
      </S.InputBox>
    </S.Col>
  );
};

export default BarcodeInsert;
