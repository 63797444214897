import * as S from '@styles/components/TableViewStyle';
// import CTable from '@components/Common/CTable/CTable';
// import { TableColumnTypes } from '@typedef/components/Common/CTable/table.column.types';

// type Props = {};

const PayPayback = () => {
  return (
    <S.Main>
      <S.TopBtns>
        {/* <h3>전체 {tableData.length}</h3> */}

        <S.Btns>
          <S.Btn type='button' className='active'>
            등록
          </S.Btn>
          <S.Btn type='button'>수정</S.Btn>
          <S.Btn type='button'>삭제</S.Btn>
        </S.Btns>
      </S.TopBtns>
    </S.Main>
  );
};

export default PayPayback;
