import ColorManage from '../components/ColorManage';
import { useState, useEffect } from 'react';
import { apiRoute, requestSecurePatch, requestSecurePost } from '@libs/api';
import useToken from '@hooks/useToken';
import { ColorTypes } from '@typedef/components/Device/Color/color.types';

type Props = {
  checked: ColorTypes | null;
  close: () => void;
  reload: () => void;
};
const ColorManageContainer = ({ checked, close, reload }: Props) => {
  const { getToken } = useToken();
  const [inputs, setInputs] = useState<Partial<ColorTypes>>({});

  // onChange (input)
  const onChangeInputs = (e: { target: HTMLInputElement }) => {
    const { name, value } = e.target;
    setInputs((inputs) => ({
      ...inputs,
      [name]: value,
    }));
  };

  // 등록
  const insert = async () => {
    const { config } = await requestSecurePost(
      apiRoute.device.color.postColor,
      {},
      inputs,
      getToken()!,
    );
    if (config.status >= 200 && config.status < 400) {
      alert('성공적으로 등록이 완료되었습니다.');
      close();
      reload();
    }
  };

  // 수정
  const update = async () => {
    const { config } = await requestSecurePatch(
      apiRoute.device.color.patchColor,
      {},
      inputs,
      getToken()!,
    );
    if (config.status >= 200 && config.status < 400) {
      alert('성공적으로 수정이 완료되었습니다.');
      close();
      reload();
    }
  };

  useEffect(() => {
    if (checked) {
      setInputs(checked);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ColorManage
      type={checked ? '수정' : '등록'}
      inputs={inputs}
      onChangeInputs={onChangeInputs}
      save={checked ? update : insert}
    />
  );
};

export default ColorManageContainer;
