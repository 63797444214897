import * as S from "@styles/components/TableViewStyle";
import CTable from "@components/Common/CTable/CTable";
import { TableColumnTypes } from "@typedef/components/Common/CTable/table.column.types";
type Props = {
  columns: TableColumnTypes[];
  tableData: any;
  insertBoard: () => void;
  updateBoard: (idx?: number) => void;
  deleteBoard: () => Promise<void>;
};

const Board = ({
  columns,
  tableData,
  insertBoard,
  updateBoard,
  deleteBoard,
}: Props) => {
  return (
    <S.Main>
      <S.TopBtns>
        <h3>전체 {tableData.length}</h3>
        <S.Btns>
          <S.Btn type="button" onClick={insertBoard} className="active">
            게시글등록
          </S.Btn>
          <S.Btn type="button" onClick={() => updateBoard()}>
            수정
          </S.Btn>
          <S.Btn type="button" onClick={deleteBoard}>
            삭제
          </S.Btn>
        </S.Btns>
      </S.TopBtns>
      <CTable columns={columns} tableData={tableData} />
    </S.Main>
  );
};

export default Board;
