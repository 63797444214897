import RelatedManage from '../components/RelatedManage';

type Props = {
  idx?: number;
  close: () => void;
  reload: () => void;
};

const RelatedManageContainer = ({ idx, close, reload }: Props) => {
  return <RelatedManage />;
};

export default RelatedManageContainer;
