import Client from "../Client";
import ClientManageContainer from "./ClientManageContainer";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSetRecoilState } from "recoil";
import { loading, modal, modalHeader } from "@stories/Atom";
import { apiRoute, requestSecureDelete, requestSecureGet } from "@libs/api";
import useToken from "@hooks/useToken";
import { ClientTypes } from "@typedef/components/Client/client.types";
import { TableColumnTypes } from "@typedef/components/Common/CTable/table.column.types";
import DetailCell from "@components/Common/CTable/components/DetailCell";
import { useNavigate } from "react-router-dom";

const ClientContainer = () => {
  const navigate = useNavigate();
  const { getToken } = useToken();
  const [tableData, setTableData] = useState<ClientTypes[]>([]);
  const [checkedIdxes, setCheckedIdxes] = useState<number[]>([]);
  // 모달설정
  const setModalHeader = useSetRecoilState(modalHeader);
  const setModal = useSetRecoilState(modal);
  // 테이블 로딩
  const setLoading = useSetRecoilState(loading);
  const [reload, setReload] = useState(0);

  const manage = useCallback(
    (type: "등록" | "수정", id?: number) => {
      setModalHeader(`거래처${type}`);
      setModal(
        <ClientManageContainer
          id={id}
          close={() => setModal(null)}
          reload={() => setReload(reload + 1)}
        />
      );
    },
    [reload, setModal, setModalHeader]
  );

  // 체크박스
  const onChangeCheckbox = useCallback(
    (idx: number) => {
      if (checkedIdxes.includes(idx)) {
        setCheckedIdxes(
          checkedIdxes.filter((checkedIdx) => checkedIdx !== idx)
        );
        return;
      }
      setCheckedIdxes((idxes) => [...idxes, idx]);
    },
    [checkedIdxes]
  );

  const deleteClient = async () => {
    if (checkedIdxes.length === 0) {
      alert("삭제할 재고를 선택해 주세요.");
      return;
    }

    if (
      window.confirm(
        `선택한 거래처 ${checkedIdxes.length}개를 삭제하시겠습니까?`
      )
    ) {
      await Promise.all(
        checkedIdxes.map(
          (idx) =>
            new Promise((resolve, reject) => {
              requestSecureDelete(
                apiRoute.client.deleteClients + idx,
                {},
                getToken()!
              ).then((res) => {
                if (res.config.status >= 200 && res.config.status < 400) {
                  resolve(res.data);
                }
              });
            })
        )
      ).then(() => {
        alert("성공적으로 삭제가 완료되었습니다.");
        setReload(reload + 1);
      });
    }
  };

  const columns: TableColumnTypes<ClientTypes>[] = useMemo(
    () => [
      {
        Header: "",
        accessor: "clientId",
        disableSortBy: true,
        width: 70,
        Cell: ({ value }) => (
          <input
            type="checkbox"
            defaultChecked={checkedIdxes.includes(value as number)}
            onChange={() => onChangeCheckbox(value as number)}
          />
        ),
      },
      {
        Header: "No",
        width: 70,
        Cell: ({ row }) => <>{tableData.length - row.index}</>,
      },
      {
        Header: "거래처명",
        accessor: "name",
        Cell: ({ row, value }) => (
          <DetailCell
            value={value as string}
            func={() => manage("수정", row.original.clientId)}
          />
        ),
      },
      {
        Header: "대표자",
        accessor: "managerName",
        disableSortBy: true,
      },
      {
        Header: "업태/업종",
        accessor: "typeOfBusiness",
        disableSortBy: true,
      },
      {
        Header: "본사소재지(주소)",
        accessor: "address",
        disableSortBy: true,
      },
      {
        Header: "계산서 발행 이메일",
        accessor: "email",
        disableSortBy: true,
      },
      {
        Header: "거래이력",
        Cell: ({ row }) => {
          return (
            <button
              onClick={() =>
                navigate(`/clients/history?clientIds=${row.original.clientId}`)
              }
            >
              내역보기
            </button>
          );
        },
        disableSortBy: true,
      },
    ],
    [checkedIdxes, manage, navigate, onChangeCheckbox, tableData.length]
  );

  const getTableData = async () => {
    const { config, data } = await requestSecureGet<ClientTypes[]>(
      apiRoute.client.getClients,
      {},
      getToken()!
    );

    if (config.status >= 200 && config.status < 400) {
      setTableData(data);
      setLoading(false);
    } else {
      alert("거래처 조회에 실패하였습니다.");
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  return (
    <Client
      columns={columns}
      tableData={tableData}
      manage={manage}
      deleteClient={deleteClient}
    />
  );
};

export default ClientContainer;
