import PasswdUpdate from '../components/PasswdUpdate';
// import useToken from '@hooks/useToken';
import { useState } from 'react';

const PasswdupdateContainer = () => {
  // const { getToken } = useToken();
  const [inputs, setInputs] = useState<{
    password: string | null;
    passwordCheck: string | null;
  }>({
    password: '',
    passwordCheck: '',
  });
  const [pwMsg, setPwMsg] = useState<string | null>(null);
  const [pwCheckMsg, setPwCheckMsg] = useState<string | null>(null);

  // 비밀번호 정규식
  const regexPassword = (pw: string) => {
    if (!!!pw) return;
    const regex = /^(?=.*?\d)(?=.*[a-zA-ZS]).{8,20}/;
    if (regex.test(pw)) {
      setPwMsg(null);
      setInputs((inputs) => ({
        ...inputs,
        password: pw,
      }));
    } else {
      setPwMsg('영문, 숫자를 사용하여 8~20자리 사이로 입력해주세요');
      setInputs((inputs) => ({
        ...inputs,
        password: null,
      }));
    }
  };

  // 비밀번호 확인
  const samePassword = (pw: string) => {
    if (!!!pw) return;
    if (inputs.password && inputs.password !== null) {
      if (inputs.password === pw) {
        setPwCheckMsg(null);
        setInputs((inputs) => ({
          ...inputs,
          passwordCheck: pw,
        }));
      } else {
        setPwCheckMsg('일치하지 않습니다');
        setInputs((inputs) => ({
          ...inputs,
          passwordCheck: null,
        }));
      }
    } else {
      setPwCheckMsg('비밀번호를 먼저 입력해 주세요');
    }
  };

  // onChange (input)
  const onChangeInputs = (e: { target: HTMLInputElement }) => {
    const { name, value } = e.target;
    if (name === 'password') {
      regexPassword(value);
    } else if (name === 'passwordCheck') {
      samePassword(value);
    }
  };

  const insert = async () => {};

  return (
    <PasswdUpdate
      inputs={inputs}
      onChangeInputs={onChangeInputs}
      pwMsg={pwMsg}
      pwCheckMsg={pwCheckMsg}
      insert={insert}
    />
  );
};

export default PasswdupdateContainer;
