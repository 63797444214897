import { Main, Split, Bottoms } from '@styles/components/ModalBodyStyle';
import * as S from '@styles/components/FormStyle';
import CSelect from '@components/Common/CSelect/CSelect';
import { BoardTypes } from '@typedef/components/Board/board.types';
import { BoardCategoryTypes } from '@typedef/components/Board/Category/board.category.types';
import { SelectDefaultTypes } from '@typedef/components/Common/CSelect/select.default.types';

type Props = {
  type: string;
  inputs: Partial<BoardTypes>;
  onChangeInputs: (e: {
    target: HTMLInputElement | HTMLTextAreaElement;
  }) => void;
  onChangeSelect: (name: string, selected: any) => void;
  categories: BoardCategoryTypes[];
  isFaQ: boolean;
  save: () => Promise<void>;
};

const BoardManage = ({
  type,
  inputs,
  onChangeInputs,
  onChangeSelect,
  categories,
  isFaQ,
  save,
}: Props) => {
  return (
    <Main width={35}>
      <Split>
        <S.InputBox isRow>
          <p>
            게시판 선택<span>*</span>
          </p>
          <CSelect<BoardCategoryTypes>
            options={categories}
            value={(category: BoardCategoryTypes) => category.boardCategoryId}
            label={(category: BoardCategoryTypes) => category.name}
            defaultValue={categories.filter(
              (category) => category.boardCategoryId === inputs.boardCategoryId,
            )}
            func={(selected: BoardCategoryTypes) => {
              onChangeSelect('boardCategoryId', selected.boardCategoryId);
            }}
            placeholder='게시판'
          />
        </S.InputBox>
        {isFaQ && (
          <S.InputBox isRow>
            <p>
              유형<span>*</span>
            </p>
            <CSelect<SelectDefaultTypes>
              options={subCategories}
              value={(category: SelectDefaultTypes) => category.value}
              label={(category: SelectDefaultTypes) => category.name}
              defaultValue={subCategories.filter(
                (category) => category.name === inputs.subCategory,
              )}
              func={(selected: SelectDefaultTypes) => {
                onChangeSelect('subCategory', selected.name);
              }}
              placeholder='카테고리'
            />
          </S.InputBox>
        )}
        <S.InputBox isRow>
          <p>
            {isFaQ ? '질문' : '제목'}
            <span>*</span>
          </p>
          <S.Input
            name='title'
            onChange={onChangeInputs}
            defaultValue={inputs.title}
          />
        </S.InputBox>
        <S.InputBox isRow>
          <p>
            {isFaQ ? '답변' : '내용'} <span>*</span>
          </p>
          <S.Textarea
            name='content'
            onChange={onChangeInputs}
            defaultValue={inputs.content}
            height={200}
          />
        </S.InputBox>
      </Split>
      <Bottoms>
        <button
          type='button'
          onClick={save}
          disabled={
            !!!inputs.boardCategoryId || !!!inputs.title || !!!inputs.content
          }>
          게시글 {type}하기
        </button>
      </Bottoms>
    </Main>
  );
};

export default BoardManage;

const subCategories = [
  {
    name: '재고관리',
    value: '재고관리',
  },
  {
    name: '정산관리',
    value: '정산관리',
  },
  {
    name: '고객관리',
    value: '고객관리',
  },
  {
    name: '설정',
    value: '설정',
  },
  {
    name: '기타',
    value: '기타',
  },
];
