import { Main, Split, Bottoms } from "@styles/components/ModalBodyStyle";
import * as S from "@styles/components/FormStyle";
import CSelect from "@components/Common/CSelect/CSelect";
import { SubserviceTypes } from "@typedef/components/Telecom/Subservice/subservice.types";
import { TelecomTypes } from "@typedef/components/Telecom/telecom.types";

type Props = {
  type: string;
  inputs: Partial<SubserviceTypes>;
  onChangeInputs: (e: {
    target: HTMLInputElement | HTMLTextAreaElement;
  }) => void;
  onChangeSelect: <T extends {}>(name: string, selected: T) => void;
  telecoms: TelecomTypes[];
  save: () => Promise<void>;
};

const SubserviceManage = ({
  type,
  inputs,
  onChangeInputs,
  onChangeSelect,
  telecoms,
  save,
}: Props) => {
  return (
    <Main width={35}>
      <Split>
        <S.InputBox isRow>
          <p>
            부가서비스명<span>*</span>
          </p>
          <S.Input
            name="name"
            onBlur={onChangeInputs}
            defaultValue={inputs.name}
            placeholder="부가서비스명"
          />
        </S.InputBox>
        <S.InputBox isRow>
          <p>
            통신사<span>*</span>
          </p>
          <CSelect<TelecomTypes>
            options={telecoms}
            value={(telecom: TelecomTypes) => telecom.telecomId}
            label={(telecom: TelecomTypes) => telecom.name}
            defaultValue={telecoms.filter(
              (telecom) => telecom.telecomId === inputs.telecomId
            )}
            func={(selected: TelecomTypes) =>
              onChangeSelect("telecomId", selected.telecomId)
            }
            placeholder="통신사"
          />
        </S.InputBox>
        <S.InputBox isRow>
          <p>
            월정액<span>*</span>
          </p>
          <S.MoneyInputs>
            <S.Input
              type="number"
              name="price"
              onBlur={onChangeInputs}
              defaultValue={inputs.price}
              placeholder="월정액"
            />
          </S.MoneyInputs>
        </S.InputBox>
        <S.InputBox isRow>
          <p>포인트</p>
          <S.MoneyInputs>
            <S.Input
              name="point"
              onBlur={onChangeInputs}
              defaultValue={inputs.point}
              placeholder="포인트"
            />
          </S.MoneyInputs>
        </S.InputBox>
        <S.InputBox isRow>
          <p>설명</p>
          <S.Textarea
            name="explanation"
            onBlur={onChangeInputs}
            defaultValue={inputs.explanation}
            height={200}
          />
        </S.InputBox>
      </Split>
      <Bottoms>
        <button type="button" onClick={save}>
          부가서비스 {type}하기
        </button>
      </Bottoms>
    </Main>
  );
};

export default SubserviceManage;
