import ReactDatePicker from "react-datepicker";
import styled from "styled-components";

export const Container = styled.main``;

export const Header = styled.section`
  ${({ theme }) => theme.flex.col}
  margin: 30px;
  background-color: #fff;
  border-radius: 15px;

  .react-datepicker__tab-loop {
    position: absolute;
    top: 0;
  }

  .react-datepicker__triangle {
    display: none !important;
  }
`;

export const FilterContainer = styled.article`
  ${({ theme }) => theme.flex.col}
  justify-content: space-between;
  gap: 16px;
  padding: 30px;
  border-bottom: 1px solid #f1f1f1;

  .title {
    font-size: 16px;
    font-weight: 600;
  }
`;

export const Filters = styled.div`
  ${({ theme }) => theme.flex.row}
  gap: 30px;

  & > div {
    ${({ theme }) => theme.flex.col}
    gap: 6px;
  }
`;

export const SelectedFilterContainer = styled.ul`
  ${({ theme }) => theme.flex.row}
  flex-wrap: wrap;
  gap: 6px;
  list-style: none;
`;

export const SelectedFilter = styled.li`
  ${({ theme }) => theme.flex.row}
  align-items: center;
  gap: 6px;
  padding: 6px 12px;
  background-color: #eee;
  border-radius: 30px;
  font-size: 14px;

  .close:before {
    font-family: "FontAwesome";
    content: "\f00d";
    color: #ccc;
  }
`;

export const SummaryContianer = styled.article`
  ${({ theme }) => theme.flex.row}
  gap: 30px;
  padding: 30px;

  & > article {
    ${({ theme }) => theme.flex.col}
    gap: 6px;
    padding-right: 30px;
    border-right: 1px solid #f1f1f1;

    .value {
      font-size: 22px;
      font-weight: 600;
    }

    &:last-child {
      border-right: 0;
    }
  }
`;

export const DateButton = styled(ReactDatePicker)`
  padding: 6px 12px;
  /* background-color: #f1f1f1; */
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
`;
