import { Main, Split, Bottoms } from "@styles/components/ModalBodyStyle";
import * as S from "@styles/components/FormStyle";
import { TabBox, Tab, Tabs } from "../Styles/PayOpenStyle";
import CustomerOpenContainer from "../containers/CustomerOpenContainer";
import PhoneplanOpenContainer from "../containers/PhoneplanOpenContainer";
import DeviceOpenContainer from "../containers/DeviceOpenContainer";
import BillOpenContainer from "../containers/BillOpenContainer";
import { OpenTypes } from "@typedef/components/Pay/Open/open.types";
import Loading from "@components/Common/Loading/Loading";

type Props = {
  type: string;
  tabs: string[];
  activeTab: number;
  setActiveTab: React.Dispatch<React.SetStateAction<number>>;
  inputs: Partial<OpenTypes>;
  setInputs: React.Dispatch<React.SetStateAction<Partial<OpenTypes>>>;
  splitPhone: {
    phone1: string;
    phone2: string;
    phone3: string;
  };
  splitOpenPhone: {
    openPhone1: string;
    openPhone2: string;
    openPhone3: string;
  };
  openTime: {
    date: Date;
    hour: string;
    minute: string;
  };
  onChangeInputs: (e: { target: HTMLInputElement }) => void;
  onChangeSelect: <T extends {}>(name: string, selected: T) => void;
  onChangeOpenTime: (name: string, selected: Date | string) => void;
  save: () => Promise<void>;
  loading: boolean;
};

const ShopDeviceOpen = ({
  type,
  tabs,
  activeTab,
  setActiveTab,
  inputs,
  setInputs,
  splitPhone,
  splitOpenPhone,
  openTime,
  onChangeInputs,
  onChangeSelect,
  onChangeOpenTime,
  save,
  loading,
}: Props) => {
  return (
    <Main width={70}>
      {loading && <Loading />}
      <S.Col>
        <TabBox>
          <h4>개통 정보 입력</h4>
          <Tabs>
            {tabs.map((tab, i) => (
              <Tab
                type="button"
                onClick={() => setActiveTab(i)}
                className={activeTab === i ? "active" : ""}
              >
                {tab}
              </Tab>
            ))}
          </Tabs>
        </TabBox>
        <Split>
          {activeTab === 0 && (
            <CustomerOpenContainer
              inputs={inputs}
              setInputs={setInputs}
              onChangeInputs={onChangeInputs}
              onChangeSelect={onChangeSelect}
              splitPhone={splitPhone}
              splitOpenPhone={splitOpenPhone}
            />
          )}
          {activeTab === 1 && (
            <PhoneplanOpenContainer
              inputs={inputs}
              setInputs={setInputs}
              onChangeInputs={onChangeInputs}
              onChangeSelect={onChangeSelect}
            />
          )}
          {activeTab === 2 && (
            <DeviceOpenContainer
              inputs={inputs}
              setInputs={setInputs}
              onChangeInputs={onChangeInputs}
              onChangeSelect={onChangeSelect}
            />
          )}
          {activeTab === 3 && (
            <BillOpenContainer
              inputs={inputs}
              setInputs={setInputs}
              onChangeInputs={onChangeInputs}
              onChangeSelect={onChangeSelect}
              openTime={openTime}
              onChangeOpenTime={onChangeOpenTime}
            />
          )}
        </Split>
      </S.Col>
      <Bottoms style={{ display: "flex", gap: "8px" }}>
        {activeTab === 3 ? (
          <>
            <button
              onClick={() => activeTab >= 0 && setActiveTab(activeTab - 1)}
              style={{ backgroundColor: "#c4c4c4" }}
            >
              이전
            </button>
            <button type="button" onClick={save}>
              개통 {type}하기
            </button>
          </>
        ) : (
          <>
            <button
              onClick={() => activeTab >= 0 && setActiveTab(activeTab - 1)}
              style={{ backgroundColor: "#c4c4c4" }}
            >
              이전
            </button>
            <button
              onClick={() =>
                activeTab <= tabs.length && setActiveTab(activeTab + 1)
              }
            >
              다음
            </button>
          </>
        )}
      </Bottoms>
    </Main>
  );
};

export default ShopDeviceOpen;
