import CustomerHistory from '../CustomerHistory';
import { useState, useEffect, useMemo } from 'react';
import { apiRoute, requestSecureGet } from '@libs/api';
import useToken from '@hooks/useToken';
import { useSetRecoilState } from 'recoil';
import { loading } from '@stories/Atom';
import { CustomerHistoryTypes } from '@typedef/components/Customer/History/customer.history.types';
import { TableColumnTypes } from '@typedef/components/Common/CTable/table.column.types';

const CustomerHistoryContainer = () => {
  const { getToken } = useToken();
  // 테이블 데이터
  const [tableData, setTableData] = useState<CustomerHistoryTypes[]>([]);
  // 테이블 로딩
  const setLoading = useSetRecoilState(loading);

  // 테이블 컬럼
  const columns: TableColumnTypes<CustomerHistoryTypes>[] = useMemo(
    () => [
      {
        Header: 'No',
        width: 70,
        isFirst: true,
        Cell: ({ row }: any) => <>{tableData.length - row.index}</>,
      },
      {
        Header: '일시',
        accessor: (originalRow) => originalRow.createdAt.slice(0, -3),
      },
      {
        Header: '작업내역',
        accessor: 'process',
        disableSortBy: true,
      },
      {
        Header: '고객명',
        accessor: 'name',
        disableSortBy: true,
      },
      {
        Header: '관리번호',
        accessor: 'manageNo',
        disableSortBy: true,
      },
      {
        Header: '메모',
        accessor: 'memo',
        disableSortBy: true,
      },
      {
        Header: '처리자',
        accessor: 'processor',
        disableSortBy: true,
      },
    ],
    [tableData.length],
  );

  const getTableData = async () => {
    const { config, data } = await requestSecureGet<{
      customerHistories: CustomerHistoryTypes[];
    }>(apiRoute.customer.history.getHistories, {}, getToken()!);
    if (config.status >= 200 && config.status < 400) {
      setTableData(data.customerHistories);
      setLoading(false);
    } else {
      setTableData([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <CustomerHistory columns={columns} tableData={tableData} />;
};

export default CustomerHistoryContainer;
