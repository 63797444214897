import Inventory from "../Inventory";
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { apiRoute, requestSecureDelete, requestSecureGet } from "@libs/api";
import useToken from "@hooks/useToken";
import useUser from "@hooks/useUser";
import { useSetRecoilState } from "recoil";
import { loading, modal, modalHeader } from "@stories/Atom";
import { TableColumnTypes } from "@typedef/components/Common/CTable/table.column.types";
import { ShopDeviceTypes } from "@typedef/components/Inventory/shop.device.types";
import CopyCell from "@components/Common/CTable/components/CopyCell";
import ShopDeviceUpdateContainer from "./ShopDeviceUpdateContainer";
import { calculateDay, formatTimezone } from "@libs/commonFuncs";
import DetailCell from "@components/Common/CTable/components/DetailCell";

// 재고관리 > 재고관리 (대리점, 판매점)
const InventoryContainer = () => {
  const { getToken } = useToken();
  const { getUser } = useUser();
  const role = useMemo(() => getUser().role, [getUser]);
  // 테이블 데이터
  const [tableData, setTableData] = useState<ShopDeviceTypes[]>([]);
  const [checkedIdxes, setCheckedIdxes] = useState<number[]>([]);
  // 모달설정
  const setModalHeader = useSetRecoilState(modalHeader);
  const setModal = useSetRecoilState(modal);
  // 테이블 로딩
  const setLoading = useSetRecoilState(loading);
  const [reload, setReload] = useState<number>(0);

  // 재고 상세조회 및 수정
  const updateShopDevice = useCallback(
    (idx?: number) => {
      if (!!!idx && checkedIdxes.length === 0) {
        alert("수정할 재고를 선택해 주세요.");
        return;
      } else if (!!!idx && checkedIdxes.length > 1) {
        alert("수정할 재고를 하나만 선택해 주세요.");
        return;
      }
      setModalHeader("재고 수정");
      setModal(
        <ShopDeviceUpdateContainer
          idx={idx ?? checkedIdxes[0]}
          close={() => setModal(null)}
          reload={() => setReload((prev) => prev + 1)}
        />
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [checkedIdxes]
  );

  // 체크박스
  const onChangeCheckbox = useCallback(
    (idx: number) => {
      if (checkedIdxes.includes(idx)) {
        setCheckedIdxes(
          checkedIdxes.filter((checkedIdx) => checkedIdx !== idx)
        );
        return;
      }
      setCheckedIdxes((idxes) => [...idxes, idx]);
    },
    [checkedIdxes]
  );

  // 테이블 컬럼
  const columns: TableColumnTypes<ShopDeviceTypes>[] = useMemo(
    () => [
      {
        Header: "",
        accessor: "idx",
        disableSortBy: true,
        width: 70,
        Cell: ({ value }) => (
          <input
            type="checkbox"
            defaultChecked={checkedIdxes.includes(value as number)}
            onChange={() => onChangeCheckbox(value as number)}
          />
        ),
      },
      {
        Header: "No",
        width: 70,
        Cell: ({ row }) => <>{tableData.length - row.index}</>,
      },
      {
        Header: "상태",
        accessor: "status.name",
        width: 100,
        Cell: ({ value }) => (
          <div
            className={
              value === "출고" || value === "개통" || value === "이관"
                ? "status out"
                : value === "분실"
                ? "status loss"
                : "status in"
            }
          >
            {value}
          </div>
        ),
      },
      {
        Header: "통신/제조사",
        accessor: (originalRow) => {
          const phone = originalRow.telecom.name;
          const etc = originalRow.maker_nm;
          return etc ?? phone;
        },
        disableSortBy: true,
        width: 100,
      },
      {
        Header: "모델명",
        accessor: (originalRow) => {
          const phone = originalRow.device_info.device.model_name;
          const etc = originalRow.model_name;
          return phone ?? etc;
        },
        Cell: ({ row, value }) => (
          <DetailCell
            value={value as string}
            func={() => updateShopDevice(row.original.idx)}
          />
        ),
      },
      {
        Header: "색상",
        accessor: (originalRow) => {
          const phone = originalRow.device_info.color.name;
          const etc = originalRow.device_color;
          return phone ?? etc;
        },
        disableSortBy: true,
      },
      {
        Header: "일련번호",
        accessor: "serial_number",
        disableSortBy: true,
        Cell: ({ value }) => <CopyCell value={value} />,
      },

      {
        Header: "입고처",
        accessor: (originalRow) => {
          const depot = originalRow.shop_depot.name;
          const owner = originalRow.owner.name;
          return depot ?? owner;
        },
        disableSortBy: true,
        Cell: ({ row, value }) => <div>{value ?? row.original.owner.name}</div>,
      },
      {
        Header: "보유처",
        accessor: "location.name",
        disableSortBy: true,
      },
      {
        Header: "입고일",
        accessor: (originalRow) => formatTimezone(originalRow.in_date),
        Cell: ({ value }) => (
          <div>
            <p>{value}</p>
            <span className="date">
              {value && "+" + calculateDay(value as string)}
            </span>
          </div>
        ),
      },
      // {
      //   Header: '이동일',
      //   accessor: (originalRow) => formatTimezone(originalRow.move_date),
      //   Cell: ({ value }) => (
      //     <div>
      //       <p>{value && value}</p>
      //       <span className='date'>
      //         {value && '+' + calculateDay(value as string)}
      //       </span>
      //     </div>
      //   ),
      // },
    ],
    [checkedIdxes, onChangeCheckbox, tableData.length, updateShopDevice]
  );

  // 재고 삭제
  const deleteShopDevice = async () => {
    if (checkedIdxes.length === 0) {
      alert("삭제할 재고를 선택해 주세요.");
    } else if (
      window.confirm(`선택한 재고 ${checkedIdxes.length}개를 삭제하시겠습니까?`)
    ) {
      await Promise.all(
        checkedIdxes.map(
          (idx) =>
            new Promise((resolve, reject) => {
              requestSecureDelete(
                apiRoute.shopDevice.deleteDevice + idx,
                {},
                getToken()!
              ).then((data) => {
                if (data.config.status >= 200 && data.config.status < 400) {
                  resolve(data);
                }
              });
            })
        )
      ).then(() => {
        alert("성공적으로 삭제가 완료되었습니다.");
        setReload((prev) => prev + 1);
      });
    }
  };

  // 재고관리 테이블 데이터 조회
  const getTableData = async () => {
    const { config, data } = await requestSecureGet<ShopDeviceTypes[]>(
      apiRoute.shopDevice.getDevices + "?status_type=in",
      {},
      getToken()!
    );
    if (config.status >= 200 && config.status < 400) {
      setTableData(data);
      setLoading(false);
    } else {
      setTableData([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  return (
    <Inventory
      columns={columns}
      tableData={tableData}
      updateShopDevice={updateShopDevice}
      deleteShopDevice={deleteShopDevice}
      isAgency={role.includes("AGENCY")}
    />
  );
};

export default InventoryContainer;
