import * as S from './styles/MobileNBStyle';
import { useRecoilState } from 'recoil';
import { mobileNB } from '@stories/Atom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';
import { LNBTypes } from '@typedef/components/Common/LNB/lnb.types';

type Props = {
  mobileNBData: LNBTypes[];
};

const MobileNB = ({ mobileNBData }: Props) => {
  const [showMobileNB, setShowMobileNB] = useRecoilState<boolean>(mobileNB);

  return (
    <S.MobileNB visible={showMobileNB}>
      <S.Background
        visible={showMobileNB}
        onClick={() => setShowMobileNB(false)}
      />
      <S.Navs visible={showMobileNB}>
        <S.Profile>
          <p className='name'>제이솔루션님</p>
          <button
            type='button'
            onClick={() => setShowMobileNB(false)}
            className='close'>
            <FontAwesomeIcon icon={faX} />
          </button>
        </S.Profile>
        {mobileNBData.map((nav) => (
          <details open key={nav.baseUrl}>
            <summary>{nav.title}</summary>
            <span>
              {nav.subNavs.map((sub) => (
                <p key={sub.path}>{sub.name}</p>
              ))}
            </span>
          </details>
        ))}
      </S.Navs>
    </S.MobileNB>
  );
};

export default MobileNB;
