import InventoryPut from "../InventoryPut";
import ShopDeviceInsertContainer from "./ShopDeviceInsertContainer";
import ShopDeviceUpdateContainer from "@components/Inventory/Inventory/containers/ShopDeviceUpdateContainer";
import { useState, useEffect, useMemo, useCallback } from "react";
import { useSetRecoilState } from "recoil";
import { loading, modal, modalHeader } from "@stories/Atom";
import { apiRoute, requestSecureDelete, requestSecureGet } from "@libs/api";
import useToken from "@hooks/useToken";
import { TableColumnTypes } from "@typedef/components/Common/CTable/table.column.types";
import { ShopDeviceTypes } from "@typedef/components/Inventory/shop.device.types";
import CopyCell from "@components/Common/CTable/components/CopyCell";
import dayjs from "dayjs";
import { ShopDeviceExcelTypes } from "@typedef/components/Inventory/shop.device.excel.types";
import { formatTimezone } from "@libs/commonFuncs";
import DetailCell from "@components/Common/CTable/components/DetailCell";
import ExcelInsertContainer from "./ExcelInsertContainer";

// 재고관리 > 입고관리 (대리점)
const InventoryPutContainer = () => {
  const { getToken } = useToken();
  // 테이블 데이터
  const [tableData, setTableData] = useState<ShopDeviceTypes[]>([]);
  const [filteredData, setFilteredData] = useState<ShopDeviceTypes[]>([]);
  // 엑셀 데이터
  const [excelData, setExcelData] = useState<ShopDeviceExcelTypes[]>([]);
  const [checkedIdxes, setCheckedIdxes] = useState<number[]>([]);
  // 모달설정 (단말요청, 재고배정)
  const setModalHeader = useSetRecoilState(modalHeader);
  const setModal = useSetRecoilState(modal);
  // 테이블 로딩
  const setLoading = useSetRecoilState(loading);
  const [reload, setReload] = useState<number>(0);

  // 재고 상세조회 및 수정
  const updateShopDevice = useCallback(
    (idx?: number) => {
      if (!!!idx && checkedIdxes.length === 0) {
        alert("수정할 재고를 선택해 주세요.");
        return;
      } else if (!!!idx && checkedIdxes.length > 1) {
        alert("수정할 재고를 하나만 선택해 주세요.");
        return;
      }
      setModalHeader("재고 수정");
      setModal(
        <ShopDeviceUpdateContainer
          idx={idx ?? checkedIdxes[0]}
          close={() => setModal(null)}
          reload={() => setReload((prev) => prev + 1)}
        />
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [checkedIdxes]
  );

  const onClickInsertByExcel = () => {
    setModalHeader("엑셀 입고");
    setModal(
      <ExcelInsertContainer
        close={() => setModal(null)}
        reload={() => setReload((prev) => prev + 1)}
      />
    );
  };

  // 체크박스
  const onChangeCheckbox = useCallback(
    (idx: number) => {
      if (checkedIdxes.includes(idx)) {
        setCheckedIdxes(
          checkedIdxes.filter((checkedIdx) => checkedIdx !== idx)
        );
        return;
      }
      setCheckedIdxes((idxes) => [...idxes, idx]);
    },
    [checkedIdxes]
  );

  const columns: TableColumnTypes<ShopDeviceTypes>[] = useMemo(
    () => [
      {
        Header: "",
        accessor: "idx",
        disableSortBy: true,
        width: 70,
        Cell: ({ value }) => (
          <input
            type="checkbox"
            defaultChecked={checkedIdxes.includes(value as number)}
            onChange={() => onChangeCheckbox(value as number)}
          />
        ),
      },
      {
        Header: "No",
        width: 70,
        Cell: ({ row }) => <>{tableData.length - row.index}</>,
      },
      {
        Header: "입고일",
        accessor: (originalRow) => formatTimezone(originalRow.in_date),
      },
      {
        Header: "입고유형",
        accessor: "category.name",
        width: 100,
      },
      {
        Header: "통신/제조사",
        accessor: (originalRow) => {
          const phone = originalRow.telecom.name;
          const etc = originalRow.maker_nm;
          return etc ?? phone;
        },
        disableSortBy: true,
        width: 100,
      },
      {
        Header: "모델명",
        accessor: (originalRow) => {
          const phone = originalRow.device_info.device.model_name;
          const etc = originalRow.model_name;
          return phone ?? etc;
        },
        disableSortBy: true,
        Cell: ({ row, value }) => (
          <DetailCell
            value={value as string}
            func={() => updateShopDevice(row.original.idx)}
          />
        ),
      },
      {
        Header: "색상",
        accessor: (originalRow) => {
          const phone = originalRow.device_info.color.name;
          const etc = originalRow.device_color;
          return phone ?? etc;
        },
        disableSortBy: true,
      },
      {
        Header: "일련번호",
        accessor: "serial_number",
        // disableSortBy: true,
        Cell: ({ value }) => <CopyCell value={value} />,
      },
      {
        Header: "입고가",
        accessor: (originalRow) => {
          const phone = originalRow.device_info.device.price;
          const etc = originalRow.price;
          return phone ?? etc;
        },
        Cell: ({ value }) => <>{value.toLocaleString()}</>,
      },
      {
        Header: "메모",
        accessor: "memo",
        disableSortBy: true,
      },
    ],
    [checkedIdxes, onChangeCheckbox, tableData.length, updateShopDevice]
  );

  // 재고입고
  const insertShopDevice = () => {
    setModalHeader("재고입고");
    setModal(
      <ShopDeviceInsertContainer
        close={() => setModal(null)}
        reload={() => setReload((prev) => prev + 1)}
      />
    );
  };

  // 유심입고
  const insertUsim = () => {};

  // 엑셀 데이터
  const getExcelData = (data: ShopDeviceTypes[]) => {
    if (data.length) {
      const excelData: ShopDeviceExcelTypes[] = data.map((data) => {
        const temp: ShopDeviceExcelTypes = {};
        temp.category_name = data.category.name;
        temp.device_model_name =
          data.device_info.device.model_name ?? data.model_name;
        temp.device_color = data.device_info.color.name ?? data.device_color;
        temp.device_price = data.device_info.device.price ?? data.price;
        temp.in_date = dayjs(data.in_date).format("YYYY-MM-DD");
        temp.memo = data.memo;
        temp.serial_number = data.serial_number;
        temp.telecom_maker_name = data.maker_nm ?? data.telecom.name;

        return temp;
      });
      setExcelData(excelData);
    } else {
      setExcelData([]);
    }
    setLoading(false);
  };

  // 재고 삭제
  const deleteShopDevice = async () => {
    if (checkedIdxes.length === 0) {
      alert("삭제할 재고를 선택해 주세요.");
    } else if (
      window.confirm(`선택한 재고 ${checkedIdxes.length}개를 삭제하시겠습니까?`)
    ) {
      await Promise.all(
        checkedIdxes.map(
          (idx) =>
            new Promise((resolve, reject) => {
              requestSecureDelete(
                apiRoute.shopDevice.deleteDevice + idx,
                {},
                getToken()!
              ).then((data) => {
                if (data.config.status >= 200 && data.config.status < 400) {
                  resolve(data);
                }
              });
            })
        )
      ).then(() => {
        alert("성공적으로 삭제가 완료되었습니다.");
        setReload((prev) => prev + 1);
      });
    }
  };

  // @FIXME
  const onChageFilter = (name: string, value: string | number) => {
    setFilteredData(filteredData);
  };

  // 테이블 데이터
  const getTableData = async () => {
    const { config, data } = await requestSecureGet<ShopDeviceTypes[]>(
      apiRoute.shopDevice.getDevices,
      {},
      getToken()!
    );
    if (config.status >= 200 && config.status < 400) {
      setTableData(data);
      getExcelData(data);
    }
  };

  useEffect(() => {
    setLoading(true);
    getTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  return (
    <InventoryPut
      columns={columns}
      tableData={filteredData.length ? filteredData : tableData}
      insertShopDevice={insertShopDevice}
      insertUsim={insertUsim}
      updateShopDevice={updateShopDevice}
      deleteShopDevice={deleteShopDevice}
      onChageFilter={onChageFilter}
      today={dayjs().format("YYMMDD")}
      excelData={excelData}
      onClickInsertByExcel={onClickInsertByExcel}
    />
  );
};

export default InventoryPutContainer;
