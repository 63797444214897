import * as S from '../styles/MainScheduleStyle';
import CCalendar from '@components/Common/CCalendar/CCalendar';
import { ScheduleTypes } from '@typedef/components/Schedule/schedule.types';
import { Link } from 'react-router-dom';

type Props = {
  clickedDate: string;
  onChangeDate: (date: Date | Date[]) => void;
  filteredSchedules: ScheduleTypes[];
};

const MainSchedule = ({
  clickedDate,
  onChangeDate,
  filteredSchedules,
}: Props) => {
  return (
    <S.Aside>
      <S.Calendar>
        <CCalendar onChangeDate={onChangeDate} />
      </S.Calendar>
      <S.Schedules>
        <header>
          <h3>{clickedDate}</h3>
          <Link to='schedule'>전체보기</Link>
        </header>
        <S.ScheduleBox>
          {filteredSchedules.length ? (
            filteredSchedules.map((schedule) => (
              <S.Schedule key={schedule.scheduleId}>
                <S.Dot bgc={schedule.colorHex} />
                <h4>{schedule.title}</h4>
              </S.Schedule>
            ))
          ) : (
            <S.Schedule className='nothing'>이날의 일정이 없습니다.</S.Schedule>
          )}
        </S.ScheduleBox>
      </S.Schedules>
    </S.Aside>
  );
};

export default MainSchedule;
