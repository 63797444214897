import ShopDetail from "../components/ShopDetail";
import { useEffect, useState } from "react";
import useToken from "@hooks/useToken";
import { apiRoute, requestSecureGet } from "@libs/api";
import { ShopTypes } from "@typedef/components/Shop/shop.types";

type Props = {
  idx: number;
};

const ShopDetailContainer = ({ idx }: Props) => {
  const { getToken } = useToken();
  const [inputs, setInputs] = useState<Partial<ShopTypes>>({});

  const getCheckedData = async () => {
    const { config, data } = await requestSecureGet<{ shop: ShopTypes }>(
      apiRoute.shop.getShop + idx,
      {},
      getToken()!
    );

    if (config.status >= 200 && config.status < 400) {
      setInputs(data.shop);
    }
  };

  useEffect(() => {
    getCheckedData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <ShopDetail inputs={inputs} />;
};

export default ShopDetailContainer;
