import BillOpen from '../components/BillOpen';
import { OpenTypes } from '@typedef/components/Pay/Open/open.types';

type Props = {
  inputs: Partial<OpenTypes>;
  setInputs: React.Dispatch<React.SetStateAction<Partial<OpenTypes>>>;
  onChangeInputs: (e: { target: HTMLInputElement }) => void;
  onChangeSelect: <T extends {}>(name: string, selected: T) => void;
  openTime: {
    date: Date;
    hour: string;
    minute: string;
  };
  onChangeOpenTime: (name: string, selected: Date | string) => void;
};

const BillOpenContainer = ({
  inputs,
  onChangeInputs,
  onChangeSelect,
  openTime,
  onChangeOpenTime,
}: Props) => {
  return (
    <BillOpen
      inputs={inputs}
      onChangeInputs={onChangeInputs}
      onChangeSelect={onChangeSelect}
      openTime={openTime}
      onChangeOpenTime={onChangeOpenTime}
    />
  );
};

export default BillOpenContainer;
