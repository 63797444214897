import * as S from '../styles/HelpViewStyle';
import { BoardTypes } from '@typedef/components/Board/board.types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';

type Props = {
  boards: BoardTypes[];
  activeCategory: string;
  onClickCategory: (category: string) => void;
  onClickBoard: (boardId: number) => void;
  activeBoards: number[];
};

const FAQ = ({
  boards,
  activeCategory,
  onClickCategory,
  onClickBoard,
  activeBoards,
}: Props) => {
  return (
    <S.Main>
      <S.TopBtns>
        <h3>전체 {boards.length}</h3>
        <S.Categories>
          {subCategories.map((category) => (
            <S.Category
              key={category}
              onClick={() => onClickCategory(category)}
              className={category === activeCategory ? 'active' : ''}>
              {category}
            </S.Category>
          ))}
        </S.Categories>
      </S.TopBtns>
      <S.Boards>
        {boards.map((board) => (
          <S.FAQ
            key={board.boardId}
            onClick={() => onClickBoard(board.boardId)}>
            <S.Board className='faq'>
              <S.Row>
                <span>{board.subCategory}</span>
                <h3>{board.title}</h3>
              </S.Row>
              <FontAwesomeIcon
                icon={
                  activeBoards.includes(board.boardId) ? faAngleUp : faAngleDown
                }
              />
            </S.Board>
            <S.FAQContent isActive={activeBoards.includes(board.boardId)}>
              <h3>A.</h3>
              <p>{board.content}</p>
            </S.FAQContent>
          </S.FAQ>
        ))}
      </S.Boards>
    </S.Main>
  );
};

export default FAQ;

const subCategories = [
  '전체',
  '재고관리',
  '정산관리',
  '고객관리',
  '설정',
  '기타',
];
