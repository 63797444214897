import * as S from "@styles/components/FormStyle";
import CSelect from "@components/Common/CSelect/CSelect";
import { ShopDeviceInsertTypes } from "@typedef/components/Inventory/Put/shop.device.insert.types";
import { TelecomTypes } from "@typedef/components/Telecom/telecom.types";
import { DeviceCategoryTypes } from "@typedef/components/Device/Category/device.category.types";
import { DeviceTypes } from "@typedef/components/Device/device.types";
import { DeviceInfoTypes } from "@typedef/components/Device/device.info.types";
import { SelectDefaultTypes } from "@typedef/components/Common/CSelect/select.default.types";
import { ItemTypes } from "@typedef/components/Item/item.types";

type Props = {
  type: "phone" | "etc";
  telecoms: TelecomTypes[];
  categories: DeviceCategoryTypes[];
  devices: DeviceTypes[];
  colors: DeviceInfoTypes[];
  items: ItemTypes[];
  inputs: ShopDeviceInsertTypes;
  onChangeInputs: (e: {
    target: HTMLInputElement | HTMLTextAreaElement;
  }) => void;
  onChangeSelect: (name: string, selected: any) => void;
  onClickModelName: (option: DeviceTypes) => void;
  onClickAddItem: () => void;
  unit: string;
};

const HandWrittenInsert = ({
  type,
  inputs,
  onChangeInputs,
  onChangeSelect,
  onClickModelName,
  telecoms,
  categories,
  devices,
  colors,
  items,
  onClickAddItem,
  unit,
}: Props) => {
  return (
    <S.Col>
      <S.InputBox isRow>
        <p>
          입고유형<span>&nbsp;*</span>
        </p>
        <CSelect<DeviceCategoryTypes>
          options={categories}
          value={(category: DeviceCategoryTypes) => category.idx}
          label={(category: DeviceCategoryTypes) => category.name}
          defaultValue={categories.filter(
            (category) => category.idx === inputs.category_idx
          )}
          func={(selected: DeviceCategoryTypes) => {
            onChangeSelect("category_idx", selected.idx);
            onChangeSelect("category_name", selected.name);
          }}
          height={180}
          placeholder="입고유형"
        />
      </S.InputBox>
      {/*{type === "etc" && (*/}
      {/*    <S.InputBox isRow>*/}
      {/*        <p>품목</p>*/}
      {/*        <CSelect<ItemTypes>*/}
      {/*            options={items}*/}
      {/*            value={(item: ItemTypes) => item.id}*/}
      {/*            label={(item: ItemTypes) => item.name}*/}
      {/*            defaultValue={items.filter((item) => item.id === inputs.itemId)}*/}
      {/*            func={(selected: ItemTypes) => {*/}
      {/*                onChangeSelect("itemId", selected.id);*/}
      {/*            }}*/}
      {/*            height={180}*/}
      {/*            placeholder="품목"*/}
      {/*        />*/}
      {/*        <Button onClick={onClickAddItem}>추가</Button>*/}
      {/*    </S.InputBox>*/}
      {/*)}*/}
      {type === "phone" && (
        <S.InputBox isRow>
          <p>
            통신사<span>&nbsp;*</span>
          </p>
          <CSelect<TelecomTypes>
            options={telecoms}
            value={(telecom: TelecomTypes) => telecom.telecomId}
            label={(telecom: TelecomTypes) => telecom.name}
            defaultValue={telecoms.filter(
              (telecom) => telecom.telecomId === inputs.telecom_idx
            )}
            func={(selected: TelecomTypes) => {
              onChangeSelect("telecom_idx", selected.telecomId);
              onChangeSelect("telecom_name", selected.name);
            }}
            autoFocus
            placeholder="통신사"
          />
        </S.InputBox>
      )}
      {type === "etc" && (
        <S.InputBox isRow>
          <p>
            제조사<span>&nbsp;*</span>
          </p>
          <S.Input
            type="text"
            name="maker_nm"
            key={inputs.maker_nm}
            defaultValue={inputs.maker_nm}
            onBlur={onChangeInputs}
            placeholder="제조사"
          />
        </S.InputBox>
      )}
      <S.InputBox isRow>
        <p>
          모델명<span>&nbsp;*</span>
        </p>
        {type === "phone" ? (
          <CSelect<DeviceTypes>
            options={devices}
            value={(device: DeviceTypes) => device.idx}
            key={inputs.model_name}
            defaultValue={devices.filter(
              (device) => device.model_name === inputs.model_name
            )}
            label={(device: DeviceTypes) => device.model_name}
            func={onClickModelName}
            placeholder="모델명"
          />
        ) : (
          <S.Input
            name="model_name"
            defaultValue={inputs.model_name}
            key={inputs.model_name}
            onBlur={onChangeInputs}
            placeholder="ex) AIP13-512"
          />
        )}
      </S.InputBox>
      {type === "phone" && (
        <S.InputBox isRow>
          <p>
            색상<span>&nbsp;*</span>
          </p>
          <CSelect<DeviceInfoTypes>
            options={colors}
            value={(device: DeviceInfoTypes) => device.idx}
            label={(device: DeviceInfoTypes) => device.color.name}
            key={inputs.device_info_idx}
            defaultValue={colors.filter(
              (device) => device.idx === inputs.device_info_idx
            )}
            func={(selected: DeviceInfoTypes) => {
              onChangeSelect("device_color", selected.color.name);
              onChangeSelect("device_info_idx", selected.idx);
            }}
            placeholder="색상"
          />
        </S.InputBox>
      )}
      {type === "etc" && (
        <S.InputBox isRow>
          <p>색상</p>
          <S.Input
            name="device_color"
            key={inputs.device_color}
            defaultValue={inputs.device_color}
            onBlur={onChangeInputs}
            placeholder="ex) 시에라블루"
          />
        </S.InputBox>
      )}
      <S.InputBox isRow>
        <p>
          일련번호<span>&nbsp;*</span>
        </p>
        <S.Input
          type="text"
          name="serial_number"
          key={inputs.serial_number}
          defaultValue={inputs.serial_number}
          onBlur={onChangeInputs}
          placeholder="일련번호"
        />
      </S.InputBox>
      {type === "phone" && (
        <S.InputBox isRow>
          <p>
            용량<span>&nbsp;*</span>
          </p>
          <S.Input
            name="volume"
            key={inputs.volume}
            defaultValue={inputs.volume}
            onBlur={onChangeInputs}
            placeholder="용량"
            disabled
          />
        </S.InputBox>
      )}
      {type === "etc" && (
        <S.InputBox isRow>
          <p>용량</p>
          <S.TwoInputs>
            <S.Input
              type="number"
              name="volume"
              key={inputs.volume}
              defaultValue={inputs.volume}
              onBlur={onChangeInputs}
              placeholder="숫자만 입력"
            />
            <CSelect<SelectDefaultTypes>
              options={volumes}
              value={(volume: SelectDefaultTypes) => volume.value}
              label={(volume: SelectDefaultTypes) => volume.name}
              defaultValue={volumes.filter((volume) => volume.value === unit)}
              func={(selected: SelectDefaultTypes) =>
                onChangeSelect("unit", selected.value)
              }
              autoFocus
              placeholder="단위"
            />
          </S.TwoInputs>
        </S.InputBox>
      )}
      <S.InputBox isRow>
        <p>
          입고가<span>&nbsp;*</span>
        </p>
        <S.Input
          type="text"
          name="price"
          key={inputs.price}
          defaultValue={
            !inputs.price ? undefined : inputs.price.toLocaleString()
          }
          onBlur={onChangeInputs}
          placeholder="숫자만 입력"
        />
      </S.InputBox>
      <S.InputBox isRow>
        <p>메모</p>
        <S.Input
          name="memo"
          key={inputs.memo}
          defaultValue={inputs.memo}
          onBlur={onChangeInputs}
          placeholder="메모"
        />
      </S.InputBox>
    </S.Col>
  );
};

export default HandWrittenInsert;

const volumes = [
  {
    name: "MB",
    value: "MB",
  },
  {
    name: "GB",
    value: "GB",
  },
  {
    name: "TB",
    value: "TB",
  },
];
