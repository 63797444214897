import PhoneplanOpen from '../components/PhoneplanOpen';
import useToken from '@hooks/useToken';
import {
  getTelecomInsurance,
  getTelecomPhoneplan,
  getTelecoms,
  getTelecomSubservice,
} from '@stories/Selector';
import { useRecoilValue } from 'recoil';
import { useState, useEffect } from 'react';
import { OpenTypes } from '@typedef/components/Pay/Open/open.types';

type Props = {
  inputs: Partial<OpenTypes>;
  setInputs: React.Dispatch<React.SetStateAction<Partial<OpenTypes>>>;
  onChangeInputs: (e: { target: HTMLInputElement }) => void;
  onChangeSelect: <T extends {}>(name: string, selected: T) => void;
};

const PhoneplanOpenContainer = ({
  inputs,
  setInputs,
  onChangeInputs,
  onChangeSelect,
}: Props) => {
  const { getToken } = useToken();
  const telecoms = useRecoilValue(getTelecoms(getToken()!));
  const phoneplans = useRecoilValue(getTelecomPhoneplan(getToken()!));
  const subservices = useRecoilValue(getTelecomSubservice(getToken()!));
  const insurances = useRecoilValue(getTelecomInsurance(getToken()!));
  // 월 요금제
  const [total, setTotal] = useState<number>(0);

  // 월 요금제 계산
  useEffect(() => {
    // 요금제 기본료
    const phoneplan = (inputs.phonePlan && inputs.phonePlan.price) ?? 0;

    // 기타 할인
    const etc = inputs.discountPhoneEtc ?? 0;

    // 부가서비스 요금
    const subservice =
      inputs.subServices?.reduce((prev, current) => {
        return prev + current.price;
      }, 0) ?? 0;

    // 보험 요금
    const insurance = (inputs.insurance && inputs.insurance.price) ?? 0;

    // (요금제 기본료 * 선약 ? 0.75 : 1) - 기타할인 + 부가서비스 요금 + 보험 요금
    setTotal(
      (inputs.contractType === 'PHONE' ? phoneplan * 0.75 : phoneplan) -
        etc +
        subservice +
        insurance,
    );
  }, [
    inputs.contractType,
    inputs.discountPhoneEtc,
    inputs.insurance,
    inputs.phonePlan,
    inputs.subServices,
  ]);

  return (
    <PhoneplanOpen
      inputs={inputs}
      onChangeInputs={onChangeInputs}
      onChangeSelect={onChangeSelect}
      telecoms={telecoms}
      phoneplans={phoneplans}
      subservices={subservices}
      insurances={insurances}
      total={total}
    />
  );
};

export default PhoneplanOpenContainer;
