import * as S from '@styles/components/TableViewStyle';
import CTable from '@components/Common/CTable/CTable';
import { TableColumnTypes } from '@typedef/components/Common/CTable/table.column.types';

type Props = {
  columns: TableColumnTypes[];
  tableData: any;
  insertPolicy: () => void;
  updatePolicy: (idx?: number) => void;
  deletePolicy: () => Promise<void>;
};
const SetupPolicy = ({
  columns,
  tableData,
  insertPolicy,
  updatePolicy,
  deletePolicy,
}: Props) => {
  return (
    <S.Main>
      <S.TopBtns>
        <h3>전체 {tableData.length}</h3>

        <S.Btns>
          <S.Btn type='button' onClick={insertPolicy} className='active'>
            정책등록
          </S.Btn>
          <S.Btn type='button' onClick={() => updatePolicy()}>
            수정
          </S.Btn>
          <S.Btn type='button' onClick={deletePolicy}>
            삭제
          </S.Btn>
        </S.Btns>
      </S.TopBtns>
      <CTable columns={columns} tableData={tableData} />
    </S.Main>
  );
};

export default SetupPolicy;
