import RelatedDetail from '../components/RelatedDetail';
import { useState, useEffect, useCallback } from 'react';
import { apiRoute, requestSecureGet } from '@libs/api';
import useToken from '@hooks/useToken';
import { ShopTypes } from '@typedef/components/Shop/shop.types';

type Props = {
  idx: number;
};

const RelatedDetailContainer = ({ idx }: Props) => {
  const { getToken } = useToken();
  const [inputs, setInputs] = useState<Partial<ShopTypes>>({});

  const getCheckedData = useCallback(async () => {
    const { config, data } = await requestSecureGet<{ shop: ShopTypes }>(
      apiRoute.shop.getShop + idx,
      {},
      getToken()!,
    );
    if (config.status >= 200 && config.status < 400) {
      setInputs(data.shop);
    }
  }, [getToken, idx]);

  useEffect(() => {
    getCheckedData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <RelatedDetail inputs={inputs} />;
};

export default RelatedDetailContainer;
