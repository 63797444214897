import styled from 'styled-components';

type Props = {
  height?: number;
  flex?: number;
  isRow?: boolean;
  align?: string;
  justify?: string;
  mt?: number;
  mr?: number;
  mb?: number;
  ml?: number;
};

const space = '1vw';

// 부제목
export const SubTitle = styled.header<Props>`
  ${({ theme }) => theme.flex.col}
  flex-direction: ${({ isRow }) => isRow && 'row'};
  align-items: ${({ align }) => align};
  justify-content: ${({ justify }) => justify};
  margin-bottom: 1vw;

  .description {
    margin-top: 0.25vw;
    font-size: 0.9rem;
  }

  // label + checkbox
  label {
    ${({ theme }) => theme.flex.row}
    align-items: center;
    font-weight: 500;
    cursor: pointer;
    margin-left: 0.25vw;

    input[type='checkbox'],
    input[type='radio'] {
      margin-right: 0.5vw;
    }
  }
`;

// 검색창
export const Search = styled.section`
  input {
    width: 200px;
    padding: 0 15px;
    border: 1px solid #ccc;
    border-radius: 3px;
    font-size: 1rem;
  }
`;

// col(default)
export const InputBox = styled.section<Props>`
  width: 100%;
  ${({ theme }) => theme.flex.col}
  flex-direction: ${({ isRow }) => isRow && 'row'};
  align-items: ${({ isRow }) => isRow && 'center'};
  justify-content: space-between;
  margin-bottom: ${space};

  p {
    width: ${({ isRow }) => isRow && '120px'};
    margin-bottom: ${({ isRow }) => !isRow && '0.5vw'}; // space / 2
    font-weight: 500;
  }

  h4 {
    margin-bottom: 0.5vw; // space / 2
  }

  .react-datepicker-wrapper {
    width: 100%;
    flex: 1;
    line-height: 38px; // border : 2px
    border: 1px solid #ccc;
    border-radius: 3px;
    font-size: 1rem;

    input {
      width: 100%;
      line-height: 38px;
      padding: 0 15px;
    }
  }

  // * 표시
  span:not(.react-select__indicator-separator) {
    color: ${({ theme }) => theme.colors.brand500};
    margin-left: 5px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  // margin
  margin-top: ${(props) => props.mt}vw !important;
  margin-right: ${(props) => props.mr}vw !important;
  margin-bottom: ${(props) => props.mb}vw !important;
  margin-left: ${(props) => props.ml}vw !important;
`;

// input
export const Input = styled.input`
  width: 100%;
  flex: 1;
  line-height: 38px; // border : 2px
  padding: 0 15px;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-size: 1rem;
`;

export const AddressInputs = styled.section`
  flex: 1;

  input {
    width: 100%;
    margin-bottom: 0.5vw;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .zonecode {
    width: 50%;
  }

  // 우편번호 검색 버튼
  button {
    width: 50%;
    margin-left: 1vw;
    background-color: ${({ theme }) => theme.colors.grey500};
    color: #191919;
    font-size: 0.95rem;
  }
`;

export const MoneyInputs = styled.section`
  flex: 1;
  position: relative;
  border: 1px solid #ccc;
  border-radius: 3px;
  input {
    width: 100%;
    border: none;
  }
  &::after {
    content: '원';
    width: 36px;
    line-height: 22px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    border-left: 1px solid #ccc;
    color: #bbb;
    text-align: center;
    font-weight: 500;
  }
`;

export const ThreeInputs = styled.section`
  flex: 1;
  ${({ theme }) => theme.flex.row}

  input, button, .select {
    width: calc(100% / 3);
    margin-right: 0.5vw;
    text-align: center;

    &:last-child {
      margin-right: 0;
    }
  }

  button {
    width: 50%;
    background-color: ${({ theme }) => theme.colors.grey500};
    border-radius: 3px;
    color: #191919;
    font-size: 0.95rem;
    font-weight: 500;
  }
`;

export const TwoInputs = styled.section`
  flex: 1;
  ${({ theme }) => theme.flex.row}

  input, button, .select {
    width: calc(100% / 2);
    margin-right: 0.5vw;
    text-align: center;

    &:last-child {
      margin-right: 0;
    }
  }

  button {
    width: 50%;
    background-color: ${({ theme }) => theme.colors.grey500};
    border-radius: 3px;
    color: #191919;
    font-size: 0.95rem;
    font-weight: 500;
  }
`;

export const Msg = styled.span<{ mb?: number }>`
  margin-bottom: ${({ mb }) => mb ?? 1}vw;
  color: ${({ theme }) => theme.colors.danger500};
  font-size: 0.9rem;
  font-weight: 500;
  margin-left: 120px;

  &::before {
    font-family: 'FontAwesome';
    content: '\f071';
    margin-right: 0.5vw;
  }
`;

// textarea
export const Textarea = styled.textarea<{ height?: number }>`
  flex: 1;
  min-height: ${({ height }) => (height ? height : 60)}px;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-size: 1rem;
  resize: none;

  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: #eee;
  }
  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 2px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #ccc;
  }
`;

// table
export const Table = styled.section`
  flex: 1;
  height: 0;
  ${({ theme }) => theme.flex.col};

  .small {
    flex: 0.5;
  }
`;

// thead
export const Thead = styled.section`
  height: 2.5vw;
  ${({ theme }) => theme.flex.row}
  align-items: center;
  margin-bottom: 0.25vw;
  padding-right: 8px; // 스크롤 너비만큼
  background-color: #eee;
  border-radius: 0.25vw;

  th {
    flex: 1;
    ${({ theme }) => theme.flex.row}
    align-items: center;
    justify-content: center;
    font-size: 0.95rem;
  }
`;

// tbody
export const Tbody = styled.section`
  flex: 1;
  ${({ theme }) => theme.flex.col}
  overflow-y: scroll;

  tr,
  div {
    ${({ theme }) => theme.flex.row}
    border-bottom: 1px solid #eee;

    &:last-child {
      border-bottom: none;
    }
  }

  td {
    line-height: 40px;
    flex: 1;
    ${({ theme }) => theme.flex.col}
    align-items: center;
    justify-content: center;
    font-size: 0.9rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    button {
      line-height: 25px;
      background-color: ${({ theme }) => theme.colors.grey500};
      padding: 0 5px;
      border-radius: 3px;
    }

    input[type='number'] {
      width: 70%;
      height: 30px;
      border: 1px solid #ccc;
      border-radius: 3px;
      text-align: center;
    }
  }

  .low {
    line-height: calc(40px / 2);
  }

  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: #eee;
  }
  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 2px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #ccc;
  }
`;

export const Btn = styled.button<Props>`
  width: 100%;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.brand500};
  color: #fff;
  font-weight: 600;
  border-radius: 3px;

  &:disabled {
    background-color: ${({ theme }) => theme.colors.grey500};
    cursor: auto;
  }

  margin-top: ${(props) => props.mt}vw;
  margin-right: ${(props) => props.mr}vw;
  margin-bottom: ${(props) => props.mb}vw;
  margin-left: ${(props) => props.ml}vw;
`;

export const LowBtn = styled.button<Props>`
  font-size: 0.9rem;
  margin-top: ${(props) => props.mt}vw;
  margin-right: ${(props) => props.mr}vw;
  margin-bottom: ${(props) => props.mb}vw;
  margin-left: ${(props) => props.ml}vw;

  span {
    text-decoration: underline;
  }
`;

export const Row = styled.section<Props>`
  flex: ${(props) => props.flex};
  ${({ theme }) => theme.flex.row};
  align-items: ${({ align }) => align};
  justify-content: ${({ justify }) => justify};

  // margin
  margin-top: ${(props) => props.mt}vw;
  margin-right: ${(props) => props.mr}vw;
  margin-bottom: ${(props) => props.mb}vw;
  margin-left: ${(props) => props.ml}vw;
`;

export const Col = styled.section<Props>`
  flex: ${(props) => props.flex};
  ${({ theme }) => theme.flex.col}
  align-items: ${({ align }) => align};
  justify-content: ${({ justify }) => justify};

  // margin
  margin-top: ${(props) => props.mt}vw;
  margin-right: ${(props) => props.mr}vw;
  margin-bottom: ${(props) => props.mb}vw;
  margin-left: ${(props) => props.ml}vw;
`;

export const Grid = styled.section<{ grid?: number }>`
  display: grid;
  grid-template-columns: 1fr 1fr ${({ grid }) => grid === 3 && '1fr'};
  gap: 0.5vw 1.5vw;
`;

export const Title = styled.h1<{ color?: string }>`
  color: ${({ color }) => color && 'red'};
`;

export const Middle = styled.section`
  ${({ theme }) => theme.flex.row}
  align-items: center;
  border-left: 1px solid #eee;
  padding: ${space};
  button {
    ${({ theme }) => theme.flex.col}
    align-items: center;
    justify-content: center;
    padding: 0.75vw;
    background-color: ${({ theme }) => theme.colors.grey200};
    border-radius: 3px;
  }
`;
