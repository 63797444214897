import * as S from '../styles/RouteStyle';
import { useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { modal } from '@stories/Atom';
import useUser from '@hooks/useUser';
import GNBContainer from '@components/Common/GNB/containers/GNBContainer';
import LNBContainer from '@components/Common/LNB/containers/LNBContainer';
import MobileHeader from '@components/Common/MobileNB/MobileHeader';
import MobileNBContainer from '@components/Common/MobileNB/containers/MobileNBContainer';
import JSolRoleNavigation from './JSolRoleNavigation';
import AgencyRoleNavigation from './AgencyRoleNavigation';
import StoreRoleNavigation from './StoreRoleNavigation';
import ModalContainer from '@components/Common/Modal/containers/ModalContainer';
import NotFound from '@components/Common/NotFound/NotFound';

const MainNavigation = () => {
  const { getUser } = useUser();
  const role = useMemo(() => getUser().role, [getUser]);
  // modal
  const currentModal = useRecoilValue(modal);

  return (
    <S.Page>
      {/* laptop gnb, lnb */}
      <GNBContainer />
      <LNBContainer />
      {/* mobile header, nb */}
      <MobileHeader />
      <MobileNBContainer />
      <S.Section>
        <Routes>
          {role?.includes('JSOL') && (
            <Route path='*' element={<JSolRoleNavigation />} />
          )}
          {role?.includes('AGENCY') && (
            <Route path='*' element={<AgencyRoleNavigation />} />
          )}
          {role?.includes('STORE') && (
            <Route path='*' element={<StoreRoleNavigation />} />
          )}
          <Route path='*' element={<NotFound />} />
        </Routes>
      </S.Section>
      {currentModal && <ModalContainer />}
    </S.Page>
  );
};

export default MainNavigation;
