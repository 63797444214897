import HandWrittenInsert from "../components/HandWrittenInsert";
import {useEffect, useState} from "react";
import {apiRoute, requestSecureGet, requestSecurePost} from "@libs/api";
import useToken from "@hooks/useToken";
import {ShopDeviceInsertTypes} from "@typedef/components/Inventory/Put/shop.device.insert.types";
import {TelecomTypes} from "@typedef/components/Telecom/telecom.types";
import {DeviceCategoryTypes} from "@typedef/components/Device/Category/device.category.types";
import {DeviceTypes} from "@typedef/components/Device/device.types";
import {SingleValue} from "react-select";
import {DeviceInfoTypes} from "@typedef/components/Device/device.info.types";
import {ItemTypes} from "@typedef/components/Item/item.types";

type Props = {
    type: "phone" | "etc";
    telecoms: TelecomTypes[];
    categories: DeviceCategoryTypes[];
    inputs: ShopDeviceInsertTypes;
    setInputs: React.Dispatch<React.SetStateAction<ShopDeviceInsertTypes>>;
    onChangeInputs: (e: {
        target: HTMLInputElement | HTMLTextAreaElement;
    }) => void;
    onChangeSelect: (name: string, selected: any) => void;
    unit: "MB" | "GB" | "TB";
};

const HandWrittenInsertContainer = ({
                                        type,
                                        telecoms,
                                        categories,
                                        inputs,
                                        setInputs,
                                        onChangeInputs,
                                        onChangeSelect,
                                        unit,
                                    }: Props) => {
    const {getToken} = useToken();
    const [devices, setDevices] = useState<DeviceTypes[]>([]);
    const [colors, setColors] = useState<DeviceInfoTypes[]>([]);
    const [items, setItems] = useState<ItemTypes[]>([]);

    // 색상 리스트 조회 (색상 선택)
    const getColorByModelName = async (name: string | undefined) => {
        const {config, data} = await requestSecureGet<DeviceInfoTypes[]>(
            apiRoute.device.getDevicesByModelName + name,
            {},
            getToken()!
        );
        if (config.status >= 200 && config.status < 400) {
            setColors(data);
        }
    };

    // 모델명 선택 시
    const onClickModelName = (option: SingleValue<DeviceTypes>) => {
        if (option) {
            setInputs((inputs) => ({
                ...inputs,
                device_color: "",
                device_info_idx: 0,
                model_name: option.model_name,
                price: option.price,
                volume: option.volume,
            }));
            // 선택한 모델명으로 색상 조회
            void getColorByModelName(option.model_name);
        }
    };

    const onClickAddItem = async () => {
        const name = window.prompt("추가할 품목명을 입력해주세요.");

        // 값을 받지 않았을 경우 return
        if (!name) return;

        const {data, config} = await requestSecurePost<ItemTypes>(
            apiRoute.item,
            {},
            {name: name},
            getToken()!
        );

        if (config.status >= 200 && config.status < 400) {
            alert("성공적으로 등록이 완료되었습니다.");
            setItems((items) => [
                ...items,
                data,
            ]);
        }
    }

    // 단말 리스트 조회 (모델명 선택)
    const getDeviceData = async () => {
        const {config, data} = await requestSecureGet<DeviceTypes[]>(
            apiRoute.device.getDevices,
            {},
            getToken()!
        );
        if (config.status >= 200 && config.status < 400) {
            setDevices(
                data.sort(function (a, b) {
                    return a.model_name < b.model_name ? -1 : 1;
                })
            );
        }
    };

    const getItems = async () => {
        const {config, data} = await requestSecureGet<ItemTypes[]>(
            apiRoute.item,
            {},
            getToken()!
        );

        if (config.status >= 200 && config.status < 400) {
            setItems(data);
        }
    };

    useEffect(() => {
        void getDeviceData();
        void getItems();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <HandWrittenInsert
            type={type}
            telecoms={telecoms}
            categories={categories}
            devices={devices}
            colors={colors}
            items={items}
            inputs={inputs}
            onChangeInputs={onChangeInputs}
            onChangeSelect={onChangeSelect}
            onClickModelName={onClickModelName}
            onClickAddItem={onClickAddItem}
            unit={unit}
        />
    );
};

export default HandWrittenInsertContainer;
