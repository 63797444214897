import ShopDeviceOpen from "../components/ShopDeviceOpen";
import { useState, useEffect } from "react";
import {
  apiRoute,
  requestSecureGet,
  requestSecurePatch,
  requestSecurePost,
} from "@libs/api";
import useToken from "@hooks/useToken";
import { ShopDeviceOpenTypes } from "@typedef/components/Pay/Open/shop.device.open.types";
import { useRecoilValue } from "recoil";
import { getTelecoms } from "@stories/Selector";
import { OpenTypes } from "@typedef/components/Pay/Open/open.types";
import { formatDate, joinPhone, splitFormatDate } from "@libs/commonFuncs";
import { CustomerTypes } from "@typedef/components/Customer/customer.types";
type Props = {
  idx?: number;
  close: () => void;
  relaod: () => void;
};

const ShopDeviceOpenContainer = ({ idx, close, relaod }: Props) => {
  const { getToken } = useToken();
  // 개통 정보 입력 탭
  const tabs = ["1. 고객정보", "2. 요금정보", "3. 단말정보", "4. 정산정보"];
  const [activeTab, setActiveTab] = useState(0);
  const [inputs, setInputs] = useState<Partial<OpenTypes>>({
    contractMonth: 24, // 약정개월
    contractType: "PHONE", // 할인유형 (선택약정)
    customer: {
      telecom: {
        name: "KT",
        telecomId: 2,
      },
    },
    telecom: {
      name: "KT",
      telecomId: 2,
    },
    installmentMonth: 24, // 할부개월
    openUsimType: "EXIST", // 유심유형
  });
  // 기존 휴대전화
  const [splitPhone, setSplitPhone] = useState({
    phone1: "010",
    phone2: "",
    phone3: "",
  });
  // 개통 휴대전화
  const [splitOpenPhone, setSplitOpenPhone] = useState({
    openPhone1: "010",
    openPhone2: "",
    openPhone3: "",
  });
  // 개통일시
  const [openTime, setOpenTime] = useState({
    date: new Date(),
    hour: new Date().getHours().toString(),
    minute: new Date().getMinutes().toString(),
  });

  // 상세조회 및 수정에서 사용
  const [loading, setLoading] = useState<boolean>(false);
  const telecoms = useRecoilValue(getTelecoms(getToken()!));

  // onChange (input)
  const onChangeInputs = (e: { target: HTMLInputElement }) => {
    const { name, value } = e.target;
    if (name.includes("phone") && !name.includes("openPhone")) {
      setSplitPhone((splitPhone) => ({
        ...splitPhone,
        [name]: value,
      }));
      return;
    }
    if (name.includes("openPhone")) {
      setSplitOpenPhone((splitOpenPhone) => ({
        ...splitOpenPhone,
        [name]: value,
      }));
      return;
    }
    setInputs((inputs) => ({
      ...inputs,
      [name]: value,
    }));
  };

  // onChange (select)
  const onChangeSelect = (name: string, selected: any) => {
    if (name === "customer") {
      if (selected.phone as CustomerTypes) {
        const splitPhone = selected.phone.split("-");
        setSplitPhone({
          phone1: splitPhone[0] ?? "",
          phone2: splitPhone[1] ?? "",
          phone3: splitPhone[2] ?? "",
        });
      }
    }
    setInputs((inputs) => ({
      ...inputs,
      [name]: selected,
    }));
  };

  const onChangeOpenTime = (name: string, selected: Date | string) => {
    setOpenTime((openTime) => ({
      ...openTime,
      [name]: selected,
    }));
  };

  // 입력한 정보 저장 (개통)
  const insert = async () => {
    // 기존 휴대전화
    const phone = joinPhone(
      splitPhone.phone1,
      splitPhone.phone2,
      splitPhone.phone3
    );

    // 개통 휴대전화
    const openPhone = joinPhone(
      splitOpenPhone.openPhone1,
      splitOpenPhone.openPhone2,
      splitOpenPhone.openPhone3
    );
    const { config } = await requestSecurePost(
      apiRoute.pay.open.postOpen,
      {},
      {
        ...inputs,
        customer: {
          ...inputs.customer,
          phone: phone,
        },
        openTime: `${formatDate(openTime.date)} ${openTime.hour}:${
          openTime.minute
        }:00`,
        openPhoneNo: openPhone,
      },
      getToken()!
    );
    if (config.status >= 200 && config.status < 400) {
      alert("성공적으로 등록이 완료되었습니다.");
      close();
      relaod();
    }
  };

  const update = async () => {
    // 기존 휴대전화
    const phone = joinPhone(
      splitPhone.phone1,
      splitPhone.phone2,
      splitPhone.phone3
    );

    // 개통 휴대전화
    const openPhone = joinPhone(
      splitOpenPhone.openPhone1,
      splitOpenPhone.openPhone2,
      splitOpenPhone.openPhone3
    );

    const { config } = await requestSecurePatch(
      apiRoute.pay.open.postOpen,
      {},
      {
        ...inputs,
        customerAddress: inputs.customer?.address,
        customerAddressDetail: inputs.customer?.addressDetail,
        customerBirthday: inputs.customer?.birthday,
        customerGenderNo: inputs.customer?.genderNo,
        customerManageNo: inputs.customer?.manageNo,
        customerMemo: inputs.customer?.memo,
        customerName: inputs.customer?.name,
        customerPhoneNo: phone,
        customerTelecomName: inputs.customer?.telecom?.name,
        openTime: `${formatDate(openTime.date)} ${openTime.hour}:${
          openTime.minute
        }:00`,
        openPhoneNo: openPhone,
      },
      getToken()!
    );
    if (config.status >= 200 && config.status < 400) {
      alert("성공적으로 수정이 완료되었습니다.");
      close();
      relaod();
    }
  };

  // 선택한 개통 정보 상세 조회
  const getCheckedData = async (idx: number) => {
    const { config, data } = await requestSecureGet<{
      open: ShopDeviceOpenTypes;
    }>(apiRoute.pay.open.getOpen + idx, {}, getToken()!);
    if (config.status >= 200 && config.status < 400) {
      const telecom = telecoms.filter(
        (telecom) => telecom.name === data.open.customerTelecomName
      );
      setInputs({
        ...data.open,
        customer: {
          address: data.open.customerAddress,
          addressDetail: data.open.customerAddressDetail,
          birthday: data.open.customerBirthday,
          genderNo: data.open.customerGenderNo,
          manageNo: data.open.customerManageNo,
          memo: data.open.customerMemo,
          name: data.open.customerName,
          telecom: telecom[0],
        },
      });
      const splitPhone = data.open.customerPhoneNo.split("-");
      setSplitPhone({
        phone1: splitPhone[0] ?? "",
        phone2: splitPhone[1] ?? "",
        phone3: splitPhone[2] ?? "",
      });
      const splitOpenPhone = data.open.openPhoneNo.split("-");
      setSplitOpenPhone({
        openPhone1: splitOpenPhone[0] ?? "",
        openPhone2: splitOpenPhone[1] ?? "",
        openPhone3: splitOpenPhone[2] ?? "",
      });
      //@FIXME
      const { formatDate, splitTime } = splitFormatDate(data.open.openTime);
      setOpenTime({
        date: formatDate,
        hour: splitTime[0],
        minute: splitTime[1],
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (idx) {
      setLoading(true);
      getCheckedData(idx);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idx]);

  return (
    <ShopDeviceOpen
      type={idx ? "수정" : "등록"}
      tabs={tabs}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      inputs={inputs}
      setInputs={setInputs}
      splitPhone={splitPhone}
      splitOpenPhone={splitOpenPhone}
      openTime={openTime}
      onChangeInputs={onChangeInputs}
      onChangeSelect={onChangeSelect}
      onChangeOpenTime={onChangeOpenTime}
      save={idx ? update : insert}
      loading={loading}
    />
  );
};

export default ShopDeviceOpenContainer;
