import SubserviceManage from "../components/SubserviceManage";
import { useState, useEffect } from "react";
import {
  apiRoute,
  requestSecureGet,
  requestSecurePatch,
  requestSecurePost,
} from "@libs/api";
import useToken from "@hooks/useToken";
import { useRecoilValue } from "recoil";
import { getTelecoms } from "@stories/Selector";
import { SubserviceTypes } from "@typedef/components/Telecom/Subservice/subservice.types";

type Props = {
  idx?: number;
  close: () => void;
  reload: () => void;
};

// 통신사관리 > 부가서비스관리 관리 모달
const SubserviceManageContainer = ({ idx, close, reload }: Props) => {
  const { getToken } = useToken();
  const [inputs, setInputs] = useState<Partial<SubserviceTypes>>({});
  // 통신사 리스트 조회
  const telecoms = useRecoilValue(getTelecoms(getToken()!));

  // onChange (input)
  const onChangeInputs = (e: {
    target: HTMLInputElement | HTMLTextAreaElement;
  }) => {
    const { name, value } = e.target;
    setInputs((inputs) => ({
      ...inputs,
      [name]: value,
    }));
  };

  // onChange (select)
  const onChangeSelect = <T extends {}>(name: string, selected: T) => {
    setInputs((inputs) => ({
      ...inputs,
      [name]: selected,
    }));
  };

  // 등록
  const insert = async () => {
    const { config } = await requestSecurePost(
      apiRoute.telecom.subservice.postSubservice,
      {},
      inputs,
      getToken()!
    );
    if (config.status >= 200 && config.status < 400) {
      alert("성공적으로 등록이 완료되었습니다.");
      close();
      reload();
    }
  };

  // 수정
  const update = async () => {
    const { config } = await requestSecurePatch(
      apiRoute.telecom.subservice.patchSubservice,
      {},
      { ...inputs, subServiceId: idx },
      getToken()!
    );
    if (config.status >= 200 && config.status < 400) {
      alert("성공적으로 수정이 완료되었습니다.");
      close();
      reload();
    }
  };

  const getCheckedData = async () => {
    const { config, data } = await requestSecureGet<{
      subService: SubserviceTypes;
    }>(apiRoute.telecom.subservice.getSubservice + idx, {}, getToken()!);
    if (config.status >= 200 && config.status < 400) {
      setInputs(data.subService);
    }
  };

  useEffect(() => {
    if (idx) {
      getCheckedData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idx]);

  return (
    <SubserviceManage
      type={idx ? "수정" : "등록"}
      inputs={inputs}
      onChangeInputs={onChangeInputs}
      onChangeSelect={onChangeSelect}
      telecoms={telecoms}
      save={idx ? update : insert}
    />
  );
};

export default SubserviceManageContainer;
