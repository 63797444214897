import { useCallback } from 'react';

const ACCESS_TOKEN_KEY = '@accessToken' as const;
const REFRESH_TOKEN_KEY = '@refreshToken' as const;

export default function useToken() {
  // accessToken
  const getToken = () => localStorage.getItem(ACCESS_TOKEN_KEY);

  const setToken = (token: string) => {
    localStorage.setItem(ACCESS_TOKEN_KEY, token);
  };

  const clearToken = (token: string) => {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
  };

  // refreshToken
  const getRefreshToken = useCallback(() => {
    return localStorage.getItem(REFRESH_TOKEN_KEY);
  }, []);

  const setRefreshToken = useCallback((refreshToken: string) => {
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
  }, []);

  const clearRefreshToken = useCallback((refreshToken: string) => {
    localStorage.removeItem(REFRESH_TOKEN_KEY);
  }, []);

  return {
    getToken,
    setToken,
    clearToken,
    getRefreshToken,
    setRefreshToken,
    clearRefreshToken,
  };
}
