import styled from "styled-components";
import copy from "copy-to-clipboard";
import img from "@assets/image";
import { useRecoilState } from "recoil";
import { CopyValue } from "@stories/Atom";
import React from "react";

const CopyCell = ({ value }: any) => {
  const [copied, setCopied] = useRecoilState(CopyValue);

  const onClickCopy = () => {
    copy(value);
    if (copied) {
      setCopied(value);
      return;
    }
    setCopied(value);
    setTimeout(() => {
      setCopied("");
    }, 2500);
  };

  // console.log('copeyCell 실행');

  return (
    <CopyBox>
      <div onClick={onClickCopy} className="copy">
        {value && (
          <img
            src={value === copied ? img.checkIcon : img.copyIcon}
            alt="복사아이콘"
          />
        )}
        <p>{value}</p>
      </div>
      {copied && (
        <MessageBox>
          <p>복사되었습니다.</p>
        </MessageBox>
      )}
    </CopyBox>
  );
};

export default React.memo(CopyCell);

const CopyBox = styled.section`
  width: 100%;
  overflow: hidden;

  .copy {
    width: fit-content;
    max-width: 100%;
    line-height: 30px;
    ${({ theme }) => theme.flex.row}
    align-items: center;
    padding: 0 7px;
    background-color: ${({ theme }) => theme.colors.brand100};
    border-radius: 5px;
    font-weight: 500;
    cursor: pointer;

    img {
      width: 15px;
      margin-right: 5px;
    }

    p {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`;

const MessageBox = styled.div`
  height: 40px;
  ${({ theme }) => theme.flex.row}
  align-items: center;
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 10px 20px;
  background-color: #fff;
  border-left: 10px solid ${({ theme }) => theme.colors.brand500};
  border-radius: 5px;
  animation: fadeInUp 2.5s;
  box-shadow: rgba(100, 100, 111, 0.025) 0px 7px 7px 0px;

  p {
    font-weight: 500;
    &::before {
      font-family: "FontAwesome";
      content: "\f058";
      color: ${({ theme }) => theme.colors.brand500};
      margin-right: 10px;
    }
  }

  @keyframes fadeInUp {
    0% {
      opacity: 0;
      bottom: -10px;
    }
    70% {
      opacity: 1;
      bottom: 20px;
    }
    to {
      opacity: 0;
      bottom: -40px;
    }
  }
`;

export const Message = styled.section``;
