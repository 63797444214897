import * as S from '@styles/components/TableViewStyle';
import CTable from '@components/Common/CTable/CTable';
import { TableColumnTypes } from '@typedef/components/Common/CTable/table.column.types';
import { PublicSupportTypes } from '@typedef/components/Telecom/PublicSupport/publicSupport.types';

type Props = {
  columns: TableColumnTypes<PublicSupportTypes>[];
  tableData: PublicSupportTypes[];
  insertPublicSupport: () => void;
  updatePublicSupport: () => void;
  deletePublicSupport: () => void;
};

const TelecomPublicSupport = ({
  columns,
  tableData,
  insertPublicSupport,
  updatePublicSupport,
  deletePublicSupport,
}: Props) => {
  return (
    <S.Main>
      <S.TopBtns>
        <h3>전체 {tableData.length}</h3>
        {/* 추가 버튼 */}
        <S.Btns>
          <S.Btn type='button' onClick={insertPublicSupport} className='active'>
            공시지원금 추가
          </S.Btn>
          <S.Btn type='button' onClick={() => updatePublicSupport()}>
            수정
          </S.Btn>
          <S.Btn type='button' onClick={deletePublicSupport}>
            삭제
          </S.Btn>
        </S.Btns>
      </S.TopBtns>

      {/* 하단 테이블 리스트 */}
      <CTable columns={columns} tableData={tableData} />
    </S.Main>
  );
};

export default TelecomPublicSupport;
