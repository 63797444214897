import { Select } from './styles/CSelectStyle';
import { components, DropdownIndicatorProps } from 'react-select';

type Props<T> = {
  options: T[]; // options
  value?: any; //
  label: any;
  defaultValue?: T[];
  key?: string | number;
  func: any;
  placeholder?: string;
  disabled?: boolean;
  clearable?: boolean;
  autoFocus?: boolean;
  height?: number;
  isMulti?: boolean;
  indicator?: '년' | '월' | '일' | '시' | '분';
};

// https://react-select.com/home
const CSelect = <T extends {}>({
  options,
  value,
  label,
  defaultValue,
  key,
  func,
  placeholder,
  disabled,
  clearable,
  autoFocus,
  height,
  isMulti,
  indicator,
}: Props<T>) => {
  const DropdownIndicator = (props: DropdownIndicatorProps<any, true>) => {
    return (
      <components.DropdownIndicator {...props} className='custom'>
        {indicator}
      </components.DropdownIndicator>
    );
  };

  return (
    <Select
      options={options}
      getOptionValue={value}
      getOptionLabel={label}
      value={defaultValue && defaultValue}
      key={key}
      onChange={func}
      placeholder={placeholder ?? '선택해 주세요'}
      isDisabled={disabled}
      isClearable={clearable ?? false}
      autoFocus={autoFocus ?? false}
      maxMenuHeight={height}
      menuPlacement='auto'
      className='select'
      classNamePrefix='react-select'
      isMulti={isMulti ?? false}
      components={indicator ? { DropdownIndicator } : undefined}
    />
  );
};

export default CSelect;
