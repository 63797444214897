import * as S from "@styles/components/TableViewStyle";
import CTable from "@components/Common/CTable/CTable";
import { TableColumnTypes } from "@typedef/components/Common/CTable/table.column.types";
import { ShopDeviceTypes } from "@typedef/components/Inventory/shop.device.types";

type Props = {
  columns: TableColumnTypes<ShopDeviceTypes>[];
  tableData: ShopDeviceTypes[];
};

const InventoryHistory = ({ columns, tableData }: Props) => {
  return (
    <S.Main>
      <S.TopBtns>
        <h3>전체 {tableData.length}</h3>
      </S.TopBtns>
      <CTable columns={columns} tableData={tableData} />
    </S.Main>
  );
};

export default InventoryHistory;
