import Schedule from '../Schedule';
import { useState, useEffect, useCallback } from 'react';
import { apiRoute, requestSecureGet } from '@libs/api';
import useToken from '@hooks/useToken';
import { useSetRecoilState } from 'recoil';
import { loading, modal, modalHeader } from '@stories/Atom';
import ScheduleManageContainer from './ScheduleManageContainer';
import { ScheduleTypes } from '@typedef/components/Schedule/schedule.types';
import dayjs from 'dayjs';

const ScheduleContainer = () => {
  const { getToken } = useToken();
  // 일정
  const [schedules, setSchedules] = useState<ScheduleTypes[]>([]);
  const [filteredSchedules, setFilteredSchedules] = useState<ScheduleTypes[]>(
    [],
  );
  // 캘린더에서 선택한 날
  const [clickedDate, setClickedDate] = useState<Date>(new Date());
  // 모달설정
  const setModal = useSetRecoilState(modal);
  const setModalHeader = useSetRecoilState(modalHeader);
  // 로딩
  const setLoading = useSetRecoilState(loading);
  const [reload, setReload] = useState<number>(0);

  // 선택한 날짜에 속하는 일정 필터링
  const filterSchedule = useCallback(
    (selected: Date) => {
      function format(date: Date | string) {
        return dayjs(date).format('YYYY-MM-DD');
      }

      return schedules.filter((schedule) => {
        const { startDate, endDate } = schedule;
        // 시작일 = 입력받은 날짜
        // 종료일 = 입력받은 날짜
        // 시작일 <= 입력받은 날짜 <= 종료일
        return (
          format(startDate) === format(selected) ||
          format(endDate) === format(selected) ||
          (format(startDate) <= format(selected) &&
            format(selected) <= format(endDate))
        );
      });
    },
    [schedules],
  );

  // 캘린더에서 날짜 선택
  const onChangeDate = (date: Date | Date[]) => {
    if (Array.isArray(date)) {
      return;
    }
    setClickedDate(date);
  };

  // 캘린더에 일정 표시
  const displaySchedule = (date: Date) => {
    return filterSchedule(date);
  };

  // 일정 등록
  const insertSchedule = () => {
    setModalHeader('일정 등록');
    setModal(
      <ScheduleManageContainer
        close={() => setModal(null)}
        reload={() => setReload((prev) => prev + 1)}
      />,
    );
  };

  // 일정 상세조회 및 수정
  const updateSchedule = (idx: number) => {
    setModalHeader('일정 상세조회');
    setModal(
      <ScheduleManageContainer
        idx={idx}
        close={() => setModal(null)}
        reload={() => setReload((prev) => prev + 1)}
      />,
    );
  };

  const getSchedules = async () => {
    setLoading(true);

    const { config, data } = await requestSecureGet<{
      schedules: ScheduleTypes[];
    }>(apiRoute.schedule.getSchedules, {}, getToken()!);

    if (config.status >= 200 && config.status < 400) {
      setSchedules(data.schedules);
      setLoading(false);
    } else {
      setSchedules([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    const filtered = filterSchedule(clickedDate);
    setFilteredSchedules(filtered);
  }, [clickedDate, filterSchedule]);

  useEffect(() => {
    getSchedules();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  return (
    <Schedule
      schedules={schedules}
      filteredSchedules={filteredSchedules}
      clickedDate={dayjs(clickedDate).format('YYYY년 MM월 DD일')}
      onChangeDate={onChangeDate}
      displaySchedule={displaySchedule}
      insertSchedule={insertSchedule}
      updateSchedule={updateSchedule}
    />
  );
};

export default ScheduleContainer;
