import { BarDatum, ResponsiveBar } from '@nivo/bar';

type Props = {
  data: BarDatum[];
  keys: string[];
  indexBy: string;
};

// https://nivo.rocks/bar/
const CResponsiveBar = ({ data, keys, indexBy }: Props) => {
  return (
    <ResponsiveBar
      data={data}
      keys={keys}
      indexBy={indexBy}
      groupMode='grouped'
      margin={{ top: 15, right: 0, bottom: 40, left: 0 }}
      padding={0.5}
      borderRadius={5} // 둥글게둥글게
      enableGridY={false} // 가로선제거
      colors={['#4F91CD']} // 차트색상
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 0,
        tickPadding: 10,
        tickRotation: 0,
        legendPosition: 'middle',
        legendOffset: 32,
      }}
      axisLeft={null}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{
        from: 'color',
        modifiers: [['brighter', 3]],
      }}
      role='application'
      ariaLabel='Nivo bar chart demo'
      barAriaLabel={function (e) {
        return e.id + ': ' + e.formattedValue + ' in country: ' + e.indexValue;
      }}
    />
  );
};

export default CResponsiveBar;
