import * as S from '../styles/SetupBasicStyle';
import img from '@assets/image';
import { FiEdit3, FiSave } from 'react-icons/fi';
import { ShopTypes } from '@typedef/components/Shop/shop.types';
// import { SHOP_ROLE } from '@config/shop.role.config';
import { RoleTypes } from '@typedef/common/common.types';

type Props = {
  shop: Partial<ShopTypes>;
  role: RoleTypes;
  isEdit: boolean;
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
  onChangeInputs: (e: React.ChangeEvent<HTMLInputElement>) => void;
  searchZonecode: () => void;
  addressRef: React.RefObject<HTMLInputElement>;
  update: () => Promise<void>;
};

const ShopInfo = ({
  shop,
  role,
  isEdit,
  setIsEdit,
  onChangeInputs,
  searchZonecode,
  addressRef,
  update,
}: Props) => {
  return (
    <S.Shop>
      <S.Header>
        <S.Circle>
          <img src={img.buildingImg} alt='빌딩' />
        </S.Circle>
        <div className='text'>
          <h2>소속정보{isEdit && '수정'}</h2>
          <span>
            {isEdit
              ? ' 업체명, 업체구분, 사업자등록번호 변경을 원할 시 따로 문의 바랍니다.'
              : '소속정보는 담당자만 수정할 수 있습니다.'}
          </span>
        </div>
        {role.includes('OWN') && (
          <button
            type='button'
            onClick={isEdit ? update : () => setIsEdit(true)}>
            {isEdit ? <FiSave /> : <FiEdit3 />}
          </button>
        )}
      </S.Header>
      <S.Contents>
        <S.RowInputBox>
          <S.Inputs>
            <span>업체명</span>
            <S.Input defaultValue={shop.name} placeholder='업체명' disabled />
          </S.Inputs>
          <S.Inputs>
            <span>업체구분</span>
            <S.Input
              // defaultValue={SHOP_ROLE[shop.role]}
              placeholder='업체구분'
              disabled
            />
          </S.Inputs>
        </S.RowInputBox>
        <S.RowInputBox>
          <S.Inputs>
            <span>사업자등록번호</span>
            <S.Input
              defaultValue={shop.businessNo}
              placeholder='사업자등록번호'
              disabled
            />
          </S.Inputs>
          <S.Inputs>
            <span>담당자</span>
            <S.Input
              defaultValue={shop.owner?.name}
              placeholder='담당자'
              disabled
            />
          </S.Inputs>
        </S.RowInputBox>
        <S.RowInputBox>
          <S.Inputs>
            <span>업체 대표전화</span>
            <S.Input
              name='phone'
              defaultValue={shop.phone}
              onChange={onChangeInputs}
              placeholder='업체 대표전화'
              disabled={!isEdit}
            />
          </S.Inputs>
          <S.Inputs>
            <span>업체 팩스번호</span>
            <S.Input
              name='fax'
              defaultValue={shop.fax}
              onChange={onChangeInputs}
              placeholder='업체 팩스번호'
              disabled={!isEdit}
            />
          </S.Inputs>
        </S.RowInputBox>
        <S.RowInputBox>
          <S.Inputs>
            <span>주소</span>
            {isEdit ? (
              <div className='address'>
                <S.Input
                  name='address'
                  defaultValue={shop.address}
                  key={shop.address}
                  onChange={onChangeInputs}
                  placeholder='주소'
                  disabled={!isEdit}
                />
                <button type='button' onClick={searchZonecode}>
                  우편번호 검색
                </button>
              </div>
            ) : (
              <S.Input
                defaultValue={shop.address}
                placeholder='주소'
                disabled
              />
            )}
          </S.Inputs>
          <S.Inputs>
            <span>상세주소</span>
            <S.Input
              name='addressDetail'
              defaultValue={shop.addressDetail}
              onChange={onChangeInputs}
              ref={addressRef}
              placeholder='상세주소'
              disabled={!isEdit}
            />
          </S.Inputs>
        </S.RowInputBox>
      </S.Contents>
    </S.Shop>
  );
};

export default ShopInfo;
