import * as S from './styles/CDatePickerStyle';
import DatePicker from 'react-datepicker';
import { ko } from 'date-fns/locale';

type Props = {
  name: string;
  selected: string; // YYYY-MM-DD
  func: (name: string, date: Date) => void;
  minDate?: string;
  maxDate?: string;
};

const CDatePicker = ({ name, selected, func, minDate, maxDate }: Props) => {
  // YYYY-MM-DD > Date
  const formatDate = (date: string) => {
    let formatted = new Date();
    if (date) {
      const split = date.split('-');
      formatted = new Date(+split[0], +split[1] - 1, +split[2]);
    }
    return formatted;
  };

  return (
    <S.CDatePicker>
      <DatePicker
        selected={formatDate(selected)}
        onChange={(date) => {
          if (date) {
            func(name, date);
          }
        }}
        minDate={minDate ? formatDate(minDate) : null}
        maxDate={maxDate ? formatDate(maxDate) : null}
        locale={ko}
        dateFormatCalendar='yyyy년 MM월'
        dateFormat='yyyy-MM-dd'
        showPopperArrow={false}
      />
    </S.CDatePicker>
  );
};

export default CDatePicker;
