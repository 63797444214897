import UserMain from '../UserMain';
import { useState, useEffect, useMemo } from 'react';
import useUser from '@hooks/useUser';
import useToken from '@hooks/useToken';
import { useRecoilValue } from 'recoil';
import { getNotices, getOpens, getShopDevices } from '@stories/Selector';
import { apiRoute, requestSecureGet, requestSecurePatch } from '@libs/api';
import { SummaryTypes } from '@typedef/components/Main/summary.types';
import { BarDatum } from '@nivo/bar';
import { FiBox, FiMeh, FiTruck } from 'react-icons/fi';
import dayjs from 'dayjs';
import { ShopTypes } from '@typedef/components/Shop/shop.types';

const AgencyMainContainer = () => {
  const { getToken } = useToken();
  const { getUser } = useUser();
  const today = new Date();
  const [loading, setLoading] = useState<boolean>(false);
  const [memo, setMemo] = useState<string>('');
  const [chartData, setChartData] = useState<BarDatum[]>([]);
  const devices = useRecoilValue(getShopDevices(getToken()!));
  const opens = useRecoilValue(getOpens(getToken()!));
  const notices = useRecoilValue(getNotices(getToken()!));
  // 사용자명
  const name = useMemo(() => getUser().name, [getUser]);
  // 소속명
  const shopName = useMemo(() => getUser().shopName, [getUser]);
  // 소속ID (메모조회에서 사용)
  const shopId = useMemo(() => [getUser().shopId], [getUser]);
  const summaries: SummaryTypes[] = useMemo(
    () => [
      {
        name: '전체재고',
        value: devices.length,
        icon: FiBox,
      },
      {
        name: '악성재고',
        value: 23,
        icon: FiMeh,
      },
      {
        name: '퀵서비스',
        value: 2,
        icon: FiTruck,
      },
    ],
    [devices.length],
  );

  // 메모 작성
  const onChangeMemo = async (value: string) => {
    // 기존 내역과 같다면 업데이트 안함
    if (value === memo) {
      return;
    }

    setLoading(true);

    const { config } = await requestSecurePatch(
      apiRoute.shop.patchShop + '/memo',
      {},
      {
        commonMemo: value,
      },
      getToken()!,
    );
    if (config.status >= 200 && config.status < 400) {
      setLoading(false);
    }
  };

  // 메모 데이터 가져오기
  const getMemo = async () => {
    const { config, data } = await requestSecureGet<{ shop: ShopTypes }>(
      apiRoute.shop.getShop + shopId,
      {},
      getToken()!,
    );
    if (config.status >= 200 && config.status < 400) {
      setMemo(data.shop.commonMemo);
    }
  };

  // 차트 데이터 가져오기
  const getChartData = () => {
    let temp = [];
    const format = 'YYYY-MM-DD';

    // 7개월 전부터 오늘까지
    for (let i = 6; i >= 0; i--) {
      // 시작일
      const startDate = new Date(today.getFullYear(), today.getMonth() - i, 1);
      // 종료일
      const endDate = new Date(
        today.getFullYear(),
        today.getMonth() - i + 1,
        0,
      );

      // 해당 달의 시작일과 종료일 사이의 개통들만
      const filtered = opens.filter((open) => {
        const { openTime } = open;
        return (
          `${dayjs(startDate).format(format)} 00:00:00` <= openTime &&
          openTime <= `${dayjs(endDate).format(format)} 23:59:59`
        );
      });

      temp.push({
        month: dayjs(startDate).format('YYYY-MM'),
        value: filtered.length,
      });
    }
    setChartData(temp);
  };

  useEffect(() => {
    getMemo();
    getChartData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <UserMain
      name={name}
      shopName={shopName}
      loading={loading}
      summaries={summaries}
      memo={memo}
      onChangeMemo={onChangeMemo}
      chartTitle={'개통현황'}
      chartDate={{
        start: dayjs(
          new Date(today.getFullYear(), today.getMonth() - 6),
        ).format('YYYY-MM-DD'),
        end: dayjs(today).format('YYYY-MM-DD'),
      }}
      chartData={chartData}
      notice={notices[0]}
    />
  );
};

export default AgencyMainContainer;
