import styled from "styled-components";

export const MobileNB = styled.main<{ visible: boolean }>`
  display: none;
  /* @media ${({ theme }) => theme.media.tablet} {
    display: block;
  } */
`;

const timing = "0.3s ease-in-out";

export const Background = styled.section<{ visible: boolean }>`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${({ visible }) => (visible ? "rgb(0,0,0,0.7)" : "")};
  visibility: ${({ visible }) => (visible ? "visible" : "hidden")};
  transition: visibility ${timing}, background-color ${timing};
`;

export const Navs = styled.section<{ visible: boolean }>`
  width: 80vw;
  height: 100vh;
  ${({ theme }) => theme.flex.col}
  position: fixed;
  top: 0;
  right: ${({ visible }) => (visible ? 0 : -80)}vw;
  background-color: #fff;
  transition: right ${timing};

  details {
    margin: 0 15px;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
  }
`;

export const Profile = styled.section`
  ${({ theme }) => theme.flex.row}
  align-items: center;
  justify-content: space-between;

  .name {
    padding: 15px;
  }

  // 닫기 버튼
  .close {
    height: 60px;
    align-self: flex-end;
    padding: 15px;

    svg {
      height: 70%;
    }
  }
`;

export const MobileHeader = styled.header`
  display: none;
  /* @media ${({ theme }) => theme.media.tablet} {
    ${({ theme }) => theme.flex.row}
    align-items: center;
    justify-content: space-between;
    width: 100vw;
    height: 60px;
    position: fixed;

    background-color: ${({ theme }) => theme.colors.brand100};
    a {
      height: 60px;
      padding: 15px;
    }

    img {
      height: 100%;
    }

    .hamburger {
      height: 60px;
      padding: 15px;

      svg {
        height: 70%;
      }
    }
  } */
`;
