import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// Link로 페이지 이동 시 스크롤 위치 최상단으로
export default function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}
