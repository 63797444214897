import SetupDepot from '../SetupDepot';
import DepotManageContainer from './DepotManageContainer';
import { useState, useEffect, useMemo, useCallback } from 'react';
import {
  apiRoute,
  requestSecureDelete,
  requestSecureGet,
  requestSecurePatch,
} from '@libs/api';
import { useSetRecoilState } from 'recoil';
import { loading, modal, modalHeader } from '@stories/Atom';
import useToken from '@hooks/useToken';
import { DepotTypes } from '@typedef/components/Setup/Depot/depot.types';
import { TableColumnTypes } from '@typedef/components/Common/CTable/table.column.types';
import ToggleCell from '@components/Common/CTable/components/ToggleCell';

// 설정 > 입고처관리 (대리점)
const SetupDepotContainer = () => {
  const { getToken } = useToken();
  // 테이블 데이터
  const [tableData, setTableData] = useState<DepotTypes[]>([]);
  const [checkedIdxes, setCheckedIdxes] = useState<number[]>([]);
  // 모달설정
  const setModal = useSetRecoilState(modal);
  const setModalHeader = useSetRecoilState(modalHeader);
  // 테이블 로딩
  const setLoading = useSetRecoilState(loading);
  const [reload, setReload] = useState<number>(0);

  // 입고처 상세조회 및 수정
  const updateDepot = useCallback(
    (idx?: number) => {
      if (!!!idx && checkedIdxes.length === 0) {
        alert('수정할 입고처를 선택해 주세요.');
        return;
      } else if (!!!idx && checkedIdxes.length > 1) {
        alert('수정할 입고처를 하나만 선택해 주세요.');
        return;
      }
      setModalHeader('입고처 상세조회');
      setModal(
        <DepotManageContainer
          idx={idx ?? checkedIdxes[0]}
          close={() => setModal(null)}
          reload={() => setReload((prev) => prev + 1)}
        />,
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [checkedIdxes],
  );

  // 체크박스
  const onChangeCheckbox = useCallback(
    (idx: number) => {
      if (checkedIdxes.includes(idx)) {
        setCheckedIdxes(
          checkedIdxes.filter((checkedIdx) => checkedIdx !== idx),
        );
        return;
      }
      setCheckedIdxes((idxes) => [...idxes, idx]);
    },
    [checkedIdxes],
  );

  // 사용여부 변경
  const onChangeEnable = useCallback(
    async (idx: number, enable: boolean | string) => {
      const { config } = await requestSecurePatch(
        apiRoute.setup.depot.patchDepot,
        {},
        {
          idx: idx,
          is_enabled: !enable,
        },
        getToken()!,
      );
      if (config.status >= 200 && config.status < 400) {
        setReload((prev) => prev + 1);
      }
    },
    [getToken],
  );

  // 테이블 컬럼
  const columns: TableColumnTypes[] = useMemo(
    () => [
      {
        Header: '',
        accessor: 'idx',
        disableSortBy: true,
        width: 70,
        Cell: ({ value }: any) => (
          <input
            type='checkbox'
            defaultChecked={checkedIdxes.includes(value)}
            onChange={() => onChangeCheckbox(value)}
          />
        ),
      },
      {
        Header: 'No',
        width: 70,
        Cell: ({ row }: any) => <>{tableData.length - row.index}</>,
      },
      {
        Header: '입고처명',
        accessor: 'name',
        disableSortBy: true,
        Cell: ({ row, value }: any) => (
          <div className='detail' onClick={() => updateDepot(row.original.idx)}>
            {value}
          </div>
        ),
      },
      {
        Header: '연락처',
        accessor: 'phone',
        disableSortBy: true,
      },
      {
        Header: '주소',
        accessor: 'address',
        disableSortBy: true,
      },
      {
        Header: '상세주소',
        accessor: 'address_detail',
        disableSortBy: true,
      },
      {
        Header: '사용여부',
        accessor: 'is_enabled',
        disableSortBy: true,
        Cell: ({ row, value }: any) => (
          <ToggleCell
            idx={row.original.idx}
            enable={value}
            onChangeEnable={onChangeEnable}
          />
        ),
      },
    ],
    [
      checkedIdxes,
      onChangeCheckbox,
      onChangeEnable,
      tableData.length,
      updateDepot,
    ],
  );

  // 입고처 등록
  const insertDepot = () => {
    setModalHeader('입고처 등록');
    setModal(
      <DepotManageContainer
        close={() => setModal(null)}
        reload={() => setReload((prev) => prev + 1)}
      />,
    );
  };

  // 입고처 삭제
  const deleteDepot = async () => {
    if (checkedIdxes.length === 0) {
      alert('삭제할 입고처를 선택해 주세요.');
      return;
    } else if (
      window.confirm(
        `선택한 ${checkedIdxes.length}개의 입고처를 삭제하시겠습니까?`,
      )
    ) {
      await Promise.all(
        checkedIdxes.map(
          (idx) =>
            new Promise((resolve, reject) => {
              requestSecureDelete(
                apiRoute.setup.depot.deleteDepot + idx,
                {},
                getToken()!,
              ).then((data) => {
                if (data.config.status >= 200 && data.config.status < 400) {
                  resolve(data);
                }
              });
            }),
        ),
      ).then(() => {
        alert('성공적으로 삭제가 완료되었습니다.');
        setReload((prev) => prev + 1);
      });
    }
  };

  // 입고처 리스트 조회
  const getTableData = async () => {
    const { config, data } = await requestSecureGet<DepotTypes[]>(
      apiRoute.setup.depot.getDepots,
      {},
      getToken()!,
    );
    if (config.status >= 200 && config.status < 400) {
      setTableData(data);
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  return (
    <SetupDepot
      columns={columns}
      tableData={tableData}
      insertDepot={insertDepot}
      updateDepot={updateDepot}
      deleteDepot={deleteDepot}
    />
  );
};

export default SetupDepotContainer;
