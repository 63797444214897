import styled from 'styled-components';

const space = '30px';
const radius = '0.5vw';

export const Main = styled.main`
  width: 100%;
  height: calc(100vh - 70px); // 전체 - Header
  ${({ theme }) => theme.flex.row}
  background-color: #fff;
  border-top: 1px solid #eee;
`;

export const Article = styled.article`
  flex: 1;
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  grid-template-rows: repeat(9, 1fr);
  gap: ${space};
  margin: 30px;

  // 요금정보
  .section:nth-child(1) {
    grid-column: 1 / 4;
    grid-row: 1 / 5;
  }
  // 요약정보
  .section:nth-child(2) {
    grid-column: 4 / 10;
    grid-row: 1 / 3;
  }
  // 차트
  .section:nth-child(3) {
    grid-column: 4 / 10;
    grid-row: 3 / 9;
  }
  // 공지
  .section:nth-child(4) {
    grid-column: 1 / 4;
    grid-row: 5 / 9;
  }
  // 이미지
  .section:nth-child(5) {
    grid-column: 1 / 10;
    grid-row: 9 / 10;
  }
`;

// 요금정보
export const Price = styled.section`
  ${({ theme }) => theme.flex.col}
  justify-content: space-between;
  padding: ${space};
  background-color: ${({ theme }) => theme.colors.brand200};
  border-radius: ${radius};

  h2 {
    margin-bottom: 15px;
  }

  p {
    font-weight: 300;
    span {
      text-decoration: underline;
    }
  }

  button {
    width: 100%;
    height: 40px;
    ${({ theme }) => theme.flex.row}
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: ${radius};
    font-weight: 500;
  }
`;

// 요약정보
export const Summaries = styled.section`
  ${({ theme }) => theme.flex.row}
  flex-wrap: wrap;
  gap: ${space};
`;

export const Summary = styled.div`
  flex: 1;
  ${({ theme }) => theme.flex.row}
  align-items: center;
  justify-content: center;
  background-color: rgb(223, 227, 255, 0.5); //#DFE3FF
  border-radius: ${radius};

  h1 {
    font-size: 2vw;
    color: #6370ff;
  }

  p {
    font-size: 1vw;
  }

  &:nth-child(2) {
    background-color: rgb(255, 226, 225, 0.5); //#FFE2E1
    h1 {
      color: #ff6da5;
    }
    .icon {
      background-color: #ffc4c9;
      color: #ff6da5;
    }
  }

  &:nth-child(3) {
    background-color: rgb(225, 248, 179, 0.5); //#F1FBD8
    h1 {
      color: #80c13a;
    }
    .icon {
      background-color: #c6ec89;
      color: #80c13a;
    }
  }
`;

export const SummaryIcon = styled.div`
  width: 3vw;
  height: 3vw;
  ${({ theme }) => theme.flex.col}
  align-items: center;
  justify-content: center;
  margin-right: 1vw;
  background-color: #c0c7ff;
  color: #6370ff;
  border-radius: 50%;
  font-size: 1.5rem;
`;

// 차트
export const Chart = styled.section`
  padding: ${space};
  background-color: #f6fafd;
  border-radius: ${radius};

  header {
    ${({ theme }) => theme.flex.row}
    align-items: center;
    justify-content: space-between;

    p {
      font-weight: 300;
    }
  }
`;

// 메모
export const Memo = styled.section`
  ${({ theme }) => theme.flex.col}
  justify-content: space-between;
  padding: ${space};
  background-color: rgb(254, 247, 213, 0.9); //#FEF7D5
  border-radius: ${radius};

  textarea {
    width: 100%;
    height: 100%;
    color: #fcbc32;
    resize: none;
    outline: none;
    overflow-y: auto;

    &::placeholder {
      color: #191919;
      font-size: 1rem;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

// 공지사항
export const Notices = styled.section`
  ${({ theme }) => theme.flex.row}
  align-items: center;
  /* background-color: ${({ theme }) => theme.colors.brand500}; */

  .icon {
    margin-right: 15px;
  }

  a:hover {
    text-decoration: underline;
  }
`;

type Props = {
  align?: string;
  justify?: string;
};

export const Row = styled.section<Props>`
  ${({ theme }) => theme.flex.row}
  align-items: ${({ align }) => align};
  justify-content: ${({ justify }) => justify};
`;

export const Col = styled.section<Props>`
  ${({ theme }) => theme.flex.col}
`;
