import FAQ from '../FAQ';
import { useState, useEffect } from 'react';
import { apiRoute, requestSecureGet } from '@libs/api';
import useToken from '@hooks/useToken';
import { BoardTypes } from '@typedef/components/Board/board.types';

const FAQContainer = () => {
  const { getToken } = useToken();
  // 전체 FAQ
  const [boards, setBoards] = useState<BoardTypes[]>([]);
  const [activeBoards, setActiveBoards] = useState<number[]>([]);
  // 카테고리로 필터링한 FAQ
  const [filteredBoards, setFilteredBoards] = useState<BoardTypes[]>([]);
  // 선택한 카테고리
  const [activeCategory, setActiveCategory] = useState<string>('전체');

  // 카테고리 선택
  const onClickCategory = (category: string) => {
    setActiveCategory(category);
    const filtered = boards.filter((board) => board.subCategory === category);
    setFilteredBoards(filtered);
  };

  // 질문 선택
  const onClickBoard = (boardId: number) => {
    if (activeBoards.includes(boardId)) {
      const filtered = activeBoards.filter(
        (activeBoard) => activeBoard !== boardId,
      );
      setActiveBoards(filtered);
    } else {
      setActiveBoards((activeBoards) => [...activeBoards, boardId]);
    }
  };

  const getFAQData = async () => {
    const { config, data } = await requestSecureGet<{ boards: BoardTypes[] }>(
      apiRoute.board.getBoards + `?category=2`,
      {},
      getToken()!,
    );
    if (config.status >= 200 && config.status < 400) {
      setBoards(data.boards);
    }
  };

  useEffect(() => {
    getFAQData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FAQ
      boards={activeCategory === '전체' ? boards : filteredBoards}
      activeCategory={activeCategory}
      onClickCategory={onClickCategory}
      onClickBoard={onClickBoard}
      activeBoards={activeBoards}
    />
  );
};

export default FAQContainer;
