import * as S from "@styles/components/TableViewStyle";
import CTable from "@components/Common/CTable/CTable";
import { TableColumnTypes } from "@typedef/components/Common/CTable/table.column.types";
import { ColorTypes } from "@typedef/components/Device/Color/color.types";

type Props = {
  columns: TableColumnTypes<ColorTypes>[];
  tableData: ColorTypes[];
  insertColor: () => void;
  updateColor: (idx?: number) => void;
  deleteColor: () => Promise<void>;
};

const DeviceColor = ({
  columns,
  tableData,
  insertColor,
  updateColor,
  deleteColor,
}: Props) => {
  return (
    <S.Main>
      <S.TopBtns>
        <h3>전체 {tableData.length}</h3>
        <S.Btns>
          <S.Btn type="button" onClick={insertColor} className="active">
            색상등록
          </S.Btn>
          <S.Btn type="button" onClick={() => updateColor()}>
            수정
          </S.Btn>
          <S.Btn type="button" onClick={deleteColor}>
            삭제
          </S.Btn>
        </S.Btns>
      </S.TopBtns>
      <CTable columns={columns} tableData={tableData} />
    </S.Main>
  );
};

export default DeviceColor;
