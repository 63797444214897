import Customer from "../Customer";
import { useState, useEffect, useMemo, useCallback } from "react";
import { apiRoute, requestSecureDelete, requestSecureGet } from "@libs/api";
import useToken from "@hooks/useToken";
import { useSetRecoilState } from "recoil";
import { loading, modal, modalHeader } from "@stories/Atom";
import { CustomerTypes } from "@typedef/components/Customer/customer.types";
import { TableColumnTypes } from "@typedef/components/Common/CTable/table.column.types";
import CustomerManageContainer from "./CustomerManageContainer";

// 고객관리 > 고객관리 (대리점, 판매점)
const CustomerContainer = () => {
  const { getToken } = useToken();
  // 테이블 데이터
  const [tableData, setTableData] = useState<CustomerTypes[]>([]);
  const [checkedIdxes, setCheckedIdxes] = useState<number[]>([]);
  // 모달설정
  const setModalHeader = useSetRecoilState(modalHeader);
  const setModal = useSetRecoilState(modal);
  // 테이블 로딩
  const setLoading = useSetRecoilState(loading);
  const [reload, setReload] = useState<number>(0);

  // 고객 상세조회 및 수정
  const updateCustomer = useCallback(
    (idx?: number) => {
      if (!!!idx && checkedIdxes.length === 0) {
        alert("수정할 고객을 선택해 주세요.");
        return;
      } else if (!!!idx && checkedIdxes.length > 1) {
        alert("수정할 고객을 하나만 선택해 주세요.");
        return;
      }
      setModalHeader("고객 상세조회");
      setModal(
        <CustomerManageContainer
          idx={idx ?? checkedIdxes[0]}
          close={() => setModal(null)}
          relaod={() => setReload((prev) => prev + 1)}
        />
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [checkedIdxes]
  );

  // 체크박스
  const onChangeCheckbox = useCallback(
    (idx: number) => {
      if (checkedIdxes.includes(idx)) {
        setCheckedIdxes(
          checkedIdxes.filter((checkedIdx) => checkedIdx !== idx)
        );
        return;
      }
      setCheckedIdxes((idxes) => [...idxes, idx]);
    },
    [checkedIdxes]
  );

  // 테이블 컬럼
  const columns: TableColumnTypes<CustomerTypes>[] = useMemo(
    () => [
      {
        Header: "",
        accessor: "customerId",
        disableSortBy: true,
        width: 70,
        Cell: ({ value }: any) => (
          <input
            type="checkbox"
            defaultChecked={checkedIdxes.includes(value)}
            onChange={() => onChangeCheckbox(value)}
          />
        ),
      },
      {
        Header: "No",
        width: 70,
        Cell: ({ row }: any) => <>{tableData.length - row.index}</>,
      },
      {
        Header: "고객명",
        accessor: "name",
        disableSortBy: true,
        Cell: ({ row, value }: any) => (
          <div
            className="detail"
            onClick={() => updateCustomer(row.original.customerId)}
          >
            {value}
          </div>
        ),
      },
      {
        Header: "생년월일",
        accessor: "birthday",
        disableSortBy: true,
      },
      {
        Header: "관리번호",
        accessor: "manageNo",
        disableSortBy: true,
      },
      {
        Header: "통신사",
        accessor: "telecom.name",
        disableSortBy: true,
      },
      {
        Header: "휴대전화",
        accessor: "phone",
        disableSortBy: true,
      },
      {
        Header: "메모",
        accessor: "memo",
        disableSortBy: true,
      },
    ],
    [checkedIdxes, onChangeCheckbox, tableData.length, updateCustomer]
  );

  // 고객등록
  const insertCustomer = () => {
    setModalHeader("고객등록");
    setModal(
      <CustomerManageContainer
        close={() => setModal(null)}
        relaod={() => setReload((prev) => prev + 1)}
      />
    );
  };

  // 고객삭제
  const deleteCustomer = async () => {
    if (checkedIdxes.length === 0) {
      alert("삭제할 고객을 선택해 주세요.");
      return;
    } else if (
      window.confirm(
        `고객정보 삭제 시 해당 고객의 개통정보와 상담정보도 삭제됩니다. \n선택한 ${checkedIdxes.length}명의 정보를 삭제하시겠습니까?`
      )
    ) {
      await Promise.all(
        checkedIdxes.map(
          (idx) =>
            new Promise((resolve, reject) => {
              requestSecureDelete(
                apiRoute.customer.deleteCustomer + idx,
                {},
                getToken()!
              ).then((data) => {
                if (data.config.status >= 200 && data.config.status < 400) {
                  resolve(data);
                }
              });
            })
        )
      ).then(() => {
        alert("성공적으로 삭제가 완료되었습니다.");
        setReload((prev) => prev + 1);
      });
    }
  };

  const getTableData = async () => {
    const { config, data } = await requestSecureGet<{
      customers: CustomerTypes[];
    }>(apiRoute.customer.getCustomers, {}, getToken()!);
    if (config.status >= 200 && config.status < 400) {
      setTableData(data.customers);
      setLoading(false);
    } else {
      setTableData([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  return (
    <Customer
      columns={columns}
      tableData={tableData}
      insertCustomer={insertCustomer}
      updateCustomer={updateCustomer}
      deleteCustomer={deleteCustomer}
    />
  );
};

export default CustomerContainer;
