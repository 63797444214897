import styled from 'styled-components';

// 대리점 > 단말배정 > 선택한 요청
export const CheckedRequest = styled.section`
  margin-bottom: 1vw;
  padding: 15px;
  background-color: ${({ theme }) => theme.colors.grey200};
  border-radius: 3px;

  h4 {
    margin-bottom: 0.5vw;
  }

  div {
    ${({ theme }) => theme.flex.row}
    line-height: 25px;
    align-items: center;
    justify-content: space-between;
    font-weight: 300;

    p {
      font-weight: 500;
    }

    .price {
      &::after {
        content: '원';
      }
    }
  }
`;

// 대리점 > 단말배정 > 배정 목록
export const AllocateBoxes = styled.section`
  height: 100%;
  ${({ theme }) => theme.flex.col}
  padding-right: 1vw;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: #eee;
  }
  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 2px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #ccc;
  }
`;

export const AllocateBox = styled.section`
  margin-bottom: 1vw;
  padding: 1vw;
  background-color: ${({ theme }) => theme.colors.brand100};
  border-radius: 0.25vw;

  div {
    line-height: 25px;
    ${({ theme }) => theme.flex.row}
    justify-content: space-between;

    p {
      font-weight: 600;
    }
  }

  button {
    width: 100%;
    height: 25px;
    margin-top: 0.5vw;
    background-color: ${({ theme }) => theme.colors.grey500};
    border-radius: 0.25vw;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;
