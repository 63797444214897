import ClientManage from "../components/ClientManage";
import { useEffect, useState } from "react";
import useToken from "@hooks/useToken";
import {
  apiRoute,
  requestSecureDelete,
  requestSecureGet,
  requestSecurePatch,
  requestSecurePost,
} from "@libs/api";
import {
  ClientDepartmentTypes,
  ClientTypes,
} from "@typedef/components/Client/client.types";

type Props = {
  id?: number;
  close: () => void;
  reload: () => void;
};

export type DepartmentInputTypes = Omit<
  ClientDepartmentTypes,
  "managerPhone" | "phone"
> & {
  phone1: string; // 매니저 연락처1
  phone2: string; // 매니저 연락처2
  phone3: string; // 매니저 연락처3
};

const ClientManageContainer = ({ id, close, reload }: Props) => {
  const { getToken } = useToken();
  const [inputs, setInputs] = useState<Omit<ClientTypes, "departments">>({
    address: "",
    clientId: 0,
    email: "",
    managerName: "",
    managerPhone: "",
    name: "",
    phone: "",
    typeOfBusiness: "",
  });
  const [departmentInputs, setDepartmentInputs] = useState<
    DepartmentInputTypes[]
  >(
    Array.from({ length: 1 }).map(() => ({
      departmentId: 0,
      managerName: "",
      name: "",
      phone1: "",
      phone2: "",
      phone3: "",
    }))
  );
  const [deleteDepartmentIds, setDeleteDepartmentIds] = useState<number[]>([]);

  // 거래처정보 onChange
  const onChangeInputs = (e: { target: HTMLInputElement }) => {
    const { name, value } = e.target;

    setInputs({ ...inputs, [name]: value });
  };

  // 담당자정보 onChange
  const onChangeDepartmentInputs = (
    e: { target: HTMLInputElement },
    idx: number
  ) => {
    const { name, value } = e.target;

    const processsedInputs = departmentInputs.map((dept, index) => {
      if (index === idx) {
        return { ...dept, [name]: value };
      }
      return dept;
    });

    setDepartmentInputs(processsedInputs);
  };

  // 담당자 입력폼 추가
  const onClickDepartmentInput = () => {
    setDepartmentInputs([
      ...departmentInputs,
      {
        departmentId: 0,
        managerName: "",
        name: "",
        phone1: "",
        phone2: "",
        phone3: "",
      },
    ]);
  };

  // 담당자 삭제
  const onClickDeleteDepartment = (id: number) => {
    const found = deleteDepartmentIds.find((selected) => selected === id);

    if (found) {
      const filtered = deleteDepartmentIds.filter(
        (selected) => selected !== id
      );

      setDeleteDepartmentIds(filtered);
    } else {
      setDeleteDepartmentIds([...deleteDepartmentIds, id]);
    }
  };

  // 담당자명, 담당 부서 필수 입력
  const validationManagerInputs = () => {
    let message = null;

    if (departmentInputs.length) {
      departmentInputs.forEach((dept) => {
        if (dept.name && !dept.managerName) {
          message = "부서 담당자명은 필수 입력란입니다.";
        }
      });
    }

    return message;
  };

  // 등록
  const insert = async () => {
    const message = validationManagerInputs();

    if (message) {
      alert(message);
      return;
    }

    const postManagerInputs = departmentInputs
      .filter((manager) => !!manager.name)
      .map((manager) => {
        const { phone1, phone2, phone3, ...rest } = manager;
        return {
          ...rest,
          managerPhone: [phone1, phone2, phone3].join("-"),
        };
      });

    const { config } = await requestSecurePost(
      apiRoute.client.postClient,
      {},
      {
        ...inputs,
        departments: postManagerInputs,
      },
      getToken()!
    );
    if (config.status >= 200 && config.status < 400) {
      alert("성공적으로 등록이 완료되었습니다.");
      close();
      reload();
    }
  };

  const update = async () => {
    const message = validationManagerInputs();

    if (message) {
      alert(message);
      return;
    }

    const postManagerInputs = departmentInputs
      .filter((manager) => manager.name)
      .map((manager) => {
        const { phone1, phone2, phone3, ...rest } = manager;
        return {
          ...rest,
          managerPhone: [phone1, phone2, phone3].join("-"),
        };
      });

    const patchClient = () => {
      return requestSecurePatch(
        `${apiRoute.client.patchClient}${inputs.clientId}`,
        {},
        {
          ...inputs,
          departments: postManagerInputs,
        },
        getToken()!
      );
    };

    const deleteDepartments = () => {
      return requestSecureDelete(
        `${apiRoute.client.deleteClients}${
          inputs.clientId
        }/departments/${deleteDepartmentIds.join(",")}`,
        {},
        getToken()!
      );
    };

    const [patchRes, deleteRes] = await Promise.allSettled(
      [patchClient, deleteDepartments].map(
        (handler) =>
          new Promise((resolve, reject) =>
            handler()
              .then((res) => resolve(res))
              .catch((err) => reject(err))
          )
      )
    );
    if (patchRes.status === "fulfilled" && deleteRes.status === "fulfilled") {
      alert("성공적으로 수정이 완료되었습니다.");
      close();
      reload();
    }
  };

  const getDataById = async (id: number) => {
    const { config, data } = await requestSecureGet<ClientTypes>(
      `${apiRoute.client.getClients}/${id}`,
      {},
      getToken()!
    );

    if (config.status >= 200 && config.status < 400) {
      const { departments, ...rest } = data;
      setInputs(rest);

      if (departments.length) {
        const processed = departments.map((dept) => {
          const { managerPhone, ...rest } = dept;

          const [phone1, phone2, phone3] = managerPhone
            ? managerPhone.split("-")
            : [undefined, undefined, undefined];

          return {
            ...rest,
            phone1: phone1 ?? "",
            phone2: phone2 ?? "",
            phone3: phone3 ?? "",
          };
        });

        setDepartmentInputs(processed);
      }
    }
  };

  useEffect(() => {
    if (id) {
      getDataById(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <ClientManage
      type={id ? "수정" : "등록"}
      inputs={inputs}
      departmentInputs={departmentInputs}
      onChangeInputs={onChangeInputs}
      onChangeDepartmentInputs={onChangeDepartmentInputs}
      onClickDepartmentInput={onClickDepartmentInput}
      onClickDeleteDepartment={onClickDeleteDepartment}
      deleteDepartmentIds={deleteDepartmentIds}
      submit={id ? update : insert}
    />
  );
};

export default ClientManageContainer;
