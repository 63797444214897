import CustomerManage from "../components/CustomerManage";
import { useState, useEffect } from "react";
import {
  apiRoute,
  requestSecureGet,
  requestSecurePatch,
  requestSecurePost,
} from "@libs/api";
import useToken from "@hooks/useToken";
import { useRecoilValue } from "recoil";
import { getTelecoms } from "@stories/Selector";
import { CustomerTypes } from "@typedef/components/Customer/customer.types";
import { joinPhone } from "@libs/commonFuncs";

type Props = {
  idx?: number;
  close: () => void;
  relaod: () => void;
};

// 고객관리 > 고객관리 관리 모달
const CustomerManageContainer = ({ idx, close, relaod }: Props) => {
  const { getToken } = useToken();
  const [inputs, setInputs] = useState<Partial<CustomerTypes>>({});
  const [splitPhone, setSplitPhone] = useState({
    phone1: "010",
    phone2: "",
    phone3: "",
  });
  const telecoms = useRecoilValue(getTelecoms(getToken()!));
  // 상세조회, 수정 모달에서만 사용, loading이 true인 경우 스피너 표출
  const [loading, setLoading] = useState<boolean>(false);

  // onChange (input)
  const onChangeInputs = (e: {
    target: HTMLInputElement | HTMLTextAreaElement;
  }) => {
    const { name, value } = e.target;

    if (name.includes("phone")) {
      setSplitPhone((splitPhone) => ({
        ...splitPhone,
        [name]: value,
      }));
      return;
    }
    setInputs((inputs) => ({
      ...inputs,
      [name]: value,
    }));
  };

  // onChange (select)
  const onChangeSelect = <T extends {}>(name: string, selected: T) => {
    setInputs((inputs) => ({
      ...inputs,
      [name]: selected,
    }));
  };

  // 등록
  const insert = async () => {
    const postData = {
      ...inputs,
      phone: joinPhone(splitPhone.phone1, splitPhone.phone2, splitPhone.phone3),
    };

    const { config } = await requestSecurePost(
      apiRoute.customer.patchCustomer,
      {},
      postData,
      getToken()!
    );
    if (config.status >= 200 && config.status < 400) {
      alert("성공적으로 등록이 완료되었습니다.");
      close();
      relaod();
    }
  };

  // 수정
  const update = async () => {
    const patchData = {
      ...inputs,
      phone: joinPhone(splitPhone.phone1, splitPhone.phone2, splitPhone.phone3),
    };

    const { config } = await requestSecurePatch(
      apiRoute.customer.patchCustomer,
      {},
      patchData,
      getToken()!
    );
    if (config.status >= 200 && config.status < 400) {
      alert("성공적으로 등록이 완료되었습니다.");
      close();
      relaod();
    }
  };

  const getCheckedData = async (idx: number) => {
    setLoading(true);

    const { config, data } = await requestSecureGet<{
      customer: CustomerTypes;
    }>(apiRoute.customer.getCustomer + idx, {}, getToken()!);
    if (config.status >= 200 && config.status < 400) {
      setInputs(data.customer);
      // 고객의 휴대전화 정보 나눠서 넣기
      if (data.customer.phone) {
        const split = data.customer.phone.split("-");
        setSplitPhone((prev) => ({
          ...prev,
          phone1: split[0],
          phone2: split[1],
          phone3: split[2],
        }));
      }

      setLoading(false);
    }
  };

  useEffect(() => {
    if (idx) {
      getCheckedData(idx);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idx]);

  return (
    <CustomerManage
      type={idx ? "수정" : "등록"}
      loading={loading}
      inputs={inputs}
      onChangeInputs={onChangeInputs}
      onChangeSelect={onChangeSelect}
      splitPhone={splitPhone}
      telecoms={telecoms}
      save={idx ? update : insert}
    />
  );
};

export default CustomerManageContainer;
