import * as S from "@styles/components/TableViewStyle";
import CTable from "@components/Common/CTable/CTable";
import { TableColumnTypes } from "@typedef/components/Common/CTable/table.column.types";
import { DeviceTypes } from "@typedef/components/Device/device.types";

type Props = {
  columns: TableColumnTypes<DeviceTypes>[];
  tableData: DeviceTypes[];
  insertDevice: () => void;
  updateDevice: (idx?: number) => void;
  deleteDevice: () => Promise<void>;
};

const Device = ({
  columns,
  tableData,
  insertDevice,
  updateDevice,
  deleteDevice,
}: Props) => {
  return (
    <S.Main>
      <S.TopBtns>
        <h3>전체 {tableData.length}</h3>

        <S.Btns>
          <S.Btn type="button" onClick={insertDevice} className="active">
            단말등록
          </S.Btn>
          <S.Btn type="button" onClick={() => updateDevice()}>
            수정
          </S.Btn>
          <S.Btn type="button" onClick={deleteDevice}>
            삭제
          </S.Btn>
        </S.Btns>
      </S.TopBtns>
      <CTable columns={columns} tableData={tableData} />
    </S.Main>
  );
};

export default Device;
