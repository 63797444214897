import PayRetroactive from '../PayRetroactive';
import { useEffect } from 'react';
import { apiRoute, requestSecureGet } from '@libs/api';
import useToken from '@hooks/useToken';

const PayRetroactiveContainer = () => {
  const { getToken } = useToken();

  const getTableData = async () => {
    const { config, data } = await requestSecureGet(
      apiRoute.pay.open.getOpens,
      {},
      getToken()!,
    );
    if (config.status >= 200 && config.status < 400) {
      console.log('data :', data);
    }
  };

  useEffect(() => {
    getTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <PayRetroactive />;
};

export default PayRetroactiveContainer;
