import SetupRelated from '../SetupRelated';
import RelatedManageContainer from './RelatedManageContainer';
import { useState, useEffect, useMemo, useCallback } from 'react';
import {
  apiRoute,
  requestSecureDelete,
  requestSecureGet,
  requestSecurePatch,
} from '@libs/api';
import { useSetRecoilState } from 'recoil';
import { loading, modal, modalHeader } from '@stories/Atom';
import useToken from '@hooks/useToken';
import { TableColumnTypes } from '@typedef/components/Common/CTable/table.column.types';
import ToggleCell from '@components/Common/CTable/components/ToggleCell';
import { RelatedShopTypes } from '@typedef/components/Setup/Related/related.shop.types';
import DetailCell from '@components/Common/CTable/components/DetailCell';
import RelatedDetailContainer from './RelatedDetailContainer';

// 설정 > 거래처관리 (대리점, 판매점)
const SetupRelatedContainer = () => {
  const { getToken } = useToken();
  // 테이블 데이터
  const [tableData, setTableData] = useState<RelatedShopTypes[]>([]);
  const [checkedIdxes, setCheckedIdxes] = useState<number[]>([]);
  // 모달설정
  const setModal = useSetRecoilState(modal);
  const setModalHeader = useSetRecoilState(modalHeader);
  // 테이블 로딩
  const setLoading = useSetRecoilState(loading);
  const [reload, setReload] = useState<number>(0);

  // 거래처 상세조회
  const detailRelated = useCallback((idx: number) => {
    setModalHeader('거래처 상세조회');
    setModal(<RelatedDetailContainer idx={idx} />);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 체크박스
  const onChangeCheckbox = useCallback(
    (idx: number) => {
      if (checkedIdxes.includes(idx)) {
        setCheckedIdxes(
          checkedIdxes.filter((checkedIdx) => checkedIdx !== idx),
        );
        return;
      }
      setCheckedIdxes((idxes) => [...idxes, idx]);
    },
    [checkedIdxes],
  );

  // 사용여부 변경
  const onChangeEnable = useCallback(
    async (idx: number, enable: boolean | string) => {
      const { config } = await requestSecurePatch(
        apiRoute.setup.related.patchRelated,
        {},
        {
          idx: idx,
          is_enabled: !enable,
        },
        getToken()!,
      );
      if (config.status >= 200 && config.status < 400) {
        setReload((prev) => prev + 1);
      }
    },
    [getToken],
  );

  // 테이블 컬럼
  const columns: TableColumnTypes<RelatedShopTypes>[] = useMemo(
    () => [
      {
        Header: '',
        accessor: 'shop.shopId',
        disableSortBy: true,
        width: 70,
        Cell: ({ value }) => (
          <input
            type='checkbox'
            defaultChecked={checkedIdxes.includes(value as number)}
            onChange={() => onChangeCheckbox(value as number)}
          />
        ),
      },
      {
        Header: 'No',
        width: 70,
        Cell: ({ row }) => <>{tableData.length - row.index}</>,
      },
      {
        Header: '거래처명',
        accessor: 'shop.name',
        disableSortBy: true,
        Cell: ({ row, value }) => (
          <DetailCell
            value={value as string}
            func={() => detailRelated(row.original.shop.shopId)}
          />
        ),
      },
      {
        Header: '연락처',
        accessor: 'shop.phone',
        disableSortBy: true,
      },
      {
        Header: '주소',
        accessor: 'shop.address',
        disableSortBy: true,
      },
      {
        Header: '상세주소',
        accessor: 'shop.addressDetail',
        disableSortBy: true,
      },
      {
        Header: '사용여부',
        accessor: 'status',
        disableSortBy: true,
        Cell: ({ row, value }: any) => (
          <ToggleCell
            idx={row.original.shop.shopId}
            enable={value === 'YES' ? true : false}
            onChangeEnable={onChangeEnable}
          />
        ),
      },
    ],
    [
      checkedIdxes,
      onChangeCheckbox,
      onChangeEnable,
      tableData.length,
      detailRelated,
    ],
  );

  // 거래처 등록
  const insertRelated = () => {
    setModalHeader('거래처 등록');
    setModal(
      <RelatedManageContainer
        close={() => setModal(null)}
        reload={() => setReload((prev) => prev + 1)}
      />,
    );
  };

  // 거래처 삭제
  const deleteRelated = async () => {
    if (checkedIdxes.length === 0) {
      alert('삭제할 거래처를 선택해 주세요.');
      return;
    } else if (
      window.confirm(
        `선택한 ${checkedIdxes.length}개의 거래처를 삭제하시겠습니까?`,
      )
    ) {
      await Promise.all(
        checkedIdxes.map(
          (idx) =>
            new Promise((resolve, reject) => {
              requestSecureDelete(
                apiRoute.setup.depot.deleteDepot + idx,
                {},
                getToken()!,
              ).then((data) => {
                if (data.config.status >= 200 && data.config.status < 400) {
                  resolve(data);
                }
              });
            }),
        ),
      ).then(() => {
        alert('성공적으로 삭제가 완료되었습니다.');
        setReload((prev) => prev + 1);
      });
    }
  };

  const getTableData = async () => {
    const { config, data } = await requestSecureGet<{
      relatedShops: RelatedShopTypes[];
    }>(apiRoute.setup.related.getRelateds, {}, getToken()!);
    if (config.status >= 200 && config.status < 400) {
      setTableData(data.relatedShops);
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  return (
    <SetupRelated
      columns={columns}
      tableData={tableData}
      insertRelated={insertRelated}
      deleteRelated={deleteRelated}
    />
  );
};

export default SetupRelatedContainer;
