import styled from "styled-components";
import { Main, Split, Bottoms } from "@styles/components/ModalBodyStyle";
import { InputBox, Input, Row } from "@styles/components/FormStyle";
import { ClientTypes } from "@typedef/components/Client/client.types";
import { DepartmentInputTypes } from "../containers/ClientManageContainer";

type Props = {
  type: "등록" | "수정";
  inputs: Omit<ClientTypes, "departments">;
  departmentInputs: DepartmentInputTypes[];
  deleteDepartmentIds: number[];
  onChangeInputs: (e: { target: HTMLInputElement }) => void;
  onChangeDepartmentInputs: (
    e: {
      target: HTMLInputElement;
    },
    idx: number
  ) => void;
  onClickDepartmentInput: () => void;
  onClickDeleteDepartment: (id: number) => void;
  submit: () => Promise<void>;
};

const ClientManage = ({
  type,
  inputs,
  departmentInputs,
  deleteDepartmentIds,
  onChangeInputs,
  onChangeDepartmentInputs,
  onClickDepartmentInput,
  onClickDeleteDepartment,
  submit,
}: Props) => {
  return (
    <Container>
      <Row>
        <Split>
          <Header>
            <h3>거래처정보</h3>
          </Header>
          <InputBox>
            <p>
              거래처명<span>*</span>
            </p>
            <Input
              name="name"
              value={inputs.name}
              onChange={onChangeInputs}
              placeholder="거래처명"
            />
          </InputBox>
          <InputBox>
            <p>
              대표자<span>*</span>
            </p>
            <Input
              name="managerName"
              value={inputs.managerName}
              onChange={onChangeInputs}
              placeholder="대표자"
            />
          </InputBox>
          <InputBox>
            <p>업태/업종</p>
            <Input
              name="typeOfBusiness"
              value={inputs.typeOfBusiness}
              onChange={onChangeInputs}
              placeholder="업태/업종"
            />
          </InputBox>
          <InputBox>
            <p>본사소재지(주소)</p>
            <Input
              name="address"
              value={inputs.address}
              onChange={onChangeInputs}
              placeholder="본사소재지(주소)"
            />
          </InputBox>
          <InputBox>
            <p>계산서 발행 이메일</p>
            <Input
              name="email"
              value={inputs.email}
              onChange={onChangeInputs}
              placeholder="계산서 발행 이메일"
            />
          </InputBox>
        </Split>
        <Split borderL>
          <Header>
            <h3>담당자정보</h3>
            <button onClick={onClickDepartmentInput}>+ 담당자추가</button>
          </Header>
          <ManagerInputContainer>
            {departmentInputs.map((manager, idx) => (
              <ManagerInput key={manager.departmentId}>
                <div>
                  <p>
                    담당자명<span>*</span>
                  </p>
                  <input
                    name="managerName"
                    value={manager.managerName}
                    onChange={(e) => onChangeDepartmentInputs(e, idx)}
                    placeholder="담당자명"
                  />
                </div>
                <div>
                  <p>
                    담당 부서<span>*</span>
                  </p>
                  <input
                    name="name"
                    value={manager.name}
                    onChange={(e) => onChangeDepartmentInputs(e, idx)}
                    placeholder="담당 부서"
                  />
                </div>
                <div>
                  <p>연락처</p>
                  <div className="phone">
                    <input
                      name="phone1"
                      value={manager.phone1}
                      onChange={(e) => onChangeDepartmentInputs(e, idx)}
                      maxLength={4}
                    />
                    <input
                      name="phone2"
                      value={manager.phone2}
                      onChange={(e) => onChangeDepartmentInputs(e, idx)}
                      maxLength={4}
                    />
                    <input
                      name="phone3"
                      value={manager.phone3}
                      onChange={(e) => onChangeDepartmentInputs(e, idx)}
                      maxLength={4}
                    />
                  </div>
                </div>
                {manager.departmentId ? (
                  <div>
                    <p>삭제</p>
                    <ManaerDeleteButton
                      onClick={() =>
                        onClickDeleteDepartment(manager.departmentId)
                      }
                      isDelete={deleteDepartmentIds.includes(
                        manager.departmentId
                      )}
                    >
                      {deleteDepartmentIds.includes(manager.departmentId)
                        ? "삭제예정"
                        : "삭제하기"}
                    </ManaerDeleteButton>
                  </div>
                ) : null}
              </ManagerInput>
            ))}
          </ManagerInputContainer>
        </Split>
      </Row>
      <Bottoms>
        <button onClick={submit} disabled={!inputs.name || !inputs.managerName}>
          {type}하기
        </button>
      </Bottoms>
    </Container>
  );
};

export default ClientManage;

const Container = styled(Main)`
  width: 800px;
`;

const Header = styled.header`
  ${({ theme }) => theme.flex.row}
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const ManagerInputContainer = styled.section`
  height: 400px;
  ${({ theme }) => theme.flex.col}
  gap: 16px;
  overflow-y: auto;
`;

const ManagerInput = styled.article`
  ${({ theme }) => theme.flex.col}
  gap: 8px;
  padding: 16px;
  background-color: #f4f4f4;
  border-radius: 4px;

  & > div {
    ${({ theme }) => theme.flex.row}
    align-items: center;
    justify-content: space-between;

    p {
      font-weight: 500;

      span {
        margin-left: 5px;
        color: ${({ theme }) => theme.colors.brand500};
      }
    }

    input {
      width: 200px;
      height: 30px;
      padding: 8px;
      background-color: #fff;
      border: 1px solid #ccc;
      border-radius: 4px;
      font-size: 14px;
    }

    .phone {
      width: 200px;
      gap: 10px;
      ${({ theme }) => theme.flex.row}

      & > input {
        width: 0;
        flex: 1;
        text-align: center;
      }
    }
  }

  .deleteManager {
    padding: 6px;
    background-color: rgb(219, 84, 65, 0.1);
    border-radius: 4px;
    color: #db5441;
    font-size: 14px;
  }
`;

const ManaerDeleteButton = styled.button<{ isDelete: boolean }>`
  padding: 6px;
  background-color: ${({ isDelete }) =>
    isDelete ? "rgb(219, 84, 65, 0.1)" : "rgb(153, 153, 153, 0.1)"};
  border-radius: 4px;
  color: #db5441;
  color: ${({ isDelete }) => (isDelete ? "#db5441" : "#999")};
  font-size: 14px;
`;
