import * as S from './styles/LoginStyle';
import img from '@assets/image';
import { Link } from 'react-router-dom';

type Props = {
  inputs: { id: string; password: string };
  onChangeInputs: (e: { target: HTMLInputElement }) => void;
  checkSaveId: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isRemember: boolean;
  login: () => Promise<void>;
};

const Login = ({
  inputs,
  onChangeInputs,
  checkSaveId,
  isRemember,
  login,
}: Props) => {
  return (
    <S.Login>
      <S.LoginImg src={img.loginIllust} alt='로그인화면 로고' className='web' />
      <S.LoginInputs>
        <h1>WELCOME!</h1>
        <S.LoginInput
          name='id'
          defaultValue={inputs.id}
          onChange={onChangeInputs}
          placeholder='아이디'
          autoComplete='off'
          autoFocus
        />
        <S.LoginInput
          name='password'
          type='password'
          onChange={onChangeInputs}
          onKeyDown={(e) => {
            if (e.key === 'Enter') login();
          }}
          placeholder='비밀번호'
        />
        <label className='saveId'>
          <input type='checkbox' onChange={checkSaveId} checked={isRemember} />
          <p>아이디저장</p>
        </label>
        <button type='button' onClick={login}>
          로그인
        </button>
        <p className='join'>
          아직 MCALL 계정이 없으신가요?
          <Link to='/terms'>회원가입하러가기</Link>
        </p>
      </S.LoginInputs>
    </S.Login>
  );
};

export default Login;
