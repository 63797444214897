import ShopDeviceRelease from "../components/ShopDeviceRelease";
import React, { useCallback, useEffect, useState } from "react";
import { apiRoute, requestSecureGet, requestSecurePatch } from "@libs/api";
import useToken from "@hooks/useToken";
import { ShopDeviceTypes } from "@typedef/components/Inventory/shop.device.types";
import { ShopDeviceReleaseTypes } from "@typedef/components/Inventory/Release/shop.device.release.types";
import { DeviceCategoryTypes } from "@typedef/components/Device/Category/device.category.types";
import dayjs from "dayjs";
import {
  ClientDepartmentTypes,
  ClientTypes,
} from "@typedef/components/Client/client.types";

type Props = {
  close: () => void;
  reload: () => void;
};

const ShopDeviceReleaseContainer = ({ close, reload }: Props) => {
  const { getToken } = useToken();
  const today = dayjs();
  // 출고유형
  const [categories, setCategories] = useState<DeviceCategoryTypes[]>([]);
  // 전체 재고 목록
  const [devices, setDevices] = useState<ShopDeviceReleaseTypes[]>([]);
  // 검색한 재고 목록
  const [searchDevices, setSearchDevices] = useState<ShopDeviceReleaseTypes[]>(
    []
  );
  // 거래처 목록
  const [clients, setClients] = useState<ClientTypes[]>([]);
  const [checkedIdxes, setCheckedIdxes] = useState<number[]>([]);
  const [inputs, setInputs] = useState<{
    year: string;
    month: string;
    day: string;
    memo: string;
    client: ClientTypes | null;
    department: ClientDepartmentTypes | null;
  }>({
    year: today.format("YYYY"),
    month: today.format("MM"),
    day: today.format("DD"),
    memo: "",
    client: null,
    department: null,
  });

  // 일련번호로 재고 검색
  const searchBySerial = (value: string) => {
    const filtered = devices.filter((device) =>
      device.serial_number.includes(value)
    );
    if (value.length < 1) {
      setSearchDevices([]);
    } else {
      setSearchDevices(filtered);
    }
  };

  // 출고할 단말 선택(체크박스)
  const onChangeCheckbox = (idx: number, checked: boolean) => {
    // 체그 했을경우
    if (checked) {
      // 체크한 idx의 device의 카테고리를 강제로 선택하게 한다.
      const findIdx = devices.findIndex((device) => device.idx === idx);
      if (findIdx !== -1 && categories.length > 0) {
        let firstCategory = categories[0];
        devices[findIdx] = {
          ...devices[findIdx],
          category_idx: firstCategory.idx,
          category_name: firstCategory.name,
        };
        setDevices(devices);
      }
      setCheckedIdxes((checkedIdxes) => [...checkedIdxes, idx]);
    } else {
      // 체크 해제 했을경우
      const filtered = checkedIdxes.filter(
        (releaseDevice) => releaseDevice !== idx
      );
      setCheckedIdxes(filtered);
    }
  };

  // 출고가 변경
  const onChangePrice = (idx: number, price: string) => {
    let copyDevices = [...devices];
    const findIdx = devices.findIndex((device) => device.idx === idx);
    if (findIdx !== -1) {
      copyDevices[findIdx] = { ...copyDevices[findIdx], out_price: +price };
      setDevices(copyDevices);
    }
  };

  // 출고유형 선택
  const onChangeCategory = (idx: number, category: DeviceCategoryTypes) => {
    let copyDevices = [...devices];
    const findIdx = devices.findIndex((device) => device.idx === idx);
    if (findIdx !== -1) {
      copyDevices[findIdx] = {
        ...copyDevices[findIdx],
        category_idx: category.idx,
        category_name: category.name,
      };
      setDevices(copyDevices);
      setSearchDevices(copyDevices);
    }
  };

  // onChange (출고일)
  const onChangeSelect = (name: string, selected: string) => {
    setInputs((inputs) => ({
      ...inputs,
      [name]: selected,
    }));
  };

  // 거래처 선택
  const onChangeClient = (client: ClientTypes | null) => {
    setInputs({
      ...inputs,
      client: client,
      department: inputs.client === client ? inputs.department : null,
    });
  };

  // 부서 선택
  const onChangeDepartment = (dept: ClientDepartmentTypes | null) => {
    setInputs({ ...inputs, department: dept });
  };

  // onChange (메모)
  const onChangeInputs = (e: { target: HTMLInputElement }) => {
    const { name, value } = e.target;
    setInputs((inputs) => ({
      ...inputs,
      [name]: value,
    }));
  };

  // 선택한 재고 출고하기
  const release = async () => {
    const checkedDevices = devices.filter((device) =>
      checkedIdxes.some((idx) => idx === device.idx)
    );

    const patchData = {
      devices: checkedDevices,
      date: `${inputs.year}-${inputs.month}-${inputs.day}`,
      memo: inputs.memo,
      clientId: inputs.client?.clientId,
      clientName: inputs.client?.name,
      departmentId: inputs.department?.departmentId,
      departmentName: inputs.department?.name,
    };

    const { config } = await requestSecurePatch(
      apiRoute.shopDevice.release.releaseDevice,
      {},
      patchData,
      getToken()!
    );
    if (!!!inputs.client) return alert("거래처를 선택해주세요");
    if (config.status >= 200 && config.status < 400) {
      alert("성공적으로 출고가 완료되었습니다.");
      close();
      reload();
      setCheckedIdxes([]);
    }
  };

  // 출고 가능한 재고 조회
  const getReleaseDeviceData = useCallback(async () => {
    const { config, data } = await requestSecureGet<ShopDeviceTypes[]>(
      apiRoute.shopDevice.getDevices + "?status_type=in",
      {},
      getToken()!
    );
    if (config.status >= 200 && config.status < 400) {
      const processed = data.map((data) => {
        return {
          idx: data.idx,
          telecom_maker_name: data.maker_nm ?? data.telecom.name,
          model_name: data.device_info.device.model_name ?? data.model_name,
          color: data.device_info.color.name ?? data.device_color,
          serial_number: data.serial_number,
          out_price: data.device_info.device.price ?? data.price,
          category_idx: 6,
          category_name: "판매",
        };
      });
      setDevices(processed);
      setSearchDevices(processed);
    }
  }, [getToken]);

  // 출고유형 조회
  const getCategories = useCallback(async () => {
    const { config, data } = await requestSecureGet<DeviceCategoryTypes[]>(
      apiRoute.device.category.getCategories + "?type=출고",
      {},
      getToken()!
    );
    if (config.status >= 200 && config.status < 400) {
      setCategories(data);
    }
  }, [getToken]);

  // 거래처 조회
  const getClients = async () => {
    const { config, data } = await requestSecureGet<ClientTypes[]>(
      apiRoute.client.getClients,
      {},
      getToken()!
    );

    if (config.status >= 200 && config.status < 400) {
      setClients(data);
    }
  };

  useEffect(() => {
    getReleaseDeviceData();
    getCategories();
    getClients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ShopDeviceRelease
      searchDevices={searchDevices}
      checkedIdxes={checkedIdxes}
      clients={clients}
      searchBySerial={searchBySerial}
      onChangeCheckbox={onChangeCheckbox}
      onChangePrice={onChangePrice}
      onChangeCategory={onChangeCategory}
      categories={categories}
      inputs={inputs}
      onChangeInputs={onChangeInputs}
      onChangeSelect={onChangeSelect}
      release={release}
      onChangeClient={onChangeClient}
      onChangeDepartment={onChangeDepartment}
    />
  );
};

export default ShopDeviceReleaseContainer;
