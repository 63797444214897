import LNB from '../LNB';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import useUser from '@hooks/useUser';
import { jsolNavs, agencyNavs, storeNavs } from '../LNBData';
import { LNBTypes } from '@typedef/components/Common/LNB/lnb.types';

const LNBContainer = () => {
  const { getUser } = useUser();
  const path = useLocation().pathname;
  const role = useMemo(() => getUser().role, [getUser]);
  const [allNavs, setAllNavs] = useState<LNBTypes[] | null>(null);
  const [LNBNav, setLNBNav] = useState<LNBTypes | null>(null);

  // Indicator 설정
  const setIndicator = useCallback(
    (items: NodeListOf<HTMLElement>) => {
      if (!LNBNav) {
        return;
      }
      let current = items[0];
      const currentPath = path.split('/')[2];
      const indicator = document.querySelector<HTMLElement>('.nav-indicator');

      const subNavs = LNBNav?.subNavs;

      // 현재 주소 Item 찾기
      if (subNavs) {
        for (let i = 0; i < subNavs.length; i++) {
          const nav = subNavs[i];
          if (`/${currentPath}` === nav.path) {
            current = items[i];
            break;
          }
        }
      }

      if (indicator) {
        const width = current.offsetWidth || current.clientWidth;
        const left = current.offsetLeft || current.clientLeft;
        indicator.style.width = `${width}px`;
        indicator.style.left = `${left}px`;
      }
    },
    [LNBNav, path],
  );

  // 전체 Navs에서 baseUrl이 같은 LNB값 가져오기
  const getLNBNavs = useCallback(() => {
    const baseUrl = path.split('/')[1];
    const LNB = allNavs?.filter((nav) => nav.baseUrl === baseUrl)[0];
    if (LNB) {
      setLNBNav(LNB);
      const items = document.querySelectorAll<HTMLElement>('.nav-item');
      setIndicator(items);
    } else {
      setLNBNav(null);
    }
  }, [allNavs, path, setIndicator]);

  const getRoleNavs = useCallback(() => {
    if (role.includes('JSOL')) {
      setAllNavs(jsolNavs);
    } else if (role.includes('AGENCY')) {
      setAllNavs(agencyNavs);
    } else {
      setAllNavs(storeNavs);
    }
  }, [role]);

  useEffect(() => {
    getRoleNavs();
    getLNBNavs();
  }, [getRoleNavs, getLNBNavs, LNBNav, setIndicator, path]);

  return <LNB LNBNav={LNBNav} />;
};

export default LNBContainer;
