import NoticeDetail from '../components/NoticeDetail';
import { useState, useEffect } from 'react';
import { apiRoute, requestSecureGet } from '@libs/api';
import useToken from '@hooks/useToken';
import { useLocation } from 'react-router-dom';
import { BoardTypes } from '@typedef/components/Board/board.types';
import dayjs from 'dayjs';

const NoticeDetailContainer = () => {
  const { getToken } = useToken();
  const location = useLocation();
  const [board, setBoard] = useState<Partial<BoardTypes>>({});
  const [loading, setLoading] = useState<boolean>(true);

  const getClickedData = async (boardId: number) => {
    const { config, data } = await requestSecureGet<{ board: BoardTypes }>(
      apiRoute.board.getBoard + boardId,
      {},
      getToken()!,
    );

    if (config.status >= 200 && config.status < 400) {
      setBoard({
        ...data.board,
        time: dayjs(data.board.time).format('YYYY-MM-DD'),
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    const boardId = location.pathname.split('/')[3];
    getClickedData(+boardId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <NoticeDetail board={board} loading={loading} />;
};

export default NoticeDetailContainer;
