import Shop from "../Shop";
import { useCallback, useEffect, useMemo, useState } from "react";
import { apiRoute, requestSecureGet } from "@libs/api";
import useToken from "@hooks/useToken";
import { useSetRecoilState } from "recoil";
import { loading, modal, modalHeader } from "@stories/Atom";
import { TableColumnTypes } from "@typedef/components/Common/CTable/table.column.types";
import { ShopTypes } from "@typedef/components/Shop/shop.types";
import ShopDetailContainer from "./ShopDetailContainer";
import DetailCell from "@components/Common/CTable/components/DetailCell";

// 업체관리 > 업체목록 (JSOL)
const ShopContainer = () => {
  const { getToken } = useToken();
  // 테이블 데이터
  const [tableData, setTableData] = useState<ShopTypes[]>([]);
  const [checkedIdxes, setCheckedIdxes] = useState<number[]>([]);
  // 모달설정
  const setModal = useSetRecoilState(modal);
  const setModalHeader = useSetRecoilState(modalHeader);
  // 테이블 로딩
  const setLoading = useSetRecoilState(loading);

  // 체크박스
  const onChangeCheckbox = useCallback(
    (idx: number) => {
      if (checkedIdxes.includes(idx)) {
        setCheckedIdxes(
          checkedIdxes.filter((checkedIdx) => checkedIdx !== idx)
        );
        return;
      }
      setCheckedIdxes((idxes) => [...idxes, idx]);
    },
    [checkedIdxes]
  );

  const detailShop = useCallback(
    (idx: number) => {
      setModalHeader("업체 상세조회");
      setModal(<ShopDetailContainer idx={idx} />);
    },
    [setModal, setModalHeader]
  );

  const columns: TableColumnTypes<ShopTypes>[] = useMemo(
    () => [
      {
        accessor: "shopId",
        disableSortBy: true,
        width: 70,
        Cell: ({ value }) => (
          <input
            type="checkbox"
            defaultChecked={checkedIdxes.includes(value as number)}
            onChange={() => onChangeCheckbox(value as number)}
          />
        ),
      },
      {
        Header: "No",
        width: 70,
        Cell: ({ row }) => <>{tableData.length - row.index}</>,
      },
      {
        Header: "업체명",
        accessor: "name",
        disableSortBy: true,
        Cell: ({ row, value }) => (
          <DetailCell
            value={value as string}
            func={() => detailShop(row.original.shopId)}
          />
        ),
      },
      {
        Header: "업체구분",
        accessor: (originRow) =>
          originRow.role === "AGENCY" ? "대리점" : "판매점",
      },
      {
        Header: "대표전화",
        accessor: "phone",
        disableSortBy: true,
      },
      {
        Header: "팩스",
        accessor: "fax",
        disableSortBy: true,
      },
      {
        Header: "주소",
        accessor: "address",
        disableSortBy: true,
      },
      {
        Header: "담당자",
        accessor: "owner.name",
        disableSortBy: true,
      },
    ],
    [checkedIdxes, detailShop, onChangeCheckbox, tableData.length]
  );

  const switchDormant = async () => {
    if (checkedIdxes.length) {
      alert("휴면계정으로 전환할 업체를 선택해 주세요.");
    } else if (
      window.confirm(
        `선택한 ${checkedIdxes.length}개의 업체를 휴면계정으로 전환하시겠습니까?`
      )
    ) {
    }
  };

  const getTableData = async () => {
    const { config, data } = await requestSecureGet<{ shops: ShopTypes[] }>(
      apiRoute.shop.getShops,
      {},
      getToken()!
    );
    if (config.status >= 200 && config.status < 400) {
      setTableData(data.shops);
      setLoading(false);
    } else {
      setTableData([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Shop
      columns={columns}
      tableData={tableData}
      switchDormant={switchDormant}
    />
  );
};

export default ShopContainer;
