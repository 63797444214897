import Join from '../Join';
import { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiRoute, requestSecureGet, requestSecurePost } from '@libs/api';
import useToken from '@hooks/useToken';
import axios from 'axios';
import { JoinCertifyTypes } from '@typedef/components/Join/join.certify.types';
import { JoinInputsTypes } from '@typedef/components/Join/join.inputs.types';
import { joinPhone } from '@libs/commonFuncs';

const JoinContainer = () => {
  const { getToken } = useToken();
  const navigate = useNavigate();
  const [inputs, setInputs] = useState<Partial<JoinInputsTypes>>({
    role: 'agency',
  });
  const [splits, setSplits] = useState({
    call1: '',
    call2: '',
    call3: '',
    fax1: '',
    fax2: '',
    fax3: '',
    phone1: '010',
    phone2: '',
    phone3: '',
  });
  // 메세지
  const [idMsg, setIdMsg] = useState<string | null>(null);
  const [pwMsg, setPwMsg] = useState<string | null>(null);
  const [pwCheckMsg, setPwCheckMsg] = useState<string | null>(null);
  const [businessNoMsg, setBusinessNoMsg] = useState<string | null>(null);
  const addressRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const onChangeInputs = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    if (
      name.includes('call') ||
      name.includes('fax') ||
      name.includes('phone')
    ) {
      setSplits((splits) => ({
        ...splits,
        [name]: value,
      }));
      return;
    }
    setInputs((inputs) => ({
      ...inputs,
      [name]: value,
    }));
  };

  // 업체구분 선택
  const onClickRole = (role: string) => {
    setInputs((inputs) => ({
      ...inputs,
      role: role,
    }));
  };

  // 아이디 중복 확인
  const regexId = async (id: string) => {
    if (!!!id) return;
    const regex = /^[A-za-z0-9]{4,16}$/;
    if (!regex.test(id)) {
      setIdMsg('4~16자의 영문 대소문자, 숫자만 가능합니다.');
      return;
    }
    setLoading(true);
    const { config, data } = await requestSecureGet<string>(
      apiRoute.auth.valid + id,
      {},
      getToken()!,
    );
    if (config.status >= 200 && config.status < 400) {
      setIdMsg(data);
      if (data.includes('가능한')) {
        inputs.id = id;
      } else {
        inputs.id = 'err';
      }
    }
    setLoading(false);
  };

  // 비밀번호
  const regexPassword = (pw: string) => {
    if (!!!pw) return;
    const regex = /^(?=.*?\d)(?=.*[a-zA-ZS]).{8,20}/;
    if (regex.test(pw)) {
      setPwMsg(null);
      inputs.password = pw;
    } else {
      setPwMsg('영문, 숫자를 사용하여 8~20자리 사이로 입력해주세요');
      inputs.password = 'err';
    }
  };

  // 비밀번호 확인
  const samePassword = (pw: string) => {
    if (!!!pw) return;
    if (inputs.password) {
      if (inputs.password === pw) {
        setPwCheckMsg('일치합니다.');
        inputs.password_check = pw;
      } else {
        setPwCheckMsg('일치하지 않습니다.');
        inputs.password_check = 'err';
      }
    } else {
      setPwCheckMsg(null);
    }
  };

  // 국세청_사업자등록정보 진위확인 오픈 API
  // https://www.data.go.kr/data/15081808/openapi.do#/
  const certifyBusinessNo = async (businessNo: string) => {
    if (!!!businessNo) return;
    if (isNaN(+businessNo)) {
      setBusinessNoMsg('숫자만 입력해 주세요.');
      return;
    } else if (businessNo.length !== 10) {
      setBusinessNoMsg('숫자 10자리를 입력해 주세요.');
      return;
    }
    setLoading(true);
    let url = `https://api.odcloud.kr/api/nts-businessman/v1/status?serviceKey=${process.env.REACT_APP_API_KEY}`;

    const postData = {
      b_no: [businessNo],
    };

    const { config, data } = await axios
      .post(url, postData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((res) => ({
        data: res.data.data[0] as JoinCertifyTypes,
        config: {
          status: res.status,
          ...res.data?.meta,
        },
      }));

    if (config.status === 200) {
      if (data.tax_type.includes('등록되지 않은')) {
        setBusinessNoMsg(data.tax_type);
        inputs.business_no = 'err';
      } else if (data.b_stt_cd !== '01') {
        // b_stt_cd = 01 : 계속사업자
        setBusinessNoMsg('휴업 또는 폐업 상태의 사업자등록번호입니다.');
        inputs.business_no = 'err';
      } else {
        setBusinessNoMsg(null);
        inputs.business_no = businessNo;
      }
    }
    setLoading(false);
  };

  // 우편번호 검색
  const searchZonecode = () => {
    new window.daum.Postcode({
      oncomplete: function (data: any) {
        var extraRoadAddr = '';
        if (data.bname !== '' && /[동|로|가]$/g.test(data.bname)) {
          extraRoadAddr += data.bname;
        }
        if (data.buildingName !== '' && data.apartment === 'Y') {
          extraRoadAddr +=
            extraRoadAddr !== '' ? ', ' + data.buildingName : data.buildingName;
        }
        if (extraRoadAddr !== '') {
          extraRoadAddr = ' (' + extraRoadAddr + ')';
        }
        setInputs((inputs) => ({
          ...inputs,
          zonecode: data.zonecode,
          address: data.roadAddress + ' (' + data.jibunAddress + ')',
        }));
        // 상세주소로 포커스
        addressRef.current?.focus();
      },
    }).open();
  };

  // 입력 값 확인
  const allInputs = () => {
    let message = '';
    if (!!!inputs.id) message = '아이디를';
    else if (inputs.id === 'err') message = '아이디를 다시';
    else if (!!!inputs.password) message = '비밀번호를';
    else if (inputs.password === 'err') message = '비밀번호를 형식에 맞게 다시';
    else if (!!!inputs.password_check) message = '비밀번호 확인을';
    else if (inputs.password_check === 'err') message = '비밀번호 확인을 다시';
    else if (!!!inputs.name) message = '업체명을';
    else if (!!!inputs.business_no) message = '사업자등록번호를';
    else if (inputs.business_no === 'err')
      message = '사용 가능한 사업자등록번호를';
    else if (!!!inputs.name) message = '업체명을';
    else if (!!!splits.call1 || !!!splits.call2) message = '대표전화를';
    else if (!!!inputs.manager_name) message = '담당자명을';
    else if (!!!splits.phone1 || !!!splits.phone2) message = '담당자 연락처를';
    else if (!!!inputs.manager_email) message = '담당자 이메일을';
    return message;
  };

  // 회원가입 버튼 클릭 시
  const join = async () => {
    const message = allInputs();
    if (!!message) {
      alert(`${message} 입력해 주세요.`);
      return;
    }

    const postData = {
      address: inputs.address,
      addressDetail: inputs.address_detail,
      businessNo: inputs.business_no,
      email: inputs.manager_email,
      fax: joinPhone(splits.fax1, splits.fax2, splits.fax3),
      name: inputs.name,
      ownerId: inputs.id,
      ownerName: inputs.manager_name,
      ownerPassword: inputs.password,
      ownerPhone: joinPhone(splits.phone1, splits.phone2, splits.phone3),
      phone: joinPhone(splits.call1, splits.call2, splits.call3),
      role: inputs.role!.toUpperCase(),
      zonecode: inputs.zonecode,
    };

    const { config } = await requestSecurePost(
      apiRoute.account.postAccount,
      {},
      postData,
      getToken()!,
    );

    if (config.status >= 200 && config.status < 400) {
      navigate('/completed');
    } else {
      alert('실패');
    }
  };

  // 이전 버튼 클릭 시
  const onClickPrev = () => {
    if (
      window.confirm('이 페이지를 벗어나면 작성된 내용은 저장되지 않습니다.')
    ) {
      navigate('login');
    }
  };

  return (
    <Join
      loading={loading}
      inputs={inputs}
      onChangeInputs={onChangeInputs}
      onClickRole={onClickRole}
      regexId={regexId}
      idMsg={idMsg}
      regexPassword={regexPassword}
      pwMsg={pwMsg}
      samePassword={samePassword}
      pwCheckMsg={pwCheckMsg}
      certifyBusinessNo={certifyBusinessNo}
      businessNoMsg={businessNoMsg}
      searchZonecode={searchZonecode}
      addressRef={addressRef}
      onClickPrev={onClickPrev}
      join={join}
    />
  );
};

export default JoinContainer;
