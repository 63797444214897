import { BackGround, ModalBox, Header } from './styles/ModalStyle';
import { useRecoilValue } from 'recoil';
import { modal, modalHeader } from '@stories/Atom';
import img from '@assets/image';

type Props = {
  close: (e: React.MouseEvent) => void;
};

const Modal = ({ close }: Props) => {
  const currentModal = useRecoilValue(modal);
  const header = useRecoilValue(modalHeader);
  return (
    <BackGround id='background'>
      <ModalBox>
        <Header>
          <h2>{header}</h2>
          <button type='button' onClick={close}>
            <img src={img.closeIcon} alt='닫기' />
          </button>
        </Header>
        {currentModal}
      </ModalBox>
    </BackGround>
  );
};

export default Modal;
