import ExcelInsert from "../components/ExcelInsert";
import { useState } from "react";
import useToken from "@hooks/useToken";
import XLSX from "xlsx";
import { apiRoute, requestSecurePost } from "@libs/api";

export type ExcelDataTypes = {
  colorName: string | null;
  inDate: string | null;
  makerName: string | null;
  memo: string | null;
  modelName: string;
  price: number | null;
  serialNumber: string;
};

type Props = {
  close: () => void;
  reload: () => void;
};

const ExcelInsertContainer = ({ close, reload }: Props) => {
  const { getToken } = useToken();
  const [excelData, setExcelData] = useState<ExcelDataTypes[]>([]);
  const [checkedExcelData, setCheckedExcelData] = useState<ExcelDataTypes[]>(
    []
  );

  // 엑셀 헤더 순서랑 아래 순서가 맞아야함.
  const excelDataKeys: { [key in number]: keyof ExcelDataTypes } = {
    0: "inDate",
    1: "makerName",
    2: "modelName",
    3: "serialNumber",
    4: "colorName",
    5: "price",
    6: "memo",
  };

  const onChangeExcelFile = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = new Uint8Array(event.target?.result as ArrayBuffer);
        const workbook = XLSX.read(data, { type: "array" });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData: Array<string | number | null>[] =
          XLSX.utils.sheet_to_json(worksheet, { header: 1, defval: null });

        const processed = jsonData.map((row) => {
          const obj: { [key in string]: any } = {};

          row.forEach((value, idx) => {
            const key = excelDataKeys[idx];

            obj[key] = value as ExcelDataTypes[typeof key];
          });

          return obj;
        }) as ExcelDataTypes[];

        const filtered = processed.filter((obj) => {
          // const isValidDate =
          //   obj.inDate === null || /\d{4}-\d{2}-\d{2}/.test(obj.inDate); // 입고일 YYYY-MM-DD
          const isModelNameValid = obj.modelName !== null; // 모델명 필수 입력
          const isSerialNumberValid = obj.serialNumber !== null; // 일련번호 필수 입력
          const isPriceValid =
            obj.price === null || typeof obj.price === "number"; // 입고가 정수

          return (
            // isValidDate &&
            isModelNameValid && isSerialNumberValid && isPriceValid
          );
        });

        setExcelData(filtered);
        setCheckedExcelData(filtered);
      };

      reader.readAsArrayBuffer(file);
    }
  };

  const onChangeCheckbox = (data: ExcelDataTypes | "all", checked: boolean) => {
    if (data === "all") {
      setCheckedExcelData(checked ? excelData : []);
    } else {
      if (checkedExcelData.includes(data)) {
        const filtered = checkedExcelData.filter((checked) => checked !== data);
        setCheckedExcelData(filtered);
      } else {
        setCheckedExcelData([...checkedExcelData, data]);
      }
    }
  };

  const insert = async () => {
    const { config } = await requestSecurePost(
      `${apiRoute.shopDevice.postDevice}/excel/custom`,
      {},
      checkedExcelData,
      getToken()!
    );

    if (config.status >= 200 && config.status < 400) {
      alert("성공적으로 등록이 완료되었습니다.");
      close();
      reload();
    }
  };

  return (
    <ExcelInsert
      excelData={excelData}
      checkedExcelData={checkedExcelData}
      onChangeExcelFile={onChangeExcelFile}
      onChangeCheckbox={onChangeCheckbox}
      insert={insert}
    />
  );
};

export default ExcelInsertContainer;
