import {Navigate, Route, Routes} from 'react-router-dom';
import CustomerContainer from '@components/Customer/Customer/containers/CustomerContainer';
import CustomerConsultContainer from '@components/Customer/Consult/containers/CustomerConsultContainer';
import CustomerHistoryContainer from '@components/Customer/History/containers/CustomerHistoryContainer';
import HelpFAQContainer from '@components/Help/FAQ/containers/FAQContainer';
import HelpNoticeContainer from '@components/Help/Notice/containers/NoticeContainer';
// import HelpQnAContainer from '@components/Help/QNA/containers/QnAContainer';
import InventoryContainer from '@components/Inventory/Inventory/containers/InventoryContainer';
import InventoryHistoryContainer from '@components/Inventory/History/containers/InventoryHistoryContainer';
import InventoryRequestContainer from '@components/Inventory/Request/containers/InventoryRequestContainer';
import StoreMainContainer from '@components/Main/containers/StoreMainContainer';
import PayCreditContainer from '@components/Pay/Credit/containers/PayCreditContainer';
import PayPaybackContainer from '@components/Pay/Payback/containers/PayPaybackContainer';
import PayRetroactiveContainer from '@components/Pay/Retroactive/containers/PayRetroactiveContainer';
import ScheduleContainer from '@components/Schedule/containers/ScheduleContainer';
import SetupBasicContainer from '@components/Setup/Basic/containers/SetupBasicContainer';
import SetupEmployeeContainer from '@components/Setup/Employee/containers/SetupEmployeeContainer';
import SetupPolicyContainer from '@components/Setup/Policy/containers/SetupPolicyContainer';
import SetupRelatedContainer from '@components/Setup/Related/containers/SetupRelatedContainer';
import PayOpenContainer from '@components/Pay/Open/containers/PayOpenContainer';
import SetupDealerContainer from '@components/Setup/Dealer/containers/SetupDealerContainer';
import NoticeDetailContainer from '@components/Help/Notice/containers/NoticeDetailContainer';

// 판매점
const StoreRoleNavigation = () => {
    return (
        <Routes>
            {/* 메인 */}
            <Route path='' element={<StoreMainContainer/>}/>
            {/* 재고관리 */}
            <Route
                path='/inventory/request'
                element={<InventoryRequestContainer/>}
            />
            <Route path='/inventory/device' element={<InventoryContainer/>}/>
            <Route
                path='/inventory/history'
                element={<InventoryHistoryContainer/>}
            />
            {/* 정산관리 */}
            <Route path='/pay/open' element={<PayOpenContainer/>}/>
            <Route path='/pay/credit' element={<PayCreditContainer/>}/>
            <Route path='/pay/retroactive' element={<PayRetroactiveContainer/>}/>
            <Route path='/pay/payback' element={<PayPaybackContainer/>}/>
            {/* 고객관리 */}
            <Route path='/customers/customer' element={<CustomerContainer/>}/>
            <Route path='/customers/consult' element={<CustomerConsultContainer/>}/>
            <Route path='/customers/history' element={<CustomerHistoryContainer/>}/>
            {/* 일정 */}
            <Route path='/schedule' element={<ScheduleContainer/>}/>
            {/* 설정 */}
            <Route path='/setup/basic' element={<SetupBasicContainer/>}/>
            <Route path='/setup/employee' element={<SetupEmployeeContainer/>}/>
            <Route path='/setup/related' element={<SetupRelatedContainer/>}/>
            <Route path='/setup/dealer' element={<SetupDealerContainer/>}/>
            <Route path='/setup/policy' element={<SetupPolicyContainer/>}/>
            {/* 고객센터 */}
            <Route path='/help/notice' element={<HelpNoticeContainer/>}/>
            <Route path='/help/notice/*' element={<NoticeDetailContainer/>}/>
            <Route path='/help/faq' element={<HelpFAQContainer/>}/>
            {/* <Route path='/help/qna' element={<HelpQnAContainer />} /> */}
            <Route path='*' element={<Navigate to=''/>}/>
        </Routes>
    );
};

export default StoreRoleNavigation;
