import styled from 'styled-components';

export const Login = styled.main`
  width: 100vw;
  height: 100vh;
  ${({ theme }) => theme.flex.row}
  align-items: center;
  justify-content: center;
`;

export const LoginImg = styled.img`
  height: 350px;
`;

export const LoginInputs = styled.div`
  height: 350px;
  ${({ theme }) => theme.flex.col}
  justify-content: space-between;
  margin-left: 150px;

  h1 {
    font-size: 2.5rem;
    font-weight: 800;
    letter-spacing: 2px;
  }

  button {
    line-height: 50px;
    background-color: ${({ theme }) => theme.colors.brand500};
    border-radius: 5px;
    color: #fff;
    font-size: 1rem;
    font-weight: 600;
  }

  .saveId {
    ${({ theme }) => theme.flex.row}
    width: fit-content;
    align-items: center;
    cursor: pointer;

    p {
      line-height: 18px;
      margin-left: 7px;
      font-size: 0.9rem;
    }
  }

  .join {
    font-size: 0.9rem;
    text-align: center;
    a {
      margin-left: 10px;
      font-weight: 500;
      text-decoration: underline;
    }
  }
`;

export const LoginInput = styled.input`
  width: 350px;
  line-height: 50px;
  padding: 0 20px;
  background-color: ${({ theme }) => theme.colors.brand200};
  border-radius: 5px;
  font-size: 1rem;
`;
