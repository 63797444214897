import { Main, Split, Bottoms } from '@styles/components/ModalBodyStyle';
import * as S from '@styles/components/FormStyle';
import { InventoryRequestTypes } from '@typedef/components/Inventory/Request/inventory.request.types';
import { ShopDeviceTypes } from '@typedef/components/Inventory/shop.device.types';
import { CheckedRequest } from '../styles/InventoryRequestStyle';
import { calculateDay } from '@libs/commonFuncs';

type Props = {
  checked: InventoryRequestTypes;
  allocableDevices: ShopDeviceTypes[];
  allocateIdx: number;
  onClickAllocate: (device: ShopDeviceTypes) => void;
  allocate: () => Promise<void>;
};

const ShopDeviceAllocate = ({
  checked,
  allocableDevices,
  allocateIdx,
  onClickAllocate,
  allocate,
}: Props) => {
  return (
    <Main width={35}>
      <Split height={70}>
        <CheckedRequest>
          <h4>선택한 요청</h4>
          <div>
            통신사<p>{checked.model_name}</p>
          </div>
          <div>
            모델명<p>{checked.telecom_name}</p>
          </div>
          <div>
            색상<p>{checked.color_name}</p>
          </div>
          <div>
            용량<p>{checked.volume}</p>
          </div>
          <div>
            요청처<p>{checked.requestor.name}</p>
          </div>
        </CheckedRequest>
        <S.SubTitle>
          <h3>배정 가능한 재고 목록&nbsp;({allocableDevices.length})</h3>
          <p className='description'>
            상단에 있는 재고일수록 MCALL이 추천해주는 최적 재고입니다. (보유기간
            고려)
          </p>
        </S.SubTitle>
        <S.Table>
          <S.Thead>
            <th className='small'></th>
            <th>일련번호</th>
            <th>보유처</th>
            <th>보유기간</th>
          </S.Thead>
          <S.Tbody>
            {allocableDevices.length ? (
              allocableDevices.map((device) => (
                <tr key={device.idx}>
                  <td className='small'>
                    <input
                      name='allocate'
                      type='radio'
                      onClick={() => onClickAllocate(device)}
                    />
                  </td>
                  <td>{device.serial_number}</td>
                  <td>{device.location.name}</td>
                  <td>{calculateDay(device.in_date)}일</td>
                </tr>
              ))
            ) : (
              <tr>데이터 없삼</tr>
            )}
          </S.Tbody>
        </S.Table>
      </Split>
      <Bottoms>
        <button type='button' onClick={allocate} disabled={!!!allocateIdx}>
          선택한 재고 배정하기
        </button>
      </Bottoms>
    </Main>
  );
};

export default ShopDeviceAllocate;
