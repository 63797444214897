import {LNBTypes} from "@typedef/components/Common/LNB/lnb.types";

// 제이솔루션
export const jsolNavs: LNBTypes[] = [
    {
        title: "업체관리",
        baseUrl: "shops",
        subNavs: [
            {
                name: "업체목록",
                path: "/shop",
            },
            {
                name: "가입요청",
                path: "/reqeust",
            },
        ],
    },
    {
        title: "단말관리",
        baseUrl: "devices",
        subNavs: [
            {
                name: "단말관리",
                path: "/device",
            },
            {
                name: "색상관리",
                path: "/color",
            },
            {
                name: "바코드관리",
                path: "/barcode",
            },
            {
                name: "제조사관리",
                path: "/maker",
            },
        ],
    },
    {
        title: "통신사관리",
        baseUrl: "telecoms",
        subNavs: [
            {
                name: "통신사관리",
                path: "/telecom",
            },
            {
                name: "요금제관리",
                path: "/phoneplan",
            },
            {
                name: "부가서비스관리",
                path: "/subservice",
            },
            {
                name: "보험관리",
                path: "/insurance",
            },
            {
                name: "공시지원금관리",
                path: "/publicsupport",
            },
        ],
    },
    {
        title: "게시글관리",
        baseUrl: "boards",
        subNavs: [
            {
                name: "게시판관리",
                path: "/category",
            },
            {
                name: "게시글관리",
                path: "/board",
            },
            {
                name: "1:1문의",
                path: "/qna",
            },
        ],
    },
];

// 대리점
export const agencyNavs: LNBTypes[] = [
    {
        title: "재고관리",
        baseUrl: "inventory",
        subNavs: [
            // {
            //     name: "재고요청관리",
            //     path: "/request",
            // },
            {
                name: "재고관리",
                path: "/device",
            },
            {
                name: "입고관리",
                path: "/put",
            },
            {
                name: "출고관리",
                path: "/release",
            },
            {
                name: "재고이력",
                path: "/history",
            },
        ],
    },
    {
        title: "정산관리",
        baseUrl: "pay",
        subNavs: [
            {
                name: "개통관리",
                path: "/open",
            },
            {
                name: "정산서관리",
                path: "/credit",
            },
            {
                name: "소급관리",
                path: "/retroactive",
            },
            {
                name: "환수관리",
                path: "/payback",
            },
        ],
    },
    {
        title: "고객관리",
        baseUrl: "customers",
        subNavs: [
            {
                name: "고객관리",
                path: "/customer",
            },
            {
                name: "고객이력",
                path: "/history",
            },
        ],
    },
    {
        title: "거래처관리",
        baseUrl: "clients",
        subNavs: [
            {
                name: "거래처관리",
                path: "/client",
            },
            {
                name: "거래이력",
                path: "/history",
            },
        ],
    },
    {
        title: "설정",
        baseUrl: "setup",
        subNavs: [
            {
                name: "기본정보관리",
                path: "/basic",
            },
            // {
            //   name: "품목관리",
            //   path: "/item",
            // },
            {
                name: "직원관리",
                path: "/employee",
            },
            // {
            //   name: "입고처관리",
            //   path: "/depot",
            // },
            // {
            //   name: "거래처관리",
            //   path: "/related",
            // },
            // {
            //   name: "정산점관리",
            //   path: "/dealer",
            // },
            {
                name: "대리점정책",
                path: "/policy",
            },
        ],
    },
    {
        title: "고객센터",
        baseUrl: "help",
        subNavs: [
            {
                name: "공지사항",
                path: "/notice",
            },
            {
                name: "FAQ",
                path: "/faq",
            },
            // {
            //   name: '문의',
            //   path: '/qna',
            // },
        ],
    },
];

// 판매점
export const storeNavs: LNBTypes[] = [
    {
        title: "재고관리",
        baseUrl: "inventory",
        subNavs: [
            {
                name: "재고요청관리",
                path: "/request",
            },
            {
                name: "재고관리",
                path: "/device",
            },
            {
                name: "재고이력",
                path: "/history",
            },
        ],
    },
    {
        title: "정산관리",
        baseUrl: "pay",
        subNavs: [
            {
                name: "개통관리",
                path: "/open",
            },
            {
                name: "정산서관리",
                path: "/credit",
            },
            {
                name: "소급관리",
                path: "/retroactive",
            },
            {
                name: "환수관리",
                path: "/payback",
            },
        ],
    },
    {
        title: "고객관리",
        baseUrl: "customers",
        subNavs: [
            {
                name: "고객관리",
                path: "/customer",
            },
            {
                name: "상담관리",
                path: "/consult",
            },
            {
                name: "고객이력",
                path: "/history",
            },
        ],
    },
    {
        title: "설정",
        baseUrl: "setup",
        subNavs: [
            {
                name: "기본정보관리",
                path: "/basic",
            },
            {
                name: "직원관리",
                path: "/employee",
            },
            // {
            //   name: "거래처관리",
            //   path: "/related",
            // },
            // {
            //   name: "정산점관리",
            //   path: "/dealer",
            // },
            // {
            //   name: "대리점정책",
            //   path: "/policy",
            // },
        ],
    },
    {
        title: "고객센터",
        baseUrl: "help",
        subNavs: [
            {
                name: "공지사항",
                path: "/notice",
            },
            {
                name: "FAQ",
                path: "/faq",
            },
            // {
            //   name: '문의',
            //   path: '/qna',
            // },
        ],
    },
];
