import Telecom from "../Telecom";
import { useState, useEffect, useMemo, useCallback } from "react";
import { apiRoute, requestSecureGet } from "@libs/api";
import useToken from "@hooks/useToken";
import { useSetRecoilState } from "recoil";
import { loading, modal, modalHeader } from "@stories/Atom";
import { TableColumnTypes } from "@typedef/components/Common/CTable/table.column.types";
import { TelecomTypes } from "@typedef/components/Telecom/telecom.types";
import TelecomManageContainer from "./TelecomManageContainer";

const TelecomContainer = () => {
  const { getToken } = useToken();
  // 테이블 데이터
  const [tableData, setTableData] = useState<TelecomTypes[]>([]);
  const [checkedIdxes, setCheckedIdxes] = useState<number[]>([]);
  // 모달설정
  const setModalHeader = useSetRecoilState(modalHeader);
  const setModal = useSetRecoilState(modal);
  // 테이블 로딩
  const setLoading = useSetRecoilState(loading);
  const [reload, setReload] = useState<number>(0);

  // 통신사 상세조회 및 수정
  const updateTelecom = useCallback(
    (idx?: number) => {
      if (!!!idx && checkedIdxes.length === 0) {
        alert("수정할 통신사를 선택해 주세요.");
        return;
      } else if (!!!idx && checkedIdxes.length > 1) {
        alert("수정할 통신사를 하나만 선택해 주세요.");
        return;
      }
      setModalHeader("통신사 상세조회");
      setModal(
        <TelecomManageContainer
          idx={idx ?? checkedIdxes[0]}
          close={() => setModal(null)}
          reload={() => setReload((prev) => prev + 1)}
        />
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [checkedIdxes]
  );

  // 체크박스
  const onChangeCheckbox = useCallback(
    (idx: number) => {
      if (checkedIdxes.includes(idx)) {
        setCheckedIdxes(
          checkedIdxes.filter((checkedIdx) => checkedIdx !== idx)
        );
        return;
      }
      setCheckedIdxes((idxes) => [...idxes, idx]);
    },
    [checkedIdxes]
  );

  // 테이블 컬럼
  const columns: TableColumnTypes<TelecomTypes>[] = useMemo(
    () => [
      {
        Header: "",
        accessor: "telecomId",
        disableSortBy: true,
        width: 70,
        Cell: ({ value }: any) => (
          <input
            type="checkbox"
            defaultChecked={checkedIdxes.includes(value)}
            onChange={() => onChangeCheckbox(value)}
          />
        ),
      },
      {
        Header: "No",
        Cell: ({ row }: any) => <>{row.index + 1}</>,
      },
      {
        Header: "통신사명",
        accessor: "name",
        disableSortBy: true,
        Cell: ({ row, value }: any) => (
          <div
            className="detail"
            onClick={() => updateTelecom(row.original.telecomId)}
          >
            {value}
          </div>
        ),
      },
    ],
    [checkedIdxes, onChangeCheckbox, updateTelecom]
  );

  // 통신사 등록
  const insertTelecom = () => {
    setModalHeader("통신사 등록");
    setModal(
      <TelecomManageContainer
        close={() => setModal(null)}
        reload={() => setReload((prev) => prev + 1)}
      />
    );
  };

  // 통신사 삭제
  const deleteTelecom = async () => {};

  const getTableData = async () => {
    const { config, data } = await requestSecureGet<{
      telecoms: TelecomTypes[];
    }>(apiRoute.telecom.getTelecoms, {}, getToken()!);
    if (config.status >= 200 && config.status < 400) {
      setTableData(data.telecoms);
      setLoading(false);
    } else {
      setTableData([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  return (
    <Telecom
      columns={columns}
      tableData={tableData}
      insertTelecom={insertTelecom}
      updateTelecom={updateTelecom}
      deleteTelecom={deleteTelecom}
    />
  );
};

export default TelecomContainer;
