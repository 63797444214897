import { Calendar, CalendarTileProperties } from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import * as S from './styles/CCalendarStyle';
import dayjs from 'dayjs';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { ScheduleTypes } from '@typedef/components/Schedule/schedule.types';

type Props = {
  onChangeDate: (date: Date | Date[]) => void;
  displaySchedule?: (date: Date) => ScheduleTypes[];
  isRange?: boolean;
  selectedFunc?: (date: Date) => string;
};

// https://projects.wojtekmaj.pl/react-calendar/
const CCalendar = ({
  onChangeDate,
  displaySchedule,
  isRange,
  selectedFunc,
}: Props) => {
  return (
    <S.CCalendar className='ccalendar'>
      <Calendar
        onChange={(value: Date | Date[]) => onChangeDate(value)}
        formatDay={(locale: string, date: Date) =>
          dayjs(date).format('DD').toString()
        } // ex) 01일 > 01
        minDetail='month'
        prev2Label={null} // 일 년 전
        prevLabel={<FiChevronLeft className='arrow' />} // 이전 달
        nextLabel={<FiChevronRight className='arrow' />} // 다음 달
        next2Label={null} // 일 년 후
        tileContent={({ date }: CalendarTileProperties) => {
          if (displaySchedule) {
            const filtered = displaySchedule(date);
            if (filtered.length) {
              let schedules: JSX.Element[] = [];
              filtered.map((schedule) =>
                schedules.push(
                  <S.Schedule key={schedule.scheduleId} bgc={schedule.colorHex}>
                    &nbsp;
                  </S.Schedule>,
                ),
              );
              return <S.Schedules>{schedules}</S.Schedules>;
            } else {
              return null;
            }
          } else {
            return null;
          }
        }} // 일정이 있으면 보여주기
        selectRange={isRange ?? false}
        tileClassName={
          selectedFunc ? ({ date }) => selectedFunc(date) : undefined
        }
      />
    </S.CCalendar>
  );
};

export default CCalendar;
