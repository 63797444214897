import { Main, Split, Bottoms } from '@styles/components/ModalBodyStyle';
import * as S from '@styles/components/FormStyle';

type Props = {
  inputs: {
    password: string | null;
    passwordCheck: string | null;
  };
  onChangeInputs: (e: { target: HTMLInputElement }) => void;
  pwMsg: string | null;
  pwCheckMsg: string | null;
  insert: () => Promise<void>;
};

const PasswdUpdate = ({
  inputs,
  onChangeInputs,
  pwMsg,
  pwCheckMsg,
  insert,
}: Props) => {
  return (
    <Main width={35}>
      <Split>
        <S.InputBox isRow>
          <p>
            새 비밀번호<span>*</span>
          </p>
          <S.Input
            type='password'
            name='password'
            onBlur={onChangeInputs}
            placeholder='8~20 영문 대소문자, 숫자 포함'
          />
        </S.InputBox>
        {pwMsg && <S.Msg>{pwMsg}</S.Msg>}
        <S.InputBox isRow>
          <p>
            비밀번호 확인<span>*</span>
          </p>
          <S.Input
            type='password'
            name='passwordCheck'
            onBlur={onChangeInputs}
            placeholder='비밀번호 확인'
          />
        </S.InputBox>
        {pwCheckMsg && <S.Msg mb={0}>{pwCheckMsg}</S.Msg>}
      </Split>
      <Bottoms>
        <button
          type='button'
          onClick={insert}
          disabled={!!!inputs.password || !!!inputs.passwordCheck}>
          비밀번호 변경하기
        </button>
      </Bottoms>
    </Main>
  );
};

export default PasswdUpdate;
