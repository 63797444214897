import DeviceManage from "../components/DeviceManage";
import { useState, useEffect } from "react";
import {
  apiRoute,
  requestSecureGet,
  requestSecurePatch,
  requestSecurePost,
} from "@libs/api";
import useToken from "@hooks/useToken";
import { DeviceTypes } from "@typedef/components/Device/device.types";
import { useRecoilValue } from "recoil";
import { getDeviceMakers, getDeviceSeries } from "@stories/Selector";

type Props = {
  idx?: number;
  close: () => void;
  reload: () => void;
};

const DeviceManageContainer = ({ idx, close, reload }: Props) => {
  const { getToken } = useToken();
  const [inputs, setInputs] = useState<Partial<DeviceTypes>>({});
  // 용량 단위
  const [unit, setUnit] = useState<"MB" | "GB" | "TB">("GB");
  // 제조사 리스트
  const makers = useRecoilValue(getDeviceMakers(getToken()!));
  // 시리즈 리스트
  const series = useRecoilValue(getDeviceSeries(getToken()!));
  const [filteredSeries, setFilteredSeries] = useState<string[]>(series);

  console.log(inputs);

  // onChange (input)
  const onChangeInputs = (e: { target: HTMLInputElement }) => {
    const { name, value } = e.target;
    setInputs((inputs) => ({
      ...inputs,
      [name]: value,
    }));
  };

  // 시리즈
  const onChangeSeries = (value: string) => {
    setInputs({ ...inputs, series: value });

    const filtered = series.filter((series) =>
      series
        .replaceAll(" ", "")
        .toLocaleUpperCase()
        .includes(value.replaceAll(" ", "").toLocaleUpperCase())
    );

    setFilteredSeries(filtered);
  };

  // onChange (select)
  const onChangeSelect = (name: string, selected: any) => {
    if (name === "unit") {
      setUnit(selected as "MB" | "GB" | "TB");
      return;
    }
    setInputs((inputs) => ({
      ...inputs,
      [name]: selected,
    }));
  };

  // 등록
  const insert = async () => {
    const { config } = await requestSecurePost(
      apiRoute.device.postDevice,
      {},
      {
        ...inputs,
        volume: inputs.volume + unit,
      },
      getToken()!
    );
    if (config.status >= 200 && config.status < 400) {
      alert("성공적으로 등록이 완료되었습니다.");
      close();
      reload();
    }
  };

  // 수정
  const update = async () => {
    const { config } = await requestSecurePatch(
      apiRoute.device.patchDevice,
      {},
      { ...inputs, volume: inputs.volume + unit, device_idx: idx },
      getToken()!
    );
    if (config.status >= 200 && config.status < 400) {
      alert("성공적으로 수정이 완료되었습니다.");
      close();
      reload();
    }
  };

  const getCheckedData = async () => {
    const { config, data } = await requestSecureGet<DeviceTypes>(
      apiRoute.device.getDevice + idx,
      {},
      getToken()!
    );
    if (config.status >= 200 && config.status < 400) {
      //
      setInputs({
        ...data,
        volume: data.volume.replace(/[^0-9]/g, ""),
      });
      setUnit(data.volume.replace(/[^a-z]/gi, "") as "MB" | "GB" | "TB");
    }
  };

  useEffect(() => {
    if (idx) {
      getCheckedData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idx]);

  return (
    <DeviceManage
      type={idx ? "수정" : "등록"}
      inputs={inputs}
      unit={unit}
      onChangeSelect={onChangeSelect}
      onChangeInputs={onChangeInputs}
      makers={makers}
      filteredSeries={filteredSeries}
      onChangeSeries={onChangeSeries}
      save={idx ? update : insert}
    />
  );
};

export default DeviceManageContainer;
