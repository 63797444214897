import styled from "styled-components";

const space = "50px";
const mediaSpace = "25px";

export const JoinTerms = styled.div`
  width: 100%;
  height: 100vh;
`;

export const Header = styled.header`
  height: 70px;
  ${({ theme }) => theme.flex.col}
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.brand200};
  padding: 0 ${space};

  img {
    width: 100px;
  }

  @media ${({ theme }) => theme.media.tablet} {
    padding: ${mediaSpace};
  }
`;

export const ProcessBox = styled.header`
  padding: 10px ${space} 0;
  background-color: ${({ theme }) => theme.colors.brand200};

  h2 {
    margin-bottom: 10px;
  }

  @media ${({ theme }) => theme.media.tablet} {
    padding: 10px ${mediaSpace} 0;
  }
`;

export const Process = styled.div`
  display: inline-flex;
  position: relative;
  max-width: 100%;
  padding-bottom: 10px;

  p {
    color: #bbb;
    margin-right: 30px;
    font-weight: 300;
  }

  .active {
    color: #191919;
    font-weight: 500;
  }

  .nav-indicator {
    width: 55.313px;
    height: 6px;
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: ${({ theme }) => theme.colors.brand500};
    border-radius: 3px 3px 0 0;
  }
`;

export const Contents = styled.section`
  ${({ theme }) => theme.flex.col}
  justify-content: space-between;
  padding: ${space};

  @media ${({ theme }) => theme.media.tablet} {
    padding: ${space} ${mediaSpace};
  }
`;

export const Terms = styled.section`
  ${({ theme }) => theme.flex.col}
  margin-bottom: 30px;
`;

export const Title = styled.section`
  ${({ theme }) => theme.flex.row}
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;

  label {
    ${({ theme }) => theme.flex.row}
    align-items: center;
    cursor: pointer;
  }

  input {
    margin-right: 10px;
  }
`;

export const Content = styled.article`
  padding: 30px;
  line-height: 30px;
  border: 1px solid #eee;
  border-radius: 10px;
`;

export const Btns = styled.section`
  ${({ theme }) => theme.flex.row}
  justify-content: center;

  .active {
    background-color: ${({ theme }) => theme.colors.brand500};
    color: #fff;
    font-weight: 500;
  }
`;

export const Btn = styled.button`
  width: 120px;
  line-height: 50px;
  background-color: ${({ theme }) => theme.colors.grey200};
  border-radius: 5px;
  font-size: 1rem;

  &:last-child {
    margin-left: 30px;
  }
`;
