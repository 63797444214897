import { atom } from 'recoil';

// 테이블 뷰의 비동기통신에서 사용
// 테이블 뷰의 경우 로딩스피너의 스타일이 다르게 들어감
export const loading = atom<boolean>({
  key: 'loading',
  default: true,
});

// 모달
export const modal = atom<JSX.Element | null>({
  key: 'modal',
  default: null,
});

// 모달 헤더
export const modalHeader = atom<string | null>({
  key: 'modalHeader',
  default: '',
});

export const popup = atom<{ header: string; component: JSX.Element } | null>({
  key: 'popup',
  default: null,
});

// 모바일 네비게이션 사용 여부
export const mobileNB = atom<boolean>({
  key: 'mobileNB',
  default: false,
});

export const CopyValue = atom<string>({
  key: 'CopyValue',
  default: '',
});
