import MakerManage from '../components/MakerManage';
import { useState, useEffect } from 'react';
import { apiRoute, requestSecurePatch, requestSecurePost } from '@libs/api';
import useToken from '@hooks/useToken';
import { MakerTypes } from '@typedef/components/Device/Maker/maker.types';

type Props = {
  checked?: MakerTypes;
  close: () => void;
  reload: () => void;
};
const MakerManageContainer = ({ checked, close, reload }: Props) => {
  const { getToken } = useToken();
  const [inputs, setInputs] = useState<Partial<MakerTypes>>({});

  // onChange (input)
  const onChangeInputs = (e: { target: HTMLInputElement }) => {
    const { name, value } = e.target;
    setInputs((inputs) => ({
      ...inputs,
      [name]: value,
    }));
  };

  // 등록
  const insert = async () => {
    const { config } = await requestSecurePost(
      apiRoute.device.maker.postMaker,
      {},
      inputs,
      getToken()!,
    );
    if (config.status >= 200 && config.status < 400) {
      alert('성공적으로 등록이 완료되었습니다.');
      close();
      reload();
    }
  };

  // 수정
  const update = async () => {
    const { config } = await requestSecurePatch(
      apiRoute.device.maker.patchMaker,
      {},
      inputs,
      getToken()!,
    );
    if (config.status >= 200 && config.status < 400) {
      alert('성공적으로 수정이 완료되었습니다.');
      close();
      reload();
    }
  };

  useEffect(() => {
    if (checked) {
      setInputs(checked);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MakerManage
      type={checked ? '수정' : '등록'}
      inputs={inputs}
      onChangeInputs={onChangeInputs}
      save={checked ? update : insert}
    />
  );
};

export default MakerManageContainer;
