import styled from "styled-components";
import { Main, Split, Bottoms } from "@styles/components/ModalBodyStyle";
import { ExcelDataTypes } from "../containers/ExcelInsertContainer";

type Props = {
  excelData: ExcelDataTypes[];
  checkedExcelData: ExcelDataTypes[];
  onChangeExcelFile: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeCheckbox: (data: ExcelDataTypes | "all", checked: boolean) => void;
  insert: () => Promise<void>;
};

const ExcelInsert = ({
  excelData,
  checkedExcelData,
  onChangeExcelFile,
  onChangeCheckbox,
  insert,
}: Props) => {
  return (
    <Main>
      <Split>
        <p>아래 양식을 다운 받은 후, 입고 정보를 작성합니다.</p>
        <button>양식 다운로드</button>
        <p>유의사항</p>
        <p>다음 내용을 꼭 유의하여주십시오. 오류가 발생할 수 있습니다.</p>
        <p>1. 모델명, 일련번호는 필수 입력입니다.</p>
        <p>2. 입고일 입력 형식은 YYYY.MM.DD 입니다.</p>
        <p>3. 입고가 입력 형식은 정수입니다.</p>

        <input type="file" onChange={onChangeExcelFile} />
        <p>추가 가능한 목록</p>
        <p>아래와 같은 이유로 추가 되지 않았을 수 있음</p>
        <p> 1. 모델명, 단말명 없음 2. 입력 형식에 맞지 않음</p>
        <Container>
          <div className="table">
            <div className="tr">
              <div>
                <input
                  type="checkbox"
                  checked={
                    checkedExcelData.length === excelData.length &&
                    checkedExcelData.length > 0
                  }
                  onChange={(e) => onChangeCheckbox("all", e.target.checked)}
                />
              </div>
              <div>입고일</div>
              <div>제조사</div>
              <div>모델명</div>
              <div>일련번호</div>
              <div>색상</div>
              <div>입고가</div>
              <div>메모</div>
            </div>
            {excelData && (
              <div>
                {excelData.map((device, idx) => (
                  <div className="tr">
                    <div>
                      <input
                        key={idx}
                        type="checkbox"
                        checked={checkedExcelData.includes(device)}
                        onChange={(e) =>
                          onChangeCheckbox(device, e.target.checked)
                        }
                      />
                    </div>
                    <div>{device.inDate}</div>
                    <div>{device.makerName}</div>
                    <div>{device.modelName}</div>
                    <div>{device.serialNumber}</div>
                    <div>{device.colorName}</div>
                    <div>{device.price}</div>
                    <div>{device.memo}</div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </Container>
      </Split>
      <Bottoms>
        <button onClick={insert} disabled={!checkedExcelData.length}>
          등록하기
        </button>
      </Bottoms>
    </Main>
  );
};

export default ExcelInsert;

const Container = styled.div`
  .table {
    height: 300px;
    overflow-y: auto;
  }

  .tr {
    ${({ theme }) => theme.flex.row}

    div {
      flex: 1;
    }
  }
`;
