import TelecomPublicSupport from '../TelecomPublicSupport';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { apiRoute, requestSecureDelete, requestSecureGet } from '@libs/api';
import useToken from '@hooks/useToken';
import { useSetRecoilState } from 'recoil';
import { loading, modal, modalHeader } from '@stories/Atom';
import { TableColumnTypes } from '@typedef/components/Common/CTable/table.column.types';
import { PublicSupportTypes } from '@typedef/components/Telecom/PublicSupport/publicSupport.types';
import PublicSupportManageContainer from './PublicSupportManageContainer';

const TelecomPublicSupportContainer = () => {
  const { getToken } = useToken();
  // 테이블 데이터
  const [tableData, setTableData] = useState<PublicSupportTypes[]>([]);
  const [checkedIdxes, setCheckedIdxes] = useState<number[]>([]);
  // 모달설정
  const setModal = useSetRecoilState(modal);
  const setModalHeader = useSetRecoilState(modalHeader);
  // 테이블 로딩
  const setLoading = useSetRecoilState(loading);
  const [reload, setReload] = useState<number>(0);

  // 체크박스
  const onChangeCheckbox = useCallback(
    (idx: number) => {
      if (checkedIdxes.includes(idx)) {
        setCheckedIdxes(
          checkedIdxes.filter((checkedIdx) => checkedIdx !== idx),
        );
        return;
      }
      setCheckedIdxes((idxes) => [...idxes, idx]);
    },
    [checkedIdxes],
  );

  const insertPublicSupport = () => {
    setModalHeader('요금제 등록');
    setModal(
      <PublicSupportManageContainer
        close={() => setModal(null)}
        reload={() => setReload((prev) => prev + 1)}
      />,
    );
  };

  // 공시지원금 상세조회 및 수정
  const updatePublicSupport = useCallback(
    (idx?: number) => {
      if (!!!idx && checkedIdxes.length === 0) {
        alert('수정할 공시지원금을 선택해 주세요.');
        return;
      } else if (!!!idx && checkedIdxes.length > 1) {
        alert('수정할 공시지원금을 하나만 선택해 주세요.');
        return;
      }
      setModalHeader('공시지원금 상세조회');
      setModal(
        <PublicSupportManageContainer
          idx={idx ?? checkedIdxes[0]}
          close={() => setModal(null)}
          reload={() => setReload((prev) => prev + 1)}
        />,
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [checkedIdxes],
  );

  // 공시지원금 삭제
  const deletePublicSupport = async () => {
    if (checkedIdxes.length === 0) {
      alert('삭제할 데이터를 선택해 주세요');
      return;
    } else if (
      window.confirm(
        `선택한 ${checkedIdxes.length}개의 공시지원금 정보를 삭제하시겠습니까?`,
      )
    ) {
      await Promise.all(
        checkedIdxes.map(
          (idx) =>
            new Promise((resolve, reject) => {
              requestSecureDelete(
                apiRoute.telecom.publicSupport.deletePublicSupport + idx,
                {},
                getToken()!,
              ).then((data) => {
                if (data.config.status >= 200 && data.config.status < 400) {
                  resolve(data);
                }
              });
            }),
        ),
      ).then(() => {
        alert('성공적으로 삭제가 완료되었습니다.');
        setCheckedIdxes([]);
        setReload((prev) => prev + 1);
      });
    }
  };

  // 테이블 컬럼
  const columns: TableColumnTypes<PublicSupportTypes>[] = useMemo(
    () => [
      {
        Header: '',
        accessor: 'publicSupportId',
        disableSortBy: true,
        width: 70,
        Cell: ({ value }: any) => (
          <input
            type='checkbox'
            defaultChecked={checkedIdxes.includes(value)}
            onChange={() => onChangeCheckbox(value)}
          />
        ),
      },
      {
        Header: 'No',
        width: 70,
        Cell: ({ row }: any) => <>{row.index + 1}</>,
      },
      {
        Header: '통신사',
        accessor: 'telecomName',
        width: 70,
      },
      {
        Header: '요금제명',
        accessor: 'phonePlanName',
        disableSortBy: true,
      },
      {
        Header: '모델명',
        accessor: 'modelName',
        disableSortBy: true,
      },
      {
        Header: '공시지원금',
        accessor: 'amount',
        disableSortBy: true,
        Cell: ({ row, value }: any) => (
          <div
            className='detail'
            onClick={() => updatePublicSupport(row.original.publicSupportId)}
          >
            {value?.toLocaleString()}
          </div>
        ),
      },
    ],
    [checkedIdxes, onChangeCheckbox, updatePublicSupport],
  );

  const getTableData = async () => {
    const { config, data } = await requestSecureGet<PublicSupportTypes[]>(
      apiRoute.telecom.publicSupport.getPublicSupports,
      {},
      getToken()!,
    );
    if (config.status >= 200 && config.status < 400) {
      setTableData(data);
      setLoading(false);
    } else {
      setTableData([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  return (
    <TelecomPublicSupport
      columns={columns}
      tableData={tableData}
      insertPublicSupport={insertPublicSupport}
      updatePublicSupport={updatePublicSupport}
      deletePublicSupport={deletePublicSupport}
    />
  );
};

export default TelecomPublicSupportContainer;
