import { Main, Split, Bottoms } from '@styles/components/ModalBodyStyle';
import * as S from '@styles/components/FormStyle';
import CSelect from '@components/Common/CSelect/CSelect';
import { TelecomTypes } from '@typedef/components/Telecom/telecom.types';
import { InsuranceTypes } from '@typedef/components/Telecom/Insurance/insurance.types';

type Props = {
  type: string;
  inputs: Partial<InsuranceTypes>;
  onChangeInputs: (e: { target: HTMLInputElement }) => void;
  onChangeSelect: <T extends {}>(name: string, selected: T) => void;
  telecoms: TelecomTypes[];
  save: () => Promise<void>;
};

const InsuranceManage = ({
  type,
  inputs,
  onChangeInputs,
  onChangeSelect,
  telecoms,
  save,
}: Props) => {
  return (
    <Main width={50}>
      <Split>
        <S.Grid>
          <S.InputBox>
            <p>
              보험명<span>*</span>
            </p>
            <S.Input
              name='name'
              onBlur={onChangeInputs}
              defaultValue={inputs.name}
              placeholder='보험명'
            />
          </S.InputBox>
          <S.InputBox>
            <p>
              통신사<span>*</span>
            </p>
            <CSelect<TelecomTypes>
              options={telecoms}
              value={(telecom: TelecomTypes) => telecom.telecomId}
              label={(telecom: TelecomTypes) => telecom.name}
              defaultValue={telecoms.filter(
                (telecom) => telecom.telecomId === inputs.telecomId,
              )}
              func={(selected: TelecomTypes) =>
                onChangeSelect('telecomId', selected.telecomId)
              }
              placeholder='통신사'
            />
          </S.InputBox>
          <S.InputBox>
            <p>
              월정액<span>*</span>
            </p>
            <S.MoneyInputs>
              <S.Input
                name='price'
                onBlur={onChangeInputs}
                defaultValue={inputs.price}
                placeholder='월정액'
              />
            </S.MoneyInputs>
          </S.InputBox>
          <S.InputBox>
            <p>대상</p>
            <S.Input
              name='targetExp'
              onBlur={onChangeInputs}
              defaultValue={inputs.targetExp}
              placeholder='대상'
            />
          </S.InputBox>
          <S.InputBox>
            <p>범위</p>
            <S.Input
              name='rangeExp'
              onBlur={onChangeInputs}
              defaultValue={inputs.rangeExp}
              placeholder='범위'
            />
          </S.InputBox>
          <S.InputBox>
            <p>자기부담금</p>
            <S.Input
              name='selfPriceExp'
              onBlur={onChangeInputs}
              defaultValue={inputs.selfPriceExp}
              placeholder='자기부담금'
            />
          </S.InputBox>
          <S.InputBox>
            <p>추가보상</p>
            <S.Input
              name='plusRewardExp'
              onBlur={onChangeInputs}
              defaultValue={inputs.plusRewardExp}
              placeholder='추가보상'
            />
          </S.InputBox>
          <S.InputBox>
            <p>최대보상</p>
            <S.Input
              name='limitExp'
              onBlur={onChangeInputs}
              defaultValue={inputs.limitExp}
              placeholder='최대보상'
            />
          </S.InputBox>
          <S.InputBox>
            <p>기타</p>
            <S.Input
              name='etc'
              onBlur={onChangeInputs}
              defaultValue={inputs.etc}
              placeholder='기타'
            />
          </S.InputBox>
        </S.Grid>
      </Split>
      <Bottoms>
        <button
          type='button'
          onClick={save}
          disabled={!!!inputs.name || !!!inputs.telecomId || !!!inputs.price}>
          등록하기
        </button>
      </Bottoms>
    </Main>
  );
};

export default InsuranceManage;
