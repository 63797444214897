import * as S from '@styles/components/TableViewStyle';
import CTable from '@components/Common/CTable/CTable';
import { TableColumnTypes } from '@typedef/components/Common/CTable/table.column.types';
import { InventoryRequestTypes } from '@typedef/components/Inventory/Request/inventory.request.types';

type Props = {
  role: string;
  columns: TableColumnTypes<InventoryRequestTypes>[];
  tableData: InventoryRequestTypes[];
  allocate: () => void;
  reject: () => Promise<void>;
  request: () => void;
  cancelRequest: () => Promise<void>;
};

const InventoryRequest = ({
  role,
  columns,
  tableData,
  allocate,
  reject,
  request,
  cancelRequest,
}: Props) => {
  return (
    <S.Main>
      <S.TopBtns>
        <h3>전체 {tableData.length}</h3>
        {role.includes('AGENCY') ? (
          <S.Btns>
            <S.Btn type='button' onClick={allocate} className='active'>
              재고배정
            </S.Btn>
            <S.Btn type='button' onClick={reject}>
              반려
            </S.Btn>
          </S.Btns>
        ) : (
          <S.Btns>
            <S.Btn type='button' onClick={request} className='active'>
              단말요청
            </S.Btn>
            <S.Btn type='button' onClick={cancelRequest}>
              요청취소
            </S.Btn>
          </S.Btns>
        )}
      </S.TopBtns>
      <CTable columns={columns} tableData={tableData} />
    </S.Main>
  );
};

export default InventoryRequest;
