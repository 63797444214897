import * as S from '@styles/components/TableViewStyle';
import CTable from '@components/Common/CTable/CTable';
import { TableColumnTypes } from '@typedef/components/Common/CTable/table.column.types';

type Props = {
  columns: TableColumnTypes[];
  tableData: any;
  insertDepot: () => void;
  updateDepot: (idx?: number) => void;
  deleteDepot: () => Promise<void>;
};

const SetupDepot = ({
  columns,
  tableData,
  insertDepot,
  updateDepot,
  deleteDepot,
}: Props) => {
  return (
    <S.Main>
      <S.TopBtns>
        <h3>전체 {tableData.length}</h3>

        <S.Btns>
          <S.Btn type='button' onClick={insertDepot} className='active'>
            입고처등록
          </S.Btn>
          <S.Btn type='button' onClick={() => updateDepot()}>
            수정
          </S.Btn>
          <S.Btn type='button' onClick={deleteDepot}>
            삭제
          </S.Btn>
        </S.Btns>
      </S.TopBtns>
      <CTable columns={columns} tableData={tableData} />
    </S.Main>
  );
};

export default SetupDepot;
