import { Main, Split, Bottoms } from '@styles/components/ModalBodyStyle';
import * as S from '@styles/components/FormStyle';
import CSelect from '@components/Common/CSelect/CSelect';
import { DeviceInfoTypes } from '@typedef/components/Device/device.info.types';
import { DeviceTypes } from '@typedef/components/Device/device.types';
import { ColorTypes } from '@typedef/components/Device/Color/color.types';

type Props = {
  type: '등록' | '수정';
  inputs: Partial<DeviceInfoTypes>;
  onChangeInputs: (e: { target: HTMLInputElement }) => void;
  onChangeSelect: (name: string, selected: any) => void;
  devices: DeviceTypes[];
  colors: ColorTypes[];
  message: string;
  save: () => Promise<void>;
};

const BarcodeManage = ({
  type,
  inputs,
  onChangeInputs,
  onChangeSelect,
  devices,
  colors,
  message,
  save,
}: Props) => {
  return (
    <Main width={35}>
      <Split>
        <S.InputBox isRow>
          <p>
            단말 선택<span>*</span>
          </p>
          <CSelect<DeviceTypes>
            options={devices}
            value={(device: DeviceTypes) => device.idx}
            label={(device: DeviceTypes) => device.model_name}
            defaultValue={devices.filter(
              (device) => device.idx === inputs.device?.idx,
            )}
            func={(selected: DeviceTypes) => onChangeSelect('device', selected)}
            disabled={type === '수정'}
            placeholder='단말 선택'
          />
        </S.InputBox>
        <S.InputBox isRow>
          <p>
            색상 선택<span>*</span>
          </p>
          <CSelect<ColorTypes>
            options={colors}
            value={(color: ColorTypes) => color.idx}
            label={(color: ColorTypes) => color.name}
            defaultValue={colors.filter(
              (color) => color.idx === inputs.color?.idx,
            )}
            func={(selected: ColorTypes) => onChangeSelect('color', selected)}
            placeholder='색상 선택'
          />
        </S.InputBox>
        {message && <S.Msg>{message}</S.Msg>}
        <S.InputBox isRow>
          <p>
            바코드<span>*</span>
          </p>
          <S.Input
            name='barcode'
            onChange={onChangeInputs}
            defaultValue={inputs.barcode}
            placeholder='ex) SMX205'
          />
        </S.InputBox>
        <S.InputBox isRow>
          <p>
            바코드색상<span>*</span>
          </p>
          <S.Input
            name='barcode_color'
            onChange={onChangeInputs}
            defaultValue={inputs.barcode_color}
            placeholder='ex) GY'
          />
        </S.InputBox>
      </Split>
      <Bottoms>
        <button
          type='button'
          onClick={save}
          disabled={
            !!message ||
            !!!inputs.device?.idx ||
            !!!inputs.color?.idx ||
            !!!inputs.barcode ||
            !!!inputs.barcode_color
          }>
          바코드 {type}하기
        </button>
      </Bottoms>
    </Main>
  );
};

export default BarcodeManage;
