import styled from "styled-components";
import {
  Header as header,
  ProcessBox as processbox,
  Process as process,
} from "@components/Join/Terms/styles/JoinTermsStyle";

export const JoinCompleted = styled.div`
  width: 100%;
  height: 100vh;
  ${({ theme }) => theme.flex.col}
`;
export const Header = styled(header)``;

export const ProcessBox = styled(processbox)``;

export const Process = styled(process)`
  .nav-indicator {
    width: 55.313px;
    height: 6px;
    position: absolute;
    left: calc(55.313px + 86.875px + 60px);
    bottom: 0;
    background-color: #3f79c4;
    border-radius: 3px 3px 0 0;
  }
`;

export const Main = styled.main`
  flex: 1;
  ${({ theme }) => theme.flex.col}
  align-items: center;
  justify-content: center;
  padding: 50px;

  h2 {
    margin-bottom: 30px;
  }

  article {
    ${({ theme }) => theme.flex.col}
    align-items: center;
  }

  p {
    font-size: 1.2rem;
    line-height: 40px;
  }
`;

export const Btns = styled.section`
  margin-top: 30px;
`;

export const Btn = styled.button`
  text-decoration: underline;
  font-size: 1.2rem;
`;
