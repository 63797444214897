import * as S from './styles/SetupBasicStyle';
import PriceInfoContainer from './containers/PriceInfoContainer';
import ShopInfoContainer from './containers/ShopInfoContainer';

type Props = {
  name: string;
  role: string;
  manage: (type: 'pw' | 'basic') => void;
};

const SetupBasic = ({ name, role, manage }: Props) => {
  return (
    <S.SetupBasic>
      <S.Left>
        <S.Profile>
          <S.Circle>{name.substring(1, 3)}</S.Circle>
          <h2>{name}</h2>
          {role.includes('OWN') ? (
            <button type='button' onClick={() => manage('pw')}>
              비밀번호 변경
            </button>
          ) : (
            <button type='button' onClick={() => manage('basic')}>
              개인정보 수정
            </button>
          )}
        </S.Profile>
        <PriceInfoContainer />
      </S.Left>
      <ShopInfoContainer />
    </S.SetupBasic>
  );
};

export default SetupBasic;
