import EmployeeManage from '../components/EmployeeManage';
import { useEffect, useState } from 'react';
import {
  apiRoute,
  requestSecureGet,
  requestSecurePatch,
  requestSecurePost,
} from '@libs/api';
import useToken from '@hooks/useToken';
import { EmployeeInsertTypes } from '@typedef/components/Setup/Employee/employee.insert.types';
import { EmployeeTypes } from '@typedef/components/Setup/Employee/employee.types';
import { joinBirthday, joinPhone } from '@libs/commonFuncs';
type Props = {
  idx?: number;
  close: () => void;
  reload: () => void;
};

// 설정 > 직원관리 (대리점, 판매점)
const EmployeeManageContainer = ({ idx, close, reload }: Props) => {
  const { getToken } = useToken();
  const [inputs, setInputs] = useState<Partial<EmployeeInsertTypes>>({
    phone1: '010',
  });
  const [msg, setMsg] = useState<{
    id?: string | null;
    passwd: string | null;
    passwdCheck: string | null;
  }>({
    id: null,
    passwd: null,
    passwdCheck: null,
  });

  const onChangeMsg = (
    name: 'id' | 'passwd' | 'passwdCheck',
    value: string | null,
  ) => {
    setMsg((msg) => ({
      ...msg,
      [name]: value,
    }));
  };

  // 아이디 정규식 및 중복확인
  const regexId = async (id: string) => {
    if (!!!id) return;

    // 정규식
    const regex = /^[A-za-z0-9]{4,16}$/;
    if (!regex.test(id)) {
      onChangeMsg('id', '4~16자의 영문 대소문자, 숫자만 가능합니다.');
      setInputs((inputs) => ({
        ...inputs,
        accountId: 'err',
      }));
      return;
    }

    // 중복확인
    const { config, data } = await requestSecureGet<string>(
      apiRoute.account.valid + id,
      {},
      getToken()!,
    );
    if (config.status >= 200 && config.status < 400) {
      // 이미 사용 중인 아이디인 경우
      // data : 있습니다
      if (data.includes('있')) {
        onChangeMsg('id', '이미 사용중인 아이디입니다');
        setInputs((inputs) => ({
          ...inputs,
          accountId: 'err',
        }));
      } else {
        onChangeMsg('id', null);
        setInputs((inputs) => ({
          ...inputs,
          accountId: id,
        }));
      }
    }
  };

  // 비밀번호 정규식
  const regexPassword = (pw: string) => {
    if (!!!pw) return;
    const regex = /^(?=.*?\d)(?=.*[a-zA-ZS]).{8,20}/;
    if (regex.test(pw)) {
      onChangeMsg('passwd', null);
      setInputs((inputs) => ({
        ...inputs,
        password: pw,
      }));
    } else {
      onChangeMsg(
        'passwd',
        '영문, 숫자를 사용하여 8~20자리 사이로 입력해 주세요',
      );
      setInputs((inputs) => ({
        ...inputs,
        password: 'err',
      }));
    }
  };

  // 비밀번호 확인
  const samePassword = (pw: string) => {
    if (!!!pw) return;

    if (inputs.password && inputs.password !== 'err') {
      if (inputs.password === pw) {
        onChangeMsg('passwdCheck', null);
        setInputs((inputs) => ({
          ...inputs,
          passwordCheck: pw,
        }));
      } else {
        onChangeMsg('passwdCheck', '일치하지 않습니다');
        setInputs((inputs) => ({
          ...inputs,
          passwordCheck: 'err',
        }));
      }
    } else {
      onChangeMsg('passwdCheck', '비밀번호를 먼저 입력해 주세요');
    }
  };

  // onChange (input)
  const onChangeInputs = (e: { target: HTMLInputElement }) => {
    const { name, value } = e.target;
    if (name === 'accountId') {
      regexId(value);
    } else if (name === 'password') {
      regexPassword(value);
    } else if (name === 'passwordCheck') {
      samePassword(value);
    } else {
      setInputs((inputs) => ({
        ...inputs,
        [name]: value,
      }));
    }
  };

  // onChange (select)
  const onChangeSelect = (name: string, selected: any) => {
    setInputs((inputs) => ({
      ...inputs,
      [name]: selected,
    }));
  };

  // 등록
  const insert = async () => {
    const postData = {
      ...inputs,
      phone: joinPhone(inputs.phone1, inputs.phone2, inputs.phone3),
      birthday: joinBirthday(inputs.year, inputs.month, inputs.day),
    };

    const { config } = await requestSecurePost(
      apiRoute.setup.employee.postEmployee,
      {},
      postData,
      getToken()!,
    );
    if (config.status >= 200 && config.status < 400) {
      alert('성공적으로 등록이 완료되었습니다.');
      close();
      reload();
    }
  };

  // 수정
  const update = async () => {
    const patchData = {
      ...inputs,
      phone: joinPhone(inputs.phone1, inputs.phone2, inputs.phone3),
      birthday: joinBirthday(inputs.year, inputs.month, inputs.day),
    };

    const { config } = await requestSecurePatch(
      apiRoute.setup.employee.patchEmployee,
      {},
      patchData,
      getToken()!,
    );
    if (config.status >= 200 && config.status < 400) {
      alert('성공적으로 수정이 완료되었습니다.');
      close();
      reload();
    }
  };

  const getCheckedData = async () => {
    const { config, data } = await requestSecureGet<{
      employee: EmployeeTypes;
    }>(apiRoute.setup.employee.getEmployee + idx, {}, getToken()!);
    if (config.status >= 200 && config.status < 400) {
      if (data.employee.birthday) {
      }
      setInputs(data.employee);
    }
  };

  useEffect(() => {
    if (idx) {
      getCheckedData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idx]);

  return (
    <EmployeeManage
      type={idx ? '수정' : '등록'}
      inputs={inputs}
      onChangeInputs={onChangeInputs}
      onChangeSelect={onChangeSelect}
      msg={msg}
      save={idx ? update : insert}
    />
  );
};

export default EmployeeManageContainer;
