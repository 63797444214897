import { Main, Split, Bottoms } from '@styles/components/ModalBodyStyle';
import * as S from '@styles/components/FormStyle';
import CSelect from '@components/Common/CSelect/CSelect';
import { PhoneplanTypes } from '@typedef/components/Telecom/Phoneplan/phoneplan.types';
import { TelecomTypes } from '@typedef/components/Telecom/telecom.types';
import { PublicSupportTypes } from '@typedef/components/Telecom/PublicSupport/publicSupport.types';
import { DeviceInfoTypes } from '@typedef/components/Device/device.info.types';

type Props = {
  type: string;
  inputs: Partial<PublicSupportTypes>;
  onChangeInputs: (e: { target: HTMLInputElement }) => void;
  onChangeSelect: <T extends {}>(name: string, selected: T) => void;
  telecoms: TelecomTypes[];
  deviceInfos: DeviceInfoTypes[];
  phonePlans: PhoneplanTypes[];
  save: () => Promise<void>;
};

const PublicSupportManage = ({
  type,
  inputs,
  onChangeInputs,
  onChangeSelect,
  telecoms,
  deviceInfos,
  phonePlans,
  save,
}: Props) => {
  return (
    <Main width={35}>
      <Split>
        <S.InputBox isRow>
          <p>
            통신사<span>*</span>
          </p>
          <CSelect<TelecomTypes>
            options={telecoms}
            value={(telecom: TelecomTypes) => telecom.name}
            label={(telecom: TelecomTypes) => telecom.name}
            defaultValue={telecoms.filter(
              (telecom) => telecom.name === inputs.telecomName,
            )}
            func={(selected: TelecomTypes) =>
              onChangeSelect('telecomName', selected.name)
            }
            placeholder='통신사'
          />
        </S.InputBox>
        <S.InputBox isRow>
          <p>
            모델명<span>*</span>
          </p>
          <CSelect<DeviceInfoTypes>
            options={deviceInfos}
            value={(deviceInfo: DeviceInfoTypes) =>
              deviceInfo.device.model_name
            }
            label={(deviceInfo: DeviceInfoTypes) =>
              deviceInfo.device.model_name
            }
            defaultValue={deviceInfos.filter(
              (deviceInfo) => deviceInfo.device.model_name === inputs.modelName,
            )}
            func={(selected: DeviceInfoTypes) =>
              onChangeSelect('modelName', selected.device.model_name)
            }
            placeholder='모델명'
          />
        </S.InputBox>
        <S.InputBox isRow>
          <p>
            요금제<span>*</span>
          </p>
          <CSelect<PhoneplanTypes>
            options={phonePlans}
            value={(phonePlan: PhoneplanTypes) => phonePlan.name}
            label={(phonePlan: PhoneplanTypes) => phonePlan.name}
            defaultValue={phonePlans.filter(
              (deviceInfo) => deviceInfo.name === inputs.phonePlanName,
            )}
            func={(selected: PhoneplanTypes) =>
              onChangeSelect('phonePlanName', selected.name)
            }
            placeholder='요금제'
          />
        </S.InputBox>
        <S.InputBox isRow>
          <p>
            공시지원금<span>*</span>
          </p>
          <S.MoneyInputs>
            <S.Input
              name='amount'
              onBlur={onChangeInputs}
              defaultValue={inputs.amount?.toLocaleString()}
              placeholder='숫자만 입력'
            />
          </S.MoneyInputs>
        </S.InputBox>
      </Split>
      <Bottoms>
        <button type='button' onClick={save}>
          공시지원금 {type}하기
        </button>
      </Bottoms>
    </Main>
  );
};

export default PublicSupportManage;
