import Login from '../Login';
import { useState, useEffect, useMemo } from 'react';
import { apiRoute, requestPost } from '@libs/api';
import { useCookies } from 'react-cookie';
import useToken from '@hooks/useToken';
import useUser from '@hooks/useUser';
import { LoginTypes } from '@typedef/components/Login/login.types';

const LoginContainer = () => {
  const { setToken, setRefreshToken } = useToken();
  const { setUser } = useUser();
  const [remember, setRemember] = useState<boolean>(false);
  const [cookies, setCookie, removeCookie] = useCookies(['rememberId']);
  const [inputs, setInputs] = useState({
    id: '',
    password: '',
  });
  const isRemember = useMemo(() => remember, [remember]);

  const onChangeInputs = (e: { target: HTMLInputElement }) => {
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  // 아이디저장
  const checkSaveId = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRemember(e.target.checked);
    if (e.target.checked) {
      setCookie('rememberId', inputs.id);
    } else {
      removeCookie('rememberId');
    }
  };

  const login = async () => {
    if (inputs.id && inputs.password) {
      const { config, data } = await requestPost<LoginTypes>(
        apiRoute.auth.login,
        {},
        {
          username: inputs.id,
          password: inputs.password,
        },
      );
      if (config.status >= 200 && config.status < 400) {
        setToken(data.access_token);
        setRefreshToken(data.refresh_token);
        setUser({
          name: data.name,
          role: data.role,
          shopId: data.shopId,
          shopName: data.shopName,
        });
        window.location.reload();
      } else {
        alert('아이디 또는 비밀번호를 잘못 입력했습니다.');
      }
    } else {
      alert('아이디 또는 비밀번호를 입력해 주세요.');
    }
  };

  useEffect(() => {
    if (cookies.rememberId) {
      inputs['id'] = cookies.rememberId;
      setRemember(true);
    }
  }, [cookies.rememberId, inputs]);

  return (
    <Login
      inputs={inputs}
      onChangeInputs={onChangeInputs}
      checkSaveId={checkSaveId}
      isRemember={isRemember}
      login={login}
    />
  );
};

export default LoginContainer;
