import ShopDeviceAllocate from '../components/ShopDeviceAllocate';
import { useState, useEffect } from 'react';
import { apiRoute, requestSecureGet, requestSecurePatch } from '@libs/api';
import useToken from '@hooks/useToken';
import { InventoryRequestTypes } from '@typedef/components/Inventory/Request/inventory.request.types';
import { ShopDeviceTypes } from '@typedef/components/Inventory/shop.device.types';

type Props = {
  checked: InventoryRequestTypes;
  close: () => void;
  reload: () => void;
};

// 재고요청관리 > 재고배정 (대리점)
const ShopDeviceAllocateContainer = ({ checked, close, reload }: Props) => {
  const { getToken } = useToken();
  const [allocableDevices, setAllocableDevices] = useState<ShopDeviceTypes[]>(
    [],
  );
  const [allocateIdx, setAllocateIdx] = useState<number>(0);

  const onClickAllocate = (device: ShopDeviceTypes) => {
    setAllocateIdx(device.idx);
  };

  // 배정
  const allocate = async () => {
    const { config } = await requestSecurePatch(
      apiRoute.shopDevice.request.postAllocate,
      {},
      {
        idx: checked.idx,
        shop_device_idx: allocateIdx,
      },
      getToken()!,
    );
    if (config.status >= 200 && config.status < 400) {
      alert('성공적으로 배정이 완료되었습니다.');
      close();
      reload();
    }
  };

  // 배정 가능한 재고 목록 조회
  const getAllocable = async () => {
    const { config, data } = await requestSecureGet<ShopDeviceTypes[]>(
      apiRoute.shopDevice.request.getAllocable +
        `?model_name=${checked.model_name}&color_name=${checked.color_name}&volume=${checked.volume}`,
      {},
      getToken()!,
    );
    if (config.status >= 200 && config.status < 400) {
      // 요청처와 보유처가 같지 않은 재고만
      const filtered = data.filter(
        (allocable) => allocable.location.idx !== checked.requestor.idx,
      );
      // 보유기간이 오래된 순서로 보여주기 위해
      setAllocableDevices(filtered.reverse());
    }
  };

  useEffect(() => {
    getAllocable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ShopDeviceAllocate
      checked={checked}
      allocableDevices={allocableDevices}
      allocateIdx={allocateIdx}
      onClickAllocate={onClickAllocate}
      allocate={allocate}
    />
  );
};

export default ShopDeviceAllocateContainer;
