import CustomerOpen from '../components/CustomerOpen';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { getCustomers, getTelecoms } from '@stories/Selector';
import useToken from '@hooks/useToken';
import { TelecomTypes } from '@typedef/components/Telecom/telecom.types';
import { OpenTypes } from '@typedef/components/Pay/Open/open.types';

type Props = {
  inputs: Partial<OpenTypes>;
  setInputs: React.Dispatch<React.SetStateAction<Partial<OpenTypes>>>;
  onChangeInputs: (e: { target: HTMLInputElement }) => void;
  onChangeSelect: <T extends {}>(name: string, selected: T) => void;
  splitPhone: {
    phone1: string;
    phone2: string;
    phone3: string;
  };
  splitOpenPhone: {
    openPhone1: string;
    openPhone2: string;
    openPhone3: string;
  };
};

const CustomerOpenContainer = ({
  inputs,
  setInputs,
  onChangeInputs,
  onChangeSelect,
  splitPhone,
  splitOpenPhone,
}: Props) => {
  const { getToken } = useToken();
  // 고객유형 (신규, 기존)
  const [customerType, setCustomerType] = useState<string>('NEW');
  const telecoms = useRecoilValue(getTelecoms(getToken()!));
  const customers = useRecoilValue(getCustomers(getToken()!));
  // 개통일자에서 사용
  let today = new Date();

  // onChange (customer)
  const onChangeCustomer = (e: { target: HTMLInputElement }) => {
    const { name, value } = e.target;
    setInputs((inputs) => ({
      ...inputs,
      customer: {
        ...inputs.customer,
        [name]: value,
      },
    }));
  };

  // onChnage (customer-telecom)
  const onChangeCustomerTelecom = (selected: TelecomTypes) => {
    setInputs((inputs) => ({
      ...inputs,
      customer: {
        ...inputs.customer,
        telecom: selected,
      },
    }));
  };

  return (
    <CustomerOpen
      customerType={customerType}
      setCustomerType={setCustomerType}
      inputs={inputs}
      onChangeInputs={onChangeInputs}
      onChangeCustomer={onChangeCustomer}
      onChangeSelect={onChangeSelect}
      onChangeCustomerTelecom={onChangeCustomerTelecom}
      telecoms={telecoms}
      customers={customers}
      today={today}
      splitPhone={splitPhone}
      splitOpenPhone={splitOpenPhone}
    />
  );
};

export default CustomerOpenContainer;
