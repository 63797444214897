import { apiRoute, requestSecureGet } from "@libs/api";
import { selectorFamily } from "recoil";
import { DeviceTypes } from "@typedef/components/Device/device.types";
import { TelecomTypes } from "@typedef/components/Telecom/telecom.types";
import { RelatedShopTypes } from "@typedef/components/Setup/Related/related.shop.types";
import { PhoneplanTypes } from "@typedef/components/Telecom/Phoneplan/phoneplan.types";
import { DeviceCategoryTypes } from "@typedef/components/Device/Category/device.category.types";
import { ShopDeviceTypes } from "@typedef/components/Inventory/shop.device.types";
import { CustomerTypes } from "@typedef/components/Customer/customer.types";
import { SubserviceTypes } from "@typedef/components/Telecom/Subservice/subservice.types";
import { InsuranceTypes } from "@typedef/components/Telecom/Insurance/insurance.types";
import { MakerTypes } from "@typedef/components/Device/Maker/maker.types";
import { OpenTypes } from "@typedef/components/Pay/Open/open.types";
import { BoardCategoryTypes } from "@typedef/components/Board/Category/board.category.types";
import { BoardTypes } from "@typedef/components/Board/board.types";
import { ScheduleTypes } from "@typedef/components/Schedule/schedule.types";
import { ColorTypes } from "@typedef/components/Device/Color/color.types";
import { DeviceInfoTypes } from "@typedef/components/Device/device.info.types";

// 업체(shop)
export const getShops = selectorFamily({
  key: "getShops",
  get: (token: string) => async () => {
    const { config, data } = await requestSecureGet(
      apiRoute.shop.getShops,
      {},
      token
    );
    if (config.status >= 200 && config.status < 400) {
      return data;
    } else {
      return [];
    }
  },
});

// 단말(device)
export const getDevices = selectorFamily({
  key: "getDevices",
  get: (token: string) => async () => {
    const { config, data } = await requestSecureGet<DeviceTypes[]>(
      apiRoute.device.getDevices,
      {},
      token
    );
    if (config.status >= 200 && config.status < 400) {
      return data.sort(function (a, b) {
        return a.pet_name < b.pet_name ? -1 : a.pet_name > b.pet_name ? 1 : 0;
      });
    } else {
      return [];
    }
  },
});

// 단말유형(device-category)
export const getDeviceCategories = selectorFamily({
  key: "getDeviceCategories",
  get: (token: string) => async () => {
    const { config, data } = await requestSecureGet<DeviceCategoryTypes[]>(
      apiRoute.device.category.getCategories,
      {},
      token
    );
    if (config.status >= 200 && config.status < 400) {
      const filtered = data.filter((data) => data.name !== "군교");
      return filtered;
    } else {
      return [];
    }
  },
});

// 단말색상(device-color)
export const getDeviceColors = selectorFamily({
  key: "getDeviceColors",
  get: (token: string) => async () => {
    const { config, data } = await requestSecureGet<ColorTypes[]>(
      apiRoute.device.color.getColors,
      {},
      token
    );
    if (config.status >= 200 && config.status < 400) {
      return data;
    } else {
      return [];
    }
  },
});

export const getDeviceInfos = selectorFamily({
  key: "getDeviceInfos",
  get: (token: string) => async () => {
    const { config, data } = await requestSecureGet<DeviceInfoTypes[]>(
      apiRoute.device.info.getInfos,
      {},
      token
    );
    if (config.status >= 200 && config.status < 400) {
      return data;
    } else {
      return [];
    }
  },
});
// 제조사(device-maker)
export const getDeviceMakers = selectorFamily({
  key: "getDeviceMakers",
  get: (token: string) => async () => {
    const { config, data } = await requestSecureGet<MakerTypes[]>(
      apiRoute.device.maker.getMakers,
      {},
      token
    );
    if (config.status >= 200 && config.status < 400) {
      return data;
    } else {
      return [];
    }
  },
});

// 시리즈
export const getDeviceSeries = selectorFamily({
  key: "getDeviceSeries",
  get: (token: string) => async () => {
    const { config, data } = await requestSecureGet<string[]>(
      apiRoute.device.series,
      {},
      token
    );
    if (config.status >= 200 && config.status < 400) {
      return data;
    } else {
      return [];
    }
  },
});

// 통신사(telecom)
export const getTelecoms = selectorFamily({
  key: "getTelecoms",
  get: (token: string) => async () => {
    const { config, data } = await requestSecureGet<{
      telecoms: TelecomTypes[];
    }>(apiRoute.telecom.getTelecoms, {}, token);
    if (config.status >= 200 && config.status < 400) {
      const filtered = data.telecoms.filter(
        (telecom) => telecom.name !== "군/교"
      );
      return filtered;
    } else {
      return [];
    }
  },
});

// 요금제(telecom-phoneplan)
export const getTelecomPhoneplan = selectorFamily({
  key: "getTelecomPhoneplan",
  get: (token: string) => async () => {
    const { config, data } = await requestSecureGet<{
      phonePlans: PhoneplanTypes[];
    }>(apiRoute.telecom.phoneplan.getPhoneplans, {}, token);
    if (config.status >= 200 && config.status < 400) {
      return data.phonePlans;
    } else {
      return [];
    }
  },
});

// 부가서비스(telecom-subservice)
export const getTelecomSubservice = selectorFamily({
  key: "getTelecomSubservice",
  get: (token: string) => async () => {
    const { config, data } = await requestSecureGet<{
      subServices: SubserviceTypes[];
    }>(apiRoute.telecom.subservice.getSubservices, {}, token);
    if (config.status >= 200 && config.status < 400) {
      return data.subServices;
    } else {
      return [];
    }
  },
});

// 보험(telecom-insurance)
export const getTelecomInsurance = selectorFamily({
  key: "getTelecomInsurance",
  get: (token: string) => async () => {
    const { config, data } = await requestSecureGet<{
      insurances: InsuranceTypes[];
    }>(apiRoute.telecom.insurance.getInsurances, {}, token);
    if (config.status >= 200 && config.status < 400) {
      return data.insurances;
    } else {
      return [];
    }
  },
});

// 재고(shopDeivce?status=in)
export const getShopDevices = selectorFamily({
  key: "getShopDevices",
  get: (token: string) => async () => {
    const { config, data } = await requestSecureGet<ShopDeviceTypes[]>(
      apiRoute.shopDevice.getDevices + "?status_type=in",
      {},
      token
    );
    if (config.status >= 200 && config.status < 400) {
      return data;
    } else {
      return [];
    }
  },
});

// 개통(open)
export const getOpens = selectorFamily({
  key: "getOpens",
  get: (token: string) => async () => {
    const { config, data } = await requestSecureGet<{
      opens: OpenTypes[];
    }>(apiRoute.pay.open.getOpens, {}, token);
    if (config.status >= 200 && config.status < 400) {
      return data.opens;
    } else {
      return [];
    }
  },
});

// 고객(customer)
export const getCustomers = selectorFamily({
  key: "getCustomers",
  get: (token: string) => async () => {
    const { config, data } = await requestSecureGet<{
      customers: CustomerTypes[];
    }>(apiRoute.customer.getCustomers, {}, token);
    if (config.status >= 200 && config.status < 400) {
      return data.customers;
    } else {
      return [];
    }
  },
});

// 거래처(setup-related)
export const getRelatedShops = selectorFamily({
  key: "getRelatedShops",
  get: (token: string) => async () => {
    const { config, data } = await requestSecureGet<{
      relatedShops: RelatedShopTypes[];
    }>(apiRoute.setup.related.getRelateds, {}, token);
    if (config.status >= 200 && config.status < 400) {
      return data.relatedShops;
    } else {
      return [];
    }
  },
});

// 일정
export const getSchedules = selectorFamily({
  key: "getSchedules",
  get: (token: string) => async () => {
    const { config, data } = await requestSecureGet<{
      schedules: ScheduleTypes[];
    }>(apiRoute.schedule.getSchedules, {}, token);
    if (config.status >= 200 && config.status < 400) {
      return data.schedules;
    } else {
      return [];
    }
  },
});

// 게시판(board-category)
export const getBoardCategories = selectorFamily({
  key: "getBoardCategories",
  get: (token: string) => async () => {
    const { config, data } = await requestSecureGet<{
      boardCategories: BoardCategoryTypes[];
    }>(apiRoute.board.category.getCategories, {}, token);
    if (config.status >= 200 && config.status < 400) {
      return data.boardCategories;
    } else {
      return [];
    }
  },
});

// 공지사항(board)
export const getNotices = selectorFamily({
  key: "getNotices",
  get: (token: string) => async () => {
    const { config, data } = await requestSecureGet<{ boards: BoardTypes[] }>(
      apiRoute.board.getBoards + `?category=1`,
      {},
      token
    );
    if (config.status >= 200 && config.status < 400) {
      return data.boards;
    } else {
      return [];
    }
  },
});
