import * as S from '../../styles/HelpViewStyle';
import Loading from '@components/Common/Loading/Loading';
import { BoardTypes } from '@typedef/components/Board/board.types';

type Props = {
  board: Partial<BoardTypes>;
  loading: boolean;
};
const NoticeDetail = ({ board, loading }: Props) => {
  return (
    <S.Main>
      {loading && <Loading />}
      <S.TopBtns>
        <h3>{board.title}</h3>
        <p>작성일&nbsp;{board.time}</p>
      </S.TopBtns>
      <S.NoticeContent>{board.content}</S.NoticeContent>
    </S.Main>
  );
};

export default NoticeDetail;
