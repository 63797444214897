import CustomerConsult from '../CustomerConsult';
import ConsulteManageContainer from './ConsulteManageContainer';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { apiRoute, requestSecureDelete, requestSecureGet } from '@libs/api';
import useToken from '@hooks/useToken';
import { useSetRecoilState } from 'recoil';
import { loading, modal, modalHeader } from '@stories/Atom';
import { TableColumnTypes } from '@typedef/components/Common/CTable/table.column.types';
import { ConsultTypes } from '@typedef/components/Customer/Consult/consult.types';

const CustomerConsultContainer = () => {
  const { getToken } = useToken();
  // 테이블 데이터
  const [tableData, setTableData] = useState<ConsultTypes[]>([]);
  const [checkedIdxes, setCheckedIdxes] = useState<number[]>([]);
  // 모달설정
  const setModal = useSetRecoilState(modal);
  const setModalHeader = useSetRecoilState(modalHeader);
  // 테이블 로딩
  const setLoading = useSetRecoilState(loading);
  const [reload, setReload] = useState<number>(0);

  // 상담 상세조회 및 수정
  const updateConsult = useCallback(
    (idx?: number) => {
      if (!!!idx && checkedIdxes.length === 0) {
        alert('수정할 상담을 선택해 주세요.');
        return;
      } else if (!!!idx && checkedIdxes.length > 1) {
        alert('수정할 상담을 하나만 선택해 주세요.');
        return;
      }
      setModalHeader('상담 상세조회');
      setModal(
        <ConsulteManageContainer
          idx={idx ?? checkedIdxes[0]}
          close={() => setModal(null)}
          reload={() => setReload((prev) => prev + 1)}
        />,
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [checkedIdxes],
  );

  // 체크박스
  const onChangeCheckbox = useCallback(
    (idx: number) => {
      if (checkedIdxes.includes(idx)) {
        setCheckedIdxes(
          checkedIdxes.filter((checkedIdx) => checkedIdx !== idx),
        );
        return;
      }
      setCheckedIdxes((idxes) => [...idxes, idx]);
    },
    [checkedIdxes],
  );

  // 테이블 컬럼
  const columns: TableColumnTypes[] = useMemo(
    () => [
      {
        Header: '',
        accessor: 'idx',
        disableSortBy: true,
        width: 70,
        Cell: ({ value }: any) => (
          <input
            type='checkbox'
            defaultChecked={checkedIdxes.includes(value)}
            onChange={() => onChangeCheckbox(value)}
          />
        ),
      },
      {
        Header: 'No',
        width: 70,
        Cell: ({ row }: any) => <>{row.index + 1}</>,
      },
      {
        Header: '색상명',
        accessor: 'name',
        disableSortBy: true,
        Cell: ({ row, value }: any) => (
          <div
            className='detail'
            onClick={() => updateConsult(row.original.idx)}>
            {value}
          </div>
        ),
      },
      {
        Header: '영어명',
        accessor: 'name_eng',
        disableSortBy: true,
      },
    ],
    [checkedIdxes, onChangeCheckbox, updateConsult],
  );

  // 상담 등록
  const insertConsult = () => {
    setModalHeader('상담 등록');
    setModal(
      <ConsulteManageContainer
        close={() => setModal(null)}
        reload={() => setReload((prev) => prev + 1)}
      />,
    );
  };

  // 상담 삭제
  const deleteConsult = async () => {
    if (checkedIdxes.length === 0) {
      alert('삭제할 상담을 선택해 주세요');
      return;
    } else if (
      window.confirm(
        `선택한 ${checkedIdxes.length}개의 상담을 삭제하시겠습니까?`,
      )
    ) {
      await Promise.all(
        checkedIdxes.map(
          (idx) =>
            new Promise((resolve, reject) => {
              requestSecureDelete(
                apiRoute.customer.consult.deleteConsult + idx,
                {},
                getToken()!,
              ).then((data) => {
                if (data.config.status >= 200 && data.config.status < 400) {
                  resolve(data);
                }
              });
            }),
        ),
      ).then(() => {
        alert('성공적으로 삭제가 완료되었습니다.');
        setReload((prev) => prev + 1);
      });
    }
  };

  const getTableData = async () => {
    const { config, data } = await requestSecureGet<{
      deviceReserves: ConsultTypes[];
    }>(apiRoute.customer.consult.getConsults, {}, getToken()!);
    if (config.status >= 200 && config.status < 400) {
      setTableData(data.deviceReserves);
      setLoading(false);
    } else {
      setTableData([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  return (
    <CustomerConsult
      columns={columns}
      tableData={tableData}
      insertConsult={insertConsult}
      updateConsult={updateConsult}
      deleteConsult={deleteConsult}
    />
  );
};

export default CustomerConsultContainer;
