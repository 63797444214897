import JoinTerms from '../JoinTerms';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const JoinTermsContainer = () => {
  const navigate = useNavigate();
  const [checkedInputs, setCheckedInputs] = useState<number>(0);

  const onChangeCheckbox = (checked: boolean) => {
    if (checked) {
      setCheckedInputs(checkedInputs + 1);
    } else {
      setCheckedInputs(checkedInputs - 1);
    }
  };

  const onClickPrev = () => {
    if (
      window.confirm('이 페이지를 벗어나면 작성된 내용은 저장되지 않습니다.')
    ) {
      navigate(-1);
    }
  };

  const onClickNext = () => {
    if (checkedInputs === 2) {
      navigate('/join');
    } else {
      alert('필수 항목에 동의해 주세요.');
      return;
    }
  };

  return (
    <JoinTerms
      onChangeCheckbox={onChangeCheckbox}
      onClickPrev={onClickPrev}
      onClickNext={onClickNext}
    />
  );
};

export default JoinTermsContainer;
