import * as S from '@styles/components/FormStyle';
import CSelect from '@components/Common/CSelect/CSelect';
import { ShopDeviceTypes } from '@typedef/components/Inventory/shop.device.types';
import { SelectDefaultTypes } from '@typedef/components/Common/CSelect/select.default.types';
import { OpenTypes } from '@typedef/components/Pay/Open/open.types';

type Props = {
  inputs: Partial<OpenTypes>;
  onChangeInputs: (e: { target: HTMLInputElement }) => void;
  onChangeSelect: <T extends {}>(name: string, selected: T) => void;
  shopDevices: ShopDeviceTypes[];
  onClickModelName: (selected: ShopDeviceTypes) => void;
  installmentPrice: number;
  installmentInterest: number;
  total: number;
};

const DeviceOpen = ({
  inputs,
  onChangeInputs,
  onChangeSelect,
  shopDevices,
  onClickModelName,
  installmentPrice,
  installmentInterest,
  total,
}: Props) => {
  return (
    <S.Grid grid={3}>
      <S.InputBox>
        <p>단말선택 (모델명, 일련번호)</p>
        <CSelect<ShopDeviceTypes>
          options={shopDevices}
          value={(shopDevice: ShopDeviceTypes) => shopDevice.idx}
          label={(shopDevice: ShopDeviceTypes) =>
            `${shopDevice.device_info.device.model_name || shopDevice.model_name}, ${shopDevice.serial_number}`
          }
          defaultValue={shopDevices.filter(
            (device) => device.serial_number === inputs.deviceSerialNo,
          )}
          func={(selected: ShopDeviceTypes) => onClickModelName(selected)}
          placeholder='단말선택'
        />
      </S.InputBox>
      <S.InputBox>
        <p>색상</p>
        <S.Input
          key={inputs.deviceSerialNo}
          defaultValue={inputs.deviceColor}
          placeholder='색상'
          disabled
        />
      </S.InputBox>
      <S.InputBox>
        <p>유심</p>
        <CSelect<SelectDefaultTypes>
          options={usims}
          value={(usim: SelectDefaultTypes) => usim.value}
          label={(usim: SelectDefaultTypes) => usim.name}
          defaultValue={usims.filter(
            (usim) => usim.value === inputs.openUsimType,
          )}
          func={(selected: SelectDefaultTypes) =>
            onChangeSelect('openUsimType', selected.value)
          }
          placeholder='유심'
        />
      </S.InputBox>
      <S.InputBox>
        <p>할부개월</p>
        <CSelect<SelectDefaultTypes>
          options={months}
          value={(month: SelectDefaultTypes) => month.value}
          label={(month: SelectDefaultTypes) => month.name}
          defaultValue={months.filter(
            (month) => month.value === inputs.installmentMonth,
          )}
          func={(selected: SelectDefaultTypes) =>
            onChangeSelect('installmentMonth', selected.value)
          }
          placeholder='할부개월'
        />
      </S.InputBox>
      <S.InputBox>
        <p>출고가</p>
        <S.MoneyInputs>
          <S.Input
            key={inputs.deviceSerialNo}
            defaultValue={inputs.devicePrice?.toLocaleString()}
            placeholder='출고가'
          />
        </S.MoneyInputs>
      </S.InputBox>
      <S.InputBox>
        <p>공시지원금</p>
        <S.MoneyInputs>
          <S.Input
            name='discountContract'
            onBlur={onChangeInputs}
            defaultValue={inputs.discountContract}
            placeholder='공시지원금'
            disabled={inputs.contractType !== 'DEVICE'}
          />
        </S.MoneyInputs>
      </S.InputBox>
      <S.InputBox>
        <p>기타 할인</p>
        <S.MoneyInputs>
          <S.Input
            name='discountDeviceEtc'
            onBlur={onChangeInputs}
            defaultValue={inputs.discountDeviceEtc}
            placeholder='기타 할인'
          />
        </S.MoneyInputs>
      </S.InputBox>
      <S.InputBox>
        <p>할부원금</p>
        <S.MoneyInputs>
          <S.Input value={installmentPrice} placeholder='할부원금' disabled />
        </S.MoneyInputs>
      </S.InputBox>
      <S.InputBox>
        <p>할부이자</p>
        <S.MoneyInputs>
          <S.Input
            value={installmentInterest}
            placeholder='할부이자'
            disabled
          />
        </S.MoneyInputs>
      </S.InputBox>
      <S.InputBox>
        <p>월 단말금</p>
        <S.MoneyInputs>
          <S.Input value={total} placeholder='월 단말금' disabled />
        </S.MoneyInputs>
      </S.InputBox>
    </S.Grid>
  );
};

export default DeviceOpen;

// 유심
const usims = [
  {
    name: '기존',
    value: 'EXIST',
  },
  {
    name: '선납',
    value: 'BEFORE',
  },
  {
    name: '후납',
    value: 'AFTER',
  },
];

// 할부개월
const months = [
  {
    name: '현금',
    value: 0,
  },
  {
    name: '6개월',
    value: 6,
  },
  {
    name: '12개월',
    value: 12,
  },
  {
    name: '18개월',
    value: 18,
  },
  {
    name: '24개월',
    value: 24,
  },
  {
    name: '30개월',
    value: 30,
  },
  {
    name: '36개월',
    value: 36,
  },
  {
    name: '48개월',
    value: 48,
  },
];
