import ShopDeviceInsert from "../components/ShopDeviceInsert";
import { useState, useEffect, useRef } from "react";
import { apiRoute, requestSecureGet, requestSecurePost } from "@libs/api";
import useToken from "@hooks/useToken";
import {
  ShopDeviceAddTypes,
  ShopDeviceInsertTypes,
} from "@typedef/components/Inventory/Put/shop.device.insert.types";
import { useRecoilValue } from "recoil";
import { getDeviceCategories, getTelecoms } from "@stories/Selector";

type Props = {
  close: () => void;
  reload: () => void;
};

const ShopDeviceInsertContainer = ({ close, reload }: Props) => {
  const { getToken } = useToken();
  const tabs: string[] = ["바코드", "수기(휴대폰)", "수기(기타)"];
  const [activeTab, setActiveTab] = useState<string>(tabs[0]);
  const [inputs, setInputs] = useState<ShopDeviceInsertTypes>({
    category_idx: 1,
    category_name: "정상",
    device_color: "",
    device_info_idx: 0,
    maker_nm: "",
    model_name: "",
    price: 0,
    serial_number: "",
    telecom_idx: 2,
    telecom_name: "KT",
    volume: "",
    itemId: 0,
  });
  // 입고 예정 목록
  const [addDevices, setAddDevices] = useState<ShopDeviceAddTypes[]>([]);
  const [checkedDevices, setCheckedDevices] = useState<ShopDeviceAddTypes[]>(
    []
  );
  const barcodeRef = useRef<HTMLInputElement>(null);
  // 용량 단위 (수기(기타)에서 사용)
  const [unit, setUnit] = useState<"MB" | "GB" | "TB">("GB");
  const telecoms = useRecoilValue(getTelecoms(getToken()!));
  const categories = useRecoilValue(getDeviceCategories(getToken()!));

  // onChange (input)
  const onChangeInputs = (e: {
    target: HTMLInputElement | HTMLTextAreaElement;
  }) => {
    const { name, value } = e.target;
    setInputs((inputs) => ({
      ...inputs,
      [name]: value,
    }));
  };

  // onChange (select)
  const onChangeSelect = (name: string, selected: any) => {
    // 모델명 변경 시
    if (name === "unit") {
      setUnit(selected as "MB" | "GB" | "TB");
      return;
    }
    setInputs((inputs) => ({
      ...inputs,
      [name]: selected,
    }));
  };

  // inputs, 바코드 검색창 초기화
  const resetInputs = () => {
    setInputs((inputs) => ({
      ...inputs,
      device_color: "",
      device_info_idx: 0,
      memo: "",
      model_name: "",
      price: 0,
      serial_number: "",
      volume: "",
    }));
    if (barcodeRef.current) {
      barcodeRef.current.value = "";
      barcodeRef.current.focus();
    }
  };

  // 입고 예정 목록에 추가
  const onClickAdd = async () => {
    // 입력 유효 확인
    if (
      !!!inputs.model_name ||
      inputs.model_name.replace(/^\s+|\s+$/g, "") === "" ||
      !!!inputs.serial_number ||
      inputs.serial_number.replace(/^\s+|\s+$/g, "") === "" ||
      !!!String(inputs.price)
    ) {
      alert("필수 입력란을 모두 입력해 주세요.");
      return;
    }

    if (activeTab === "수기(기타)") {
      if (
        !!!inputs.maker_nm ||
        inputs.maker_nm.replace(/^\s+|\s+$/g, "") === "" ||
        !!!inputs.price ||
        String(inputs.price).replace(/^\s+|\s+$/g, "") === ""
      ) {
        alert("필수 입력란을 모두 입력해 주세요.");
        return;
      }
    }

    if (activeTab === "수기(휴대폰)") {
      if (!!!inputs.device_color || !!!inputs.volume) {
        alert("필수 입력란을 모두 입력해 주세요.");
        return;
      }
    }

    // addDevices에 같은 단말이 추가되어있는지 확인
    if (addDevices) {
      if (
        addDevices.some(
          (item) =>
            item.serial_number === inputs.serial_number &&
            item.model_name === inputs.model_name
        )
      ) {
        alert("이미 추가된 단말입니다.");
        return;
      }
    }

    // 재고목록에 일련번호, 모델명이 같은 단말이 등록되어있는지 확인
    const { config, data } = await requestSecureGet<string>(
      apiRoute.shopDevice.validDevice +
        `?serial_number=${inputs.serial_number}&model_name=${inputs.model_name}`,
      {},
      getToken()!
    );
    if (config.status >= 200 && config.status < 400) {
      if (data === "존재합니다") {
        alert("이미 재고목록에 등록된 단말입니다.");
        resetInputs();
        return;
      }
    }

    let addDevice: ShopDeviceAddTypes = {
      category_idx: inputs.category_idx,
      category_name: inputs.category_name,
      device_color: inputs.device_color,
      memo: inputs.memo,
      model_name: inputs.model_name.toUpperCase(),
      serial_number: inputs.serial_number,
      price: +inputs.price.toString().replace(/[^0-9]/g, ""),
      volume: inputs.volume,
    };

    if (activeTab.includes("바코드")) {
      addDevice.telecom_idx = inputs.telecom_idx;
      addDevice.telecom_name = inputs.telecom_name;
    } else if (activeTab.includes("휴대폰")) {
      addDevice.telecom_idx = inputs.telecom_idx;
      addDevice.telecom_name = inputs.telecom_name;
      addDevice.device_info_idx = inputs.device_info_idx;
    } else {
      addDevice.maker_nm = inputs.maker_nm;
      addDevice.volume = inputs.volume ? inputs.volume + unit : "";
      addDevice.itemId = inputs.itemId ?? 0;
    }

    setAddDevices((addDevices) => [...addDevices, addDevice]);
    setCheckedDevices((addDevices) => [...addDevices, addDevice]);

    // inputs 초기화
    resetInputs();
  };

  // 입고 예정 목록 전체 선택 (체크박스)
  const onChangeAllCheckbox = (checked: boolean) => {
    if (checked) {
      setCheckedDevices(addDevices);
    } else {
      setCheckedDevices([]);
    }
  };

  // 입고 예정 목록 개별 선택 (체크박스)
  const onChangeCheckbox = (checked: boolean, device: ShopDeviceAddTypes) => {
    if (checked) {
      setCheckedDevices((checkedDevices) => [...checkedDevices, device]);
    } else {
      const filtered = checkedDevices.filter((checked) => checked !== device);
      setCheckedDevices(filtered);
    }
  };

  // 입고
  const insert = async () => {
    if (addDevices.length === 0) {
      alert("단말을 추가해 주세요.");
      return;
    }
    if (checkedDevices.length === 0) {
      alert("단말을 선택해 주세요.");
      return;
    }

    const { config } = await requestSecurePost(
      apiRoute.shopDevice.postDevice,
      {},
      { devices: checkedDevices },
      getToken()!
    );
    if (config.status >= 200 && config.status < 400) {
      alert("성공적으로 입고가 완료되었습니다.");
      close();
      reload();
    }
  };

  // tab 이동 시 inputs 초기화
  useEffect(() => {
    resetInputs();
  }, [activeTab]);

  return (
    <ShopDeviceInsert
      tabs={tabs}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      telecoms={telecoms}
      categories={categories}
      inputs={inputs}
      setInputs={setInputs}
      onChangeInputs={onChangeInputs}
      onChangeSelect={onChangeSelect}
      barcodeRef={barcodeRef}
      unit={unit}
      onClickAdd={onClickAdd}
      addDevices={addDevices}
      checkedDevices={checkedDevices}
      onChangeAllCheckbox={onChangeAllCheckbox}
      onChangeCheckbox={onChangeCheckbox}
      insert={insert}
    />
  );
};

export default ShopDeviceInsertContainer;
