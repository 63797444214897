import { Main, Split, Bottoms } from '@styles/components/ModalBodyStyle';
import * as S from '@styles/components/FormStyle';
import CSelect from '@components/Common/CSelect/CSelect';
import { temp, Temp } from '@components/temp';

const RelatedManage = () => {
  return (
    <Main width={35}>
      <Split>
        <S.InputBox isRow>
          <p>업체선택</p>
          <CSelect<Temp>
            options={temp}
            value={(temp: Temp) => temp.id}
            label={(temp: Temp) => temp.value}
            func={console.log('hi')}
            placeholder='업체'
          />
        </S.InputBox>
      </Split>
      <Bottoms>
        <button type='button'>선택한 업체 요청하기</button>
      </Bottoms>
    </Main>
  );
};

export default RelatedManage;
