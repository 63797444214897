import ClientHistory from "../components/ClientHistory";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import useToken from "@hooks/useToken";
import { useSetRecoilState } from "recoil";
import { loading } from "@stories/Atom";
import { apiRoute, requestSecureGet } from "@libs/api";
import { TableColumnTypes } from "@typedef/components/Common/CTable/table.column.types";
import { ClientHistoryTypes } from "@typedef/components/Client/client.history.types";
import { formatTimezone } from "@libs/commonFuncs";
import { ClientTypes } from "@typedef/components/Client/client.types";
import { ItemTypes } from "@typedef/components/Item/item.types";
import dayjs from "dayjs";
import { ClientSummaryTypes } from "@typedef/components/Client/client.summary.types";

export type ClientHistoryFilterTypes = {
  clientIds: string;
  endDate: string;
  itemIds: string;
  startDate: string;
};

const ClientHistoryContainer = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const { getToken } = useToken();
  const [summary, setSummary] = useState<ClientSummaryTypes>({
    statuses: [],
    totalInPrice: 0,
    totalOutPrice: 0,
  });
  const [tableData, setTableData] = useState<ClientHistoryTypes[]>([]);
  const [clients, setClients] = useState<ClientTypes[]>([]);
  const [items, setItems] = useState<ItemTypes[]>([]);
  const [filters, setFilters] = useState<Partial<ClientHistoryFilterTypes>>({});
  // 테이블 로딩
  const setLoading = useSetRecoilState(loading);

  const columns: TableColumnTypes<ClientHistoryTypes>[] = useMemo(
    () => [
      {
        Header: "No",
        width: 70,
        Cell: ({ row }) => <>{tableData.length - row.index}</>,
      },
      {
        Header: "출고일",
        accessor: (row) => formatTimezone(row.createdAt),
      },
      {
        Header: "거래처명",
        accessor: "clientName",
        disableSortBy: true,
      },
      {
        Header: "부서명",
        accessor: "departmentName",
      },
      {
        Header: "모델명",
        accessor: "modelName",
      },
      {
        Header: "일련번호",
        accessor: "serialNumber",
      },
      { Header: "입고가", accessor: (row) => row.inPrice.toLocaleString() },
      { Header: "출고가", accessor: (row) => row.outPrice.toLocaleString() },
    ],
    [tableData.length]
  );

  // 거래처, 품목 선택
  const onChangeFilter = (key: string, value: string, isClear: boolean) => {
    const params = Object.fromEntries(searchParams.entries());

    if (params[key]) {
      // %2C = 인코딩된 쉼표
      const split = params[key].split(",");

      if (isClear) {
        params[key] = split.filter((prev) => prev !== value).join(",");

        if (params[key].length === 0) {
          delete params[key];
        }
      } else {
        params[key] = [...split, value].join(",");
      }
    } else {
      params[key] = value;
    }

    setSearchParams(new URLSearchParams(params));
  };

  // 검색시작일, 검색종료일 선택
  const onChangeDate = (key: "startDate" | "endDate", value: Date | null) => {
    const params = Object.fromEntries(searchParams.entries());

    if (value === null) {
      delete params[key];
    } else {
      params[key] = dayjs(value).format("YYYY-MM-DD");
    }

    setSearchParams(new URLSearchParams(params));
  };

  // 거래이력 요약 조회
  const getSummaryData = async () => {
    const { config, data } = await requestSecureGet<ClientSummaryTypes>(
      apiRoute.client.getClientSummary + location.search,
      {},
      getToken()!
    );

    if (config.status >= 200 && config.status < 400) {
      setSummary(data);
    }
  };

  // 거래이력 리스트 조회
  const getTableData = async () => {
    const { config, data } = await requestSecureGet<ClientHistoryTypes[]>(
      apiRoute.client.getClientHistory + location.search,
      {},
      getToken()!
    );

    if (config.status >= 200 && config.status < 400) {
      setTableData(data);
      setLoading(false);
    } else {
      alert("거래처 조회에 실패하였습니다.");
      setLoading(false);
    }
  };

  // 거래처 리스트 조회
  const getClients = async () => {
    const { config, data } = await requestSecureGet<ClientTypes[]>(
      apiRoute.client.getClients,
      {},
      getToken()!
    );

    if (config.status >= 200 && config.status < 400) {
      setClients(data);
    }
  };

  // 품목 리스트 조회
  const getItems = async () => {
    const { config, data } = await requestSecureGet<ItemTypes[]>(
      apiRoute.item,
      {},
      getToken()!
    );
    if (config.status >= 200 && config.status < 400) {
      setItems(data);
    }
  };

  useEffect(() => {
    const params = Object.fromEntries(searchParams.entries());
    setFilters(params);

    console.log(params);

    getSummaryData();
    getTableData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    getClients();
    getItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ClientHistory
      columns={columns}
      summary={summary}
      tableData={tableData}
      clients={clients}
      items={items}
      filters={filters}
      onChangeFilter={onChangeFilter}
      onChangeDate={onChangeDate}
    />
  );
};

export default ClientHistoryContainer;
