import {useCallback, useEffect, useMemo, useState} from 'react';
import {apiRoute, requestSecureDelete, requestSecureGet, requestSecurePatch,} from '@libs/api';
import useToken from '@hooks/useToken';
import {TableColumnTypes} from '@typedef/components/Common/CTable/table.column.types';
import {useSetRecoilState} from 'recoil';
import {loading, modal, modalHeader} from '@stories/Atom';
import ShopDeviceTempOutContainer from './ShopDeviceTempOutContainer';
import CopyCell from '@components/Common/CTable/components/CopyCell';
import {ShopDeviceTypes} from '@typedef/components/Inventory/shop.device.types';
import {formatTimezone} from '@libs/commonFuncs';
import InventoryTempOut from "@components/Inventory/TempOut/InventoryTempOut";

// 재고관리 > 수리관리, 대여관리 (대리점)
export type TempOutTypes = "수리" | "대여";

type Props = {
    type: TempOutTypes
}
const InventoryTempOutContainer = ({type}: Props) => {
    const {getToken} = useToken();
    // 테이블 데이터
    const [tableData, setTableData] = useState<ShopDeviceTypes[]>([]);
    const [checkedIdxes, setCheckedIdxes] = useState<number[]>([]);
    // 모달 설정
    const setModal = useSetRecoilState(modal);
    const setModalHeader = useSetRecoilState(modalHeader);
    // 테이블 로딩
    const setLoading = useSetRecoilState(loading);
    const [reload, setReload] = useState<number>(0);

    // 체크박스
    const onChangeCheckbox = useCallback(
        (idx: number) => {
            if (checkedIdxes.includes(idx)) {
                setCheckedIdxes(
                    checkedIdxes.filter((checkedIdx) => checkedIdx !== idx),
                );
                return;
            }
            setCheckedIdxes((idxes) => [...idxes, idx]);
        },
        [checkedIdxes],
    );

    const columns: TableColumnTypes<ShopDeviceTypes>[] = useMemo(
        () => [
            {
                Header: '',
                accessor: 'idx',
                disableSortBy: true,
                width: 70,
                Cell: ({value}) => (
                    <input
                        type='checkbox'
                        defaultChecked={checkedIdxes.includes(value as number)}
                        onChange={() => onChangeCheckbox(value as number)}
                    />
                ),
            },
            {
                Header: 'No',
                width: 70,
                Cell: ({row}) => <>{tableData.length - row.index}</>,
            },
            {
                Header: `${type}일`,
                accessor: (originalRow) => formatTimezone(originalRow.out_date),
            },
            {
                Header: '통신/제조사',
                accessor: (originalRow) => {
                    const phone = originalRow.telecom.name;
                    const etc = originalRow.maker_nm;
                    return etc ?? phone;
                },
                disableSortBy: true,
                width: 100,
            },
            {
                Header: '모델명',
                accessor: (originalRow) => {
                    const deviceInfoModelName = originalRow.device_info.device.model_name;
                    const etcModelName = originalRow.model_name;
                    return deviceInfoModelName ?? etcModelName;
                },
                disableSortBy: true,
            },
            {
                Header: '색상',
                accessor: (originalRow) => {
                    const phone = originalRow.device_info.color.name;
                    const etc = originalRow.device_color;
                    return phone ?? etc;
                },
                disableSortBy: true,
            },
            {
                Header: '일련번호',
                accessor: 'serial_number',
                disableSortBy: true,
                Cell: ({value}) => <CopyCell value={value}/>,
            },
            {
                Header: '메모',
                accessor: 'memo',
                disableSortBy: true,
            },
        ],
        [checkedIdxes, onChangeCheckbox, tableData.length, type],
    );

    // 재고출고
    const release = () => {
        setModalHeader(`재고${type}`);
        setModal(
            <ShopDeviceTempOutContainer
                type={type}
                close={() => setModal(null)}
                reload={() => setReload((prev) => prev + 1)}
            />,
        );
    };

    // 재입고 (출고 취소)
    const cancelRelease = async () => {
        if (checkedIdxes.length === 0) {
            alert('재입고할 재고를 선택해 주세요.');
        } else if (
            window.confirm(
                `선택한 재고 ${checkedIdxes.length}개의 재고를 재입고하시겠습니까?`,
            )
        ) {
            const {config} = await requestSecurePatch(
                apiRoute.shopDevice.release.cancelRelease,
                {},
                {idxes: [...checkedIdxes]},
                getToken()!,
            );
            if (config.status >= 200 && config.status < 400) {
                alert('성공적으로 재입고 처리되었습니다.');
                setReload((prev) => prev + 1);
            }
        }
    };

    // 재고 삭제
    const deleteShopDevice = async () => {
        if (checkedIdxes.length === 0) {
            alert('삭제할 재고를 선택해 주세요.');
        } else if (
            window.confirm(`선택한 재고 ${checkedIdxes.length}개를 삭제하시겠습니까?`)
        ) {
            await Promise.all(
                checkedIdxes.map(
                    (idx) =>
                        new Promise((resolve, reject) => {
                            requestSecureDelete(
                                apiRoute.shopDevice.deleteDevice + idx,
                                {},
                                getToken()!,
                            ).then((data) => {
                                if (data.config.status >= 200 && data.config.status < 400) {
                                    resolve(data);
                                }
                            });
                        }),
                ),
            ).then(() => {
                alert('성공적으로 삭제가 완료되었습니다.');
                setReload((prev) => prev + 1);
            });
        }
    };

    const getTableData = useCallback(async () => {
        const {config, data} = await requestSecureGet<any>(
            apiRoute.shopDevice.getDevices + `?status_idx=${type === '수리' ? 8 : 9}`,
            {},
            getToken()!,
        );
        if (config.status >= 200 && config.status < 400) {
            setTableData(data);
            setLoading(false);
        }
    }, [getToken, setLoading, type]);

    useEffect(() => {
        setLoading(true);
        getTableData();
        setCheckedIdxes([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload]);

    return (
        <InventoryTempOut
            type={type}
            columns={columns}
            tableData={tableData}
            release={release}
            cancelRelease={cancelRelease}
            deleteShopDevice={deleteShopDevice}
        />
    );
};

export default InventoryTempOutContainer;
