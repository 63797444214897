import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const LNB = styled.section`
  padding: 10px 30px 0;

  h2 {
    margin-bottom: 10px;
  }

  /* @media ${({ theme }) => theme.media.tablet} {
    display: none;
  } */
`;

export const Navs = styled.nav`
  display: inline-flex;
  position: relative;
  max-width: 100%;

  .nav-indicator {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 6px;
    transition: 0.35s;
    height: 6px;
    z-index: 1;
    border-radius: 3px 3px 0 0;
    background-color: ${({ theme }) => theme.colors.brand500};
  }

  .nav-item:not(.is-active):hover:before {
    opacity: 1;
    bottom: 0;
  }
`;

export const Nav = styled(NavLink)`
  color: #c4c4c4;
  text-decoration: none;
  transition: 0.1s;
  position: relative;
  margin-right: 30px;
  padding-bottom: 10px;
  &::before {
    content: "";
    position: absolute;
    bottom: -6px;
    left: 0;
    width: 100%;
    height: 6px;
    background-color: #e9e9e9;
    border-radius: 3px 3px 0 0;
    opacity: 0;
    transition: 0.1s;
  }
  &[aria-current] {
    color: #191919;
    font-weight: 500;
  }
`;
