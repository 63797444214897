import { Main, Split, Bottoms } from '@styles/components/ModalBodyStyle';
import * as S from '@styles/components/FormStyle';
import CSelect from '@components/Common/CSelect/CSelect';
import { CustomerTypes } from '@typedef/components/Customer/customer.types';
import { TelecomTypes } from '@typedef/components/Telecom/telecom.types';
import Loading from '@components/Common/Loading/Loading';

type Props = {
  type: string;
  loading: boolean;
  inputs: Partial<CustomerTypes>;
  onChangeInputs: (e: {
    target: HTMLInputElement | HTMLTextAreaElement;
  }) => void;
  onChangeSelect: <T extends {}>(name: string, selected: T) => void;
  splitPhone: {
    phone1: string;
    phone2: string;
    phone3: string;
  };
  telecoms: TelecomTypes[];
  save: () => Promise<void>;
};

const CustomerManage = ({
  type,
  loading,
  inputs,
  onChangeInputs,
  onChangeSelect,
  splitPhone,
  telecoms,
  save,
}: Props) => {
  return (
    <Main width={35}>
      {loading && <Loading />}
      <Split>
        <S.InputBox isRow>
          <p>
            고객명<span>*</span>
          </p>
          <S.Input
            name='name'
            onBlur={onChangeInputs}
            defaultValue={inputs.name}
            placeholder='이름'
          />
        </S.InputBox>
        <S.InputBox isRow>
          <p>
            생년월일<span>*</span>
          </p>
          <S.TwoInputs>
            <S.Input
              name='birthday'
              onBlur={onChangeInputs}
              defaultValue={inputs.birthday}
              maxLength={6}
              placeholder='주민등록번호'
            />
            <S.Input
              name='genderNo'
              onBlur={onChangeInputs}
              defaultValue={inputs.genderNo}
              maxLength={1}
              placeholder='뒷자리 한글자'
            />
          </S.TwoInputs>
        </S.InputBox>
        <S.InputBox isRow>
          <p>
            관리번호<span>*</span>
          </p>
          <S.Input
            name='manageNo'
            onBlur={onChangeInputs}
            defaultValue={inputs.manageNo}
            placeholder='관리번호'
          />
        </S.InputBox>
        <S.InputBox isRow>
          <p>
            통신사<span>*</span>
          </p>
          <CSelect<TelecomTypes>
            options={telecoms}
            value={(telecom: TelecomTypes) => telecom.telecomId}
            label={(telecom: TelecomTypes) => telecom.name}
            defaultValue={telecoms.filter(
              (telecom) => telecom.telecomId === inputs.telecom?.telecomId,
            )}
            func={(selected: TelecomTypes) =>
              onChangeSelect<TelecomTypes>('telecom', selected)
            }
            placeholder='통신사'
          />
        </S.InputBox>
        <S.InputBox isRow>
          <p>
            휴대전화<span>*</span>
          </p>
          <S.ThreeInputs>
            <S.Input
              name='phone1'
              onBlur={onChangeInputs}
              defaultValue={splitPhone.phone1}
              maxLength={4}
            />
            <S.Input
              name='phone2'
              onBlur={onChangeInputs}
              defaultValue={splitPhone.phone2}
              maxLength={4}
            />
            <S.Input
              name='phone3'
              onBlur={onChangeInputs}
              defaultValue={splitPhone.phone3}
              maxLength={4}
            />
          </S.ThreeInputs>
        </S.InputBox>
        <S.InputBox isRow>
          <p>
            주소<span>*</span>
          </p>
          <S.AddressInputs>
            <S.Input
              name='address'
              onBlur={onChangeInputs}
              defaultValue={inputs.address}
              placeholder='주소'
            />
            <S.Input
              name='addressDetail'
              onBlur={onChangeInputs}
              defaultValue={inputs.addressDetail}
              placeholder='상세주소'
            />
          </S.AddressInputs>
        </S.InputBox>
        <S.InputBox isRow>
          <p>메모</p>
          <S.Textarea
            name='memo'
            onBlur={onChangeInputs}
            defaultValue={inputs.memo}
          />
        </S.InputBox>
      </Split>
      <Bottoms>
        <button
          type='button'
          onClick={save}
          disabled={
            !!!inputs.name ||
            !!!inputs.birthday ||
            !!!inputs.genderNo ||
            !!!inputs.telecom ||
            !!!splitPhone.phone1 ||
            !!!splitPhone.phone2 ||
            !!!inputs.address ||
            !!!inputs.addressDetail
          }>
          고객 {type}하기
        </button>
      </Bottoms>
    </Main>
  );
};

export default CustomerManage;
