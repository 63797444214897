import styled from 'styled-components';

const space = '1.5vw';

export const TabBox = styled.section`
  ${({ theme }) => theme.flex.row};
  align-items: center;
  justify-content: space-between;
  padding: calc(${space} / 2) ${space};
  border-bottom: 1px solid #eee;
`;

export const Tabs = styled.section`
  ${({ theme }) => theme.flex.row};

  .active {
    background-color: ${({ theme }) => theme.colors.brand900};
    color: #fff;
    font-weight: 500;
  }
`;

export const Tab = styled.button`
  width: fit-content;
  line-height: 30px;
  margin-left: 1vw;
  padding: 0 7px;
  background-color: ${({ theme }) => theme.colors.grey200};
  border-radius: 3px;
  cursor: pointer;

  &:first-child {
    margin-left: 0;
  }
`;

export const RadioBtns = styled.section`
  flex: 1;
  ${({ theme }) => theme.flex.row}

  .active {
    border-width: 2px;
    border-color: ${({ theme }) => theme.colors.brand500};
    color: ${({ theme }) => theme.colors.brand500};
    font-weight: 500;
    .icon {
      color: ${({ theme }) => theme.colors.brand500};
    }
  }
`;

export const RadioBtn = styled.button`
  height: 38px;
  flex: 1;
  ${({ theme }) => theme.flex.row}
  align-items: center;
  justify-content: center;
  margin-right: 0.5vw;
  border: 1px solid #ccc;
  border-radius: 3px;
  color: #777;
  font-size: 1rem;
  font-weight: 300;

  .icon {
    margin-right: 10px;
  }

  &:last-child {
    margin-right: 0;
  }
`;
