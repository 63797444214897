import GNB from "../GNB";
import { useState, useMemo } from "react";
import useUser from "@hooks/useUser";
import useToken from "@hooks/useToken";
import { GNBTypes } from "@typedef/components/Common/GNB/gnb.types";

// 제이솔루션(관리자)
const jsolNavs: GNBTypes[] = [
  {
    name: "업체관리",
    path: "/shops/shop",
  },
  {
    name: "단말관리",
    path: "/devices/device",
  },
  {
    name: "통신사관리",
    path: "/telecoms/telecom",
  },
  {
    name: "게시글관리",
    path: "/boards/category",
  },
];

// 사용자(대리점, 판매점)
const userNavs: GNBTypes[] = [
  {
    name: "재고관리",
    path: "/inventory/request",
  },
  {
    name: "정산관리",
    path: "/pay/open",
  },
  {
    name: "고객관리",
    path: "/customers/customer",
  },
  {
    name: "거래처관리",
    path: "/clients/client",
  },
  // {
  //   name: "일정",
  //   path: "/schedule",
  // },
];

const GNBContainer = () => {
  const { getUser, clearUser } = useUser();
  const { getToken, clearToken } = useToken();
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const role = useMemo(() => getUser().role, [getUser]);
  const name = useMemo(() => getUser().name, [getUser]);
  const shopName = useMemo(() => getUser().shopName, [getUser]);

  // 로그아웃
  const logout = () => {
    clearToken(getToken()!);
    clearUser();
    window.location.reload();
  };

  return (
    <GNB
      name={name}
      shopName={shopName}
      navs={role.includes("JSOL") ? jsolNavs : userNavs}
      showMenu={showMenu}
      setShowMenu={setShowMenu}
      logout={logout}
    />
  );
};

export default GNBContainer;
