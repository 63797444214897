import styled from 'styled-components';
import {
  Thead as FormThead,
  Tbody as FormTbody,
} from '@styles/components/FormStyle';
import { Bottoms } from '@styles/components/ModalBodyStyle';

export const Thead = styled(FormThead)`
  .small {
    flex: 0.3;
  }

  .category {
    flex: 1.5;
  }
`;

export const Tbody = styled(FormTbody)`
  .small {
    flex: 0.3;
  }

  input[type='text'] {
    width: 70%;
    height: 30px;
    border: 1px solid #ccc;
    border-radius: 3px;
    text-align: center;
  }

  .category {
    flex: 1.5;
    ${({ theme }) => theme.flex.row}

    input {
      margin-right: 0.25vw;
    }

    label {
      ${({ theme }) => theme.flex.row}
      align-items: center;
      justify-content: center;
      margin-right: 0.5vw;
    }
  }
`;

export const Bottom = styled(Bottoms)`
  button {
    align-self: flex-end;
  }
`;
