import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const GNB = styled.header`
  height: 70px;
  ${({ theme }) => theme.flex.row}
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  img {
    width: 100px;
    margin-top: 7px;
  }

  /* @media ${({ theme }) => theme.media.tablet} {
    display: none;
  } */
`;

export const Navs = styled.nav`
  ${({ theme }) => theme.flex.row}
  align-items: center;
`;

export const Nav = styled(NavLink)`
  margin-left: 30px;
  font-weight: 500;
`;

export const Profile = styled.button`
  position: relative;
  cursor: pointer;
  .name {
    ${({ theme }) => theme.flex.row}
    align-items: center;
    .icon {
      margin-left: 0.5vw;
    }
  }
`;

export const ProfileMenuBox = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding-top: 30px;
  z-index: 10;
`;

export const ProfileMenu = styled.div`
  width: 150px;
  ${({ theme }) => theme.flex.col}
  position: relative;
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 0.5vw;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

  a,
  button {
    width: 100%;
    ${({ theme }) => theme.flex.row}
    align-items: center;
    padding: 15px;
    font-weight: 500;

    &:hover {
      text-decoration: underline;
    }
  }

  .icon {
    margin-right: 1vw;
  }
`;
