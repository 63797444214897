import ShopDeviceRequest from '../components/ShopDeviceRequest';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { getDevices, getRelatedShops, getTelecoms } from '@stories/Selector';
import useToken from '@hooks/useToken';
import { DeviceInfoTypes } from '@typedef/components/Device/device.info.types';
import { ShopDeviceRequestTypes } from '@typedef/components/Inventory/Request/shop.device.request.types';
import { apiRoute, requestSecureGet, requestSecurePost } from '@libs/api';

type Props = {
  close: () => void;
  reload: () => void;
};

// 판매점 > 재고요청
const ShopDeviceRequestContainer = ({ close, reload }: Props) => {
  const { getToken } = useToken();
  // 수기입력 (true : select > input), 현재 사용 안함
  const [isHand, setIsHand] = useState<boolean>(false);
  const [inputs, setInputs] = useState<ShopDeviceRequestTypes>({
    telecom_name: 'KT',
    model_name: '',
    color_name: '',
    volume: '',
    count: 1,
    shop_device_idx: 0,
  });
  // 통신사 선택
  const telecoms = useRecoilValue(getTelecoms(getToken()!));
  // 모델명 선택
  const devices = useRecoilValue(getDevices(getToken()!));
  // 색상 선택
  const [colors, setColors] = useState<DeviceInfoTypes[]>([]);
  // 요청처 선택
  const relatedShops = useRecoilValue(getRelatedShops(getToken()!));
  // 요청 예정 목록
  const [addDevices, setAddDevices] = useState<ShopDeviceRequestTypes[]>([]);
  const [checkedIdxes, setCheckedIdxes] = useState<number[]>([]);
  const [receiver, setReceiver] = useState<number>(0);

  // onChange (input)
  const onChangeInputs = (e: { target: HTMLInputElement }) => {
    const { name, value } = e.target;
    setInputs((inputs) => ({
      ...inputs,
      [name]: value,
    }));
  };

  // 색상 조회
  const getColorByModelName = async (name: string) => {
    const { config, data } = await requestSecureGet<DeviceInfoTypes[]>(
      apiRoute.device.getDevicesByModelName + name,
      {},
      getToken()!,
    );
    if (config.status >= 200 && config.status < 400) {
      setColors(data);
    }
  };

  // onChange (select)
  const onChangeSelect = (name: string, selected: any) => {
    if (name === 'model_name') {
      // 선택한 모델명으로 색상 조회
      getColorByModelName(selected);

      // 기존에 선택한 색상 초기화
      setInputs((inputs) => ({
        ...inputs,
        color_name: '',
      }));
    }
    if (name === 'receiver') {
      setReceiver(selected);
      return;
    }
    setInputs((inputs) => ({
      ...inputs,
      [name]: selected,
    }));
  };

  // 요청 예정 목록에 추가
  const onClickAdd = () => {
    if (!!!inputs.model_name || !!!inputs.color_name) {
      alert('필수 입력란을 모두 입력해 주세요.');
      return;
    }

    const filtered = addDevices.filter(
      (device) => device.shop_device_idx === inputs.shop_device_idx,
    );

    // 같은 재고가 있는 경우 수량만 수정
    if (filtered.length) {
      let copyAddDevices = [...addDevices];
      const findIdx = addDevices.findIndex(
        (device) => device.shop_device_idx === inputs.shop_device_idx,
      );

      copyAddDevices[findIdx] = {
        ...copyAddDevices[findIdx],
        count: +filtered[0].count + +inputs.count,
      };

      setAddDevices(copyAddDevices);
      return;
    }

    setAddDevices(addDevices.concat(inputs));
    setCheckedIdxes(checkedIdxes.concat(inputs.shop_device_idx));
  };

  // 요청 예정 목록 전체 선택 (체크박스)
  const onChangeAllCheckbox = (checked: boolean) => {
    if (checked) {
      const idxes = addDevices.map((device) => device.shop_device_idx);
      setCheckedIdxes(idxes);
    } else {
      setCheckedIdxes([]);
    }
  };

  // 요청 예정 목록 개별 선택 (체크박스)
  const onChangeCheckbox = (checked: boolean, idx: number) => {
    if (checked) {
      setCheckedIdxes((checkedIdxes) => [...checkedIdxes, idx]);
    } else {
      const filtered = checkedIdxes.filter((checked) => checked !== idx);
      setCheckedIdxes(filtered);
    }
  };

  // 요청 예정 목록 수량 수정
  const onChangeCount = (idx: number, value: number) => {
    let copyAddDevices = [...addDevices];
    const findIdx = addDevices.findIndex(
      (device) => device.shop_device_idx === idx,
    );
    if (findIdx !== -1) {
      copyAddDevices[findIdx] = { ...copyAddDevices[findIdx], count: value };
      setAddDevices(copyAddDevices);
    }
  };

  // 선택한 재고 요청하기
  const request = async () => {
    if (!!!receiver) {
      alert('요청처를 선택해 주세요.');
      return;
    }

    const checkedDevices = addDevices.filter((device) =>
      checkedIdxes.some((idx) => idx === device.shop_device_idx),
    );

    let temp: ShopDeviceRequestTypes[] = [];

    // 수량만큼 객체 추가하여 요청
    checkedDevices.forEach((request) => {
      for (let i = 0; i < request.count; i++) {
        temp.push(request);
      }
    });

    const postData = {
      receiver_idx: receiver,
      requests: temp,
    };

    const { config } = await requestSecurePost(
      apiRoute.shopDevice.request.postRequest,
      {},
      postData,
      getToken()!,
    );

    if (config.status >= 200 && config.status < 400) {
      alert('성공적으로 요청이 완료되었습니다.');
      close();
      reload();
    } else {
    }
  };

  return (
    <ShopDeviceRequest
      isHand={isHand}
      setIsHand={setIsHand}
      telecoms={telecoms}
      devices={devices}
      colors={colors}
      inputs={inputs}
      onChangeInputs={onChangeInputs}
      onChangeSelect={onChangeSelect}
      onClickAdd={onClickAdd}
      addDevices={addDevices}
      checkedIdxes={checkedIdxes}
      onChangeAllCheckbox={onChangeAllCheckbox}
      onChangeCheckbox={onChangeCheckbox}
      onChangeCount={onChangeCount}
      relatedShops={relatedShops}
      request={request}
    />
  );
};

export default ShopDeviceRequestContainer;
