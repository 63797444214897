import styled from 'styled-components';

export const CDatePicker = styled.section`
  .react-datepicker {
    border: none;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

    input {
      font-size: 20px !important;
    }
  }
`;
