import * as S from "@styles/components/TableViewStyle";
import CTable from "@components/Common/CTable/CTable";
import { TableColumnTypes } from "@typedef/components/Common/CTable/table.column.types";
import { ClientTypes } from "@typedef/components/Client/client.types";

type Props = {
  columns: TableColumnTypes<ClientTypes>[];
  tableData: ClientTypes[];
  manage: (type: "등록" | "수정") => void;
  deleteClient: () => Promise<void>;
};

const Client = ({ columns, tableData, manage, deleteClient }: Props) => {
  return (
    <S.Main>
      <S.TopBtns>
        <h3>전체 {tableData.length}</h3>
        <S.Btns>
          <S.Btn onClick={() => manage("등록")} className="active">
            거래처등록
          </S.Btn>
          <S.Btn onClick={deleteClient}>삭제</S.Btn>
        </S.Btns>
      </S.TopBtns>
      <CTable columns={columns} tableData={tableData} />
    </S.Main>
  );
};

export default Client;
