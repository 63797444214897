import styled from "styled-components";

const space = "30px";
const halfSpace = "15px";
const radius = "7px";

export const CTableBox = styled.section`
  min-height: calc(100vh - 130px);
  ${({ theme }) => theme.flex.col}
  justify-content: space-between;
  padding: ${space};
  position: relative;

  /* @media ${({ theme }) => theme.media.tablet} {
    padding: 0;
  } */
`;

export const Table = styled.table`
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
`;

export const Thead = styled.thead`
  height: 40px;
  background-color: ${({ theme }) => theme.colors.brand200};
  font-size: 0.92rem;
  th {
    padding: 0 10px;
    font-weight: 500;
    text-align: left;
    &:first-child {
      padding-left: 20px;
      border-radius: ${radius} 0 0 ${radius};
    }
    &:last-child {
      border-radius: 0 ${radius} ${radius} 0;
    }
  }

  // 정렬
  .sorted {
    cursor: pointer;
    &::after {
      font-family: "FontAwesome";
      content: "\f0dc";
      margin-left: 10px;
    }
  }
  .down:after {
    content: "\f0d8";
  }
  .up:after {
    content: "\f0d7";
  }

  /* @media ${({ theme }) => theme.media.tablet} {
    display: none;
  } */
`;

export const Tbody = styled.tbody`
  font-size: 0.92rem;
  font-weight: 300;
  tr {
    border-bottom: 1px solid #eee;
  }

  td {
    padding: 20px 10px;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &:first-child {
      padding-left: 20px;
    }
  }

  .status {
    ${({ theme }) => theme.flex.row}
    align-items: center;
    font-weight: 500;
    &::before {
      font-family: "FontAwesome";
      font-size: 1.1rem;
      margin-right: 10px;
    }
  }

  .in {
    color: ${({ theme }) => theme.colors.brand500};
    &::before {
      content: "\f0a9";
    }
  }
  .out {
    color: ${({ theme }) => theme.colors.danger500};
    &::before {
      content: "\f0a8";
    }
  }
  .loss {
    &::before {
      content: "\f059";
    }
  }
  .date {
    font-weight: 500;
  }

  // 반응형
  /* @media ${({ theme }) => theme.media.tablet} {
    tr {
      display: block;
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
    }

    td {
      display: block;
      text-align: right;
      border-bottom: 1px dotted #ccc;
    }

    td:last-child {
      border-bottom: 0;
    }

    td:before {
      content: attr(data-label);
      float: left;
      font-weight: bold;
    }
  } */
`;

export const Pagination = styled.section`
  ${({ theme }) => theme.flex.row}
  align-items: center;
  justify-content: flex-end;
  margin-top: 30px;

  button {
    width: 30px;
    height: 30px;
    ${({ theme }) => theme.flex.row}
    align-items: center;
    justify-content: center;
    margin-left: ${halfSpace};
    border: 1px solid #eee;
    border-radius: 5px;
  }
  button:disabled,
  button[disabled] {
    color: #ddd;
  }
`;

// 페이지네이션 번호
export const Index = styled.section`
  margin-left: ${halfSpace};
  font-size: 0.92rem;
  font-weight: 600;
`;

// 테이블 데이터 없을 때 스타일
export const None = styled.div`
  flex: 1;
  ${({ theme }) => theme.flex.col}
  align-items: center;
  justify-content: center;
  font-weight: 400;

  .icon {
    margin-bottom: 10px;
    font-size: 1.1rem;
  }
`;

// 필터 버튼
export const FilterBtn = styled.button`
  height: 40px;
  ${({ theme }) => theme.flex.row}
  align-items: center;
  justify-content: center;
  margin-right: ${halfSpace};
  padding: ${halfSpace};
  background-color: ${({ theme }) => theme.colors.brand200};
  border-radius: ${radius};
  font-size: 0.85rem;
  font-weight: 600;

  p {
    margin-left: 10px;
  }

  .length {
    width: 20px;
    height: 20px;
    ${({ theme }) => theme.flex.row}
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.colors.brand500};
    border-radius: 50%;
    color: #fff;
  }
`;

export const FilterBox = styled.section<{ visible: boolean }>`
  position: absolute;
  top: 55px; // FilterBtn height + half space
  left: 0;
  padding: ${halfSpace};
  background-color: #fff;
  visibility: ${({ visible }) => (visible ? "visible" : "hidden")};
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: ${radius};
  z-index: 10;

  button {
    height: 35px;
    font-weight: 300;
    &::before {
      font-family: "FontAwesome";
      margin-right: ${halfSpace};
    }
  }

  /* @media ${({ theme }) => theme.media.tablet} {
    display: none;
  } */
`;

export const FilterBtns = styled.section`
  ${({ theme }) => theme.flex.row}
  align-items: center;
  justify-content: space-between;

  .active {
    padding: 0 ${space};
    background-color: ${({ theme }) => theme.colors.brand900};
    color: #fff;
    border-radius: ${radius};
  }
`;

export const SearchInput = styled.input`
  width: 300px;
  height: 40px;
  padding: 0 15px;
  background-color: ${({ theme }) => theme.colors.brand200};
  border-radius: ${radius};
  font-size: 0.85rem;

  &::placeholder {
    font-size: 0.85rem;
  }
`;

export const SearchValue = styled.section`
  height: 35px;
  ${({ theme }) => theme.flex.row}
  align-items: center;
  margin-right: 15px;
  padding: 0 10px;
  background-color: ${({ theme }) => theme.colors.brand500};
  color: #fff;
  border-radius: ${radius};
  font-size: 0.85rem;
  font-weight: 600;

  button {
    ${({ theme }) => theme.flex.row}
    align-items: center;
    justify-content: center;
    margin-left: ${halfSpace};
    color: #fff;
  }
`;

export const Col = styled.section`
  ${({ theme }) => theme.flex.col}
`;

export const Row = styled.section<{
  mb?: number;
}>`
  ${({ theme }) => theme.flex.row}
  position: relative;
  margin-bottom: ${({ mb }) => mb}px;
`;
