import { Main, Split } from '@styles/components/ModalBodyStyle';
import * as S from '@styles/components/FormStyle';
import { ShopTypes } from '@typedef/components/Shop/shop.types';

type Props = {
  inputs: Partial<ShopTypes>;
};

const RelatedDetail = ({ inputs }: Props) => {
  return (
    <Main width={35}>
      <Split>
        <S.InputBox isRow>
          <p>업체명</p>
          <S.Input value={inputs.name} disabled />
        </S.InputBox>
        <S.InputBox isRow>
          <p>업체구분</p>
          <S.Input
            value={inputs.role === 'AGENCY' ? '대리점' : '판매점'}
            disabled
          />
        </S.InputBox>
        <S.InputBox isRow>
          <p>대표전화</p>
          <S.Input value={inputs.phone} disabled />
        </S.InputBox>
        <S.InputBox isRow>
          <p>팩스</p>
          <S.Input value={inputs.fax} disabled />
        </S.InputBox>
        <S.InputBox isRow>
          <p>주소</p>
          <S.Input value={inputs.address} disabled />
        </S.InputBox>
        <S.InputBox isRow>
          <p>상세주소</p>
          <S.Input value={inputs.addressDetail} disabled />
        </S.InputBox>
        <S.InputBox isRow>
          <p>담당자명</p>
          <S.Input value={inputs.owner?.name} disabled />
        </S.InputBox>
        <S.InputBox isRow>
          <p>담당자 이메일</p>
          <S.Input value={inputs.email} disabled />
        </S.InputBox>
      </Split>
    </Main>
  );
};

export default RelatedDetail;
