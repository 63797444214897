import {
  Container,
  DateButton,
  FilterContainer,
  Filters,
  Header,
  SelectedFilter,
  SelectedFilterContainer,
  SummaryContianer,
} from "@styles/TableFilterStyle";
import * as S from "@styles/components/TableViewStyle";
import CTable from "@components/Common/CTable/CTable";
import { TableColumnTypes } from "@typedef/components/Common/CTable/table.column.types";
import { ClientHistoryTypes } from "@typedef/components/Client/client.history.types";
import { ko } from "date-fns/locale";
import Dropdown from "@components/Common/Dropdown/Dropdown";
import { ClientTypes } from "@typedef/components/Client/client.types";
import { ClientHistoryFilterTypes } from "../containers/ClientHistoryContainer";
import { ItemTypes } from "@typedef/components/Item/item.types";
import { ClientSummaryTypes } from "@typedef/components/Client/client.summary.types";

type Props = {
  columns: TableColumnTypes<ClientHistoryTypes>[];
  summary: ClientSummaryTypes;
  tableData: ClientHistoryTypes[];
  clients: ClientTypes[];
  items: ItemTypes[];
  filters: Partial<ClientHistoryFilterTypes>;
  onChangeFilter: (key: string, value: string, isClear: boolean) => void;
  onChangeDate: (key: "startDate" | "endDate", value: Date | null) => void;
};

const ClientHistory = ({
  columns,
  summary,
  tableData,
  clients,
  items,
  filters,
  onChangeFilter,
  onChangeDate,
}: Props) => {
  const stringSplitByComma = (string: string) => {
    return string.split(",");
  };

  return (
    <Container>
      <Header>
        <FilterContainer>
          <p className="title">거래이력 요약</p>
          <Filters>
            <div>
              <p>거래처</p>
              <Dropdown
                name={`거래처선택 (${
                  filters.clientIds
                    ? stringSplitByComma(filters.clientIds).length
                    : 0
                })`}
              >
                <ul>
                  {clients.map((client) => (
                    <li key={client.clientId}>
                      <label>
                        <input
                          type="checkbox"
                          checked={
                            filters.clientIds
                              ? stringSplitByComma(filters.clientIds).includes(
                                  client.clientId.toString()
                                )
                              : false
                          }
                          onChange={(e) => {
                            onChangeFilter(
                              "clientIds",
                              client.clientId.toString(),
                              !e.target.checked
                            );
                          }}
                        />
                        {client.name}
                      </label>
                    </li>
                  ))}
                </ul>
              </Dropdown>
            </div>
            <div>
              <p>품목</p>
              <Dropdown
                name={`품목선택 (${
                  filters.itemIds
                    ? stringSplitByComma(filters.itemIds).length
                    : 0
                })`}
              >
                <ul>
                  {items.map((item) => (
                    <li key={item.id}>
                      <label>
                        <input
                          type="checkbox"
                          checked={
                            filters.itemIds
                              ? stringSplitByComma(filters.itemIds).includes(
                                  item.id.toString()
                                )
                              : false
                          }
                          onChange={(e) => {
                            onChangeFilter(
                              "itemIds",
                              item.id.toString(),
                              !e.target.checked
                            );
                          }}
                        />
                        {item.name}
                      </label>
                    </li>
                  ))}
                </ul>
              </Dropdown>
            </div>
            <div>
              <p>검색시작일</p>
              <DateButton
                selected={
                  filters.startDate ? new Date(filters.startDate) : undefined
                }
                onChange={(date: Date | null) =>
                  onChangeDate("startDate", date)
                }
                locale={ko}
                dateFormat="yyyy-MM-dd"
                placeholderText="검색시작일"
              />
            </div>
            <div>
              <p>검색종료일</p>
              <DateButton
                selected={
                  filters.endDate ? new Date(filters.endDate) : undefined
                }
                onChange={(date: Date | null) => onChangeDate("endDate", date)}
                locale={ko}
                dateFormat="yyyy-MM-dd"
                placeholderText="검색종료일"
              />
            </div>
          </Filters>
          <SelectedFilterContainer>
            {filters.clientIds
              ? stringSplitByComma(filters.clientIds).map((selected) => (
                  <SelectedFilter>
                    <p>
                      {
                        clients.find(
                          (client) => client.clientId === Number(selected)
                        )?.name
                      }
                    </p>
                    <button
                      onClick={() =>
                        onChangeFilter("clientIds", selected, true)
                      }
                      className="close"
                    />
                  </SelectedFilter>
                ))
              : null}
            {filters.itemIds
              ? stringSplitByComma(filters.itemIds).map((selected) => (
                  <SelectedFilter>
                    <p>
                      {items.find((item) => item.id === Number(selected))?.name}
                    </p>
                    <button
                      onClick={() => onChangeFilter("itemIds", selected, true)}
                      className="close"
                    />
                  </SelectedFilter>
                ))
              : null}
            {filters.startDate ? (
              <SelectedFilter>
                <p>검색시작일 {filters.startDate}</p>
                <button
                  onClick={() => onChangeDate("startDate", null)}
                  className="close"
                />
              </SelectedFilter>
            ) : null}
            {filters.endDate ? (
              <SelectedFilter>
                <p>검색종료일 {filters.endDate}</p>
                <button
                  onClick={() => onChangeDate("endDate", null)}
                  className="close"
                />
              </SelectedFilter>
            ) : null}
          </SelectedFilterContainer>
        </FilterContainer>
        <SummaryContianer>
          <article>
            <p>거래수</p>
            <p className="value">{tableData.length}</p>
          </article>
          <article>
            <p>총매출</p>
            <p className="value">
              {summary.totalOutPrice?.toLocaleString() ?? 0}
            </p>
          </article>
          <article>
            <p>총매입</p>
            <p className="value">
              {summary.totalInPrice?.toLocaleString() ?? 0}
            </p>
          </article>
          <article>
            <p>마진</p>
            <p className="value">
              {(summary.totalOutPrice - summary.totalInPrice).toLocaleString()}
            </p>
          </article>
        </SummaryContianer>
      </Header>
      <S.Main>
        <S.TopBtns>
          <h3>거래이력</h3>
        </S.TopBtns>
        <CTable columns={columns} tableData={tableData} />
      </S.Main>
    </Container>
  );
};

export default ClientHistory;
