import BoardManage from '../components/BoardManage';
import { useState, useEffect } from 'react';
import {
  apiRoute,
  requestSecureGet,
  requestSecurePatch,
  requestSecurePost,
} from '@libs/api';
import useToken from '@hooks/useToken';
import { useRecoilValue } from 'recoil';
import { getBoardCategories } from '@stories/Selector';
import { BoardTypes } from '@typedef/components/Board/board.types';
// import Board from '../Board';

type Props = {
  idx?: number;
  close: () => void;
  reload: () => void;
};

const BoardManageContainer = ({ idx, close, reload }: Props) => {
  const { getToken } = useToken();
  const [inputs, setInputs] = useState<Partial<BoardTypes>>({});
  // true : 게시판 === '자주 찾는 질문'
  const [isFaQ, setIsFaQ] = useState<boolean>(false);
  const categories = useRecoilValue(getBoardCategories(getToken()!));

  const updateIsFaQ = (selected: number) => {
    const faq = categories.filter(
      (category) => category.name === '자주 찾는 질문',
    )[0];
    return selected === faq.boardCategoryId ? true : false;
  };

  // onChange (input)
  const onChangeInputs = (e: {
    target: HTMLInputElement | HTMLTextAreaElement;
  }) => {
    const { name, value } = e.target;
    setInputs((inputs) => ({
      ...inputs,
      [name]: value,
    }));
  };

  // onChange (select)
  const onChangeSelect = (name: string, selected: any) => {
    setInputs((inputs) => ({
      ...inputs,
      [name]: selected,
    }));

    // 게시판 선택 시 isFaQ 상태 변경
    if (name === 'boardCategoryId') {
      const boolean = updateIsFaQ(selected);
      setIsFaQ(boolean);
    }
  };

  const insert = async () => {
    const { config } = await requestSecurePost(
      apiRoute.board.postBoard,
      {},
      inputs,
      getToken()!,
    );
    if (config.status >= 200 && config.status < 400) {
      alert('성공적으로 등록이 완료되었습니다');
      close();
      reload();
    }
  };
  const update = async () => {
    const { config } = await requestSecurePatch(
      apiRoute.board.patchBoard,
      {},
      inputs,
      getToken()!,
    );
    if (config.status >= 200 && config.status < 400) {
      alert('성공적으로 수정이 완료되었습니다');
      close();
      reload();
    }
  };

  const getCheckedData = async (idx: number) => {
    const { config, data } = await requestSecureGet<{ board: BoardTypes }>(
      apiRoute.board.getBoard + idx,
      {},
      getToken()!,
    );
    if (config.status >= 200 && config.status < 400) {
      setInputs({
        ...data.board,
        boardCategoryId: data.board.boardCategory.boardCategoryId,
      });
      const boolean = updateIsFaQ(data.board.boardCategory.boardCategoryId);
      setIsFaQ(boolean);
    }
  };

  useEffect(() => {
    if (idx) {
      getCheckedData(idx);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idx]);

  return (
    <BoardManage
      type={idx ? '수정' : '등록'}
      inputs={inputs}
      onChangeInputs={onChangeInputs}
      onChangeSelect={onChangeSelect}
      categories={categories}
      isFaQ={isFaQ}
      save={idx ? update : insert}
    />
  );
};

export default BoardManageContainer;
