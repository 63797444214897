import styled from "styled-components";
import { useRef, useState } from "react";
import useOutsideClick from "@hooks/useOutsideClick";

type Props = {
  children: React.ReactNode;
  name: string;
};

const Dropdown = ({ children, name }: Props) => {
  const [isVisible, setIsVisible] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  useOutsideClick([
    {
      ref: dropdownRef,
      isVisible: isVisible,
      handler: () => setIsVisible(false),
    },
  ]);

  return (
    <Container ref={dropdownRef}>
      <Selected onClick={() => setIsVisible((prev) => !prev)}>
        <p>{name}</p>
        {/* <Icon name="Chevron" className="icon" /> */}
      </Selected>
      {isVisible && <OptionContainer>{children}</OptionContainer>}
    </Container>
  );
};

export default Dropdown;

const Container = styled.div`
  position: relative;

  .icon {
    width: 16px;
    transform: rotate(270deg);
  }
`;

const Selected = styled.button`
  padding: 6px 12px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
`;

const OptionContainer = styled.div`
  width: 200px;
  margin-top: 6px;
  padding: 6px;
  position: absolute;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  z-index: 99;

  ul {
    max-height: 200px;
    ${({ theme }) => theme.flex.col};
    align-items: flex-start;
    overflow-y: auto;

    li {
      line-height: 30px;
      cursor: pointer;

      label {
        ${({ theme }) => theme.flex.row};
        align-items: center;
        gap: 7.5px;
        cursor: pointer;
      }
    }
  }
`;
