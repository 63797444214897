import * as S from './styles/UserMainStyle';
import MainScheduleContainer from './containers/MainScheduleContainer';
import { SummaryTypes } from '@typedef/components/Main/summary.types';
import CResponsiveBar from '@components/Common/CChart/CResponsiveBar';
import { BarDatum } from '@nivo/bar';
import Loading from '@components/Common/Loading/Loading';
import { FiArrowRight, FiVolume2 } from 'react-icons/fi';
import { BoardTypes } from '@typedef/components/Board/board.types';
import { Link } from 'react-router-dom';

type Props = {
  name: string;
  shopName: string;
  loading: boolean;
  summaries: SummaryTypes[];
  memo: string;
  onChangeMemo: (memo: string) => Promise<void>;
  chartTitle: string;
  chartDate: {
    start: string;
    end: string;
  };
  chartData: BarDatum[];
  notice: BoardTypes;
};

const UserMain = ({
  name,
  shopName,
  loading,
  summaries,
  memo,
  onChangeMemo,
  chartTitle,
  chartDate,
  chartData,
  notice,
}: Props) => {
  return (
    <S.Main>
      {loading && <Loading />}
      <S.Article>
        <S.Price className='section'>
          <S.Col>
            <h2>반갑습니다, {name}님</h2>
            <p>
              이번달 <span>{shopName}</span>의 예상 마감량은 100개입니다.
            </p>
          </S.Col>
          <Link to='/setup/basic'>
            <button type='button'>
              요금정보 확인하기&nbsp;
              <FiArrowRight />
            </button>
          </Link>
        </S.Price>
        <S.Summaries className='section'>
          {summaries.map((summary, idx) => (
            <S.Summary key={`${idx}.${summary.name}`}>
              <S.SummaryIcon className='icon'>
                <summary.icon />
              </S.SummaryIcon>
              <S.Col>
                <h1>{summary.value}</h1>
                <p>{summary.name}</p>
              </S.Col>
            </S.Summary>
          ))}
        </S.Summaries>
        <S.Chart className='section'>
          <header>
            <h3>최근 7개월 {chartTitle}</h3>
            <p>
              {chartDate.start} ~ {chartDate.end}
            </p>
          </header>
          <CResponsiveBar data={chartData} keys={['value']} indexBy='month' />
        </S.Chart>
        <S.Memo className='section'>
          <textarea
            defaultValue={memo}
            onBlur={(e) => onChangeMemo(e.target.value)}
            placeholder='팀 메모 작성...'
          />
        </S.Memo>
        <S.Notices className='section'>
          {notice && (
            <S.Row align='center'>
              <FiVolume2 className='icon' />
              <Link to={`/help/notice/${notice.boardId}`}>
                <p>{notice.title}</p>
              </Link>
            </S.Row>
          )}
        </S.Notices>
      </S.Article>
      <MainScheduleContainer />
    </S.Main>
  );
};

export default UserMain;
