import dayjs from 'dayjs';

const today = new Date();
const format = 'YYYY-MM-DD';

// 시작일
export function getStartDate() {
  const date = new Date(
    today.getFullYear(),
    today.getMonth(),
    // 이번 달 1일로 설정
    1,
    // today.getDate(),
  );

  return `${dayjs(date).format(format)} 00:00:00`;
}

// 종료일
export function getEndDate() {
  return `${dayjs(today).format(format)} 23:59:59`;
}

// 시작일 업데이트
export function updateStartDate(name: '전월' | '전일' | '당일' | '당월') {
  let date = new Date();

  if (name === '전월') {
    date = new Date(today.getFullYear(), today.getMonth() - 1, 1);
  } else if (name === '전일') {
    date = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
  } else if (name === '당일') {
    date = new Date();
  } else {
    date = new Date(today.getFullYear(), today.getMonth(), 1);
  }

  return `${dayjs(date).format(format)} 00:00:00`;
}

// 종료일 업데이트
export function updateEndDate(name: '전월' | '전일' | '당일' | '당월') {
  let date = new Date();

  if (name === '전월') {
    date = new Date(today.getFullYear(), today.getMonth(), 0);
  } else if (name === '전일') {
    date = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
  } else if (name === '당일') {
    date = new Date();
  } else {
    date = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  }

  return `${dayjs(date).format(format)} 23:59:59`;
}
