import styled from "styled-components";
import {
  Header as header,
  ProcessBox as processbox,
  Process as process,
} from "@components/Join/Terms/styles/JoinTermsStyle";

type Props = {
  width?: string;
  fd?: "row" | "column";
  align?: string;
  justify?: string;
  mb?: string;
};

const space = "50px";
const mediaSpace = "25px";

export const Join = styled.div`
  width: 100%;
  height: 100vh;
`;

export const Header = styled(header)``;
export const ProcessBox = styled(processbox)``;
export const Process = styled(process)`
  .nav-indicator {
    width: 86.875px;
    height: 6px;
    position: absolute;
    left: calc(55.313px + 30px);
    bottom: 0;
    background-color: ${({ theme }) => theme.colors.brand500};
    border-radius: 3px 3px 0 0;
  }
`;

// Form
export const Contents = styled.section`
  margin: ${space};
  padding-bottom: ${space};
  border-top: 3px solid ${({ theme }) => theme.colors.brand500};

  @media ${({ theme }) => theme.media.tablet} {
    margin: ${space} ${mediaSpace};
    border-top: none;
  }
`;

// Label + InputBox
export const Content = styled.section`
  height: 100%;
  ${({ theme }) => theme.flex.row}
  border-bottom: 1px solid #eee;

  .row {
    ${({ theme }) => theme.flex.row}
  }

  @media ${({ theme }) => theme.media.tablet} {
    flex-direction: column;
    border: none;
  }
`;

export const Label = styled.div`
  width: 180px;
  ${({ theme }) => theme.flex.row}
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.brand100};
  font-weight: 500;

  // * 표시
  span {
    color: ${({ theme }) => theme.colors.brand500};
    font-weight: 500;
    margin-left: 7px;
  }

  @media ${({ theme }) => theme.media.tablet} {
    width: 100%;
    justify-content: flex-start;
    margin-bottom: calc(25px / 2); // mediaSpace / 2
    background: none;
  }
`;

export const InputBox = styled.section<Props>`
  flex: 1;
  ${({ theme }) => theme.flex.row}
  flex-direction: ${(props) => props.fd};
  align-items: ${(props) => props.align || "center"};
  justify-content: ${(props) => props.justify || "space-between"};
  padding: 20px 30px;

  .danger {
    color: ${({ theme }) => theme.colors.danger500};
    &::before {
      content: "\f071";
    }
  }

  // 업체구분 버튼 활성화 스타일
  .active {
    border-width: 2px;
    border-color: ${({ theme }) => theme.colors.brand500};
    color: ${({ theme }) => theme.colors.brand500};
    font-weight: 500;
    .icon {
      color: ${({ theme }) => theme.colors.brand500};
    }
  }

  // 우편번호 검색
  .address {
    height: 47px;
    background-color: ${({ theme }) => theme.colors.grey500};
    color: #191919;
    font-weight: 500;
  }

  @media ${({ theme }) => theme.media.tablet} {
    width: 100%;
    flex-direction: ${(props) => props.fd ?? "column"};
    align-items: flex-start;
    padding: 0 0 ${mediaSpace} 0;
  }
`;

export const SeveralInputs = styled.section<{ mb?: number }>`
  width: 350px;
  ${({ theme }) => theme.flex.row}
  margin-bottom: ${({ mb }) => mb && mb}px;

  input,
  button {
    width: 100%;
    flex: 1;
    margin-right: 10px;
    text-align: center;

    &:last-child {
      margin-right: 0;
    }
  }

  @media ${({ theme }) => theme.media.tablet} {
    width: 100%;
    input {
      flex: 1;
    }
  }
`;

export const Input = styled.input<Props>`
  width: ${(props) => props.width || "350px"};
  line-height: 45px;
  margin-bottom: ${(props) => props.mb || "0"};
  padding: 0 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;

  @media ${({ theme }) => theme.media.tablet} {
    width: 100%;
  }
`;

export const RadioBtn = styled.button`
  width: calc(340px / 2);
  height: 45px;
  ${({ theme }) => theme.flex.row}
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  color: #777;
  font-size: 1rem;
  font-weight: 300;

  .icon {
    margin-right: 10px;
  }

  &:last-child {
    margin-right: 0;
  }

  @media ${({ theme }) => theme.media.tablet} {
    flex: 1;
  }
`;

export const Msg = styled.p`
  font-size: 0.9rem;
  font-weight: 500;

  @media ${({ theme }) => theme.media.tablet} {
    margin-top: calc(25px / 2); // mediaSpace / 2
    font-size: 1rem;
  }

  &::before {
    font-family: "FontAwesome";
    margin-right: 0.5vw;
  }
`;

export const Btns = styled.section`
  ${({ theme }) => theme.flex.row}
  justify-content: center;
  margin-top: 30px;

  .active {
    background-color: ${({ theme }) => theme.colors.brand500};
    color: #fff;
    font-weight: 500;
  }
`;

export const Btn = styled.button`
  width: 120px;
  line-height: 50px;
  background-color: ${({ theme }) => theme.colors.grey500};
  border-radius: 5px;
  font-size: 1rem;

  &:last-child {
    margin-left: 30px;
  }
`;
