import { Bottoms, Main, Split } from '@styles/components/ModalBodyStyle';
import * as S from '@styles/components/FormStyle';
import {
  TypeBox,
  Types,
  Type,
  ButtonBox,
  AddBtn,
} from '../styles/InventoryPutStyle';
import BarcodeInsertContainer from '../containers/BarcodeInsertContainer';
import HandWrittenInsertContainer from '../containers/HandWrittenInsertContainer';
import { TelecomTypes } from '@typedef/components/Telecom/telecom.types';
import { DeviceCategoryTypes } from '@typedef/components/Device/Category/device.category.types';
import {
  ShopDeviceAddTypes,
  ShopDeviceInsertTypes,
} from '@typedef/components/Inventory/Put/shop.device.insert.types';
import { FiPlus } from 'react-icons/fi';

type Props = {
  tabs: string[];
  activeTab: string;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
  telecoms: TelecomTypes[];
  categories: DeviceCategoryTypes[];
  inputs: ShopDeviceInsertTypes;
  setInputs: React.Dispatch<React.SetStateAction<ShopDeviceInsertTypes>>;
  onChangeInputs: (e: {
    target: HTMLInputElement | HTMLTextAreaElement;
  }) => void;
  onChangeSelect: (name: string, selected: any) => void;
  barcodeRef: React.RefObject<HTMLInputElement>;
  unit: 'MB' | 'GB' | 'TB';
  onClickAdd: () => void;
  addDevices: ShopDeviceAddTypes[];
  checkedDevices: ShopDeviceAddTypes[];
  onChangeAllCheckbox: (checked: boolean) => void;
  onChangeCheckbox: (checked: boolean, device: ShopDeviceAddTypes) => void;
  insert: () => Promise<void>;
};

const ShopDeviceInsert = ({
  tabs,
  activeTab,
  setActiveTab,
  telecoms,
  categories,
  inputs,
  setInputs,
  onChangeInputs,
  onChangeSelect,
  barcodeRef,
  unit,
  onClickAdd,
  addDevices,
  checkedDevices,
  onChangeAllCheckbox,
  onChangeCheckbox,
  insert,
}: Props) => {
  return (
    <Main width={90}>
      <S.Row>
        <S.Col style={{ width: '33.333%' }}>
          <TypeBox>
            <h4>입고유형 선택</h4>
            <Types>
              {tabs.map((tab) => (
                <Type
                  key={tab}
                  onClick={() => setActiveTab(tab)}
                  className={tab === activeTab ? 'active' : ''}>
                  {tab}
                </Type>
              ))}
            </Types>
          </TypeBox>
          <Split width={100}>
            {activeTab.includes('바코드') ? (
              <BarcodeInsertContainer
                telecoms={telecoms}
                categories={categories}
                inputs={inputs}
                setInputs={setInputs}
                onChangeInputs={onChangeInputs}
                onChangeSelect={onChangeSelect}
                barcodeRef={barcodeRef}
              />
            ) : (
              <HandWrittenInsertContainer
                type={activeTab.includes('휴대폰') ? 'phone' : 'etc'}
                telecoms={telecoms}
                categories={categories}
                inputs={inputs}
                setInputs={setInputs}
                onChangeInputs={onChangeInputs}
                onChangeSelect={onChangeSelect}
                unit={unit}
              />
            )}
          </Split>
        </S.Col>
        <ButtonBox>
          <AddBtn type='button' onClick={onClickAdd}>
            <FiPlus />
          </AddBtn>
        </ButtonBox>
        <Split width={66.666} borderL>
          <S.SubTitle>
            <h3>입고 예정 목록&nbsp;({addDevices.length})</h3>
          </S.SubTitle>
          <S.Table>
            <S.Thead>
              <th className='small'>
                <input
                  type='checkbox'
                  key={checkedDevices.length}
                  defaultChecked={
                    addDevices.length !== 0 &&
                    addDevices.length === checkedDevices.length
                  }
                  onChange={(e) => onChangeAllCheckbox(e.target.checked)}
                />
              </th>
              <th>통신/제조사</th>
              <th>입고유형</th>
              <th>모델명</th>
              <th>색상</th>
              <th>일련번호</th>
              <th>용량</th>
              <th>입고가</th>
              <th>메모</th>
            </S.Thead>
            <S.Tbody>
              {addDevices.map((device) => (
                <tr key={device.serial_number}>
                  <td className='small'>
                    <input
                      type='checkbox'
                      defaultChecked={
                        checkedDevices.indexOf(device) ? true : false
                      }
                      onChange={(e) =>
                        onChangeCheckbox(e.target.checked, device)
                      }
                    />
                  </td>
                  <td>{device.maker_nm ?? device.telecom_name}</td>
                  <td>{device.category_name}</td>
                  <td>{device.model_name}</td>
                  <td>{device.device_color}</td>
                  <td>{device.serial_number}</td>
                  <td>{device.volume}</td>
                  <td>{device.price.toLocaleString()}</td>
                  <td>{device.memo}</td>
                </tr>
              ))}
            </S.Tbody>
          </S.Table>
        </Split>
      </S.Row>
      <Bottoms>
        <button
          type='button'
          onClick={insert}
          disabled={!!!checkedDevices.length}>
          선택한 재고 입고하기&nbsp;({checkedDevices.length})
        </button>
      </Bottoms>
    </Main>
  );
};

export default ShopDeviceInsert;
