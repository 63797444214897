import MainSchedule from "../components/MainSchedule";
import { useState } from "react";
import useToken from "@hooks/useToken";
import { useRecoilValue } from "recoil";
import { getSchedules } from "@stories/Selector";
import dayjs from "dayjs";
import { ScheduleTypes } from "@typedef/components/Schedule/schedule.types";
// import { faLariSign } from '@fortawesome/free-solid-svg-icons';

const MainScheduleContainer = () => {
  const { getToken } = useToken();
  const [clickedDate, setClickedDate] = useState<Date>(new Date());
  // 일정
  const schedules = useRecoilValue(getSchedules(getToken()!));
  const [filteredSchedules, setFilteredSchedules] = useState<ScheduleTypes[]>(
    []
  );

  // 선택한 날짜에 속하는 일정 필터링
  const filterSchedule = (selected: Date) => {
    function format(date: Date | string) {
      return dayjs(date).format("YYYY-MM-DD");
    }

    return schedules.filter((schedule) => {
      const { startDate, endDate } = schedule;
      // 시작일 = 입력받은 날짜
      // 종료일 = 입력받은 날짜
      // 시작일 <= 입력받은 날짜 <= 종료일
      return (
        format(startDate) === format(selected) ||
        format(endDate) === format(selected) ||
        (format(startDate) <= format(selected) &&
          format(selected) <= format(endDate))
      );
    });
  };

  // 캘린더에서 날짜 선택
  const onChangeDate = (date: Date | Date[]) => {
    if (Array.isArray(date)) {
      return;
    }
    setClickedDate(date);

    const filtered = filterSchedule(date);
    setFilteredSchedules(filtered);
  };

  return (
    <MainSchedule
      clickedDate={dayjs(clickedDate).format("YYYY년 MM월 DD일")}
      onChangeDate={onChangeDate}
      filteredSchedules={filteredSchedules}
    />
  );
};

export default MainScheduleContainer;
