import * as S from '@styles/components/TableViewStyle';
import CTable from '@components/Common/CTable/CTable';
import { TableColumnTypes } from '@typedef/components/Common/CTable/table.column.types';
import { ShopDeviceTypes } from '@typedef/components/Inventory/shop.device.types';

type Props = {
  columns: TableColumnTypes<ShopDeviceTypes>[];
  tableData: ShopDeviceTypes[];
  updateShopDevice: () => void;
  deleteShopDevice: () => Promise<void>;
  isAgency: boolean;
};

const Inventory = ({
  columns,
  tableData,
  updateShopDevice,
  deleteShopDevice,
  isAgency,
}: Props) => {
  return (
    <S.Main>
      <S.TopBtns>
        <h3>전체&nbsp;{tableData.length}</h3>
        {isAgency && (
          <S.Btns>
            <S.Btn type='button' onClick={() => updateShopDevice()}>
              수정
            </S.Btn>
            <S.Btn type='button' onClick={deleteShopDevice}>
              삭제
            </S.Btn>
          </S.Btns>
        )}
      </S.TopBtns>
      <CTable columns={columns} tableData={tableData} />
    </S.Main>
  );
};

export default Inventory;
