import * as S from '@styles/components/TableViewStyle';
import CTable from '@components/Common/CTable/CTable';
import { PhoneplanTypes } from '@typedef/components/Telecom/Phoneplan/phoneplan.types';
import { TableColumnTypes } from '@typedef/components/Common/CTable/table.column.types';

type Props = {
  columns: TableColumnTypes<PhoneplanTypes>[];
  tableData: PhoneplanTypes[];
  insertPhoneplan: () => void;
  updatePhoneplan: (idx?: number) => void;
  deletePhoneplan: () => Promise<void>;
};

const TelecomPhoneplan = ({
  columns,
  tableData,
  insertPhoneplan,
  updatePhoneplan,
  deletePhoneplan,
}: Props) => {
  return (
    <S.Main>
      <S.TopBtns>
        <h3>전체 {tableData.length}</h3>

        <S.Btns>
          <S.Btn type='button' onClick={insertPhoneplan} className='active'>
            요금제등록
          </S.Btn>
          <S.Btn type='button' onClick={() => updatePhoneplan()}>
            수정
          </S.Btn>
          <S.Btn type='button' onClick={deletePhoneplan}>
            삭제
          </S.Btn>
        </S.Btns>
      </S.TopBtns>
      <CTable columns={columns} tableData={tableData} />
    </S.Main>
  );
};

export default TelecomPhoneplan;
