import styled from 'styled-components';

export const Page = styled.div`
  /* width: 100vw; */
  min-height: 100vh;
  ${({ theme }) => theme.flex.col}
`;

export const Section = styled.section`
  ${({ theme }) => theme.flex.col}
  flex: 1;
  background-color: ${({ theme }) => theme.colors.brand200};

  @media ${({ theme }) => theme.media.tablet} {
    margin-top: 60px;
    padding: 15px;
  }
`;
