import Modal from '../Modal';
import { useSetRecoilState } from 'recoil';
import { modal, modalHeader } from '@stories/Atom';

const ModalContainer = () => {
  const setModal = useSetRecoilState(modal);
  const setModalHeader = useSetRecoilState(modalHeader);

  // 모달 닫기
  const close = (e: React.MouseEvent) => {
    if (e.currentTarget.id === 'background') {
      if (e.target !== e.currentTarget) return;
    }
    setModal(null);
    setModalHeader(null);
  };

  return <Modal close={close} />;
};

export default ModalContainer;
