import * as S from '@styles/components/TableViewStyle';
import CTable from '@components/Common/CTable/CTable';
import { TableColumnTypes } from '@typedef/components/Common/CTable/table.column.types';
import { SubserviceTypes } from '@typedef/components/Telecom/Subservice/subservice.types';

type Props = {
  columns: TableColumnTypes[];
  tableData: SubserviceTypes[];
  insertSubservice: () => void;
  updateSubservice: (idx?: number) => void;
  deleteSubservice: () => Promise<void>;
};

const TelecomSubservice = ({
  columns,
  tableData,
  insertSubservice,
  updateSubservice,
  deleteSubservice,
}: Props) => (
  <S.Main>
    <S.TopBtns>
      <h3>전체 {tableData.length}</h3>

      <S.Btns>
        <S.Btn type='button' onClick={insertSubservice} className='active'>
          부가서비스등록
        </S.Btn>
        <S.Btn type='button' onClick={() => updateSubservice()}>
          수정
        </S.Btn>
        <S.Btn type='button' onClick={deleteSubservice}>
          삭제
        </S.Btn>
      </S.Btns>
    </S.TopBtns>
    <CTable columns={columns} tableData={tableData} />
  </S.Main>
);

export default TelecomSubservice;
