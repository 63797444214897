import TelecomSubservice from "../TelecomSubservice";
import SubserviceManageContainer from "./SubserviceManageContainer";
import { useCallback, useEffect, useMemo, useState } from "react";
import { apiRoute, requestSecureDelete, requestSecureGet } from "@libs/api";
import useToken from "@hooks/useToken";
import { useSetRecoilState } from "recoil";
import { loading, modal, modalHeader } from "@stories/Atom";
import { SubserviceTypes } from "@typedef/components/Telecom/Subservice/subservice.types";
import { TableColumnTypes } from "@typedef/components/Common/CTable/table.column.types";

const TelecomSubserviceContainer = () => {
  const { getToken } = useToken();
  // 테이블 데이터
  const [tableData, setTableData] = useState<SubserviceTypes[]>([]);
  const [checkedIdxes, setCheckedIdxes] = useState<number[]>([]);
  // 모달설정
  const setModal = useSetRecoilState(modal);
  const setModalHeader = useSetRecoilState(modalHeader);
  // 테이블 로딩
  const setLoading = useSetRecoilState(loading);
  const [reload, setReload] = useState<number>(0);

  // 부가서비스 상세조회 및 수정
  const updateSubservice = useCallback(
    (idx?: number) => {
      if (!!!idx && checkedIdxes.length === 0) {
        alert("수정할 부가서비스를 선택해 주세요.");
        return;
      } else if (!!!idx && checkedIdxes.length > 1) {
        alert("수정할 부가서비스를 하나만 선택해 주세요.");
        return;
      }
      setModalHeader("부가서비스 상세조회");
      setModal(
        <SubserviceManageContainer
          idx={idx ?? checkedIdxes[0]}
          close={() => setModal(null)}
          reload={() => setReload((prev) => prev + 1)}
        />
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [checkedIdxes]
  );

  // 체크박스
  const onChangeCheckbox = useCallback(
    (idx: number) => {
      if (checkedIdxes.includes(idx)) {
        setCheckedIdxes(
          checkedIdxes.filter((checkedIdx) => checkedIdx !== idx)
        );
        return;
      }
      setCheckedIdxes((idxes) => [...idxes, idx]);
    },
    [checkedIdxes]
  );

  // 테이블 컬럼
  const columns: TableColumnTypes<SubserviceTypes>[] = useMemo(
    () => [
      {
        Header: "",
        accessor: "subServiceId",
        disableSortBy: true,
        width: 70,
        Cell: ({ value }: any) => (
          <input
            type="checkbox"
            defaultChecked={checkedIdxes.includes(value)}
            onChange={() => onChangeCheckbox(value)}
          />
        ),
      },
      {
        Header: "No",
        width: 70,
        Cell: ({ row }: any) => <>{row.index + 1}</>,
      },
      {
        Header: "통신사",
        accessor: "telecomId",
        width: 70,
      },
      {
        Header: "부가서비스명",
        accessor: "name",
        disableSortBy: true,
        Cell: ({ row, value }: any) => (
          <div
            className="detail"
            onClick={() => updateSubservice(row.original.subServiceId)}
          >
            {value}
          </div>
        ),
      },
      {
        Header: "내용",
        accessor: "explanation",
        disableSortBy: true,
      },
      {
        Header: "가격",
        accessor: "price",
        disableSortBy: true,
      },
      {
        Header: "포인트",
        accessor: "point",
        disableSortBy: true,
      },
    ],
    [checkedIdxes, onChangeCheckbox, updateSubservice]
  );

  // 부가서비스 등록
  const insertSubservice = () => {
    setModalHeader("부가서비스 등록");
    setModal(
      <SubserviceManageContainer
        close={() => setModal(null)}
        reload={() => setReload((prev) => prev + 1)}
      />
    );
  };

  // 부가서비스 삭제
  const deleteSubservice = async () => {
    if (checkedIdxes.length === 0) {
      alert("삭제할 부가서비스를 선택해 주세요");
      return;
    } else if (
      window.confirm(
        `선택한 ${checkedIdxes.length}개의 부가서비스를 삭제하시겠습니까?`
      )
    ) {
      await Promise.all(
        checkedIdxes.map(
          (idx) =>
            new Promise((resolve, reject) => {
              requestSecureDelete(
                apiRoute.telecom.subservice.deleteSubservice + idx,
                {},
                getToken()!
              ).then((data) => {
                if (data.config.status >= 200 && data.config.status < 400) {
                  resolve(data);
                }
              });
            })
        )
      ).then(() => {
        alert("성공적으로 삭제가 완료되었습니다.");
        setReload((prev) => prev + 1);
      });
    }
  };

  const getTableData = async () => {
    const { config, data } = await requestSecureGet<{
      subServices: SubserviceTypes[];
    }>(apiRoute.telecom.subservice.getSubservices, {}, getToken()!);
    if (config.status >= 200 && config.status < 400) {
      setTableData(data.subServices);
      setLoading(false);
    } else {
      setTableData([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  return (
    <TelecomSubservice
      columns={columns}
      tableData={tableData}
      insertSubservice={insertSubservice}
      updateSubservice={updateSubservice}
      deleteSubservice={deleteSubservice}
    />
  );
};

export default TelecomSubserviceContainer;
