import DepotManage from '../components/DepotManage';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  apiRoute,
  requestSecureGet,
  requestSecurePatch,
  requestSecurePost,
} from '@libs/api';
import useToken from '@hooks/useToken';
import { DepotTypes } from '@typedef/components/Setup/Depot/depot.types';

type Props = {
  idx?: number;
  close: () => void;
  reload: () => void;
};

const DepotManageContainer = ({ idx, close, reload }: Props) => {
  const { getToken } = useToken();
  const [inputs, setInputs] = useState<Partial<DepotTypes>>({});
  const [splitPhone, setSplitPhone] = useState({
    phone1: '010',
    phone2: '',
    phone3: '',
  });
  // 상세조회, 수정 모달에서만 사용, loading이 true인 경우 스피너 표출
  const [loading, setLoading] = useState<boolean>(false);
  const addressDetailRef = useRef<HTMLInputElement>(null);

  // onChange (input)
  const onChangeInputs = useCallback(
    (e: { target: HTMLInputElement | HTMLTextAreaElement }) => {
      const { name, value } = e.target;
      if (name.includes('phone')) {
        setSplitPhone((splitPhone) => ({
          ...splitPhone,
          [name]: value,
        }));
        return;
      }
      setInputs((inputs) => ({
        ...inputs,
        [name]: value,
      }));
    },
    [],
  );

  // 우편번호 검색
  const searchZonecode = () => {
    new window.daum.Postcode({
      oncomplete: function (data: any) {
        var extraRoadAddr = '';
        if (data.bname !== '' && /[동|로|가]$/g.test(data.bname)) {
          extraRoadAddr += data.bname;
        }
        if (data.buildingName !== '' && data.apartment === 'Y') {
          extraRoadAddr +=
            extraRoadAddr !== '' ? ', ' + data.buildingName : data.buildingName;
        }
        if (extraRoadAddr !== '') {
          extraRoadAddr = ' (' + extraRoadAddr + ')';
        }
        setInputs((inputs) => ({
          ...inputs,
          zipcode: data.zonecode,
          address: data.roadAddress,
        }));
        // 상세주소로 포커스
        addressDetailRef.current?.focus();
      },
    }).open();
  };

  // 등록
  const insert = async () => {
    const postData = {
      ...inputs,
    };

    if (splitPhone.phone1 && splitPhone.phone2) {
      let phone;
      phone = `${splitPhone.phone1}-${splitPhone.phone2}`;
      if (splitPhone.phone3) {
        phone += `-${splitPhone.phone3}`;
      }
      postData.phone = phone;
    }

    const { config } = await requestSecurePost(
      apiRoute.setup.depot.postDepot,
      {},
      postData,
      getToken()!,
    );
    if (config.status >= 200 && config.status < 400) {
      alert('성공적으로 등록이 완료되었습니다.');
      close();
      reload();
    }
  };

  // 수정
  const update = async () => {
    const patchData = {
      ...inputs,
    };

    if (splitPhone.phone1 && splitPhone.phone2) {
      let phone;
      phone = `${splitPhone.phone1}-${splitPhone.phone2}`;
      if (splitPhone.phone3) {
        phone += `-${splitPhone.phone3}`;
      }
      patchData.phone = phone;
    }

    console.log(patchData);

    const { config } = await requestSecurePatch(
      apiRoute.setup.depot.patchDepot,
      {},
      { ...patchData, phonePlanId: idx },
      getToken()!,
    );
    if (config.status >= 200 && config.status < 400) {
      alert('성공적으로 수정이 완료되었습니다.');
      close();
      reload();
    }
  };

  const getCheckedData = async () => {
    const { config, data } = await requestSecureGet<DepotTypes>(
      apiRoute.setup.depot.getDepot + idx,
      {},
      getToken()!,
    );
    if (config.status >= 200 && config.status < 400) {
      setInputs(data);
      // 입고처의 휴대전화 정보 나눠서 넣기
      if (data.phone) {
        const split = data.phone.split('-');
        setSplitPhone(() => ({
          phone1: split[0],
          phone2: split[1],
          phone3: split[2],
        }));
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (idx) {
      setLoading(true);
      getCheckedData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idx]);

  return (
    <DepotManage
      type={idx ? '수정' : '등록'}
      inputs={inputs}
      splitPhone={splitPhone}
      onChangeInputs={onChangeInputs}
      searchZonecode={searchZonecode}
      addressDetailRef={addressDetailRef}
      save={idx ? update : insert}
      loading={loading}
    />
  );
};

export default DepotManageContainer;
