import InventoryHistory from '../InventoryHistory';
import { useState, useEffect, useMemo } from 'react';
import { apiRoute, requestSecureGet } from '@libs/api';
import useToken from '@hooks/useToken';
import { TableColumnTypes } from '@typedef/components/Common/CTable/table.column.types';
import { ShopDeviceTypes } from '@typedef/components/Inventory/shop.device.types';
import { useSetRecoilState } from 'recoil';
import { loading } from '@stories/Atom';
import { formatTimezone } from '@libs/commonFuncs';
import { Row } from 'react-table';

// 재고관리 > 재고이력 (대리점, 판매점)
const InventoryHistoryContainer = () => {
  const { getToken } = useToken();
  // 테이블 데이터
  const [tableData, setTableData] = useState<ShopDeviceTypes[]>([]);
  // 테이블 로딩
  const setLoading = useSetRecoilState(loading);

  const columns: TableColumnTypes<ShopDeviceTypes>[] = useMemo(
    () => [
      {
        Header: 'No',
        width: 70,
        Cell: ({ row }: { row: Row<ShopDeviceTypes> }) => (
          <>{tableData.length - row.index}</>
        ),
      },
      {
        Header: '일시',
        accessor: (originalRow) =>
          formatTimezone(originalRow.createdAt, 'time'),
      },
      {
        Header: '작업',
        accessor: 'operation',
        disableSortBy: true,
        Cell: ({ value }: any) => <>{value}</>,
      },
      {
        Header: '통신사',
        accessor: 'telecom.name',
        width: 70,
        disableSortBy: true,
      },
      {
        Header: '모델명',
        accessor: 'model_name',
        disableSortBy: true,
      },
      {
        Header: '색상',
        accessor: 'device_color',
        disableSortBy: true,
      },
      {
        Header: '일련번호',
        accessor: 'serial_number',
        disableSortBy: true,
      },

      {
        Header: '보유처',
        accessor: 'location.name',
        disableSortBy: true,
      },
      {
        Header: '처리자',
        accessor: 'processor',
        disableSortBy: true,
      },
    ],
    [tableData.length],
  );

  const getTableData = async () => {
    const { config, data } = await requestSecureGet<ShopDeviceTypes[]>(
      apiRoute.shopDevice.history.getHistories,
      {},
      getToken()!,
    );
    if (config.status >= 200 && config.status < 400) {
      setTableData(data);
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <InventoryHistory columns={columns} tableData={tableData} />;
};

export default InventoryHistoryContainer;
