import { Main, Split, Bottoms } from '@styles/components/ModalBodyStyle';
import * as S from '@styles/components/FormStyle';
import CSelect from '@components/Common/CSelect/CSelect';
import { TelecomTypes } from '@typedef/components/Telecom/telecom.types';
import { DeviceTypes } from '@typedef/components/Device/device.types';
import { DeviceInfoTypes } from '@typedef/components/Device/device.info.types';
import { ShopDeviceRequestTypes } from '@typedef/components/Inventory/Request/shop.device.request.types';
import { RelatedShopTypes } from '@typedef/components/Setup/Related/related.shop.types';
import { FiPlus } from 'react-icons/fi';

type Props = {
  isHand: boolean;
  setIsHand: React.Dispatch<React.SetStateAction<boolean>>;
  telecoms: TelecomTypes[];
  devices: DeviceTypes[];
  colors: DeviceInfoTypes[];
  inputs: Partial<ShopDeviceRequestTypes>;
  onChangeInputs: (e: { target: HTMLInputElement }) => void;
  onChangeSelect: (name: string, selected: any) => void;
  onClickAdd: () => void;
  addDevices: ShopDeviceRequestTypes[];
  checkedIdxes: number[];
  onChangeAllCheckbox: (checked: boolean) => void;
  onChangeCheckbox: (checked: boolean, device: number) => void;
  onChangeCount: (idx: number, value: number) => void;
  relatedShops: RelatedShopTypes[];
  request: () => Promise<void>;
};

const ShopDeviceRequest = ({
  isHand,
  setIsHand,
  telecoms,
  devices,
  colors,
  inputs,
  onChangeInputs,
  onChangeSelect,
  onClickAdd,
  addDevices,
  checkedIdxes,
  onChangeAllCheckbox,
  onChangeCheckbox,
  onChangeCount,
  relatedShops,
  request,
}: Props) => {
  return (
    <Main width={90}>
      <S.Row>
        <Split>
          <S.SubTitle isRow justify='space-between'>
            <h3>단말정보 입력</h3>
            {/* <label>
              <input
                type='checkbox'
                defaultChecked={false}
                onClick={() => setIsHand(!isHand)}
              />
              수기입력
            </label> */}
          </S.SubTitle>
          <S.Grid>
            <S.InputBox>
              <p>
                통신사<span>*</span>
              </p>
              <CSelect<TelecomTypes>
                options={telecoms}
                value={(telecom: TelecomTypes) => telecom.telecomId}
                label={(telecom: TelecomTypes) => telecom.name}
                func={(selected: TelecomTypes) =>
                  onChangeSelect('telecom_name', selected.name)
                }
                defaultValue={telecoms.filter(
                  (telecom) => telecom.name === 'KT',
                )}
                placeholder='통신사'
              />
            </S.InputBox>
            <S.InputBox>
              <p>
                모델명<span>*</span>
              </p>
              {isHand ? (
                <S.Input
                  placeholder='ex) AIP13-512'
                  name='model_name'
                  onChange={onChangeInputs}
                />
              ) : (
                <CSelect<DeviceTypes>
                  options={devices}
                  value={(device: DeviceTypes) => device.idx}
                  label={(device: DeviceTypes) => device.model_name}
                  func={(selected: DeviceTypes) => {
                    onChangeSelect('model_name', selected.model_name);
                    onChangeSelect('volume', selected.volume);
                  }}
                  placeholder='모델명'
                />
              )}
            </S.InputBox>
            <S.InputBox>
              <p>
                색상<span>*</span>
              </p>
              {isHand ? (
                <S.Input
                  placeholder='ex) 시에라블루'
                  name='color_name'
                  onChange={onChangeInputs}
                />
              ) : (
                <CSelect<DeviceInfoTypes>
                  options={colors}
                  value={(device: DeviceInfoTypes) => device.idx}
                  label={(device: DeviceInfoTypes) => device.color.name}
                  defaultValue={colors.filter(
                    (device) => device.color.name === inputs.color_name,
                  )}
                  func={(selected: DeviceInfoTypes) => {
                    onChangeSelect('color_name', selected.color.name);
                    onChangeSelect('shop_device_idx', selected.idx);
                  }}
                  placeholder='색상'
                />
              )}
            </S.InputBox>
            <S.InputBox>
              <p>
                용량<span>*</span>
              </p>
              <S.Input
                name='volume'
                defaultValue={inputs.volume}
                placeholder='용량'
                disabled
              />
            </S.InputBox>
            <S.InputBox>
              <p>
                수량<span>*</span>
              </p>
              <S.Input
                type='number'
                name='count'
                onChange={onChangeInputs}
                defaultValue={1}
                min={1}
              />
            </S.InputBox>
          </S.Grid>
        </Split>
        <S.Middle>
          <button type='button' onClick={onClickAdd}>
            <FiPlus />
          </button>
        </S.Middle>
        <Split height={50} borderL>
          <S.SubTitle>
            <h3>요청 예정 목록&nbsp;({addDevices.length})</h3>
          </S.SubTitle>
          <S.Table>
            <S.Thead>
              <th className='small'>
                <input
                  type='checkbox'
                  key={checkedIdxes.length}
                  defaultChecked={
                    addDevices.length !== 0 &&
                    addDevices.length === checkedIdxes.length
                  }
                  onChange={(e) => onChangeAllCheckbox(e.target.checked)}
                />
              </th>
              <th className='small'>통신사</th>
              <th>모델명</th>
              <th>색상</th>
              <th>용량</th>
              <th className='small'>수량</th>
            </S.Thead>
            <S.Tbody>
              {addDevices.map((request, idx) => (
                <div key={`${request}${idx}`}>
                  <td className='small'>
                    <input
                      type='checkbox'
                      key={checkedIdxes.length}
                      defaultChecked={checkedIdxes.includes(
                        request.shop_device_idx,
                      )}
                      onChange={(e) =>
                        onChangeCheckbox(
                          e.target.checked,
                          request.shop_device_idx,
                        )
                      }
                    />
                  </td>
                  <td className='small'>{request.telecom_name}</td>
                  <td>{request.model_name}</td>
                  <td>{request.color_name}</td>
                  <td>{request.volume}</td>
                  <td className='small'>
                    <input
                      type='number'
                      key={request.count}
                      defaultValue={request.count}
                      onBlur={(e) =>
                        onChangeCount(request.shop_device_idx, +e.target.value)
                      }
                    />
                  </td>
                </div>
              ))}
            </S.Tbody>
          </S.Table>
        </Split>
      </S.Row>
      <Bottoms self='flex-end'>
        <S.InputBox mr={1} mb={0}>
          <p>
            요청처<span>*</span>
          </p>
          <CSelect<RelatedShopTypes>
            options={relatedShops}
            value={(related: RelatedShopTypes) => related.shop.shopId}
            label={(related: RelatedShopTypes) => related.shop.name}
            func={(selected: RelatedShopTypes) => {
              onChangeSelect('receiver', selected.shop.shopId);
            }}
            placeholder='요청처'
          />
        </S.InputBox>
        <button
          type='button'
          disabled={!!!checkedIdxes.length}
          onClick={request}>
          선택한 재고 요청하기&nbsp;({checkedIdxes.length})
        </button>
      </Bottoms>
    </Main>
  );
};

export default ShopDeviceRequest;
