import * as S from './styles/JoinStyle';
import { RefObject } from 'react';
import img from '@assets/image';
import { JoinInputsTypes } from '@typedef/components/Join/join.inputs.types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle as checkRegular } from '@fortawesome/fontawesome-free-regular';
import { faCheckCircle as checkSolid } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import Loading from '@components/Common/Loading/Loading';

type Props = {
  loading: boolean;
  inputs: Partial<JoinInputsTypes>;
  onChangeInputs: (e: React.FormEvent<HTMLInputElement>) => void;
  regexId: (id: string) => Promise<void>;
  idMsg: string | null;
  regexPassword: (pw: string) => void;
  pwMsg: string | null;
  samePassword: (pw: string) => void;
  pwCheckMsg: string | null;
  onClickRole: (role: string) => void;
  certifyBusinessNo: (businessNo: string) => Promise<void>;
  businessNoMsg: string | null;
  searchZonecode: () => void;
  addressRef: RefObject<HTMLInputElement>;
  onClickPrev: () => void;
  join: () => void;
};

const Join = ({
  inputs,
  onChangeInputs,
  regexId,
  idMsg,
  regexPassword,
  pwMsg,
  samePassword,
  pwCheckMsg,
  onClickRole,
  certifyBusinessNo,
  searchZonecode,
  businessNoMsg,
  addressRef,
  join,
  onClickPrev,
  loading,
}: Props) => {
  return (
    <S.Join>
      {loading && <Loading />}
      <S.Header>
        <img src={img.mcallLogo} alt='MCALL로고' />
      </S.Header>
      <S.ProcessBox>
        <h2>회원가입</h2>
        <S.Process>
          <p>약관동의</p>
          <p className='active'>업체정보 입력</p>
          <p>가입완료</p>
          <span className='nav-indicator'></span>
        </S.Process>
      </S.ProcessBox>
      <S.Contents>
        <S.Content>
          <S.Label>
            업체구분<span>*</span>
          </S.Label>
          <S.InputBox fd='row' justify='flex-start'>
            <S.RadioBtn
              type='button'
              onClick={() => onClickRole('agency')}
              className={inputs.role === 'agency' ? 'active' : ''}>
              <FontAwesomeIcon
                icon={
                  inputs.role === 'agency'
                    ? checkSolid
                    : (checkRegular as IconProp)
                }
                className='icon'
              />
              대리점
            </S.RadioBtn>
            <S.RadioBtn
              type='button'
              onClick={() => onClickRole('store')}
              className={inputs.role === 'store' ? 'active' : ''}>
              <FontAwesomeIcon
                icon={
                  inputs.role === 'store'
                    ? checkSolid
                    : (checkRegular as IconProp)
                }
                className='icon'
              />
              판매점
            </S.RadioBtn>
          </S.InputBox>
        </S.Content>
        <S.Content>
          <S.Label>
            아이디<span>*</span>
          </S.Label>
          <S.InputBox>
            <S.Input
              onBlur={(e) => regexId(e.target.value)}
              placeholder='아이디'
            />
            {idMsg && (
              <S.Msg className={idMsg.includes('사용 가능한') ? '' : 'danger'}>
                {idMsg}
              </S.Msg>
            )}
          </S.InputBox>
        </S.Content>
        <S.Content>
          <S.Label>
            비밀번호<span>*</span>
          </S.Label>
          <S.InputBox>
            <S.Input
              type='password'
              onBlur={(e) => regexPassword(e.target.value)}
              placeholder='8-20 영문, 숫자 포함'
            />
            {pwMsg && <S.Msg className='danger'>{pwMsg}</S.Msg>}
          </S.InputBox>
        </S.Content>
        <S.Content>
          <S.Label>
            비밀번호 확인<span>*</span>
          </S.Label>
          <S.InputBox>
            <S.Input
              type='password'
              onBlur={(e) => samePassword(e.target.value)}
              placeholder='비밀번호 확인'
            />
            {pwCheckMsg && (
              <S.Msg
                className={pwCheckMsg.includes('일치합니다') ? '' : 'danger'}>
                {pwCheckMsg}
              </S.Msg>
            )}
          </S.InputBox>
        </S.Content>
        <S.Content>
          <S.Label>
            업체명<span>*</span>
          </S.Label>
          <S.InputBox>
            <S.Input name='name' onBlur={onChangeInputs} placeholder='업체명' />
          </S.InputBox>
        </S.Content>
        <S.Content>
          <S.Label>
            사업자등록번호<span>*</span>
          </S.Label>
          <S.InputBox>
            <S.Input
              name='business_no'
              onBlur={(e) => certifyBusinessNo(e.target.value)}
              maxLength={10}
              placeholder="'-' 없이 숫자만 입력"
            />
            {businessNoMsg && <S.Msg className='danger'>{businessNoMsg}</S.Msg>}
          </S.InputBox>
        </S.Content>
        <S.Content>
          <S.Label>
            대표전화<span>*</span>
          </S.Label>
          <S.InputBox>
            <S.SeveralInputs>
              <S.Input name='call1' onBlur={onChangeInputs} max={4} />
              <S.Input name='call2' onBlur={onChangeInputs} max={4} />
              <S.Input name='call3' onBlur={onChangeInputs} max={4} />
            </S.SeveralInputs>
          </S.InputBox>
        </S.Content>
        <S.Content>
          <S.Label>팩스</S.Label>
          <S.InputBox>
            <S.SeveralInputs>
              <S.Input name='fax1' onBlur={onChangeInputs} max={4} />
              <S.Input name='fax2' onBlur={onChangeInputs} max={4} />
              <S.Input name='fax3' onBlur={onChangeInputs} max={4} />
            </S.SeveralInputs>
          </S.InputBox>
        </S.Content>
        <S.Content>
          <S.Label>
            주소<span>*</span>
          </S.Label>
          <S.InputBox fd='column' align='flex-start'>
            <S.SeveralInputs mb={20}>
              <S.Input
                name='zonecode'
                onBlur={onChangeInputs}
                defaultValue={inputs.zonecode}
                key={inputs.zonecode}
                placeholder='우편번호'
                disabled
              />
              <S.RadioBtn
                type='button'
                onClick={searchZonecode}
                className='address'>
                우편번호 검색
              </S.RadioBtn>
            </S.SeveralInputs>
            <S.Input
              name='address'
              onBlur={onChangeInputs}
              defaultValue={inputs.address}
              key={inputs.address}
              placeholder='주소'
              mb='20px'
            />
            <S.Input
              name='address_detail'
              onBlur={onChangeInputs}
              ref={addressRef}
              placeholder='상세주소'
            />
          </S.InputBox>
        </S.Content>
        <S.Content>
          <S.Label>
            담당자명<span>*</span>
          </S.Label>
          <S.InputBox>
            <S.Input
              name='manager_name'
              onBlur={onChangeInputs}
              placeholder='담당자명'
            />
          </S.InputBox>
        </S.Content>
        <S.Content>
          <S.Label>
            담당자 연락처<span>*</span>
          </S.Label>
          <S.InputBox>
            <S.SeveralInputs>
              <S.Input
                name='phone1'
                defaultValue='010'
                onBlur={onChangeInputs}
                max={4}
              />
              <S.Input name='phone2' onBlur={onChangeInputs} max={4} />
              <S.Input name='phone3' onBlur={onChangeInputs} max={4} />
            </S.SeveralInputs>
          </S.InputBox>
        </S.Content>
        <S.Content>
          <S.Label>
            담당자 이메일<span>*</span>
          </S.Label>
          <S.InputBox>
            <S.Input
              name='manager_email'
              onBlur={onChangeInputs}
              placeholder='담당자 이메일'
            />
          </S.InputBox>
        </S.Content>
        <S.Btns>
          <S.Btn type='button' onClick={onClickPrev}>
            취소
          </S.Btn>
          <S.Btn type='button' onClick={join} className='active'>
            회원가입
          </S.Btn>
        </S.Btns>
      </S.Contents>
    </S.Join>
  );
};

export default Join;
