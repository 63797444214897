import TelecomManage from "../components/TelecomManage";
import { useState, useEffect } from "react";
import {
  apiRoute,
  requestSecureGet,
  requestSecurePatch,
  requestSecurePost,
} from "@libs/api";
import useToken from "@hooks/useToken";
import { TelecomTypes } from "@typedef/components/Telecom/telecom.types";

type Props = {
  idx?: number;
  close: () => void;
  reload: () => void;
};

// 통신사관리 > 통신사관리 관리 모달
const TelecomManageContainer = ({ idx, close, reload }: Props) => {
  const { getToken } = useToken();
  const [inputs, setInputs] = useState<string>("");

  // 등록
  const insert = async () => {
    const { config } = await requestSecurePost(
      apiRoute.telecom.postTelecom,
      {},
      { name: inputs },
      getToken()!
    );
    if (config.status >= 200 && config.status < 400) {
      alert("성공적으로 등록이 완료되었습니다");
      close();
      reload();
    }
  };

  // 수정
  const update = async () => {
    const { config } = await requestSecurePatch(
      apiRoute.telecom.patchTelecom,
      {},
      { name: inputs },
      getToken()!
    );
    if (config.status >= 200 && config.status < 400) {
      alert("성공적으로 수정이 완료되었습니다");
      close();
      reload();
    }
  };

  const getCheckedData = async (idx: number) => {
    const { config, data } = await requestSecureGet<{ telecom: TelecomTypes }>(
      apiRoute.telecom.getTelecom + idx,
      {},
      getToken()!
    );
    if (config.status >= 200 && config.status < 400) {
      setInputs(data.telecom.name);
    }
  };

  useEffect(() => {
    if (idx) {
      getCheckedData(idx);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idx]);

  return (
    <TelecomManage
      inputs={inputs}
      setInputs={setInputs}
      save={idx ? update : insert}
    />
  );
};

export default TelecomManageContainer;
