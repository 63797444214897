import { Main, Split, Bottoms } from "@styles/components/ModalBodyStyle";
import * as S from "@styles/components/FormStyle";
import CSelect from "@components/Common/CSelect/CSelect";
import { PhoneplanTypes } from "@typedef/components/Telecom/Phoneplan/phoneplan.types";
import { TelecomTypes } from "@typedef/components/Telecom/telecom.types";

type Props = {
  type: string;
  inputs: Partial<PhoneplanTypes>;
  onChangeInputs: (e: { target: HTMLInputElement }) => void;
  onChangeSelect: <T extends {}>(name: string, selected: T) => void;
  telecoms: TelecomTypes[];
  save: () => Promise<void>;
};

const PhoneplanManage = ({
  type,
  inputs,
  onChangeInputs,
  onChangeSelect,
  telecoms,
  save,
}: Props) => {
  return (
    <Main width={35}>
      <Split>
        <S.InputBox isRow>
          <p>
            요금제명<span>*</span>
          </p>
          <S.Input
            name="name"
            onBlur={onChangeInputs}
            defaultValue={inputs.name}
            placeholder="요금제명"
          />
        </S.InputBox>
        <S.InputBox isRow>
          <p>
            통신사<span>*</span>
          </p>
          <CSelect<TelecomTypes>
            options={telecoms}
            value={(telecom: TelecomTypes) => telecom.telecomId}
            label={(telecom: TelecomTypes) => telecom.name}
            defaultValue={telecoms.filter(
              (telecom) => telecom.telecomId === inputs.telecom?.telecomId
            )}
            func={(selected: TelecomTypes) =>
              onChangeSelect("telecom", selected)
            }
            placeholder="통신사"
          />
        </S.InputBox>
        <S.InputBox isRow>
          <p>
            카테고리<span>*</span>
          </p>
          <S.Input
            name="category"
            onBlur={onChangeInputs}
            defaultValue={inputs.category}
            placeholder="카테고리"
          />
        </S.InputBox>
        <S.InputBox isRow>
          <p>
            월정액<span>*</span>
          </p>
          <S.MoneyInputs>
            <S.Input
              type="number"
              name="price"
              onBlur={onChangeInputs}
              defaultValue={inputs.price?.toLocaleString()}
              placeholder="숫자만 입력"
            />
          </S.MoneyInputs>
        </S.InputBox>
        <S.InputBox isRow>
          <p>
            데이터<span>*</span>
          </p>
          <S.Input
            name="dataExp"
            onBlur={onChangeInputs}
            defaultValue={inputs.dataExp}
            placeholder="데이터"
          />
        </S.InputBox>
        <S.InputBox isRow>
          <p>
            음성통화<span>*</span>
          </p>
          <S.Input
            name="callExp"
            onBlur={onChangeInputs}
            defaultValue={inputs.callExp}
            placeholder="음성통화"
          />
        </S.InputBox>
        <S.InputBox isRow>
          <p>
            문자메세지<span>*</span>
          </p>
          <S.Input
            name="mailExp"
            onBlur={onChangeInputs}
            defaultValue={inputs.mailExp}
            placeholder="문자"
          />
        </S.InputBox>
      </Split>
      <Bottoms>
        <button type="button" onClick={save}>
          요금제 {type}하기
        </button>
      </Bottoms>
    </Main>
  );
};

export default PhoneplanManage;
