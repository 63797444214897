import axios from "axios";

const idm = "https://api-idm.j-sol.co.kr/api/inout";
const mcall = "https://api-mcall.j-sol.co.kr/api";
// const mcall = "http://192.168.0.4:11201/api"

axios.defaults.baseURL = "";

axios.interceptors.request.use((req) => {
  if (process.env.NODE_ENV === "development") {
    console.log(req);
  }

  return req;
});

axios.interceptors.response.use(
  (res) => {
    if (process.env.NODE_ENV === "development") {
      console.log(res);
    }

    return res;
  },
  async (error) => {
    // if (error.response.status === 400) {
    //   localStorage.removeItem("@accessToken");
    //   localStorage.removeItem("@refreshToken");
    //   localStorage.removeItem("@user");

    //   alert("장시간 미사용으로 로그아웃 되었습니다.");
    //   window.location.reload();
    // }

    if (error.response >= 500) {
      console.log("500");
    }

    return Promise.reject(error);
  }
);

export const apiRoute = {
  auth: {
    login: `${mcall}/v1/login`,
    valid: `${mcall}/v1/valid/`,
  },
  account: {
    valid: `${mcall}/v1/account/exist/`,
    postAccount: `${mcall}/v1/shop`,
  },
  // 업체관리(jsol)
  shop: {
    getShop: `${mcall}/v1/shops/`,
    getShops: `${mcall}/v1/shops`,
    patchShop: `${mcall}/v1/shop`,
    request: {},
  },
  // 단말관리(jsol)
  device: {
    getDevice: `${idm}/device/`,
    getDevices: `${idm}/device`,
    getDevicesByModelName: `${idm}/device/info?model_name=`,
    postDevice: `${idm}/device`,
    patchDevice: `${idm}/device`,
    deleteDevice: `${idm}/device/`,
    info: {
      getInfo: `${idm}/device/info/`,
      getInfos: `${idm}/device/info`,
      postInfo: `${idm}/device/info`,
      patchInfo: `${idm}/device/info`,
      deleteInfo: `${idm}/device/info/`,
    },
    category: {
      getCategories: `${idm}/category`,
    },
    color: {
      getColor: `${idm}/color/`,
      getColors: `${idm}/color`,
      postColor: `${idm}/color`,
      patchColor: `${idm}/color`,
      deleteColor: `${idm}/color/`,
    },
    barcode: {
      getBarcodes: `${idm}/device/info?barcode=`,
      deleteBarcode: `${mcall}/v1/devices/`,
    },
    maker: {
      getMakers: `${idm}/maker`,
      postMaker: `${idm}/maker`,
      patchMaker: `${idm}/maker`,
      deleteMaker: `${idm}/maker/`,
    },
    series: `${idm}/device/series-list`,
  },
  // 통신사관리(jsol)
  telecom: {
    getTelecom: `${mcall}/v1/telecoms/`,
    getTelecoms: `${mcall}/v1/telecoms`,
    postTelecom: `${mcall}/v1/telecom`,
    patchTelecom: `${mcall}/v1/telecom`,
    deleteTelecom: `${mcall}/v1/telecoms/`,
    phoneplan: {
      getPhoneplan: `${mcall}/v1/phone-plans/`,
      getPhoneplans: `${mcall}/v1/phone-plans`,
      postPhoneplan: `${mcall}/v1/phone-plan`,
      patchPhoneplan: `${mcall}/v1/phone-plan`,
      deletePhoneplan: `${mcall}/v1/phone-plans/`,
    },
    subservice: {
      getSubservice: `${mcall}/v1/subServices/`,
      getSubservices: `${mcall}/v1/subServices`,
      postSubservice: `${mcall}/v1/subService`,
      patchSubservice: `${mcall}/v1/subService`,
      deleteSubservice: `${mcall}/v1/subServices/`,
    },
    insurance: {
      getInsurance: `${mcall}/v1/insurances/`,
      getInsurances: `${mcall}/v1/insurances`,
      postInsurance: `${mcall}/v1/insurance`,
      patchInsurance: `${mcall}/v1/insurance`,
      deleteInsurance: `${mcall}/v1/insurances/`,
    },
    publicSupport: {
      getPublicSupport: `${mcall}/v1/public-supports/`,
      getPublicSupports: `${mcall}/v1/public-supports`,
      postPublicSupport: `${mcall}/v1/public-support`,
      patchPublicSupport: `${mcall}/v1/public-supports/`,
      deletePublicSupport: `${mcall}/v1/public-supports/`,
    },
  },
  // 게시글관리(jsol)
  board: {
    getBoard: `${mcall}/v1/boards/`,
    getBoards: `${mcall}/v1/boards`,
    postBoard: `${mcall}/v1/board`,
    patchBoard: `${mcall}/v1/board`,
    deleteBoard: `${mcall}/v1/boards/`,
    category: {
      getCategory: `${mcall}/v1/board-categories/`,
      getCategories: `${mcall}/v1/board-categories`,
      postCategory: `${mcall}/v1/board-category`,
      patchCategory: `${mcall}/v1/board-category`,
      deleteCategory: `${mcall}/v1/board-categories/`,
    },
    qna: {},
  },
  // 재고관리(shop)
  shopDevice: {
    getDevice: `${idm}/shop/device/`,
    getDevices: `${idm}/shop/device`,
    postDevice: `${idm}/shop/device`,
    patchDevice: `${idm}/shop/device`,
    deleteDevice: `${idm}/shop/device/`,
    validDevice: `${idm}/shop/device/valid`,
    request: {
      getRequests: `${idm}/shop/device/request`,
      getAllocable: `${idm}/shop/device/allocable`,
      postRequest: `${idm}/shop/device/request`,
      postAllocate: `${idm}/shop/device/request/allocation`,
      patchReject: `${idm}/shop/device/request/reject`,
      patchCancel: `${idm}/shop/device/request`,
    },
    release: {
      getRelease: `${idm}/category?type=출고`,
      releaseDevice: `${idm}/shop/device/stockout`,
      cancelRelease: `${idm}/shop/device/stockout/cancel`,
      tempOut: `${idm}/shop/device/tempOut`,
    },
    history: {
      getHistories: `${idm}/history`,
    },
  },
  // 정산관리
  pay: {
    open: {
      getOpen: `${mcall}/v1/opens/`,
      getOpens: `${mcall}/v1/opens`,
      postOpen: `${mcall}/v1/open`,
      patchOpen: ``,
      patchCancel: `${mcall}/v1/open/cancel`,
      deleteOpen: `${mcall}/v1/opens/`,
    },
  },
  // 고객관리
  customer: {
    getCustomer: `${mcall}/v1/customers/`,
    getCustomers: `${mcall}/v1/customers`,
    patchCustomer: `${mcall}/v1/customer`,
    deleteCustomer: `${mcall}/v1/customers/`,
    consult: {
      getConsult: `${mcall}/v1/device-reserve/`,
      getConsults: `${mcall}/v1/device-reserves`,
      postConsult: `${mcall}/v1/device-reserve`,
      patchConsult: `${mcall}/v1/device-reserve`,
      deleteConsult: `${mcall}/v1/device-reserves/`,
    },
    history: {
      getHistories: `${mcall}/v1/customer-histories`,
    },
  },
  // 일정
  schedule: {
    getSchedule: `${mcall}/v1/schedules/`,
    getSchedules: `${mcall}/v1/schedules`,
    postSchedule: `${mcall}/v1/schedules`,
    patchSchedule: `${mcall}/v1/schedules`,
  },
  // 설정
  setup: {
    employee: {
      getEmployee: `${mcall}/v1/employees/`,
      getEmployees: `${mcall}/v1/employees`,
      postEmployee: `${mcall}/v1/employee`,
      patchEmployee: `${mcall}/v1/employee`,
      deleteEmployee: `${mcall}/v1/employees/`,
    },
    related: {
      getRelated: `${mcall}/v1/shops/related`,
      getRelateds: `${mcall}/v1/shops/related`,
      postRelated: `${mcall}/v1/shops/related`,
      patchRelated: `${mcall}/v1/shops/related`,
      deleteRelated: `${mcall}/v1/shops/related`,
    },
    depot: {
      getDepot: `${idm}/shop/depot/`,
      getDepots: `${idm}/shop/depot`,
      postDepot: `${idm}/shop/depot`,
      patchDepot: `${idm}/shop/depot`,
      deleteDepot: `${idm}/shop/depot/`,
    },
    dealer: {
      getDealer: `${mcall}/v1/dealers/`,
      getDealers: `${mcall}/v1/dealers`,
      postDealer: `${mcall}/v1/dealer`,
      patchDealer: `${mcall}/v1/dealer`,
      deleteDealer: `${mcall}/v1/dealers/`,
    },
    policy: {
      getPolicy: `${mcall}/v1/policies/`,
      getPolicies: `${mcall}/v1/policies`,
      postPolicy: `${mcall}/v1/policy`,
      patchPolicy: `${mcall}/v1/policy`,
      deletePolicy: `${mcall}/v1/policies/`,
    },
  },
  client: {
    getClients: `${mcall}/v1/clients`,
    postClient: `${mcall}/v1/clients`,
    patchClient: `${mcall}/v1/clients/`,
    deleteClients: `${mcall}/v1/clients/`,
    getClientHistory: `${idm}/client-histories`,
    getClientSummary: `${idm}/client-histories/status`,
  },
  item: `${idm}/items`,
};

export type BasicApiResponse<T> = {
  data: T;
  config: {
    status: number;
  };
};

export type BasicListApiResponse<T> = {
  data: T;
  meta: {
    page: number;
    take: number;
    itemCount: number;
    pageCount: number;
    hasPreviousPage: boolean;
    hasNextPage: boolean;
  };
};

export function requestGet<T>(
  url: string,
  header: object
): Promise<BasicApiResponse<T>> {
  return axios
    .get(url, {
      headers: {
        "Content-Type": "application/json",
        ...header,
      },
    })
    .then(
      (res) =>
        ({
          data: res.data as T,
          config: {
            status: res.status,
            ...res.data?.meta,
          },
        } as BasicApiResponse<T>)
    )
    .catch((err) => {
      console.error("[Axios Error]", err);

      return {
        data: {} as T,
        config: {
          status: -1,
        },
      } as BasicApiResponse<T>;
    });
}

export function requestSecureGet<T>(
  url: string,
  header: object,
  token: string
): Promise<BasicApiResponse<T>> {
  return axios
    .get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
        ...header,
      },
    })
    .then(
      (res) =>
        ({
          data: res.data as T,
          config: {
            status: res.status,
            ...res.data?.meta,
          },
        } as BasicApiResponse<T>)
    )
    .catch((err) => {
      console.error("[Axios Error]", err);

      return {
        data: {} as T,
        config: {
          status: -1,
        },
      } as BasicApiResponse<T>;
    });
}

export function requestDelete<T>(
  url: string,
  header: object
): Promise<BasicApiResponse<T>> {
  return axios
    .delete(url, {
      headers: {
        "Content-Type": "application/json",
        ...header,
      },
    })
    .then(
      (res) =>
        ({
          data: res.data as T,
          config: {
            status: res.status,
            ...res.data?.meta,
          },
        } as BasicApiResponse<T>)
    )
    .catch((err) => {
      console.error("[Axios Error]", err);

      return {
        data: {} as T,
        config: {
          status: -1,
        },
      } as BasicApiResponse<T>;
    });
}

export function requestSecureDelete<T>(
  url: string,
  header: object,
  token: string
): Promise<BasicApiResponse<T>> {
  return axios
    .delete(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
        ...header,
      },
    })
    .then(
      (res) =>
        ({
          data: res.data as T,
          config: {
            status: res.status,
            ...res.data?.meta,
          },
        } as BasicApiResponse<T>)
    )
    .catch((err) => {
      console.error("[Axios Error]", err);

      return {
        data: {} as T,
        config: {
          status: -1,
        },
      } as BasicApiResponse<T>;
    });
}

export function requestSecurePost<T>(
  url: string,
  header: object,
  body: object,
  token: string,
  isFormData?: boolean
): Promise<BasicApiResponse<T>> {
  const contentType = isFormData ? "multipart/form-data" : "application/json";
  return axios
    .post(url, body, {
      headers: {
        "Content-Type": contentType,
        Authorization: `${token}`,
        refresh_token: `${token}`,
        ...header,
      },
    })
    .then(
      (res) =>
        ({
          data: res.data as T,
          config: {
            status: res.status,
            ...res.data?.meta,
          },
        } as BasicApiResponse<T>)
    )
    .catch((err) => {
      console.error("[Axios Error]", err);

      return {
        data: {} as T,
        config: {
          status: -1,
        },
      } as BasicApiResponse<T>;
    });
}

export function requestPost<T>(
  url: string,
  header: object,
  body: object
): Promise<BasicApiResponse<T>> {
  return axios
    .post(url, body, {
      headers: {
        "Content-Type": "application/json",
        ...header,
      },
    })
    .then(
      (res) =>
        ({
          data: res.data as T,
          config: {
            status: res.status,
            ...res.data?.meta,
          },
        } as BasicApiResponse<T>)
    )
    .catch((err) => {
      console.error("[Axios Error]", err.response);

      return {
        data: {} as T,
        config: {
          status: -1,
        },
      } as BasicApiResponse<T>;
    });
}

export function requestSecurePatch<T>(
  url: string,
  header: object,
  body: object,
  token: string
): Promise<BasicApiResponse<T>> {
  return axios
    .patch(url, body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
        ...header,
      },
    })
    .then(
      (res) =>
        ({
          data: res.data as T,
          config: {
            status: res.status,
            ...res.data?.meta,
          },
        } as BasicApiResponse<T>)
    )
    .catch((err) => {
      console.error("[Axios Error]", err);

      return {
        data: {} as T,
        config: {
          status: -1,
        },
      } as BasicApiResponse<T>;
    });
}

export function requestMultipart<T>(
  url: string,
  header: object,
  body: FormData,
  token: string
): Promise<BasicApiResponse<T>> {
  return axios
    .post(url, body, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `${token}`,
        ...header,
      },
    })
    .then(
      (res) =>
        ({
          data: res.data as T,
          config: {
            status: res.status,
            ...res.data?.meta,
          },
        } as BasicApiResponse<T>)
    )
    .catch((err) => {
      console.error("[Axios Error]", err);

      return {
        data: {} as T,
        config: {
          status: -1,
        },
      } as BasicApiResponse<T>;
    });
}
