import { Main, Split, Bottoms } from '@styles/components/ModalBodyStyle';
import * as S from '@styles/components/FormStyle';
import { BoardCategoryTypes } from '@typedef/components/Board/Category/board.category.types';

type Props = {
  type: string;
  inputs: Partial<BoardCategoryTypes>;
  onChangeInputs: (e: { target: HTMLInputElement }) => void;
  save: () => Promise<void>;
};

const BoardCategoryManage = ({ type, inputs, onChangeInputs, save }: Props) => {
  return (
    <Main width={35}>
      <Split>
        <S.InputBox isRow>
          <p>
            게시판명<span>*</span>
          </p>
          <S.Input
            name='name'
            onChange={onChangeInputs}
            defaultValue={inputs.name}
            placeholder='게시판명'
          />
        </S.InputBox>
      </Split>
      <Bottoms>
        <button type='button' onClick={save} disabled={!!!inputs.name}>
          게시판 {type}하기
        </button>
      </Bottoms>
    </Main>
  );
};

export default BoardCategoryManage;
