export type DateTypes = {
  name: string;
  value: string;
};

const today = new Date();

export function years() {
  const years: DateTypes[] = [];
  for (let i = today.getFullYear(); i >= today.getFullYear() - 100; i--) {
    years.push({ name: i.toString(), value: i.toString() });
  }
  return years;
}

export function months() {
  const months: DateTypes[] = [];
  for (let i = 1; i <= 12; i++) {
    months.push({ name: addZero(i), value: addZero(i) });
  }
  return months;
}

export function days() {
  const days: DateTypes[] = [];
  for (let i = 1; i <= 31; i++) {
    days.push({ name: addZero(i), value: addZero(i) });
  }
  return days;
}

export function hours() {
  const hours: DateTypes[] = [];
  for (let i = 0; i < 24; i++) {
    hours.push({ name: addZero(i), value: addZero(i) });
  }
  return hours;
}

export function minutes() {
  const minutes: DateTypes[] = [];
  for (let i = 0; i < 60; i++) {
    minutes.push({ name: addZero(i), value: addZero(i) });
  }
  return minutes;
}

export function addZero(num: number) {
  return num.toString().padStart(2, '0');
}
