import { useEffect } from "react";

type ElementTypes<T> = {
  ref: React.RefObject<T>;
  isVisible: boolean;
  handler: () => void;
};

export default function useOutsideClick<T extends HTMLElement>(
  elements: ElementTypes<T>[]
) {
  useEffect(() => {
    const listener = (e: MouseEvent | TouchEvent) => {
      elements.forEach((element) => {
        const { ref, isVisible, handler } = element;

        if (!isVisible) return;

        if (!ref.current || ref.current.contains(e.target as Node)) {
          return;
        }
        handler();
      });
    };
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [elements]);
}
