import ScheduleManage from '../components/ScheduleManage';
import { useState, useEffect } from 'react';
import {
  apiRoute,
  requestSecureGet,
  requestSecurePatch,
  requestSecurePost,
} from '@libs/api';
import useToken from '@hooks/useToken';
import { ScheduleTypes } from '@typedef/components/Schedule/schedule.types';
import dayjs from 'dayjs';

type Props = {
  idx?: number;
  close: () => void;
  reload: () => void;
};

const ScheduleManageContainer = ({ idx, close, reload }: Props) => {
  const { getToken } = useToken();
  const today = new Date();
  const format = 'YYYY-MM-DD';
  const [inputs, setInputs] = useState<Partial<ScheduleTypes>>({
    colorHex: '#FFC7C7',
    startDate: dayjs(today).format(format),
    endDate: dayjs(today).format(format),
  });

  // 날짜 선택
  const onChangeDate = (date: Date | Date[]) => {
    if (!Array.isArray(date)) {
      return;
    }
    setInputs((inputs) => ({
      ...inputs,
      startDate: dayjs(date[0]).format(format),
      endDate: dayjs(date[1]).format(format),
    }));
  };

  // onChange (input)
  const onChangeInputs = (e: {
    target: HTMLInputElement | HTMLTextAreaElement;
  }) => {
    const { name, value } = e.target;
    setInputs((inputs) => ({
      ...inputs,
      [name]: value,
    }));
  };

  // 색상 변경
  const onClickColor = (color: string) => {
    setInputs((inputs) => ({
      ...inputs,
      colorHex: color,
    }));
  };

  // 등록
  const insert = async () => {
    const postDate = {
      ...inputs,
      startDate: inputs.startDate + ' 00:00:00',
      endDate: inputs.endDate + ' 23:59:59',
    };

    const { config } = await requestSecurePost(
      apiRoute.schedule.postSchedule,
      {},
      postDate,
      getToken()!,
    );
    if (config.status >= 200 && config.status < 400) {
      alert('성공적으로 등록이 완료되었습니다.');
      close();
      reload();
    }
  };

  // 수정
  const update = async () => {
    const patchData = {
      ...inputs,
      startDate: inputs.startDate + ' 00:00:00',
      endDate: inputs.endDate + ' 23:59:59',
    };

    const { config } = await requestSecurePatch(
      apiRoute.schedule.postSchedule,
      {},
      patchData,
      getToken()!,
    );
    if (config.status >= 200 && config.status < 400) {
      alert('성공적으로 등록이 완료되었습니다.');
      close();
      reload();
    }
  };

  const setSelectedDate = (date: Date) => {
    let className = '';
    if (inputs.startDate && inputs.endDate) {
      const formatDate = dayjs(date).format('YYYY-MM-DD');
      if (inputs.startDate <= formatDate && formatDate <= inputs.endDate) {
        className = 'range';
      }
      if (formatDate === inputs.startDate) {
        console.log('start', formatDate);
        className = 'start';
      }
      if (formatDate === inputs.endDate) {
        console.log('end', formatDate);
        className = 'end';
      }
    }
    return className;
  };

  // 캘린더에 선택된 날짜 스타일
  const getSelectedData = async () => {
    const { config, data } = await requestSecureGet<{
      schedule: ScheduleTypes;
    }>(apiRoute.schedule.getSchedule + idx, {}, getToken()!);

    if (config.status >= 200 && config.status < 400) {
      const { schedule } = data;

      setInputs({
        ...schedule,
        startDate: schedule.startDate.slice(0, 10),
        endDate: schedule.endDate.slice(0, 10),
      });
    }
  };

  useEffect(() => {
    if (idx) {
      getSelectedData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idx]);

  return (
    <ScheduleManage
      type={idx ? '수정' : '등록'}
      inputs={inputs}
      onChangeDate={onChangeDate}
      onChangeInputs={onChangeInputs}
      onClickColor={onClickColor}
      setSelectedDate={setSelectedDate}
      save={idx ? update : insert}
    />
  );
};

export default ScheduleManageContainer;
