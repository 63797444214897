import ConsultManage from '../components/ConsultManage';
import { useState } from 'react';
import useToken from '@hooks/useToken';
import { useRecoilValue } from 'recoil';
import { getCustomers, getTelecoms } from '@stories/Selector';
import { ConsultTypes } from '@typedef/components/Customer/Consult/consult.types';
import { apiRoute, requestSecurePost } from '@libs/api';

type Props = {
  idx?: number;
  close: () => void;
  reload: () => void;
};

const ConsulteManageContainer = ({ idx, close, reload }: Props) => {
  const { getToken } = useToken();
  const [inputs, setInputs] = useState<Partial<ConsultTypes>>({});
  const customers = useRecoilValue(getCustomers(getToken()!));
  const telecoms = useRecoilValue(getTelecoms(getToken()!));

  // onChange (input)
  const onChangeInputs = (e: {
    target: HTMLInputElement | HTMLTextAreaElement;
  }) => {
    const { name, value } = e.target;
    setInputs((inputs) => ({
      ...inputs,
      [name]: value,
    }));
  };

  // onChange (select)
  const onChangeSelect = <T extends {}>(name: string, selected: T) => {
    setInputs((inputs) => ({
      ...inputs,
      [name]: selected,
    }));
  };

  const insert = async () => {
    const postData = {
      ...inputs,
      customerId: inputs.customer?.customerId,
    };

    // 희망 모델명 입력되면 대문자로 변환하기
    if (inputs.wishModelName) {
      postData.wishModelName = inputs.wishModelName.toUpperCase();
    }

    const { config } = await requestSecurePost(
      apiRoute.customer.consult.postConsult,
      {},
      postData,
      getToken()!,
    );

    if (config.status >= 200 && config.status < 400) {
    }
  };

  const update = async () => {
    const { config } = await requestSecurePost(
      apiRoute.customer.consult.patchConsult,
      {},
      inputs,
      getToken()!,
    );

    if (config.status >= 200 && config.status < 400) {
    }
  };

  return (
    <ConsultManage
      type={idx ? '수정' : '등록'}
      inputs={inputs}
      onChangeInputs={onChangeInputs}
      onChangeSelect={onChangeSelect}
      customers={customers}
      telecoms={telecoms}
      save={idx ? update : insert}
    />
  );
};

export default ConsulteManageContainer;
