import { Main, Split, Bottoms } from '@styles/components/ModalBodyStyle';
import * as S from '@styles/components/FormStyle';

type Props = {
  inputs: string;
  setInputs: React.Dispatch<React.SetStateAction<string>>;
  save: () => Promise<void>;
};

const TelecomManage = ({ inputs, setInputs, save }: Props) => {
  return (
    <Main width={35}>
      <Split>
        <S.InputBox isRow>
          <p>
            통신사 이름<span>*</span>
          </p>
          <S.Input
            defaultValue={inputs}
            onChange={(e) => setInputs(e.target.value)}
            placeholder='통신사 이름'
          />
        </S.InputBox>
      </Split>
      <Bottoms>
        <button type='button' onClick={save} disabled={!!!inputs}>
          저장
        </button>
      </Bottoms>
    </Main>
  );
};

export default TelecomManage;
