const image = {
  mcallLogo: '/assets/images/logo-mcall.png',
  loginIllust: '/assets/images/illust-login.png',
  mainIllust: '/assets/images/illust-main.svg',
  checkIcon: '/assets/images/ic-check.svg',
  closeIcon: '/assets/images/ic-close.svg',
  copyIcon: '/assets/images/ic-copy.svg',
  infoIcon: '/assets/images/ic-info.svg',
  buildingImg: '/assets/images/img-building.jpg',
};

export default image;
