import * as S from '../styles/HelpViewStyle';
import { BoardTypes } from '@typedef/components/Board/board.types';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

type Props = {
  boards: BoardTypes[];
};

const Notice = ({ boards }: Props) => {
  return (
    <S.Main>
      <S.TopBtns>
        <h3>전체 {boards.length}</h3>
      </S.TopBtns>
      <S.Boards>
        {boards.map((board) => (
          <S.Board key={board.boardId}>
            <S.Row>
              <span>공지</span>
              <Link to={`/help/notice/${board.boardId}`}>
                <h3 className='notice'>{board.title}</h3>
              </Link>
            </S.Row>
            <p className='date'>{dayjs(board.time).format('YYYY-MM-DD')}</p>
          </S.Board>
        ))}
      </S.Boards>
    </S.Main>
  );
};

export default Notice;
