import DeviceColor from "../DeviceColor";
import ColorManageContainer from "./ColorManageContainer";
import { useCallback, useEffect, useMemo, useState } from "react";
import { apiRoute, requestSecureDelete, requestSecureGet } from "@libs/api";
import useToken from "@hooks/useToken";
import { useSetRecoilState } from "recoil";
import { loading, modal, modalHeader } from "@stories/Atom";
import { TableColumnTypes } from "@typedef/components/Common/CTable/table.column.types";
import { ColorTypes } from "@typedef/components/Device/Color/color.types";

const DeviceColorContainer = () => {
  const { getToken } = useToken();
  // 테이블 데이터
  const [tableData, setTableData] = useState<ColorTypes[]>([]);
  const [checkedIdxes, setCheckedIdxes] = useState<number[]>([]);
  // 모달설정
  const setModal = useSetRecoilState(modal);
  const setModalHeader = useSetRecoilState(modalHeader);
  // 테이블 로딩
  const setLoading = useSetRecoilState(loading);
  const [reload, setReload] = useState<number>(0);

  // 색상 상세조회 및 수정
  const updateColor = useCallback(
    (idx?: number) => {
      if (!!!idx && checkedIdxes.length === 0) {
        alert("수정할 색상을 선택해 주세요.");
        return;
      } else if (!!!idx && checkedIdxes.length > 1) {
        alert("수정할 색상을 하나만 선택해 주세요.");
        return;
      }
      const filtered = tableData.filter(
        (color) => color.idx === idx ?? checkedIdxes[0]
      );
      setModalHeader("색상 상세조회");
      setModal(
        <ColorManageContainer
          checked={filtered[0]}
          close={() => setModal(null)}
          reload={() => setReload((prev) => prev + 1)}
        />
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tableData, checkedIdxes]
  );

  // 체크박스
  const onChangeCheckbox = useCallback(
    (idx: number) => {
      if (checkedIdxes.includes(idx)) {
        setCheckedIdxes(
          checkedIdxes.filter((checkedIdx) => checkedIdx !== idx)
        );
        return;
      }
      setCheckedIdxes((idxes) => [...idxes, idx]);
    },
    [checkedIdxes]
  );

  // 테이블 컬럼
  const columns: TableColumnTypes<ColorTypes>[] = useMemo(
    () => [
      {
        Header: "",
        accessor: "idx",
        disableSortBy: true,
        width: 70,
        Cell: ({ value }: any) => (
          <input
            type="checkbox"
            defaultChecked={checkedIdxes.includes(value)}
            onChange={() => onChangeCheckbox(value)}
          />
        ),
      },
      {
        Header: "No",
        width: 70,
        Cell: ({ row }: any) => <>{row.index + 1}</>,
      },
      {
        Header: "색상명",
        accessor: "name",
        disableSortBy: true,
        Cell: ({ row, value }: any) => (
          <div className="detail" onClick={() => updateColor(row.original.idx)}>
            {value}
          </div>
        ),
      },
      {
        Header: "영어명",
        accessor: "name_eng",
        disableSortBy: true,
      },
    ],
    [checkedIdxes, onChangeCheckbox, updateColor]
  );

  // 색상 등록
  const insertColor = () => {
    setModalHeader("색상 등록");
    setModal(
      <ColorManageContainer
        checked={null}
        close={() => setModal(null)}
        reload={() => setReload((prev) => prev + 1)}
      />
    );
  };

  // 색상 삭제
  const deleteColor = async () => {
    if (checkedIdxes.length === 0) {
      alert("삭제할 색상을 선택해 주세요");
      return;
    } else if (
      window.confirm(
        `선택한 ${checkedIdxes.length}개의 색상을 삭제하시겠습니까?`
      )
    ) {
      await Promise.all(
        checkedIdxes.map(
          (idx) =>
            new Promise((resolve, reject) => {
              requestSecureDelete(
                apiRoute.device.color.deleteColor + idx,
                {},
                getToken()!
              ).then((data) => {
                if (data.config.status >= 200 && data.config.status < 400) {
                  resolve(data);
                }
              });
            })
        )
      ).then(() => {
        alert("성공적으로 삭제가 완료되었습니다.");
        setReload((prev) => prev + 1);
      });
    }
  };

  const getTableData = async () => {
    const { config, data } = await requestSecureGet<ColorTypes[]>(
      apiRoute.device.color.getColors,
      {},
      getToken()!
    );
    if (config.status >= 200 && config.status < 400) {
      setTableData(data);
      setLoading(false);
    } else {
      setTableData([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  return (
    <DeviceColor
      columns={columns}
      tableData={tableData}
      insertColor={insertColor}
      updateColor={updateColor}
      deleteColor={deleteColor}
    />
  );
};

export default DeviceColorContainer;
