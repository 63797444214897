import Notice from '../Notice';
import { useState, useEffect } from 'react';
import { apiRoute, requestSecureGet } from '@libs/api';
import useToken from '@hooks/useToken';
import { BoardTypes } from '@typedef/components/Board/board.types';

const NoticeContainer = () => {
  const { getToken } = useToken();
  const [boards, setBoards] = useState<BoardTypes[]>([]);

  const getFAQData = async () => {
    const { config, data } = await requestSecureGet<{ boards: BoardTypes[] }>(
      apiRoute.board.getBoards + `?category=1`,
      {},
      getToken()!,
    );
    if (config.status >= 200 && config.status < 400) {
      setBoards(data.boards);
    }
  };

  useEffect(() => {
    getFAQData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Notice boards={boards} />;
};

export default NoticeContainer;
