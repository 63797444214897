import SetupEmployee from '../SetupEmployee';
import EmployeeManageContainer from './EmployeeManageContainer';
import { useState, useEffect, useMemo, useCallback } from 'react';
import { apiRoute, requestSecureDelete, requestSecureGet } from '@libs/api';
import useToken from '@hooks/useToken';
import { useSetRecoilState } from 'recoil';
import { loading, modal, modalHeader } from '@stories/Atom';
import { EmployeeTypes } from '@typedef/components/Setup/Employee/employee.types';
import { TableColumnTypes } from '@typedef/components/Common/CTable/table.column.types';
import dayjs from 'dayjs';
import DetailCell from '@components/Common/CTable/components/DetailCell';

// 설정 > 직원관리 (대리점, 판매점)
const SetupEmployeeContainer = () => {
  const { getToken } = useToken();
  // 테이블 데이터
  const [tableData, setTableData] = useState<EmployeeTypes[]>([]);
  const [checkedIdxes, setCheckedIdxes] = useState<number[]>([]);
  // 모달설정
  const setModalHeader = useSetRecoilState(modalHeader);
  const setModal = useSetRecoilState(modal);
  // 테이블 로딩
  const setLoading = useSetRecoilState(loading);
  const [reload, setReload] = useState<number>(0);

  // 직원 상세조회 및 수정
  const updateEmployee = useCallback(
    (idx?: number) => {
      if (!!!idx && checkedIdxes.length === 0) {
        alert('수정할 직원을 선택해 주세요.');
        return;
      } else if (!!!idx && checkedIdxes.length > 1) {
        alert('수정할 직원을 하나만 선택해 주세요.');
        return;
      }

      setModalHeader('직원 상세조회');
      setModal(
        <EmployeeManageContainer
          idx={idx ?? checkedIdxes[0]}
          close={() => setModal(null)}
          reload={() => setReload((prev) => prev + 1)}
        />,
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [checkedIdxes],
  );

  // 체크박스
  const onChangeCheckbox = useCallback(
    (idx: number) => {
      if (checkedIdxes.includes(idx)) {
        setCheckedIdxes(
          checkedIdxes.filter((checkedIdx) => checkedIdx !== idx),
        );
        return;
      }
      setCheckedIdxes((idxes) => [...idxes, idx]);
    },
    [checkedIdxes],
  );

  // 테이블 컬럼
  const columns: TableColumnTypes<EmployeeTypes>[] = useMemo(
    () => [
      {
        accessor: 'employeeId',
        disableSortBy: true,
        width: 70,
        Cell: ({ value }) => (
          <input
            type='checkbox'
            defaultChecked={checkedIdxes.includes(value as number)}
            onChange={() => onChangeCheckbox(value as number)}
          />
        ),
      },
      {
        Header: 'No',
        width: 70,
        Cell: ({ row }) => <>{tableData.length - row.index}</>,
      },
      {
        Header: '이름',
        accessor: 'account.name',
        disableSortBy: true,
        Cell: ({ row, value }) => (
          <DetailCell
            value={value as string}
            func={() => updateEmployee(row.original.employeeId)}
          />
        ),
      },
      {
        Header: '휴대전화',
        accessor: 'phone',
        disableSortBy: true,
      },
      {
        Header: '이메일',
        accessor: 'email',
        disableSortBy: true,
      },
      {
        Header: '생년월일',
        accessor: (originalRow) =>
          dayjs(originalRow.birthday).format('YYYY-MM-DD'),
        disableSortBy: true,
      },
    ],
    [checkedIdxes, onChangeCheckbox, tableData.length, updateEmployee],
  );

  // 직원 등록
  const insertEmployee = () => {
    setModalHeader('직원 등록');
    setModal(
      <EmployeeManageContainer
        close={() => setModal(null)}
        reload={() => setReload((prev) => prev + 1)}
      />,
    );
  };

  // 직원 삭제
  const deleteEmployee = async () => {
    if (checkedIdxes.length === 0) {
      alert('삭제할 직원을 선택해 주세요');
      return;
    } else if (
      window.confirm(
        `선택한 ${checkedIdxes.length}개의 직원을 삭제하시겠습니까?`,
      )
    ) {
      await Promise.all(
        checkedIdxes.map(
          (idx) =>
            new Promise((resolve, reject) => {
              requestSecureDelete(
                apiRoute.setup.employee.deleteEmployee + idx,
                {},
                getToken()!,
              ).then((data) => {
                if (data.config.status >= 200 && data.config.status < 400) {
                  resolve(data);
                }
              });
            }),
        ),
      ).then(() => {
        alert('성공적으로 삭제가 완료되었습니다.');
        setReload((prev) => prev + 1);
      });
    }
  };

  const getTableData = async () => {
    const { config, data } = await requestSecureGet<{
      employees: EmployeeTypes[];
    }>(apiRoute.setup.employee.getEmployees, {}, getToken()!);
    if (config.status >= 200 && config.status < 400) {
      setTableData(data.employees);
      setLoading(false);
    } else {
      setTableData([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  return (
    <SetupEmployee
      columns={columns}
      tableData={tableData}
      insertEmployee={insertEmployee}
      updateEmployee={updateEmployee}
      deleteEmployee={deleteEmployee}
    />
  );
};

export default SetupEmployeeContainer;
