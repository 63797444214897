import styled from 'styled-components';

const space = '1.5vw';

// 입고유형 선택
export const TypeBox = styled.section`
  ${({ theme }) => theme.flex.row};
  align-items: center;
  justify-content: space-between;
  padding: calc(${space} / 2) ${space};
  border-bottom: 1px solid #eee;
`;

export const Types = styled.section`
  ${({ theme }) => theme.flex.row};

  .active {
    background-color: ${({ theme }) => theme.colors.brand900};
    color: #fff;
    font-weight: 500;
  }
`;

export const Type = styled.div`
  width: fit-content;
  line-height: 30px;
  margin-left: 1vw;
  padding: 0 7px;
  background-color: ${({ theme }) => theme.colors.grey200};
  border-radius: 3px;
  cursor: pointer;

  &:first-child {
    margin-left: 0;
  }
`;

// 바코드입고 > 바코드 검색 정보
export const Info = styled.div`
  h4 {
    margin-bottom: 0.5vw;
  }

  margin-bottom: ${space};
  padding: 15px;
  background-color: ${({ theme }) => theme.colors.grey200};
  border-radius: 3px;

  div {
    ${({ theme }) => theme.flex.row}
    line-height: 25px;
    align-items: center;
    justify-content: space-between;
    font-weight: 300;

    p {
      font-weight: 500;
    }

    .price {
      &::after {
        content: '원';
      }
    }
  }
`;

export const ButtonBox = styled.section`
  ${({ theme }) => theme.flex.row}
  align-items: center;
  border-left: 1px solid #eee;
  padding: ${space};
`;

export const AddBtn = styled.button`
  ${({ theme }) => theme.flex.col}
  align-items: center;
  justify-content: center;
  padding: 0.75vw;
  background-color: ${({ theme }) => theme.colors.grey200};
  border-radius: 3px;
`;
