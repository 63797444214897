import styled from 'styled-components';

export const SetupBasic = styled.main`
  flex: 1;
  ${({ theme }) => theme.flex.row}
  margin: 30px;
`;

export const Left = styled.section`
  flex: 0.7;
  ${({ theme }) => theme.flex.col}
`;

const margin = '30px';
const padding = '20px';

export const Profile = styled.section`
  ${({ theme }) => theme.flex.row}
  align-items: center;
  position: relative;
  margin-bottom: ${margin};
  padding: ${padding};
  background-color: #fff;
  border-radius: 10px;

  button {
    width: 130px;
    height: 45px;
    position: absolute;
    right: ${padding};
    border: 1px solid #e5e5e5;
    border-radius: 30px;
    text-align: center;
    cursor: pointer;
  }
`;

export const Price = styled.section`
  flex: 1;
  background-color: #fff;
  border-radius: 10px;
`;

export const Shop = styled.section`
  flex: 1;
  background-color: #fff;
  margin-left: ${margin};
  border-radius: 10px;
`;

export const Header = styled.header`
  ${({ theme }) => theme.flex.row}
  align-items: center;
  padding: ${padding};
  position: relative;

  .text {
    span {
      font-size: 0.9rem;
      font-weight: 300;
    }
  }

  button {
    width: 80px;
    height: 45px;
    position: absolute;
    right: ${padding};
    border: 1px solid #e5e5e5;
    border-radius: 30px;
    text-align: center;
    cursor: pointer;
  }
`;

export const Circle = styled.div`
  width: 70px;
  height: 70px;
  line-height: 70px;
  margin-right: 20px;
  background-color: ${({ theme }) => theme.colors.brand500};
  color: #fff;
  border-radius: 50%;
  box-shadow: rgba(99, 99, 99, 0.3) 0px 2px 8px 0px;
  font-size: 1.3rem;
  font-weight: 500;
  text-align: center;
  overflow: hidden;

  img {
    width: 130%;
    height: 130%;
  }
`;

export const Contents = styled.section`
  ${({ theme }) => theme.flex.col}
  padding: ${padding};
`;

export const RowInputBox = styled.div`
  ${({ theme }) => theme.flex.row}
  justify-content: space-between;

  // 소속정보 > 주소 수정
  .address {
    ${({ theme }) => theme.flex.row}
    justify-content: space-between;

    input {
      flex: 1;
    }

    button {
      width: 100px;
      height: 40px;
      margin-left: 10px;
      background-color: ${({ theme }) => theme.colors.grey500};
      border-radius: 5px;
      font-size: 0.9rem;
      font-weight: 500;
    }
  }
`;

export const Inputs = styled.div`
  width: 70%;
  ${({ theme }) => theme.flex.col}
  margin-bottom: 10px;

  span {
    margin-bottom: 5px;
    font-weight: 500;
  }

  &:last-child {
    margin-left: ${padding};
  }
`;

export const Input = styled.input`
  width: 100%;
  line-height: 40px;
  padding: 0 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 0.9rem;
  font-weight: 300;
`;
