import * as S from '@styles/components/TableViewStyle';
import CTable from '@components/Common/CTable/CTable';
import { TableColumnTypes } from '@typedef/components/Common/CTable/table.column.types';
import { DeviceInfoTypes } from '@typedef/components/Device/device.info.types';

type Props = {
  columns: TableColumnTypes<DeviceInfoTypes>[];
  tableData: DeviceInfoTypes[];
  insertBarcode: () => void;
  updateBarcode: (idx?: number) => void;
  deleteBarcode: () => Promise<void>;
};

const DeviceBarcode = ({
  columns,
  tableData,
  insertBarcode,
  updateBarcode,
  deleteBarcode,
}: Props) => {
  return (
    <S.Main>
      <S.TopBtns>
        <h3>전체 {tableData.length}</h3>
        <S.Btns>
          <S.Btn type='button' onClick={insertBarcode} className='active'>
            바코드등록
          </S.Btn>
          <S.Btn type='button' onClick={() => updateBarcode()}>
            수정
          </S.Btn>
          <S.Btn type='button' onClick={deleteBarcode}>
            삭제
          </S.Btn>
        </S.Btns>
      </S.TopBtns>
      <CTable columns={columns} tableData={tableData} />
    </S.Main>
  );
};

export default DeviceBarcode;
