import { Main, Split, Bottoms } from '@styles/components/ModalBodyStyle';
import * as S from '@styles/components/FormStyle';
import CSelect from '@components/Common/CSelect/CSelect';
import { FileInput } from '../styles/SetupPolicyStyle';
import { PolicyTypes } from '@typedef/components/Setup/Policy/policy.types';
import { TelecomTypes } from '@typedef/components/Telecom/telecom.types';

type Props = {
  type: string;
  inputs: Partial<PolicyTypes>;
  onChangeInputs: (e: { target: HTMLInputElement }) => void;
  onChangeSelect: <T extends {}>(name: string, selected: T) => void;
  onChangeFile: (e: { target: HTMLInputElement }) => void;
  telecoms: TelecomTypes[];
  save: () => Promise<void>;
};

const PolicyManage = ({
  type,
  inputs,
  onChangeInputs,
  onChangeSelect,
  onChangeFile,
  telecoms,
  save,
}: Props) => {
  return (
    <Main width={35}>
      <Split>
        <S.InputBox isRow>
          <p>
            제목<span>*</span>
          </p>
          <S.Input name='title' onChange={onChangeInputs} placeholder='제목' />
        </S.InputBox>
        <S.InputBox isRow>
          <p>
            통신사<span>*</span>
          </p>
          <CSelect<TelecomTypes>
            options={telecoms}
            value={(telecom: TelecomTypes) => telecom.telecomId}
            label={(telecom: TelecomTypes) => telecom.name}
            func={(selected: TelecomTypes) =>
              onChangeSelect('telecomId', selected.telecomId)
            }
            placeholder='통신사'
          />
        </S.InputBox>
        <S.InputBox isRow>
          <p>
            파일올리기<span>*</span>
          </p>
          <FileInput
            type='file'
            accept='image/jpg, image/jpeg, image/png'
            onChange={onChangeFile}
          />
        </S.InputBox>
      </Split>
      <Bottoms>
        <button
          type='button'
          onClick={save}
          disabled={!!!inputs.title || !!!inputs.telecomId || !!!inputs.img}>
          정책 {type}하기
        </button>
      </Bottoms>
    </Main>
  );
};

export default PolicyManage;
