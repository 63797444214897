import * as S from './styles/LNBStyle';
import { LNBTypes } from '@typedef/components/Common/LNB/lnb.types';

type Props = {
  LNBNav: LNBTypes | null;
};

const LNB = ({ LNBNav }: Props) => {
  return LNBNav ? (
    <S.LNB className='lnb'>
      <h2>{LNBNav.title}</h2>
      <S.Navs>
        {LNBNav.subNavs.map((nav, index) => (
          <S.Nav
            to={LNBNav.baseUrl + nav.path}
            key={nav.path}
            className='nav-item'>
            {nav.name}
          </S.Nav>
        ))}
        <span className='nav-indicator'></span>
      </S.Navs>
    </S.LNB>
  ) : null;
};

export default LNB;
