import DeviceOpen from '../components/DeviceOpen';
import { useState, useEffect } from 'react';
import useToken from '@hooks/useToken';
import { useRecoilValue } from 'recoil';
import { getShopDevices } from '@stories/Selector';
import { ShopDeviceTypes } from '@typedef/components/Inventory/shop.device.types';
import { OpenTypes } from '@typedef/components/Pay/Open/open.types';

type Props = {
  inputs: Partial<OpenTypes>;
  setInputs: React.Dispatch<React.SetStateAction<Partial<OpenTypes>>>;
  onChangeInputs: (e: { target: HTMLInputElement }) => void;
  onChangeSelect: <T extends {}>(name: string, selected: T) => void;
};

const DeviceOpenContainer = ({
  inputs,
  setInputs,
  onChangeInputs,
  onChangeSelect,
}: Props) => {
  const { getToken } = useToken();
  const shopDevices = useRecoilValue(getShopDevices(getToken()!));
  // 할부원금
  const [installmentPrice, setInstallmentPrice] = useState<number>(0);
  // 할부이자
  const [installmentInterest, setInstallmentInterest] = useState<number>(0);
  // 월 단말금
  const [total, setTotal] = useState<number>(0);

  // 모델명 선택 시
  const onClickModelName = (selected: ShopDeviceTypes) => {
    setInputs((inputs) => ({
      ...inputs,
      deviceColor: selected.device_info.color.name,
      deviceModelName: selected.device_info.device.model_name,
      devicePrice: selected.device_info.device.price,
      deviceSerialNo: selected.serial_number,
    }));
  };

  // 할부원금, 할부이자, 월 단말금 계산
  useEffect(() => {
    // 출고가
    const device = inputs.devicePrice ?? 0;
    // 공시지원금
    const contract = inputs.discountContract ?? 0;
    // 기타 할인
    const etc = inputs.discountDeviceEtc ?? 0;
    // 할부개월
    const installmentMonth = inputs.installmentMonth!;

    // 할부원금 (출고가 - 공시지원금 - 기타할인(선납금))
    setInstallmentPrice(device - contract - etc);

    // 할부이자
    setInstallmentInterest(
      Math.round(installmentPrice * Math.floor(installmentMonth / 12) * 0.059),
    );

    // 월 단말금 (할부원금 + 할부이자 / 할부개월)
    setTotal(
      Math.round((installmentPrice + installmentInterest) / installmentMonth),
    );
  }, [
    inputs.devicePrice,
    inputs.discountContract,
    inputs.discountDeviceEtc,
    inputs.installmentMonth,
    installmentInterest,
    installmentPrice,
  ]);

  return (
    <DeviceOpen
      inputs={inputs}
      onChangeInputs={onChangeInputs}
      onChangeSelect={onChangeSelect}
      shopDevices={shopDevices}
      onClickModelName={onClickModelName}
      installmentPrice={installmentPrice}
      installmentInterest={installmentInterest}
      total={total}
    />
  );
};

export default DeviceOpenContainer;
