import * as S from '@styles/components/TableViewStyle';
import CTable from '@components/Common/CTable/CTable';
import {TableColumnTypes} from '@typedef/components/Common/CTable/table.column.types';
import {ShopDeviceTypes} from '@typedef/components/Inventory/shop.device.types';
import {TempOutTypes} from "@components/Inventory/TempOut/containers/InventoryTempOutContainer";

type Props = {
    type: TempOutTypes
    columns: TableColumnTypes<ShopDeviceTypes>[];
    tableData: ShopDeviceTypes[];
    release: () => void;
    cancelRelease: () => Promise<void>;
    deleteShopDevice: () => Promise<void>;
};

const InventoryTempOut = ({
                              type,
                              columns,
                              tableData,
                              release,
                              cancelRelease,
                              deleteShopDevice,
                          }: Props) => {
    return (
        <S.Main>
            <S.TopBtns>
                <h3>전체 {tableData.length}</h3>
                <S.Btns>
                    <S.Btn type='button' onClick={release} className='active minus'>
                        재고{type}
                    </S.Btn>
                    <S.Btn type='button' onClick={cancelRelease}>
                        입고처리
                    </S.Btn>
                    <S.Btn type='button' onClick={deleteShopDevice}>
                        삭제
                    </S.Btn>
                </S.Btns>
            </S.TopBtns>
            <CTable columns={columns} tableData={tableData}/>
        </S.Main>
    );
};

export default InventoryTempOut;
