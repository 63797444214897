import styled from "styled-components";
import { Main, Split, Bottoms } from "@styles/components/ModalBodyStyle";
import * as S from "@styles/components/FormStyle";
import CSelect from "@components/Common/CSelect/CSelect";
import { DeviceTypes } from "@typedef/components/Device/device.types";
import { SelectDefaultTypes } from "@typedef/components/Common/CSelect/select.default.types";
import { MakerTypes } from "@typedef/components/Device/Maker/maker.types";

type Props = {
  type: string;
  inputs: Partial<DeviceTypes>;
  unit: "MB" | "GB" | "TB";
  onChangeInputs: (e: { target: HTMLInputElement }) => void;
  onChangeSelect: (name: string, selected: any) => void;
  makers: MakerTypes[];
  filteredSeries: string[];
  onChangeSeries: (value: string) => void;
  save: () => Promise<void>;
};

const DeviceManage = ({
  type,
  inputs,
  unit,
  onChangeInputs,
  onChangeSelect,
  makers,
  filteredSeries,
  onChangeSeries,
  save,
}: Props) => {
  return (
    <Main width={35}>
      <Split>
        <S.InputBox isRow>
          <p>
            제조사<span>*</span>
          </p>
          <CSelect<MakerTypes>
            options={makers}
            value={(maker: MakerTypes) => maker.idx}
            label={(maker: MakerTypes) => maker.name}
            defaultValue={makers.filter(
              (maker) => maker.idx === inputs.maker?.idx
            )}
            func={(selected: MakerTypes) => onChangeSelect("maker", selected)}
            placeholder="제조사"
          />
        </S.InputBox>

        <S.InputBox isRow>
          <p>
            모델명<span>*</span>
          </p>
          <S.Input
            name="model_name"
            onChange={onChangeInputs}
            defaultValue={inputs.model_name}
            placeholder="ex) AIP13-512"
          />
        </S.InputBox>
        <S.InputBox isRow>
          <p>
            펫네임<span>*</span>
          </p>
          <S.Input
            name="pet_name"
            onChange={onChangeInputs}
            defaultValue={inputs.pet_name}
            placeholder="ex) 아이폰 13 프로"
          />
        </S.InputBox>
        <S.InputBox isRow>
          <p>
            용량<span>*</span>
          </p>
          <S.TwoInputs>
            <S.Input
              type="number"
              name="volume"
              onChange={onChangeInputs}
              defaultValue={inputs.volume}
              placeholder="숫자"
            />
            <CSelect<SelectDefaultTypes>
              options={volumes}
              value={(volume: SelectDefaultTypes) => volume.value}
              label={(volume: SelectDefaultTypes) => volume.name}
              defaultValue={volumes.filter((volume) => volume.value === unit)}
              func={(selected: SelectDefaultTypes) =>
                onChangeSelect("unit", selected.value)
              }
              autoFocus
              placeholder="단위"
            />
          </S.TwoInputs>
        </S.InputBox>
        <S.InputBox isRow>
          <p>
            가격<span>*</span>
          </p>
          <S.MoneyInputs>
            <S.Input
              name="price"
              onChange={onChangeInputs}
              defaultValue={inputs.price}
            />
          </S.MoneyInputs>
        </S.InputBox>
        <S.InputBox isRow>
          <p>시리즈</p>
          <S.Input
            value={inputs.series}
            onChange={(e) => onChangeSeries(e.target.value)}
            placeholder="아래에서 선택 또는 입력해 주세요"
          />
        </S.InputBox>
        <SeriesButtonContainer>
          {filteredSeries.map((series) => (
            <button
              key={series}
              onClick={() => onChangeSelect("series", series)}
            >
              {series}
            </button>
          ))}
        </SeriesButtonContainer>
      </Split>
      <Bottoms>
        <button
          type="button"
          onClick={save}
          disabled={
            !!!inputs.model_name ||
            !!!inputs.maker ||
            !!!inputs.pet_name ||
            !!!inputs.volume ||
            !!!inputs.price
          }
        >
          단말 {type}하기
        </button>
      </Bottoms>
    </Main>
  );
};

export default DeviceManage;

const volumes = [
  {
    name: "MB",
    value: "MB",
  },
  {
    name: "GB",
    value: "GB",
  },
  {
    name: "TB",
    value: "TB",
  },
];

const SeriesButtonContainer = styled.div`
  height: 160px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 8px;
  margin-left: 120px;
  overflow-y: auto;

  & > button {
    padding: 4px;
    background-color: ${({ theme }) => theme.colors.grey200};
    border-radius: 4px;
    font-size: 15px;
    font-weight: 500;
  }
`;
