import * as S from '@styles/components/TableViewStyle';
import CTable from '@components/Common/CTable/CTable';
import { TableColumnTypes } from '@typedef/components/Common/CTable/table.column.types';
import { DealerTypes } from '@typedef/components/Setup/Dealer/dealer.types';

type Props = {
  columns: TableColumnTypes[];
  tableData: DealerTypes[];
  insertDealer: () => void;
  updateDealer: (idx?: number) => void;
  deleteDealer: () => Promise<void>;
};

const SetupDealer = ({
  columns,
  tableData,
  insertDealer,
  updateDealer,
  deleteDealer,
}: Props) => {
  return (
    <S.Main>
      <S.TopBtns>
        <h3>전체 {tableData.length}</h3>

        <S.Btns>
          <S.Btn type='button' onClick={insertDealer} className='active'>
            정산점등록
          </S.Btn>
          <S.Btn type='button' onClick={() => updateDealer()}>
            수정
          </S.Btn>
          <S.Btn type='button' onClick={deleteDealer}>
            삭제
          </S.Btn>
        </S.Btns>
      </S.TopBtns>
      <CTable columns={columns} tableData={tableData} />
    </S.Main>
  );
};

export default SetupDealer;
