import TelecomPhoneplan from '../TelecomPhoneplan';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { apiRoute, requestSecureDelete, requestSecureGet } from '@libs/api';
import useToken from '@hooks/useToken';
import { useSetRecoilState } from 'recoil';
import { loading, modal, modalHeader } from '@stories/Atom';
import { TableColumnTypes } from '@typedef/components/Common/CTable/table.column.types';
import { PhoneplanTypes } from '@typedef/components/Telecom/Phoneplan/phoneplan.types';
import PhoneplanManageContainer from './PhoneplanManageContainer';

const TelecomPhoneplanContainer = () => {
  const { getToken } = useToken();
  // 테이블 데이터
  const [tableData, setTableData] = useState<PhoneplanTypes[]>([]);
  const [checkedIdxes, setCheckedIdxes] = useState<number[]>([]);
  // 모달설정
  const setModal = useSetRecoilState(modal);
  const setModalHeader = useSetRecoilState(modalHeader);
  // 테이블 로딩
  const setLoading = useSetRecoilState(loading);
  const [reload, setReload] = useState<number>(0);

  // 요금제 상세조회 및 수정
  const updatePhoneplan = useCallback(
    (idx?: number) => {
      if (!!!idx && checkedIdxes.length === 0) {
        alert('수정할 요금제를 선택해 주세요.');
        return;
      } else if (!!!idx && checkedIdxes.length > 1) {
        alert('수정할 요금제를 하나만 선택해 주세요.');
        return;
      }
      setModalHeader('요금제 상세조회');
      setModal(
        <PhoneplanManageContainer
          idx={idx ?? checkedIdxes[0]}
          close={() => setModal(null)}
          reload={() => setReload((prev) => prev + 1)}
        />,
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [checkedIdxes],
  );

  // 체크박스
  const onChangeCheckbox = useCallback(
    (idx: number) => {
      if (checkedIdxes.includes(idx)) {
        setCheckedIdxes(
          checkedIdxes.filter((checkedIdx) => checkedIdx !== idx),
        );
        return;
      }
      setCheckedIdxes((idxes) => [...idxes, idx]);
    },
    [checkedIdxes],
  );

  // 테이블 컬럼
  const columns: TableColumnTypes<PhoneplanTypes>[] = useMemo(
    () => [
      {
        Header: '',
        accessor: 'phonePlanId',
        disableSortBy: true,
        width: 70,
        Cell: ({ value }: any) => (
          <input
            type='checkbox'
            defaultChecked={checkedIdxes.includes(value)}
            onChange={() => onChangeCheckbox(value)}
          />
        ),
      },
      {
        Header: 'No',
        width: 70,
        Cell: ({ row }: any) => <>{row.index + 1}</>,
      },
      {
        Header: '통신사',
        accessor: 'telecom.name',
        width: 70,
      },
      {
        Header: '요금제명',
        accessor: 'name',
        disableSortBy: true,
        Cell: ({ row, value }: any) => (
          <div
            className='detail'
            onClick={() => updatePhoneplan(row.original.phonePlanId)}
          >
            {value}
          </div>
        ),
      },
      {
        Header: '카테고리',
        accessor: 'category',
        disableSortBy: true,
      },
      {
        Header: '데이터',
        accessor: 'dataExp',
        disableSortBy: true,
      },
      {
        Header: '음성통화',
        accessor: 'callExp',
        disableSortBy: true,
      },
      {
        Header: '문자메시지',
        accessor: 'mailExp',
        disableSortBy: true,
      },
    ],
    [checkedIdxes, onChangeCheckbox, updatePhoneplan],
  );

  // 요금제 등록
  const insertPhoneplan = () => {
    setModalHeader('요금제 등록');
    setModal(
      <PhoneplanManageContainer
        close={() => setModal(null)}
        reload={() => setReload((prev) => prev + 1)}
      />,
    );
  };

  // 요금제 삭제
  const deletePhoneplan = async () => {
    if (checkedIdxes.length === 0) {
      alert('삭제할 요금제를 선택해 주세요');
      return;
    } else if (
      window.confirm(
        `선택한 ${checkedIdxes.length}개의 요금제를 삭제하시겠습니까?`,
      )
    ) {
      await Promise.all(
        checkedIdxes.map(
          (idx) =>
            new Promise((resolve, reject) => {
              requestSecureDelete(
                apiRoute.telecom.phoneplan.deletePhoneplan + idx,
                {},
                getToken()!,
              ).then((data) => {
                if (data.config.status >= 200 && data.config.status < 400) {
                  resolve(data);
                }
              });
            }),
        ),
      ).then(() => {
        alert('성공적으로 삭제가 완료되었습니다.');
        setReload((prev) => prev + 1);
      });
    }
  };

  const getTableData = async () => {
    const { config, data } = await requestSecureGet<{
      phonePlans: PhoneplanTypes[];
    }>(apiRoute.telecom.phoneplan.getPhoneplans, {}, getToken()!);
    if (config.status >= 200 && config.status < 400) {
      setTableData(data.phonePlans);
      setLoading(false);
    } else {
      setTableData([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  return (
    <TelecomPhoneplan
      columns={columns}
      tableData={tableData}
      insertPhoneplan={insertPhoneplan}
      updatePhoneplan={updatePhoneplan}
      deletePhoneplan={deletePhoneplan}
    />
  );
};

export default TelecomPhoneplanContainer;
