import { Suspense } from 'react';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle } from '@styles/global-style';
import { theme } from '@styles/theme';
import Loading from '@components/Common/Loading/Loading';
import RootNavigation from '@routes/RootNavigation';
import { useRecoilValue } from 'recoil';
import { mobileNB, modal } from '@stories/Atom';

function MainApp() {
  const showMobileNB = useRecoilValue(mobileNB);
  const currentModal = useRecoilValue(modal);
  return (
    <Suspense fallback={<Loading />}>
      <ThemeProvider theme={theme}>
        <GlobalStyle overflow={showMobileNB || !!currentModal} />
        <RootNavigation />
      </ThemeProvider>
    </Suspense>
  );
}
export {};
export default MainApp;
