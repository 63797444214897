import ShopRequest from "../ShopRequest";
import { useCallback, useEffect, useMemo, useState } from "react";
import { apiRoute, requestSecureGet, requestSecurePatch } from "@libs/api";
import useToken from "@hooks/useToken";
import { useSetRecoilState } from "recoil";
import { loading } from "@stories/Atom";
import { TableColumnTypes } from "@typedef/components/Common/CTable/table.column.types";
import { ShopTypes } from "@typedef/components/Shop/shop.types";

const ShopRequestContainer = () => {
  const { getToken } = useToken();
  // 테이블 데이터
  const [tableData, setTableData] = useState<ShopTypes[]>([]);
  const [checkedIdxes, setCheckedIdxes] = useState<number[]>([]);
  // 테이블 로딩
  const setLoading = useSetRecoilState(loading);
  const [reload, setReload] = useState<number>(0);

  // 체크박스
  const onChangeCheckbox = useCallback(
    (idx: number) => {
      if (checkedIdxes.includes(idx)) {
        setCheckedIdxes(
          checkedIdxes.filter((checkedIdx) => checkedIdx !== idx)
        );
        return;
      }
      setCheckedIdxes((idxes) => [...idxes, idx]);
    },
    [checkedIdxes]
  );

  // 테이블 컬럼
  const columns: TableColumnTypes<ShopTypes>[] = useMemo(
    () => [
      {
        accessor: "shopId",
        disableSortBy: true,
        width: 70,
        Cell: ({ value }) => (
          <input
            type="checkbox"
            defaultChecked={checkedIdxes.includes(value as number)}
            onChange={() => onChangeCheckbox(value as number)}
          />
        ),
      },
      {
        Header: "No",
        width: 70,
        Cell: ({ row }) => <div>{tableData.length - row.index}</div>,
      },
      {
        Header: "업체명",
        accessor: "name",
        disableSortBy: true,
      },
      {
        Header: "업체구분",
        accessor: (originRow) =>
          originRow.role === "AGENCY" ? "대리점" : "판매점",
        disableSortBy: true,
      },
      {
        Header: "대표전화",
        accessor: "phone",
        disableSortBy: true,
      },
      {
        Header: "팩스",
        accessor: "fax",
        disableSortBy: true,
      },
      {
        Header: "주소",
        accessor: "address",
        disableSortBy: true,
      },
      {
        Header: "담당자",
        accessor: "owner.name",
        disableSortBy: true,
      },
    ],
    [checkedIdxes, onChangeCheckbox, tableData.length]
  );

  const getTableData = async () => {
    setLoading(true);
    const { config, data } = await requestSecureGet<{ shops: ShopTypes[] }>(
      apiRoute.shop.getShops + "?status=REQUEST",
      {},
      getToken()!
    );
    if (config.status >= 200 && config.status < 400) {
      setTableData(data.shops);
      setLoading(false);
    } else {
      setTableData([]);
      setLoading(false);
    }
  };

  const accept = async () => {
    await Promise.all(
      checkedIdxes.map(
        (idx) =>
          new Promise((resolve, reject) =>
            requestSecurePatch(
              apiRoute.shop.patchShop,
              {},
              {
                shopId: idx,
                status: "DONE",
              },
              getToken()!
            ).then(({ config, data }) => {
              if (config.status >= 200 && config.status < 400) {
                resolve(data);
              } else {
                reject(`http request failed with ${config.status}`);
              }
            })
          )
      )
    )
      .then(() => {
        setReload((prev) => prev);
      })
      .catch((err) => alert(err));
  };

  useEffect(() => {
    getTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  return (
    <ShopRequest columns={columns} tableData={tableData} accept={accept} />
  );
};

export default ShopRequestContainer;
