import styled from "styled-components";
import {Bottoms, Main, Split} from "@styles/components/ModalBodyStyle";
import * as S from "@styles/components/FormStyle";
import {Tbody, Thead} from "../styles/InventoryTempoutStyle";
import {ShopDeviceReleaseTypes} from "@typedef/components/Inventory/Release/shop.device.release.types";
import {DateTypes, days, months, years} from "@libs/date";
import CSelect from "@components/Common/CSelect/CSelect";
import {ClientDepartmentTypes, ClientTypes,} from "@typedef/components/Client/client.types";
import {useMemo} from "react";

type Props = {
    type: "수리" | "대여";
    searchDevices: ShopDeviceReleaseTypes[];
    checkedIdxes: number[];
    clients: ClientTypes[];
    searchBySerial: (value: string) => void;
    onChangeCheckbox: (idx: number, checked: boolean) => void;
    inputs: {
        year: string;
        month: string;
        day: string;
        memo: string;
        client: ClientTypes | null;
        department: ClientDepartmentTypes | null;
    };
    onChangeInputs: (e: { target: HTMLInputElement }) => void;
    onChangeSelect: (name: string, selected: string) => void;
    tempOut: () => Promise<void>;
    onChangeClient: (client: ClientTypes | null) => void;
    onChangeDepartment: (dept: ClientDepartmentTypes | null) => void;
};

const ShopDeviceTempout = ({
                              type,
                              searchDevices,
                              checkedIdxes,
                              clients,
                              searchBySerial,
                              onChangeCheckbox,
                              inputs,
                              onChangeInputs,
                              onChangeSelect,
                              tempOut,
                              onChangeClient,
                              onChangeDepartment,
                          }: Props) => {
    const departments = useMemo(
        () =>
            clients.find((client) => client.clientId === inputs.client?.clientId)
                ?.departments,
        [clients, inputs.client]
    );

    return (
        <Main>
            <Split height={70}>
                <S.SubTitle>
                    <h3>1. {type} 정보 입력</h3>
                </S.SubTitle>
                <InfoContainer>
                    <div>
                        <S.InputBox mr={1} mb={0}>
                            <p>대여일 *</p>
                            <S.ThreeInputs>
                                <CSelect
                                    options={years()}
                                    value={(year: DateTypes) => year.value}
                                    label={(year: DateTypes) => year.name}
                                    defaultValue={years().filter(
                                        (year) => year.value === inputs.year
                                    )}
                                    func={(selected: DateTypes) =>
                                        onChangeSelect("year", selected.value)
                                    }
                                    height={175}
                                    indicator="년"
                                />
                                <CSelect
                                    options={months()}
                                    value={(month: DateTypes) => month.value}
                                    label={(month: DateTypes) => month.name}
                                    defaultValue={months().filter(
                                        (month) => month.value === inputs.month
                                    )}
                                    func={(selected: DateTypes) =>
                                        onChangeSelect("month", selected.value)
                                    }
                                    height={175}
                                    indicator="월"
                                />
                                <CSelect
                                    options={days()}
                                    value={(day: DateTypes) => day.value}
                                    label={(day: DateTypes) => day.name}
                                    defaultValue={days().filter(
                                        (day) => day.value === inputs.day
                                    )}
                                    func={(selected: DateTypes) =>
                                        onChangeSelect("day", selected.value)
                                    }
                                    height={175}
                                    indicator="일"
                                />
                            </S.ThreeInputs>
                        </S.InputBox>
                        <S.InputBox>
                            <p>거래처</p>
                            <S.TwoInputs>
                                <CSelect<ClientTypes>
                                    options={clients}
                                    value={(client: ClientTypes) => client.clientId}
                                    label={(client: ClientTypes) => client.name}
                                    defaultValue={clients.filter(
                                        (client) => client === inputs.client
                                    )}
                                    func={(selected: ClientTypes | null) =>
                                        onChangeClient(selected)
                                    }
                                    clearable
                                    placeholder="거래처 선택"
                                />
                                {inputs.client && (
                                    <CSelect<ClientDepartmentTypes>
                                        options={departments || []}
                                        value={(dept: ClientDepartmentTypes) => dept.departmentId}
                                        label={(dept: ClientDepartmentTypes) => dept.name}
                                        defaultValue={departments?.filter(
                                            (dept) => dept === inputs.department
                                        )}
                                        func={(selected: ClientDepartmentTypes | null) =>
                                            onChangeDepartment(selected)
                                        }
                                        clearable
                                        placeholder="부서 선택"
                                    />
                                )}
                            </S.TwoInputs>
                        </S.InputBox>
                    </div>
                    <S.InputBox mr={1} mb={0}>
                        <p>메모</p>
                        <S.Input placeholder="메모" name="memo" onChange={onChangeInputs}/>
                    </S.InputBox>
                </InfoContainer>
                <S.SubTitle isRow align="center" justify="space-between">
                    <h3>2. {type}할 단말 선택&nbsp;({checkedIdxes.length})</h3>
                    <S.Search>
                        <S.Input
                            placeholder="일련번호 검색"
                            onChange={(e) => searchBySerial(e.target.value)}
                        />
                    </S.Search>
                </S.SubTitle>
                <S.Table>
                    <Thead>
                        <th className="small"></th>
                        <th>통신/제조사</th>
                        <th>모델명</th>
                        <th>색상</th>
                        <th>일련번호</th>
                    </Thead>
                    <Tbody>
                        {searchDevices.map((device, index) => (
                            <tr key={`${device}${index}`}>
                                <td className="small">
                                    <input
                                        type="checkbox"
                                        onChange={(e) =>
                                            onChangeCheckbox(device.idx, e.target.checked)
                                        }
                                    />
                                </td>
                                <td>{device.telecom_maker_name}</td>
                                <td>{device.model_name}</td>
                                <td>{device.color}</td>
                                <td>{device.serial_number}</td>
                            </tr>
                        ))}
                    </Tbody>
                </S.Table>
            </Split>
            <Bottoms self="flex-end">
                <button
                    type="button"
                    onClick={tempOut}
                    disabled={checkedIdxes.length === 0}
                >
                    선택한 재고 {type}하기&nbsp;({checkedIdxes.length})
                </button>
            </Bottoms>
        </Main>
    );
};

export default ShopDeviceTempout;

const InfoContainer = styled.section`
  ${({theme}) => theme.flex.col}
  gap: 10px;
  margin-bottom: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;

  & > div {
    ${({theme}) => theme.flex.row}
  }

  input {
    background-color: #fff;
  }
`;
