import * as S from './styles/GNBStyle';
import img from '@assets/image';
import { Link } from 'react-router-dom';
import { GNBTypes } from '@typedef/components/Common/GNB/gnb.types';
import {
  FiChevronDown,
  FiSliders,
  FiHelpCircle,
  FiLogOut,
} from 'react-icons/fi';
type Props = {
  name: string;
  shopName: string;
  navs: GNBTypes[];
  showMenu: boolean;
  setShowMenu: React.Dispatch<React.SetStateAction<boolean>>;
  logout: () => void;
};

const GNB = ({
  name,
  shopName,
  navs,
  showMenu,
  setShowMenu,
  logout,
}: Props) => {
  return (
    <S.GNB>
      <S.Navs>
        <Link to=''>
          <img src={img.mcallLogo} alt='MCALL로고' />
        </Link>
        {navs.map((nav, index) => (
          <S.Nav to={nav.path} key={index}>
            {nav.name}
          </S.Nav>
        ))}
      </S.Navs>
      <S.Profile
        onMouseEnter={() => setShowMenu(true)}
        onMouseLeave={() => setShowMenu(false)}>
        <p className='name'>
          {name}님<FiChevronDown className='icon' />
        </p>
        {showMenu && (
          <S.ProfileMenuBox>
            <S.ProfileMenu>
              <Link to='setup/basic'>
                <FiSliders className='icon' />
                설정
              </Link>
              <Link to='help/notice'>
                <FiHelpCircle className='icon' />
                고객센터
              </Link>
              <button type='button' onClick={logout}>
                <FiLogOut className='icon' /> 로그아웃
              </button>
            </S.ProfileMenu>
          </S.ProfileMenuBox>
        )}
      </S.Profile>
    </S.GNB>
  );
};

export default GNB;
