import * as S from './styles/SheduleStyle';
import CCalendar from '@components/Common/CCalendar/CCalendar';
import { ScheduleTypes } from '@typedef/components/Schedule/schedule.types';
import { FiEdit3 } from 'react-icons/fi';

type Props = {
  schedules: ScheduleTypes[];
  filteredSchedules: ScheduleTypes[];
  clickedDate: string;
  onChangeDate: (date: Date | Date[]) => void;
  displaySchedule: (date: Date) => ScheduleTypes[];
  insertSchedule: () => void;
  updateSchedule: (idx: number) => void;
};

const Schedule = ({
  schedules,
  filteredSchedules,
  clickedDate,
  onChangeDate,
  displaySchedule,
  insertSchedule,
  updateSchedule,
}: Props) => {
  return (
    <S.Main>
      <S.Schedules>
        <header>
          <h3>{clickedDate}</h3>
          <button type='button' onClick={insertSchedule}>
            <FiEdit3 />
          </button>
        </header>
        {filteredSchedules.length ? (
          filteredSchedules.map((schedule) => (
            <S.Schedule
              type='button'
              key={schedule.scheduleId}
              onClick={() => updateSchedule(schedule.scheduleId)}
              color={schedule.colorHex}>
              <h4>{schedule.title}</h4>
              <p>{schedule.content}</p>
            </S.Schedule>
          ))
        ) : (
          <S.Schedule className='nothing'>일정이 없습니다.</S.Schedule>
        )}
      </S.Schedules>
      <S.Calendar>
        <CCalendar
          onChangeDate={onChangeDate}
          displaySchedule={displaySchedule}
        />
      </S.Calendar>
    </S.Main>
  );
};

export default Schedule;
