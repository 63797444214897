import dayjs from "dayjs";

// Timezone > YYYY-MM-DD HH:MM
export function formatTimezone(timezone: string, time?: "time") {
  let formatted = "";

  if (timezone) {
    // split[0] : YYYY-MM-DD
    const split = timezone.split("T");
    formatted += split[0];
    if (time) {
      const splitTime = split[1].split(":");
      // splitTime[0] : HH
      // splitTime[1] : MM
      formatted += ` ${splitTime[0]}:${splitTime[1]}`;
    }
  }

  return formatted;
}

// Timezone => {YYYY, MM, DD}
export function splitTimezone(timezone: string) {
  const date = formatTimezone(timezone);
  const split = date.split("-");

  return split;
}

export function splitFormatDate(date: string) {
  const split = date.split(" ");
  const splitDate = split[0].split("-");
  const splitTime = split[1].split(":");

  const formatDate = new Date(+splitDate[0], +splitDate[1] - 1, +splitDate[2]);

  return {
    formatDate,
    splitTime,
  };
}

// date > YYYY-MM-DD | YYYY-MM-DD HH:MM
export function formatDate(date: Date, time?: "time") {
  let formatted = "";

  if (date) {
    formatted += dayjs(date).format("YYYY-MM-DD");

    if (time) {
      formatted += ` ${dayjs(date).format("HH:MM")}`;
    }
  }

  return formatted;
}

export function joinPhone(phone1?: string, phone2?: string, phone3?: string) {
  let joined = "";

  if (phone1 && phone2) {
    joined += `${phone1}-${phone2}`;
    if (phone3) {
      joined += `-${phone3}`;
    }
  }

  return joined;
}

export function joinBirthday(yyyy?: string, month?: string, day?: string) {
  let joined = "";

  if (yyyy && month && day) {
    joined += `${yyyy}-${month}-${day}`;
  }

  return joined;
}

// 용량 값과 단위 분리 ('128GB' > 128, GB)
export function splitVolume(volume: string | null) {
  if (volume === null) {
    return { num: "0", unit: "GB" as "GB" };
  }
  const num = volume.replace(/[^0-9]/g, "");
  const unit = volume.replace(/[^a-z]/gi, "") as "MB" | "GB" | "TB";

  return {
    num,
    unit,
  };
}

// 일자 계산
export function calculateDay(date: string) {
  // 오늘 - date
  const diffDate = new Date().getTime() - new Date(date).getTime();
  // 일 : 밀리세컨 * 초 * 분 * 시
  return Math.ceil(diffDate / (1000 * 60 * 60 * 24));
}
