import PriceInfo from '../components/PriceInfo';
// import { useMemo } from 'react';
// import useUser from '@hooks/useUser';

const PriceInfoContainer = () => {
  // const { getUser } = useUser();
  // const role = useMemo(() => getUser().role, [getUser]);

  return <PriceInfo />;
};

export default PriceInfoContainer;
