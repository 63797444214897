import * as S from "@styles/components/TableViewStyle";
import CTable from "@components/Common/CTable/CTable";
import { TableColumnTypes } from "@typedef/components/Common/CTable/table.column.types";
import { CustomerTypes } from "@typedef/components/Customer/customer.types";

type Props = {
  columns: TableColumnTypes<CustomerTypes>[];
  tableData: CustomerTypes[];
  insertCustomer: () => void;
  updateCustomer: (idx?: number) => void;
  deleteCustomer: () => Promise<void>;
};

const Customer = ({
  columns,
  tableData,
  insertCustomer,
  updateCustomer,
  deleteCustomer,
}: Props) => {
  return (
    <S.Main>
      <S.TopBtns>
        <h3>전체 {tableData.length}</h3>

        <S.Btns>
          <S.Btn type="button" onClick={insertCustomer} className="active">
            고객등록
          </S.Btn>
          <S.Btn type="button" onClick={() => updateCustomer()}>
            수정
          </S.Btn>
          <S.Btn type="button" onClick={deleteCustomer}>
            삭제
          </S.Btn>
        </S.Btns>
      </S.TopBtns>
      <CTable columns={columns} tableData={tableData} />
    </S.Main>
  );
};

export default Customer;
