import MobileNB from '../MobileNB';
import { useEffect } from 'react';
// import useUser from '@hooks/useUser';
import {
  jsolNavs,
  // agencyNavs,
  // storeNavs,
} from '@components/Common/LNB/LNBData';
// import { LNBTypes } from '@typedef/components/Common/LNB/lnb.types';

const MobileNBContainer = () => {
  // const { getUser } = useUser();
  // const role = useMemo(() => getUser().role, [getUser]);
  // const [mobileNBData, setMobileNBData] = useState<LNBTypes[]>([]);

  // const getRoleNavs = () => {
  //   if (role.includes('JSOL')) {
  //     setMobileNBData(jsolNavs);
  //   } else if (role.includes('AGENCY')) {
  //     setMobileNBData(agencyNavs);
  //   } else {
  //     setMobileNBData(storeNavs);
  //   }
  // };

  useEffect(() => {
    // getRoleNavs();
  }, []);

  return <MobileNB mobileNBData={jsolNavs} />;
};

export default MobileNBContainer;
