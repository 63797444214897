import { Main, Split, Bottoms } from '@styles/components/ModalBodyStyle';
import * as S from '@styles/components/FormStyle';
import { EmployeeInsertTypes } from '@typedef/components/Setup/Employee/employee.insert.types';
import CSelect from '@components/Common/CSelect/CSelect';
import { DateTypes, days, months, years } from '@libs/date';

type Props = {
  type: '수정' | '등록';
  inputs: Partial<EmployeeInsertTypes>;
  onChangeInputs: (e: { target: HTMLInputElement }) => void;
  onChangeSelect: (name: string, selected: any) => void;
  msg: {
    id?: string | null | undefined;
    passwd: string | null;
    passwdCheck: string | null;
  };
  save: () => Promise<void>;
};

const EmployeeManage = ({
  type,
  inputs,
  onChangeInputs,
  onChangeSelect,
  msg,
  save,
}: Props) => {
  return (
    <Main width={35}>
      <Split>
        <S.InputBox isRow>
          <p>
            이름<span>*</span>
          </p>
          <S.Input
            name='name'
            defaultValue={inputs.name}
            onChange={onChangeInputs}
            placeholder='이름'
          />
        </S.InputBox>
        <S.InputBox isRow>
          <p>
            아이디<span>*</span>
          </p>
          <S.Input
            name='accountId'
            defaultValue={inputs.accountId}
            onBlur={onChangeInputs}
            placeholder='4~16 영문 대소문자, 숫자만 가능'
            disabled={type === '수정'}
          />
        </S.InputBox>
        {msg.id && <S.Msg>{msg.id}</S.Msg>}
        {type === '등록' && (
          <S.Col>
            <S.InputBox isRow>
              <p>
                비밀번호<span>*</span>
              </p>
              <S.Input
                type='password'
                name='password'
                onBlur={onChangeInputs}
                placeholder='8~20 영문, 숫자 포함'
              />
            </S.InputBox>
            {msg.passwd && <S.Msg>{msg.passwd}</S.Msg>}
            <S.InputBox isRow mb={1}>
              <p>
                비밀번호 확인<span>*</span>
              </p>
              <S.Input
                type='password'
                name='passwordCheck'
                onBlur={onChangeInputs}
                placeholder='비밀번호 확인'
              />
            </S.InputBox>
            {msg.passwdCheck && <S.Msg>{msg.passwdCheck}</S.Msg>}
          </S.Col>
        )}
        <S.InputBox isRow>
          <p>휴대전화</p>
          <S.ThreeInputs>
            <S.Input
              name='phone1'
              defaultValue={inputs.phone1}
              onChange={onChangeInputs}
            />
            <S.Input
              name='phone2'
              defaultValue={inputs.phone2}
              onChange={onChangeInputs}
            />
            <S.Input
              name='phone3'
              defaultValue={inputs.phone3}
              onChange={onChangeInputs}
            />
          </S.ThreeInputs>
        </S.InputBox>
        <S.InputBox isRow>
          <p>이메일</p>
          <S.Input
            name='email'
            defaultValue={inputs.email}
            onChange={onChangeInputs}
          />
        </S.InputBox>
        <S.InputBox isRow>
          <p>생년월일</p>
          <S.ThreeInputs>
            <CSelect
              options={years()}
              value={(year: DateTypes) => year.value}
              label={(year: DateTypes) => year.name}
              defaultValue={years().filter(
                (year) => year.value === inputs.year,
              )}
              func={(selected: DateTypes) =>
                onChangeSelect('year', selected.value)
              }
              height={150}
              indicator='년'
              placeholder=''
            />
            <CSelect
              options={months()}
              value={(month: DateTypes) => month.value}
              label={(month: DateTypes) => month.name}
              defaultValue={months().filter(
                (month) => month.value === inputs.month,
              )}
              func={(selected: DateTypes) =>
                onChangeSelect('month', selected.value)
              }
              height={150}
              indicator='월'
              placeholder=''
            />
            <CSelect
              options={days()}
              value={(day: DateTypes) => day.value}
              label={(day: DateTypes) => day.name}
              defaultValue={days().filter((day) => day.value === inputs.day)}
              func={(selected: DateTypes) =>
                onChangeSelect('day', selected.value)
              }
              height={150}
              indicator='일'
              placeholder=''
            />
          </S.ThreeInputs>
        </S.InputBox>
      </Split>
      <Bottoms>
        <button type='button' onClick={save}>
          직원 {type}하기
        </button>
      </Bottoms>
    </Main>
  );
};

export default EmployeeManage;
