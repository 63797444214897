import * as S from '@styles/components/TableViewStyle';
// import CTable from '@components/Common/CTable/CTable';
// import { TableColumnTypes } from '@typedef/components/Common/CTable/table.column.types';

// type Props = {};

const PayCredit = () => {
  return (
    <S.Main>
      <S.TopBtns>{/* <h3>전체 {tableData.length}</h3> */}</S.TopBtns>
    </S.Main>
  );
};

export default PayCredit;
