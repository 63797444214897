import * as S from "@styles/components/TableViewStyle";
import CTable from "@components/Common/CTable/CTable";
import { TableColumnTypes } from "@typedef/components/Common/CTable/table.column.types";
import { ShopTypes } from "@typedef/components/Shop/shop.types";

type Props = {
  columns: TableColumnTypes<ShopTypes>[];
  tableData: ShopTypes[];
  accept: () => Promise<void>;
};

const ShopRequest = ({ columns, tableData, accept }: Props) => {
  return (
    <S.Main>
      <S.TopBtns>
        <h3>전체 {tableData.length}</h3>
        <S.Btns>
          <S.Btn type="button" onClick={accept} className="active check">
            가입승인
          </S.Btn>
          {/* <S.Btn type="button">반려</S.Btn> */}
        </S.Btns>
      </S.TopBtns>
      <CTable columns={columns} tableData={tableData} />
    </S.Main>
  );
};

export default ShopRequest;
