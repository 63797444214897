import PayOpen from '../PayOpen';
import { useState, useEffect, useMemo, useCallback } from 'react';
import {
  apiRoute,
  requestSecureDelete,
  requestSecureGet,
  requestSecurePatch,
} from '@libs/api';
import useToken from '@hooks/useToken';
import useUser from '@hooks/useUser';
import { useSetRecoilState } from 'recoil';
import { loading, modal, modalHeader } from '@stories/Atom';
import { OpenTypes } from '@typedef/components/Pay/Open/open.types';
import ShopDeviceOpenContainer from './ShopDeviceOpenContainer';
import { TableColumnTypes } from '@typedef/components/Common/CTable/table.column.types';
import CopyCell from '@components/Common/CTable/components/CopyCell';
import DetailCell from '@components/Common/CTable/components/DetailCell';
import {
  getEndDate,
  getStartDate,
  updateEndDate,
  updateStartDate,
} from '@libs/filter';
import { formatDate } from '@libs/commonFuncs';

// 정산관리 > 개통관리 (대리점, 판매점)
const PayOpenContainer = () => {
  const { getToken } = useToken();
  const { getUser } = useUser();
  const role = useMemo(() => getUser().role, [getUser]);
  // 테이블 데이터
  const [tableData, setTableData] = useState<OpenTypes[]>([]);
  const [checkedIdxes, setCheckedIdxes] = useState<number[]>([]);
  const [filterDate, setFilterDate] = useState<{
    start: string;
    end: string;
  }>({
    start: getStartDate(),
    end: getEndDate(),
  });
  // 모달설정
  const setModal = useSetRecoilState(modal);
  const setModalHeader = useSetRecoilState(modalHeader);
  // 테이블 로딩
  const setLoading = useSetRecoilState(loading);
  const [reload, setReload] = useState<number>(0);

  // 상세보기 및 수정
  const updateOpen = useCallback(
    (idx?: number) => {
      if (!!!idx) {
        if (checkedIdxes.length === 0) {
          alert('수정할 개통을 선택해 주세요.');
          return;
        } else if (checkedIdxes.length > 1) {
          alert('수정할 개통을 하나만 선택해 주세요.');
          return;
        }
      }
      setModalHeader('개통');
      setModal(
        <ShopDeviceOpenContainer
          idx={idx}
          close={() => setModal(null)}
          relaod={() => setReload((prev) => prev + 1)}
        />,
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [checkedIdxes],
  );

  // 체크박스
  const onChangeCheckbox = useCallback(
    (idx: number) => {
      if (checkedIdxes.includes(idx)) {
        setCheckedIdxes(
          checkedIdxes.filter((checkedIdx) => checkedIdx !== idx),
        );
        return;
      }
      setCheckedIdxes((idxes) => [...idxes, idx]);
    },
    [checkedIdxes],
  );

  // 테이블 컬럼
  const columns: TableColumnTypes<OpenTypes>[] = useMemo(
    () => [
      {
        Header: '',
        accessor: 'openId',
        disableSortBy: true,
        width: 70,
        Cell: ({ value }) => (
          <input
            type='checkbox'
            defaultChecked={checkedIdxes.includes(value as number)}
            onChange={() => onChangeCheckbox(value as number)}
          />
        ),
      },
      {
        Header: 'No',
        width: 70,
        Cell: ({ row }) => <>{tableData.length - row.index}</>,
      },
      {
        Header: '개통일',
        accessor: 'openTime',
      },
      {
        Header: '고객명',
        accessor: 'customerName',
        disableSortBy: true,
        Cell: ({ row, value }) => (
          <DetailCell
            value={value as string}
            func={() => updateOpen(row.original.openId)}
          />
        ),
      },
      {
        Header: '모델명',
        accessor: 'deviceModelName',
        disableSortBy: true,
      },
      {
        Header: '일련번호',
        accessor: 'deviceSerialNo',
        disableSortBy: true,
        Cell: ({ value }) => <CopyCell value={value} />,
      },
      {
        Header: '판매점',
        accessor: 'store.name',
        disableSortBy: true,
      },
      {
        Header: '개통점',
        accessor: 'agency.name',
        disableSortBy: true,
      },
      {
        Header: '처리자',
        accessor: 'processorName',
        disableSortBy: true,
      },
    ],
    [checkedIdxes, onChangeCheckbox, tableData.length, updateOpen],
  );

  // 개통
  const insertOpen = () => {
    setModalHeader('개통');
    setModal(
      <ShopDeviceOpenContainer
        close={() => setModal(null)}
        relaod={() => setReload((prev) => prev + 1)}
      />,
    );
  };

  // 개통취소
  const openCancel = async () => {
    if (checkedIdxes.length === 0) {
      alert('개통 취소할 재고를 선택해 주세요.');
      return;
    } else if (
      window.confirm(
        `선택한 ${checkedIdxes.length}개의 개통을 취소하시겠습니까?`,
      )
    ) {
      const { config } = await requestSecurePatch(
        apiRoute.pay.open.patchCancel,
        {},
        { openId: checkedIdxes[0] },
        getToken()!,
      );
      if (config.status >= 200 && config.status < 400) {
        alert('성공적으로 개통취소가 완료되었습니다');
        setReload((prev) => prev + 1);
      }
    }
  };

  // 삭제
  const deleteOpen = async () => {
    if (checkedIdxes.length === 0) {
      alert('삭제할 개통을 선택해 주세요');
      return;
    } else if (
      window.confirm(
        `선택한 ${checkedIdxes.length}개의 개통을 삭제하시겠습니까?`,
      )
    ) {
      await Promise.all(
        checkedIdxes.map(
          (idx) =>
            new Promise((resolve, reject) => {
              requestSecureDelete(
                apiRoute.pay.open.deleteOpen + idx,
                {},
                getToken()!,
              ).then((data) => {
                if (data.config.status >= 200 && data.config.status < 400) {
                  resolve(data);
                }
              });
            }),
        ),
      ).then(() => {
        alert('성공적으로 삭제가 완료되었습니다.');
        setReload((prev) => prev + 1);
      });
    }
  };

  // 날짜 직접 변경
  const onChangeDate = (name: string, date: Date) => {
    if (name === 'start') {
      setFilterDate((filterDate) => ({
        ...filterDate,
        start: `${formatDate(date)} 00:00:00`,
      }));
    } else if (name === 'end') {
      setFilterDate((filterDate) => ({
        ...filterDate,
        end: `${formatDate(date)} 23:59:59`,
      }));
    }
  };

  // 날짜 필터링
  const onClickDate = (unit: '전월' | '전일' | '당일' | '당월') => {
    setFilterDate({
      start: updateStartDate(unit),
      end: updateEndDate(unit),
    });
  };

  // 필터링 적용 / 초기화
  const applyFilter = (name: 'apply' | 'reset') => {
    if (name === 'reset') {
      setFilterDate({
        start: getStartDate(),
        end: getEndDate(),
      });
    }
    setReload((prev) => prev + 1);
  };

  const getTableData = useCallback(async () => {
    setLoading(true);

    const { config, data } = await requestSecureGet<{ opens: OpenTypes[] }>(
      apiRoute.pay.open.getOpens +
        `?startDate=${filterDate.start}&endDate=${filterDate.end}`,
      {},
      getToken()!,
    );
    if (config.status >= 200 && config.status < 400) {
      setTableData(data.opens);
    } else {
      setTableData([]);
    }

    setLoading(false);
  }, [filterDate, setLoading, getToken]);

  useEffect(() => {
    getTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  return (
    <PayOpen
      role={role}
      columns={columns}
      tableData={tableData}
      insertOpen={insertOpen}
      openCancel={openCancel}
      updateOpen={updateOpen}
      deleteOpen={deleteOpen}
      startDate={filterDate.start.split(' ')[0]}
      endDate={filterDate.end.split(' ')[0]}
      onChangeDate={onChangeDate}
      onClickDate={onClickDate}
      applyFilter={applyFilter}
    />
  );
};

export default PayOpenContainer;
