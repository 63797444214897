import * as S from "@styles/components/TableViewStyle";
import CTable from "@components/Common/CTable/CTable";
import { TableColumnTypes } from "@typedef/components/Common/CTable/table.column.types";
import { ShopDeviceExcelTypes } from "@typedef/components/Inventory/shop.device.excel.types";
import { CSVLink } from "react-csv";
import { ShopDeviceTypes } from "@typedef/components/Inventory/shop.device.types";

type Props = {
  columns: TableColumnTypes<ShopDeviceTypes>[];
  tableData: ShopDeviceTypes[];
  insertShopDevice: () => void;
  insertUsim: () => void;
  updateShopDevice: (idx?: number) => void;
  deleteShopDevice: () => Promise<void>;
  onChageFilter: (name: string, value: string | number) => void;
  today: string;
  excelData: ShopDeviceExcelTypes[];
  onClickInsertByExcel: () => void;
};

const InventoryPut = ({
  columns,
  tableData,
  insertShopDevice,
  insertUsim,
  updateShopDevice,
  deleteShopDevice,
  today,
  excelData,
  onClickInsertByExcel,
}: Props) => {
  return (
    <S.Main>
      <S.TopBtns>
        <h3>전체 {tableData.length}</h3>
        <S.Btns>
          <S.Btn type="button" onClick={insertShopDevice} className="active">
            재고입고
          </S.Btn>
          {/* <S.Btn type='button' onClick={insertUsim} className='active'>
            유심입고
          </S.Btn> */}
          {/* <S.Btn onClick={onClickInsertByExcel} className="active">
            엑셀입고
          </S.Btn> */}
          <CSVLink
            filename={`입고관리_${today}`}
            data={excelData}
            headers={headers}
            onClick={() => {
              if (excelData.length) {
                return true;
              } else {
                alert("등록된 데이터가 없습니다.");
                return false;
              }
            }}
          >
            <S.Btn type="button" className="excel">
              엑셀다운로드
            </S.Btn>
          </CSVLink>
          <S.Btn type="button" onClick={() => updateShopDevice()}>
            수정
          </S.Btn>
          <S.Btn type="button" onClick={deleteShopDevice}>
            삭제
          </S.Btn>
        </S.Btns>
      </S.TopBtns>
      <CTable
        columns={columns}
        tableData={tableData}
        // filterComponent={FilterBox}
      />
    </S.Main>
  );
};

export default InventoryPut;

// const FilterBox = () => {
//   return (
//     <S.FilterBox>
//       {/* <section>
//         <h4>기간</h4>
//         <button type='button'>당일</button>
//         <button type='button'>전일</button>
//         <button type='button'>당월</button>
//         <button type='button'>전월</button>
//       </section> */}
//       <section>
//         <h4>입고유형</h4>
//         <button type='button'>당일</button>
//         <button type='button'>전일</button>
//         <button type='button'>당월</button>
//         <button type='button'>전월</button>
//       </section>
//       <section>
//         <h4>통신사</h4>
//         <button type='button'>당일</button>
//         <button type='button'>전일</button>
//         <button type='button'>당월</button>
//         <button type='button'>전월</button>
//       </section>
//     </S.FilterBox>
//   );
// };

const headers = [
  { label: "입고일", key: "in_date" },
  { label: "입고유형", key: "category_name" },
  { label: "통신/제조사", key: "telecom_maker_name" },
  { label: "모델명", key: "device_model_name" },
  { label: "색상", key: "device_color" },
  { label: "일련번호", key: "serial_number" },
  { label: "입고가", key: "device_price" },
  { label: "메모", key: "memo" },
];
