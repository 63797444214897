import * as S from '@styles/components/TableViewStyle';
import CTable from '@components/Common/CTable/CTable';
import { TableColumnTypes } from '@typedef/components/Common/CTable/table.column.types';
import { OpenTypes } from '@typedef/components/Pay/Open/open.types';
import CDatePicker from '@components/Common/CDatePicker/CDatePicker';

type Props = {
  role: string;
  columns: TableColumnTypes<OpenTypes>[];
  tableData: OpenTypes[];
  insertOpen: () => void;
  openCancel: () => Promise<void>;
  updateOpen: () => void;
  deleteOpen: () => Promise<void>;
  startDate: string;
  endDate: string;
  onChangeDate: (name: string, date: Date) => void;
  onClickDate: (unit: '당일' | '전일' | '당월' | '전월') => void;
  applyFilter: (name: 'apply' | 'reset') => void;
};

const PayOpen = ({
  role,
  columns,
  tableData,
  insertOpen,
  openCancel,
  updateOpen,
  deleteOpen,
  startDate,
  endDate,
  onChangeDate,
  onClickDate,
  applyFilter,
}: Props) => {
  return (
    <S.Main>
      <S.TopBtns>
        <h3>전체 {tableData.length}</h3>
        {role.includes('AGENCY') && (
          <S.Btns>
            <S.Btn type='button' onClick={insertOpen} className='active'>
              개통
            </S.Btn>
            <S.Btn type='button' onClick={openCancel}>
              개통취소
            </S.Btn>
            <S.Btn type='button' onClick={() => updateOpen()}>
              수정
            </S.Btn>
            <S.Btn type='button' onClick={deleteOpen}>
              삭제
            </S.Btn>
          </S.Btns>
        )}
      </S.TopBtns>
      <CTable
        columns={columns}
        tableData={tableData}
        filterComponent={
          <section>
            <S.FilterBox>
              <h4>기간</h4>
              <section className='inputs'>
                <CDatePicker
                  name='start'
                  selected={startDate}
                  func={onChangeDate}
                  maxDate={endDate}
                />
                <span>~</span>
                <CDatePicker
                  name='end'
                  selected={endDate}
                  func={onChangeDate}
                  minDate={startDate}
                />
              </section>
              <section className='btns'>
                {['전월', '전일', '당일', '당월'].map((unit) => (
                  <button
                    type='button'
                    key={unit}
                    onClick={() =>
                      onClickDate(unit as '전월' | '전일' | '당일' | '당월')
                    }>
                    {unit}
                  </button>
                ))}
              </section>
            </S.FilterBox>
          </section>
        }
        filterFunc={{
          apply: () => applyFilter('apply'),
          reset: () => applyFilter('reset'),
        }}
      />
    </S.Main>
  );
};

export default PayOpen;
