import SetupDealer from '../SetupDealer';
import DealerManageContainer from './DealerManageContainer';
import { useState, useEffect, useMemo, useCallback } from 'react';
import { apiRoute, requestSecureDelete, requestSecureGet } from '@libs/api';
import { useSetRecoilState } from 'recoil';
import { loading, modal, modalHeader } from '@stories/Atom';
import useToken from '@hooks/useToken';
import { TableColumnTypes } from '@typedef/components/Common/CTable/table.column.types';
import { DealerTypes } from '@typedef/components/Setup/Dealer/dealer.types';

// 설정 > 정산점관리 (대리점)
const SetupDealerContainer = () => {
  const { getToken } = useToken();
  // 테이블 데이터
  const [tableData, setTableData] = useState<DealerTypes[]>([]);
  const [checkedIdxes, setCheckedIdxes] = useState<number[]>([]);
  // 모달설정
  const setModal = useSetRecoilState(modal);
  const setModalHeader = useSetRecoilState(modalHeader);
  // 테이블 로딩
  const setLoading = useSetRecoilState(loading);
  const [reload, setReload] = useState<number>(0);

  // 정산점 상세조회 및 수정
  const updateDealer = useCallback(
    (idx?: number) => {
      if (!!!idx && checkedIdxes.length === 0) {
        alert('수정할 정산점를 선택해 주세요.');
        return;
      } else if (!!!idx && checkedIdxes.length > 1) {
        alert('수정할 정산점를 하나만 선택해 주세요.');
        return;
      }
      setModalHeader('정산점 상세조회');
      setModal(
        <DealerManageContainer
          idx={idx ?? checkedIdxes[0]}
          close={() => setModal(null)}
          reload={() => setReload((prev) => prev + 1)}
        />,
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [checkedIdxes],
  );

  // 체크박스
  const onChangeCheckbox = useCallback(
    (idx: number) => {
      if (checkedIdxes.includes(idx)) {
        setCheckedIdxes(
          checkedIdxes.filter((checkedIdx) => checkedIdx !== idx),
        );
        return;
      }
      setCheckedIdxes((idxes) => [...idxes, idx]);
    },
    [checkedIdxes],
  );

  // 테이블 컬럼
  const columns: TableColumnTypes[] = useMemo(
    () => [
      {
        Header: '',
        accessor: 'dealerId',
        disableSortBy: true,
        width: 70,
        Cell: ({ value }: any) => (
          <input
            type='checkbox'
            defaultChecked={checkedIdxes.includes(value)}
            onChange={() => onChangeCheckbox(value)}
          />
        ),
      },
      {
        Header: 'No',
        width: 70,
        Cell: ({ row }: any) => <>{tableData.length - row.index}</>,
      },
      {
        Header: '이름',
        accessor: 'name',
        disableSortBy: true,
        Cell: ({ row, value }: any) => (
          <div
            className='detail'
            onClick={() => updateDealer(row.original.dealerId)}>
            {value}
          </div>
        ),
      },
      {
        Header: '연락처',
        accessor: 'phone',
        disableSortBy: true,
      },
      {
        Header: '팩스',
        accessor: 'fax',
        disableSortBy: true,
      },
      {
        Header: '이메일',
        accessor: 'eamil',
        disableSortBy: true,
      },
      {
        Header: '메모',
        accessor: 'memo',
        disableSortBy: true,
      },
    ],
    [checkedIdxes, onChangeCheckbox, tableData.length, updateDealer],
  );

  // 정산점 등록
  const insertDealer = () => {
    setModalHeader('정산점등록');
    setModal(
      <DealerManageContainer
        close={() => setModal(null)}
        reload={() => setReload((prev) => prev + 1)}
      />,
    );
  };

  // 정산점 삭제
  const deleteDealer = async () => {
    if (checkedIdxes.length === 0) {
      alert('삭제할 정산점을 선택해 주세요.');
      return;
    } else if (
      window.confirm(
        `선택한 ${checkedIdxes.length}개의 정산점을 삭제하시겠습니까?`,
      )
    ) {
      await Promise.all(
        checkedIdxes.map(
          (idx) =>
            new Promise((resolve, reject) => {
              requestSecureDelete(
                apiRoute.setup.dealer.deleteDealer + idx,
                {},
                getToken()!,
              ).then((data) => {
                if (data.config.status >= 200 && data.config.status < 400) {
                  resolve(data);
                }
              });
            }),
        ),
      ).then(() => {
        alert('성공적으로 삭제가 완료되었습니다.');
        setReload((prev) => prev + 1);
      });
    }
  };

  const getTableData = async () => {
    const { config, data } = await requestSecureGet<{ dealers: DealerTypes[] }>(
      apiRoute.setup.dealer.getDealers,
      {},
      getToken()!,
    );
    if (config.status >= 200 && config.status < 400) {
      setTableData(data.dealers);
      setLoading(false);
    } else {
      setTableData([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  return (
    <SetupDealer
      columns={columns}
      tableData={tableData}
      insertDealer={insertDealer}
      updateDealer={updateDealer}
      deleteDealer={deleteDealer}
    />
  );
};

export default SetupDealerContainer;
