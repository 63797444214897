import * as S from '../styles/SetupBasicStyle';
import { faCoins } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FiEdit3 } from 'react-icons/fi';

const PriceInfo = () => {
  return (
    <S.Price>
      <S.Header>
        <S.Circle>
          <FontAwesomeIcon icon={faCoins} />
        </S.Circle>
        <div className='text'>
          <h2>요금정보</h2>
          <span>6월 요금정보입니다. (05-01 ~ 05-31)</span>
        </div>
        <button type='button'>
          <FiEdit3 />
        </button>
      </S.Header>
      <S.Contents>
        <S.RowInputBox>
          <S.Inputs>
            <span>월 재고 수량</span>
            <S.Input type='text' placeholder='월 재고 수량' disabled />
          </S.Inputs>
          <S.Inputs>
            <span>이번 달 사용량</span>
            <S.Input type='text' placeholder='이번 달 사용량' disabled />
          </S.Inputs>
        </S.RowInputBox>
        <S.RowInputBox>
          <S.Inputs>
            <span>청구금액</span>
            <S.Input type='text' placeholder='청구금액' disabled />
          </S.Inputs>
          <S.Inputs>
            <span>이번 달 예상 마감량</span>
            <S.Input type='text' placeholder='이번 달 예상 마감량' disabled />
          </S.Inputs>
        </S.RowInputBox>
      </S.Contents>
    </S.Price>
  );
};

export default PriceInfo;
