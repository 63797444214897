import * as S from '@styles/components/TableViewStyle';
import CTable from '@components/Common/CTable/CTable';
import { TableColumnTypes } from '@typedef/components/Common/CTable/table.column.types';
import { RelatedShopTypes } from '@typedef/components/Setup/Related/related.shop.types';

type Props = {
  columns: TableColumnTypes<RelatedShopTypes>[];
  tableData: RelatedShopTypes[];
  insertRelated: () => void;
  deleteRelated: () => Promise<void>;
};

const SetupRelated = ({
  columns,
  tableData,
  insertRelated,
  deleteRelated,
}: Props) => {
  return (
    <S.Main>
      <S.TopBtns>
        <h3>전체 {tableData.length}</h3>

        <S.Btns>
          <S.Btn type='button' onClick={insertRelated} className='active'>
            거래처요청
          </S.Btn>
          <S.Btn type='button' onClick={deleteRelated}>
            삭제
          </S.Btn>
        </S.Btns>
      </S.TopBtns>
      <CTable columns={columns} tableData={tableData} />
    </S.Main>
  );
};

export default SetupRelated;
