import Board from '../Board';
import BoardManageContainer from './BoardManageContainer';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { apiRoute, requestSecureDelete, requestSecureGet } from '@libs/api';
import useToken from '@hooks/useToken';
import { useSetRecoilState } from 'recoil';
import { loading, modal, modalHeader } from '@stories/Atom';
import { TableColumnTypes } from '@typedef/components/Common/CTable/table.column.types';
import { BoardTypes } from '@typedef/components/Board/board.types';
import DetailCell from '@components/Common/CTable/components/DetailCell';

const BoardContainer = () => {
  const { getToken } = useToken();
  // 테이블 데이터
  const [tableData, setTableData] = useState<BoardTypes[]>([]);
  const [checkedIdxes, setCheckedIdxes] = useState<number[]>([]);
  // 모달설정
  const setModal = useSetRecoilState(modal);
  const setModalHeader = useSetRecoilState(modalHeader);
  // 테이블 로딩
  const setLoading = useSetRecoilState(loading);
  const [reload, setReload] = useState<number>(0);

  // 게시글 상세조회 및 수정
  const updateBoard = useCallback(
    (idx?: number) => {
      if (!!!idx && checkedIdxes.length === 0) {
        alert('수정할 게시글을 선택해 주세요.');
        return;
      } else if (!!!idx && checkedIdxes.length > 1) {
        alert('수정할 게시글을 하나만 선택해 주세요.');
        return;
      }
      setModalHeader('게시글 상세조회');
      setModal(
        <BoardManageContainer
          idx={idx ?? checkedIdxes[0]}
          close={() => setModal(null)}
          reload={() => setReload((prev) => prev + 1)}
        />,
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [checkedIdxes],
  );

  // 체크박스
  const onChangeCheckbox = useCallback(
    (idx: number) => {
      if (checkedIdxes.includes(idx)) {
        setCheckedIdxes(
          checkedIdxes.filter((checkedIdx) => checkedIdx !== idx),
        );
        return;
      }
      setCheckedIdxes((idxes) => [...idxes, idx]);
    },
    [checkedIdxes],
  );

  // 테이블 컬럼
  const columns: TableColumnTypes[] = useMemo(
    () => [
      {
        Header: '',
        accessor: 'boardId',
        disableSortBy: true,
        width: 70,
        Cell: ({ value }: any) => (
          <input
            type='checkbox'
            defaultChecked={checkedIdxes.includes(value)}
            onChange={() => onChangeCheckbox(value)}
          />
        ),
      },
      {
        Header: 'No',
        width: 70,
        Cell: ({ row }: any) => <>{row.index + 1}</>,
      },
      {
        Header: '제목',
        accessor: 'title',
        disableSortBy: true,
        Cell: ({ row, value }: any) => (
          <DetailCell
            value={value}
            func={() => updateBoard(row.original.boardId)}
          />
        ),
      },
      {
        Header: '카테고리',
        accessor: 'boardCategory.name',
        disableSortBy: true,
      },
      {
        Header: '유형',
        accessor: 'subCategory',
        disableSortBy: true,
      },
    ],
    [checkedIdxes, onChangeCheckbox, updateBoard],
  );

  // 게시글 등록
  const insertBoard = () => {
    setModalHeader('게시글 등록');
    setModal(
      <BoardManageContainer
        close={() => setModal(null)}
        reload={() => setReload((prev) => prev + 1)}
      />,
    );
  };

  // 게시글 삭제
  const deleteBoard = async () => {
    if (checkedIdxes.length === 0) {
      alert('삭제할 게시글을 선택해 주세요');
      return;
    } else if (
      window.confirm(
        `선택한 ${checkedIdxes.length}개의 게시글을 삭제하시겠습니까?`,
      )
    ) {
      await Promise.all(
        checkedIdxes.map(
          (idx) =>
            new Promise((resolve, reject) => {
              requestSecureDelete(
                apiRoute.board.deleteBoard + idx,
                {},
                getToken()!,
              ).then((data) => {
                if (data.config.status >= 200 && data.config.status < 400) {
                  resolve(data);
                }
              });
            }),
        ),
      ).then(() => {
        alert('성공적으로 삭제가 완료되었습니다.');
        setReload((prev) => prev + 1);
      });
    }
  };

  const getTableData = async () => {
    const { config, data } = await requestSecureGet<{ boards: BoardTypes[] }>(
      apiRoute.board.getBoards,
      {},
      getToken()!,
    );
    if (config.status >= 200 && config.status < 400) {
      setTableData(data.boards);
      setLoading(false);
    } else {
      setTableData([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  return (
    <Board
      columns={columns}
      tableData={tableData}
      insertBoard={insertBoard}
      updateBoard={updateBoard}
      deleteBoard={deleteBoard}
    />
  );
};

export default BoardContainer;
