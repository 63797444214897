import PayCredit from '../PayCredit';
import { useEffect } from 'react';
import { requestSecureGet } from '@libs/api';
import useToken from '@hooks/useToken';

const PayCreditContainer = () => {
  const { getToken } = useToken();

  const getTableData = async () => {
    const { config, data } = await requestSecureGet('url', {}, getToken()!);
    if (config.status >= 200 && config.status < 400) {
      console.log('data :', data);
    }
  };

  useEffect(() => {
    getTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <PayCredit />;
};

export default PayCreditContainer;
