import { Main, Split, Bottoms } from '@styles/components/ModalBodyStyle';
import * as S from '@styles/components/FormStyle';
import { DepotTypes } from '@typedef/components/Setup/Depot/depot.types';
import Loading from '@components/Common/Loading/Loading';

type Props = {
  type: string;
  inputs: Partial<DepotTypes>;
  splitPhone: {
    phone1: string;
    phone2: string;
    phone3: string;
  };
  onChangeInputs: (e: {
    target: HTMLInputElement | HTMLTextAreaElement;
  }) => void;
  searchZonecode: () => void;
  addressDetailRef: React.RefObject<HTMLInputElement>;
  save: () => Promise<void>;
  loading: boolean;
};

const DepotManage = ({
  type,
  inputs,
  splitPhone,
  onChangeInputs,
  searchZonecode,
  addressDetailRef,
  save,
  loading,
}: Props) => {
  return (
    <Main width={35}>
      {loading && <Loading />}
      <Split>
        <S.InputBox isRow>
          <p>
            입고처명<span>*</span>
          </p>
          <S.Input
            name='name'
            onChange={onChangeInputs}
            defaultValue={inputs.name}
            placeholder='입고처명'
          />
        </S.InputBox>
        <S.InputBox isRow>
          <p>연락처</p>
          <S.ThreeInputs>
            <S.Input
              name='phone1'
              key='inputs_phone1'
              onChange={onChangeInputs}
              value={splitPhone.phone1}
              maxLength={4}
            />
            <S.Input
              name='phone2'
              key='inputs_phone2'
              onChange={onChangeInputs}
              value={splitPhone.phone2}
              maxLength={4}
            />
            <S.Input
              name='phone3'
              key='inputs_phone3'
              onChange={onChangeInputs}
              value={splitPhone.phone3}
              maxLength={4}
            />
          </S.ThreeInputs>
        </S.InputBox>
        <S.InputBox isRow>
          <p>주소</p>
          <S.AddressInputs>
            <S.Row>
              <S.Input
                defaultValue={inputs.zipcode}
                placeholder='우편번호'
                className='zonecode'
                disabled
              />
              <S.Btn type='button' onClick={searchZonecode}>
                우편번호 검색
              </S.Btn>
            </S.Row>
            <S.Input
              name='address'
              onChange={onChangeInputs}
              defaultValue={inputs.address}
              placeholder='주소'
            />
            <S.Input
              name='address_detail'
              onChange={onChangeInputs}
              defaultValue={inputs.address_detail}
              ref={addressDetailRef}
              placeholder='상세 주소'
            />
          </S.AddressInputs>
        </S.InputBox>
        <S.InputBox isRow>
          <p>메모</p>
          <S.Textarea
            name='memo'
            onBlur={onChangeInputs}
            defaultValue={inputs.memo}
          />
        </S.InputBox>
      </Split>
      <Bottoms>
        <button type='button' onClick={save} disabled={!!!inputs.name}>
          입고처 {type}하기
        </button>
      </Bottoms>
    </Main>
  );
};

export default DepotManage;
